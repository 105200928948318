<div class="media-bottombg pt-0">
    <div class="container">
        <div class="media-relasttop">
            <h3>Standard Terms and Conditions for Access and Use of the MatchMove Banking Operating System API (“MMBOS”)
            </h3>
        </div>
        <div class="termsbg">
            <h4><u>Standard MMBOS Terms & Conditions</u></h4>
            <br />

            <p>These standard terms and conditions (“<u><b>Standard MMBOS Ts&amp;Cs</b></u>”) govern the access and use
                of
                the
                MatchMove Banking Operating System(“<b>MMBOS</b>”) by all entities ( “<b>Partner/ You/Your</b>”) who
                have signed the
                MMBOS
                Agreement ( “<b>MMBOS Agreement</b>”) with MatchMove, a company incorporated in Singapore with
                registration
                number
                200902936W having its registered office at 137 Telok Ayer Street, #03-03, Singapore 068602, or
                MatchMove’s
                affiliates in various countries or subsidiaries (the "<b>Provider/Us</b>").</p>

            <br />
            <h4>Background</h4>
            <p> We have developed and provide the proprietary MatchMove Banking Operating System ("<b>MMBOS</b>") as a
                PCI-DSS
                certified platform-as-a-service, which consists of a set of software and hardware technologies, API
                libraries and secure switching connections to other major global networks and financial institutions, in
                addition to relevant technical support and management reporting tools. MMBOS and related products and
                services gives organizations the ability to develop specific digital capabilities for regulatory
                compliant payments, cross-border remittances, lending, insurance and investments (<b>Spend. Send. Lend.
                    Defend. Ascend. TM</b>), and subsequently deploy such capabilities securely to Your Users across the
                internet, and in various form factors, including mobile apps, web pages, wearables and IoT devices,
                amongst others. Users may include Your customers and/or employees, and/or approved business partners
                (“<b>Users</b>”) and as specified in the Agreement </p>

            <br />

            <br />

            <h4>1. Definitions</h4>
            <div class="outer-indent">
                <p>Except to the extent expressly provided otherwise, in these MMBOS Standard Ts&Cs :</p>

                <p>1.1. "Account" means an account enabling a User to access and use
                    the Platform Services, including both administrator accounts and user accounts and for an individual
                    user,
                    includes any account opened for a Minor at the request of the Guardian/Parent and the Minor is
                    represented
                    in all transactions by the Guardian until attainment of majority, i.e. the Guardian/Parent is solely
                    and
                    exclusively liable and responsible for all the charges/expenses/other monies incurred/due and
                    payable.
                </p>

                <p>1.2. "Affiliate" means an entity that Controls, is Controlled by, or is under common Control with the
                    relevant
                    entity and with respect to You, includes only entities that operate under the same brand as You.</p>

                <p>1.3. “AML" means anti money laundering and refers to a set of procedures, laws or regulations
                    designed to
                    stop the
                    practice of generating income through illegal means; </p>

                <p>1.4. “API” means a set of functions and procedures that
                    allow the creation of applications which access the features or data of an operating system,
                    application, or
                    other service; </p>

                <p>1.5. “BIN" means the Bank Identification Numbers which uniquely identifies the bank issuing
                    the virtual or physical card, where such a card may, for example, be a charge card, debit card,
                    credit
                    card,
                    prepaid card or an electronic gift card; </p>

                <p>1.6. "Business Day(s)" means any weekday other than a bank or
                    public holiday in Singapore; </p>

                <p>1.7. "Business Hours" means the hours of 09:00 to 17:00 SGT on a Business Day;</p>

                <p>1.8. "Charges" means the following amounts: </p>
                <div class="indent">
                    <p> 1.8.1 The amounts specified in Schedule 2 of the MMBOS
                        Agreement; and </p>
                    <p> 1.8.2 The amounts as may be agreed in writing by the parties from time to time</p>
                </div>

                <p>1.9. "Control(s)" means the legal power to control directly or indirectly the management of an entity
                    (and "Controlled" should be construed accordingly);</p>

                <p>1.10. "Closed Loop” means transactions on the MMBOS that do not go through a Network;</p>

                <p>1.11. "Confidential Information" means: </p>
                <div class="indent">
                    <p>1.11.1 Any information disclosed by a Party to the other Party
                        during the Term (whether disclosed in writing, orally or otherwise) that at the time of
                        disclosure:
                        <br />
                        (i) Was marked or described as "Confidential"; or
                        <br />
                        (ii) Should have been reasonably understood by the other
                        to be confidential;
                        <br />
                        (iii) Partner Data;
                    </p>

                    <p>1.11.2 The terms of the MMBOS Agreement.</p>
                </div>

                <p>1.12. "Customization" means a customization of the Platform Services, whether made through the
                    development, configuration or integration of software, or otherwise; </p>

                <p>1.13. "Documentation" means the documentation for the Platform Services produced by Us including
                    service
                    level agreements and delivered
                    or made available by Us on our website or otherwise to You; </p>

                <p>1.14. "Effective Date" means the date of
                    execution of the MMBOS Agreement; </p>

                <p>1.15. "Expenses" means the travel, accommodation and subsistence
                    expenses that are reasonably necessary for, and incurred by Us exclusively in connection with the
                    performance of our obligations under these MMBOS Standard Ts&amp;Cs;</p>

                <p>1.16. “Force Majeure Event” has the
                    meaning as defined in Clause 21.1 of these MMBOS Standard Ts&amp;Cs;
                </p>

                <p>1.17. “Guardian/Parent” refers to the
                    legal and/or natural guardian of the Minor;</p>

                <p>1.18. "Indemnity Event" has the meaning given to it in
                    Clause 17.1; </p>

                <p>1.19. "Intellectual Property Rights" means all intellectual property rights wherever in the
                    world, whether registrable or unregistrable, registered or unregistered, including any application
                    or
                    right of application for such rights and these "intellectual property rights" include copyright and
                    related rights, database rights, confidential information, trade secrets, know-how, business names,
                    trade names, trademarks, service marks, passing off rights, unfair competition rights, patents,
                    utility
                    models, and rights in designs;
                </p>
                <p>
                    1.20. “Effective Launch Date” or “Launch Date” means 60 days after the
                    UAT/Pre-Production version of the Platform is made accessible to You, or the public launch of
                    Partner
                    Program, whichever is sooner. For avoidance of doubt, public launch shall mean the day Your first
                    end-user or customer utilises any of the MMBOS platform features in the Production environment
                </p>
                <p>1.21. "Maintenance Services" means the general maintenance of the Platform and Platform Services, and
                    the
                    application of Updates and Upgrades; </p>

                <p>1.22. "Minimum Term" means, in respect of these MMBOS Standard
                    Ts&Cs and the MMBOS Agreement, the period of two (2) years beginning on the Effective Date;</p>

                <p>1.23. “Minor” refers to an infant or person who is under the age of legal competence according to the
                    laws of
                    the country the Minor is residing e.g. in Singapore, under the age of 18 years old. </p>

                <p>1.24. "Network" means a major national or international network such as American Express, Mastercard,
                    Visa, JCB,
                    Discover, UnionPay, Thunes, Moneygram, Wallex, Currrencycloud or any other global network contracted
                    by
                    Provider to switch and settle transactions between merchants and/or users, according to its own
                    standards and protocols; </p>

                <p>1.25. "Open Loop" means transactions on the MMBOS that go through a Network;</p>


                <p>1.26. "Partner Data" means all data, works and materials: uploaded to or stored on the Platform by
                    You;
                    transmitted by the Platform at your instigation; supplied by You to Us for uploading to,
                    transmission by
                    or
                    storage on the Platform;</p>

                <p>1.27. “Partner Mobile App" means the mobile application that is commissioned by You
                    either from Us or from an approved third party mobile app developer provider, and is made available
                    for
                    general distribution by You through the Google Play Store and/or the Apple App Store, and only after
                    obtaining an acceptable security review or VAPT from Us or an accredited third-party; </p>

                <p>1.28. "Partner
                    Personal Data" means Personal Data of a Your User that is processed by Us on behalf of You in
                    relation
                    to
                    these MMBOS Standard Ts&amp;Cs; </p>

                <p>1.29. "Partner Systems" means Your hardware and software systems that interact
                    with, or may reasonably be expected to interact with, the Platform Services; </p>

                <p>1.30. "Platform Access Services" means the configuration of MMBOS for You to access in accordance
                    with
                    Documentation (Platform
                    Service Information); </p>

                <p>1.31. "Platform Services" means all or part of the MMBOS, as specified in the Platform
                    Services Specification, which will be made available by Us to You as a service via secure digital
                    API
                    connections, management and performance reporting tools and technical support services and in
                    accordance
                    with these MMBOS Standard Ts&Cs ; </p>

                <p>1.32. "Platform Services Defect" means a defect, error or bug in the
                    Platform having a material adverse effect on operation, functionality or performance of the Platform
                    Services, but excluding any defect, error or bug caused by or arising as a result of: </p>
                <div class="indent">
                    <p>1.32.1 Any act or
                        omission by You or any person authorized by You to use the Platform or Platform Services; </p>

                    <p>1.32.2 Any use
                        of
                        the Platform or Platform Services contrary to the Documentation, whether by You or by any person
                        authorized
                        by You; </p>

                    <p>1.32.3 A failure on your part to perform or observe any of its obligations in these MMBOS
                        Standard
                        Ts&Cs or the MMBOS Agreement; and/or </p>

                    <p>1.32.4 An incompatibility between the Platform or Platform Services
                        and
                        any other system, network, application, program, hardware or software not specified as
                        compatible in the
                        Platform Services Specification; </p>
                </div>

                <p>1.33. "Platform Services Specification" means the specification for the
                    Platform and Platform Services set out in Documentation Platform Services particulars and described
                    and
                    updated from time to time in the Documentation available at <a target="_blank"
                        href="https://www.matchmove.com/terms">https://www.matchmove.com/terms</a> </p>

                <p>1.34. "Personal Data" has the meaning given to it in any law covering the personal data, including
                    the
                    Singapore Personal
                    Data Protection Act,
                    <a target="_blank"
                        href="https://www.pdpc.gov.sg/Overview-of-PDPA/The-Legislation/Personal-Data-Protection-Act">
                        https://www.pdpc.gov.sg/Overview-of-PDPA/The-Legislation/Personal-Data-Protection-Act
                    </a>
                    and all other country’s privacy laws, including data protection laws and regulations applicable to
                    MatchMove
                    in the performance of the Services under the MMBOS Agreement;

                </p>

                <p>1.35. “Platform” or “MMBOS" means the platform
                    developed and managed by Us and used by You to provide the Platform Services, including the
                    application
                    and
                    database software for the Platform Services, the system and server software used to provide the
                    Platform
                    Services, and the computer hardware on which that application, database, system and server software
                    is
                    installed, the technical documentation and support, and the reporting tools for customer management,
                    business management and infrastructure monitoring, and includes UAT/Pre-Prod and the Live Production
                    System
                    ;
                </p>

                <p>1.36. “Pool Account” means a non-operative current account opened for You by Us with a bank selected
                    by
                    Us.</p>

                <p>1.37. "Provider Representatives" means the person or persons identified as such in Clause 36
                    (Representatives), and any additional or replacement persons that may be appointed by Us giving to
                    You
                    written notice of the appointment;</p>

                <p>1.38. “Regulator” means the central banks or national body/bodies
                    responsible for developing policies relevant to the MMBOS Platform and Services, and enforcing such
                    policies
                    in the Territory. For example, this includes Monetary Authority of Singapore(MAS), Bank Indonesia,
                    Reserve
                    Bank of India, Bangko Sentral ng Pilipinas, and their equivalents in the Territory in which MMBOS is
                    made
                    available to You. </p>

                <p>1.39. “Regulatory” means the policies and laws as directed by the Regulator in the
                    Territory.</p>

                <p>1.40. "Schedule" means any schedule attached to the main body of these MMBOS Standard Ts&Cs
                    and/or the MMBOS Agreement;</p>

                <p>1.41. "Services" means any services that we provide to You, or have an
                    obligation to provide to You, under these MMBOS Standard Ts&Cs and the MMBOS Agreement; </p>

                <p>1.42. "Services Order Form" means an online order form published by Us and available on MatchMove
                    Support
                    Portal
                    (https://support.matchmove.com) and completed and submitted by You in each case incorporating these
                    Terms
                    and Conditions by reference; </p>

                <p>1.43. "Support Services" means support in relation to the use of, and the
                    identification and resolution of errors in, the Platform Services, but shall not include the
                    provision
                    of
                    training services; </p>

                <p>1.44. "Supported Web Browser" means the current release from time to time of Microsoft
                    Internet Explorer, Mozilla Firefox, Google Chrome or Apple Safari, or any other web browser that the
                    Provider agrees in writing shall be supported;</p>

                <p>1.45. "Term" means the term as defined in the MMBOS
                    Agreement; </p>

                <p>1.46. “Territory" means country or countries where the Services in these MMBOS Standard Ts&Cs are
                    valid and available as specified in Schedule 2 of the MMBOS Agreement; </p>

                <p>1.47. "Update" means a hotfix, patch
                    or minor version update to any Platform software; and </p>

                <p>1.48. "Upgrade" means a major version upgrade of any
                    Platform software. </p>

                <p>1.49. “User(s)” shall have the meaning given to it in Recital 2 of these MMBOS Standard
                    Ts&amp;Cs .</p>

                <p>1.50. “VAPT” means the Vulnerability and Penetration Test that must be satisfactorily passed by all
                    your products that connect to the MMBOS Platform. The VAPT must be conducted prior to the initial
                    launch
                    on
                    the Production environment, and thereafter every 12 months, or whenever there is a significant
                    change to
                    the
                    software or protocols of the Your product, whichever is sooner. You may choose Us to conduct such
                    VAPT
                    tests
                    or You may utilise the services of an accredited third party. You remain fully responsible for the
                    quality
                    and security of Your product(s) and software, and under no circumstances will We be liable for any
                    errors or
                    omissions that affect the quality and security of Your product and software.</p>
            </div>
            <h4>2. Platform Access Services </h4>
            <div class="outer-indent">
                <p>2.1. We shall provide You with access to the MMBOS Platform in two distinct stages, both of which
                    must
                    comply with the Terms of the Agreement, including these Ts&Cs: </p>
                <div class="indent">
                    <p>2.1.1. <b>UAT/Pre-Prod environment </b> where You can
                        develop and test your intended products in a closed or sandbox environment with dummy data.
                        Since the
                        UAT/Pre-Prod environment is subject to regular updates, Production Environment level SLAs may
                        not apply.
                        Due to Regulator or Network rules, the UAT/Pre-Prod environment may not contain all the services
                        that
                        would be available in the Production environment.</p>

                    <p>2.1.2.<b> Production Environment </b>, which is the real-world environment where all transactions
                        are considered
                        valid, all data is real, Your Users are on-boarded and all Network, local Regulatory
                        requirements and
                        SLA’s are in full effect. </p>

                    <p> 2.1.3. We shall use all reasonable endeavors to ensure that Platform Access Services are
                        provided
                        according to the initial plan indicated in Schedule [1] of the MMBOS Agreement. </p>
                </div>
                <p>
                    2.2. You acknowledge that a delay in You performing your obligations in these MMBOS Standard Ts&Cs
                    may
                    result in a delay in the performance of the provision of the Platform Access Services and we will
                    not be
                    liable to You in respect of any failure to meet the timetable to the extent that that failure arises
                    out
                    of a delay in You performing your obligations.
                </p>
            </div>


            <h4>3. Platform Services</h4>
            <div class="outer-indent">
                <p>3.1. We hereby grant to You a non-exclusive licence to use the Platform Services in
                    accordance with the Documentation during the Term in the Territory.</p>
                <p>3.2. Except to the extent expressly permitted in these MMBOS Standard Ts&amp;Cs or
                    required by law on a non-excludable basis, the licence granted by Us to You under
                    this Clause 3 is subject to the following prohibitions:
                <div class="indent">
                    <p>3.2.1 You must not sub-license your right to access and use the Platform Services;</p>
                    <p>3.2.2 You must not permit any unauthorized person to access or use the Platform Services;</p>
                </div>
                <p>3.3. You acknowledge and agree that Documentation and local Regulator rules shall
                    govern the availability of the Platform Services.</p>
                <p>3.4. You must comply with and must ensure that all persons using the Platform Services
                    are only with Uour authority or by means of an Account that complies the with Documentation and
                    these
                    Ts&amp;Cs.</p>
                <p>3.5. You must not use the Platform Services in any way that causes, or may cause,
                    damage to the Platform Services or Platform or impairment of the availability or
                    accessibility of the Platform Services.</p>
                <p>3.6. You must not use the Platform Services:</p>
                <div class="indent">
                    <p>3.6.1 In any way that is unlawful, illegal, fraudulent or harmful; or</p>
                    <p>3.6.2 In connection with any unlawful, illegal, fraudulent or harmful purpose or activity.</p>
                </div>
                <p>3.7. For the avoidance of doubt, You have no right to access the software code (including
                    object code, intermediate code and source code) of the Platform, either during or after
                    the Term.</p>
            </div>
            <h4>4. Customisations </h4>
            <div class="outer-indent">
                <p>4.1. We may agree with You during the Term that we shall design, develop and implement
                    Customisations in accordance with a specification and project plan and pricing as agreed in writing
                    by
                    us. </p>

                <p>4.2. All Intellectual Property Rights in the Customisations shall be Our exclusive property (unless
                    the
                    parties agree otherwise in writing). </p>
                <p> 4.3. From the time and date when a Customisation is first delivered or
                    made available by Us to You, the Customisation shall form part of the Platform, and accordingly from
                    that
                    time and date Your right to use the Customisation shall be governed by Clause 3.</p>
            </div>
            <h4>5. Maintenance Services </h4>
            <div class="outer-indent">
                <p>5.1. We shall provide Maintenance Services to You during the Term, in accordance
                    with Documentation.</p>

                <p>5.2. We may suspend the provision of the Maintenance Services if any amount due to be
                    paid by You to Us under these MMBOS Standard Ts&Cs is overdue, and we have given to You at least
                    thirty
                    (30)
                    days' written notice, following the amount becoming overdue, of our intention to suspend the
                    Maintenance
                    Services on this basis.
                </p>
                <p>
                    5.3 Where Updates and Upgrades are required by Regulators or otherwise by applicable
                    laws and regulations, You shall allow and fully assist Us to install such Updates and Upgrades
                    without
                    delay. For other Updates and Upgrades, You shall have 30 days to implement such Updates and
                    Upgrades.
                </p>

            </div>

            <h4>6. Support Services </h4>
            <div class="outer-indent">
                <p>6.1. We shall provide the Support Services to You during the Term in accordance with Documentation.
                </p>

                <p>6.2. We shall provide the Support Services in accordance with the standards of skill and care
                    reasonably
                    expected from a leading service provider in the Provider's industry. </p>

                <p>6.3. We may suspend the provision of the Support Services if any amount due to be paid by You to Us
                    under
                    these MMBOS Standard Ts&amp;Cs is overdue, and we have given to You at least thirty (30) days’
                    written
                    notice, following the amount becoming overdue, of our intention to suspend the Support Services on
                    this
                    basis. </p>
            </div>

            <h4>7. Partner Data </h4>
            <div class="outer-indent">
                <p>7.1. You hereby grant to Us a non-exclusive licence to copy, reproduce, store, distribute,
                    publish, export, adapt, edit and translate the Your Data to the extent reasonably required for the
                    performance of our obligations to Regulators and Networks, enhancing product development, and the
                    exercise
                    of our rights under these MMBOS Standard Ts&amp;Cs . </p>

                <p>7.2. You warrant to Us that the Your Data when used by Us
                    in accordance with these MMBOS Standard Ts&Cs will not infringe the Intellectual Property Rights or
                    other
                    legal rights of any person, and will not breach the provisions of any law, statute or regulation, in
                    any
                    jurisdiction and under any applicable law and to indemnify Us in the event of any breach. </p>

                <p>7.3. We agree not
                    to use any Your Data for our other partners or transfer any Partner Data to our other partners,
                    except
                    licensed partners or any third parties notified to You under the MMBOS Agreement. </p>

                <p>7.4. We may also disclose
                    or transfer Partner Data to Regulator or government bodies and authorities as required by law. You
                    will
                    be
                    informed if such requests are made. </p>
            </div>
            <h4>8. Mobile Apps</h4>
            <div class="outer-indent">
                <p> 8.1 We acknowledge and agree that You may integrate the MMBOS capabilities into an existing or
                    new Partner Mobile App to be developed either: </p>
                <div class="indent">
                    <p>8.1.1. By You; or </p>

                    <p>8.1.2. 10.1.2 At Your cost by an agent
                        appointed by You; or </p>

                    <p>8.1.3. At Your cost and developed by Us.</p>
                </div>
                <p>8.2. The parties acknowledge and agree that in the case of a Partner Mobile App as developed by You
                    or
                    your
                    appointed agent, the parties' respective rights and obligations in relation to the Partner Mobile
                    App
                    and
                    any liabilities of ours arising out of the use of the Partner Mobile App shall be subject to the
                    agreed
                    terms and conditions, and this will not create or impose any liabilities on Us. You will however
                    ensure
                    that
                    the development and usage of the Partner Mobile App is at all times subject to the terms of these
                    MMBOS
                    Standard Ts&Cs . </p>

                <p>8.3. The parties further acknowledge and agree that in the case of a Partner Mobile App
                    developed by Us, the parties' respective rights and obligations in relation to the Partner Mobile
                    App
                    and
                    any liabilities of ours arising out of the use of the MMBOS by the Partner Mobile App shall be
                    subject
                    to
                    the terms and conditions of these MMBOS Standard Ts&Cs, the MMBOS Agreement and any further changes
                    as
                    mutually agreed in writing. </p>
            </div>
            <h4>9. Intellectual Property </h4>
            <div class="outer-indent">
                <p>9.1. You acknowledge and agree that We own all the Intellectual Property of MMBOS
                    including services and the documentation. Except as expressly stated herein, these MMBOS Standard
                    Ts&Cs
                    and
                    the MMBOS Agreement do not grant You any rights to, or in, patents, copyrights database rights,
                    trade
                    secrets, trade names, trademarks (whether registered or unregistered), or any other rights or
                    licences
                    in
                    respect of the services or the documentation. </p>

                <p>9.2. To the extent that any modifications or improvements to
                    the application, services and/or the documentation are carried out under or in connection with these
                    MMBOS
                    Standard Ts&Cs or with the MMBOS Agreement by Us, all intellectual property rights to such
                    underlying
                    ideas
                    and in any resulting improvements or modifications shall be assigned to and shall vest with and be
                    solely
                    owned by Us, unless agreed in writing by both parties. </p>

                <p>9.3. We acknowledge and agree that You retain
                    ownership of all pre-existing Intellectual Property that belongs to or was created by You without
                    any
                    reference to the application, services and/or the documentation carried out under or in connection
                    with
                    these MMBOS Standard Ts&Cs by Us. </p>

                <p>9.4. Nothing in these MMBOS Standard Ts&Cs shall operate to assign or
                    transfer any Intellectual Property Rights from Us to You, or from You to Us. </p>

            </div>
            <h4>10. Charges </h4>
            <div class="outer-indent">
                <p> 10.1. You shall pay the charges to Us in accordance with these MMBOS Standard Ts&Cs and Schedule
                    2 of the MMBOS Agreement. We reserve the right to review the prices mentioned in Schedule 2 of the
                    MMBOS
                    Agreement once per year upon written notice to You and such review may result in increased cost of
                    Services
                    by an amount sufficient to reasonably cover our increased expenses. Discrepancy with this review, if
                    any,
                    shall be raised by You within seven (7) days of receipt of notice of the same. </p>

                <p>10.2. The Parties agree and
                    acknowledge that any amount payable by Us to You (where applicable) under these MMBOS Standard Ts&Cs
                    and
                    the
                    MMBOS Agreement shall be paid as net of all charges due to You as on that respective month. Any
                    shortfall
                    post settlement of net charges shall be paid by You as per the Payment Terms specified in the
                    Agreement.
                </p>

                <p>10.3. For the avoidance of doubt, we may elect to vary any element of the Charges by giving to You
                    not
                    less
                    than thirty (30) days' written notice of the variation on any anniversary of the date of execution
                    of
                    the
                    MMBOS Agreement, providing that no such variation shall result in an aggregate percentage increase
                    in
                    the relevant element of the Charges during the Term that exceeds two (2) % over the percentage
                    increase
                    of the Consumer Price Index in the Territory during the same period. </p>
            </div>
            <h4>11. Payments & Taxes </h4>
            <div class="outer-indent">
                <p> 11.1. We shall issue invoices for the Charges to You from time to time during the Term. </p>
                <p>11.2. All payments shall be made in the mutually agreed upon currency as listed in Schedule 2 of the
                    MMBOS
                    Agreement to the designated bank account indicated in the invoice (issued electronically) within
                    fifteen
                    (15) days by electronic funds transfer.</p>

                <p>11.3. You must pay the Charges by bank transfer or using such
                    payment details as are notified by Us to You from time to time. </p>

                <p>11.4. If You do not pay any amount properly
                    due to Us under these MMBOS Standard Ts&Cs or the MMBOS Agreement, including but not limited to any
                    initial
                    payment required, within thirty (30) days from the date of receipt of the invoice, You shall pay
                    late
                    payment interest of two (2) % per month, effective from the date of late payment, up to and
                    including
                    the
                    date of total payment to Us by You and pro rata in case of a partial month and, we at our discretion
                    may
                    implement a service attenuation plan as may be prepared by Us from time to time, without prejudice
                    to
                    any
                    other rights contained in these MMBOS Standard Ts&amp;Cs . We may, if You fail to pay us any amounts
                    due
                    to us
                    pursuant to these MMBOS Standard Ts&Cs or the MMBOS Agreement on the due date, offset such amounts
                    against
                    any other amounts owed by Us to You under these MMBOS Standard Ts&Cs, the MMBOS Agreement or any
                    other
                    agreement(s). </p>

                <p>11.5. Except as specifically prescribed in these MMBOS Standard Ts&Cs or the MMBOS Agreement,
                    each Party is separately responsible for their own taxes payable in connection with these MMBOS
                    Standard
                    Ts&Cs and the MMBOS Agreement. </p>

                <p>11.6. All sums payable (if any) shall be paid in full, free of all deductions
                    and withholdings of any kind except where any deduction or withholding is required by law. </p>

                <p>11.7. The Charges
                    excludes applicable taxes. Where applicable tax is payable, You agrees that the Charges will be
                    increased by
                    an amount equivalent to the taxes as applicable. </p>

                <p>11.8. If any service provider or vendor of ours involved in
                    the services under these MMBOS Standard Ts&Cs and the MMBOS Agreement introduces or increases a new
                    fee
                    or
                    increase their fees/charges to us, we shall be entitled to increase the Charges accordingly by
                    notifying
                    You
                    thirty (30) day in advance. </p>

            </div>
            <h4> 12. Confidentiality obligations </h4>
            <div class="outer-indent">
                <p>12.1. The Parties must:</p>
                <div class="indent">
                    <p>12.1.1. Keep the other party's Confidential
                        Information including access granted to development portal and any access to other linked
                        contents or
                        platforms strictly confidential; and</p>

                    <p>12.1.2. Not disclose the other party's Confidential Information to any
                        person without the other party’s prior written consent. </p>
                </div>
                <p>12.2. This Clause 12 imposes no obligations upon a
                    party with respect to the other party’s Confidential Information that: </p>
                <div class="indent">
                    <p>12.2.1. Is known to the party before
                        disclosure under these MMBOS Standard Ts&Cs and the MMBOS Agreement and is not subject to any
                        other
                        obligation of confidentiality; or </p>

                    <p>12.2.2. Is or becomes publicly known through no act or default of a
                        party. </p>
                </div>

                <p>12.3. The restrictions in this Clause 12 do not apply to the extent that any party’s Confidential
                    Information is required to be disclosed by any law or regulation, by any judicial or governmental
                    order
                    or
                    request, or pursuant to disclosure requirements relating to the listing of the stock of a party on
                    any
                    recognised stock exchange. </p>

                <p>12.4. We agree not to use any of Your Confidential Information for our other
                    partners or transfer any of Your Confidential Information to Our other partners, except licensed
                    partners or
                    any third parties notified to You under the MMBOS Agreement. </p>

                <p>12.5. Upon the termination of the MMBOS
                    Agreement each party must immediately cease to use the other party’s Confidential Information. </p>

                <p>12.6. Within
                    ten (10) Business Days following the date of termination of the MMBOS Agreement, each party must
                    destroy
                    or
                    return to the other party (at the other party’s option) all media containing Confidential
                    Information
                    and
                    must irrevocably delete the other parties Confidential Information from its computer systems, unless
                    otherwise required by regulators or local laws. </p>

                <p>12.7. The provisions of this Clause 12 shall continue in
                    force for a period of three (3) years following the termination of the MMBOS Agreement.</p>
            </div>
            <h4>13. Publicity </h4>
            <div class="outer-indent">
                <p>13.1. You may not make any public disclosures relating to these MMBOS Standard Ts&Cs and the
                    MMBOS Agreement whether to Our (but not limited to) banking partners, technology partner, Network or
                    in
                    relation to the subject matter of these MMBOS Standard Ts&Cs and the MMBOS Agreement (including
                    disclosures
                    in press releases, public announcements and marketing materials) without Our prior written consent,
                    such
                    consent shall not to be unreasonably withheld or delayed if applicable. Any breach of this provision
                    by
                    You
                    shall without prejudice require You to immediately rectify the breach by issuing such corrigendum as
                    may
                    be
                    directed by Us and pay per instance a cost equal to one month’s fees payable to Us under these MMBOS
                    Standard Ts&amp;Cs . </p>

                <p>13.2. Nothing in this Clause 13 shall be construed as limiting the obligations of the
                    parties under Clause 12.</p>
            </div>


            <h4>14. Data protection </h4>

            <div class="outer-indent">

                <p>14.1. You warrant to Us that you have the legal right to disclose all Personal Data
                    disclosed to You under or in connection with these MMBOS Standard Ts&Cs and the MMBOS Agreement.
                </p>

                <p>14.2. We
                    warrant to You that: </p>
                <div class="indent">
                    <p>14.2.1. We have in place appropriate security measures (both technical and
                        organisational) against unlawful or unauthorized processing of Partner Personal Data and against
                        loss or
                        corruption of Partner Personal Data;</p>

                    <p>14.2.2. We will only process the Partner Personal Data for the purposes
                        of performing its obligations and exercising its rights under these MMBOS Standard Ts&amp;Cs;
                        and </p>

                    <p>14.2.3. We
                        will process the Partner Personal Data in compliance with all applicable laws. </p>
                </div>
                <p>14.3. We shall ensure that
                    access to the Partner Personal Data is limited to only those personnel of Ours who have a reasonable
                    need to
                    access the Partner Personal Data to enable Us to perform our duties under these MMBOS Standard
                    Ts&amp;Cs
                    . </p>

                <p>14.4.
                    We agree not to use any Partner Personal Data for our other partners or transfer any Partner
                    Personal
                    Data
                    to our other partners, except licensed partners or any third parties notified to You under the MMBOS
                    Agreement. We may also be required to disclose or transfer Partner Personal Data to government
                    bodies
                    and
                    authorities as required by law. You will be informed if this is required. </p>
            </div>
            <h4>15. Warranties</h4>
            <div class="outer-indent">
                <p>15.1.Each party warrants that: </p>
                <div class="indent">
                    <p>15.1.1. The party has the legal right and authority to enter
                        into the MMBOS Agreement and these MMBOS Standard Ts&Cs and to perform its obligations under the
                        MMBOS
                        Agreement and these MMBOS Standard Ts&amp;Cs ;</p>

                    <p>15.1.2. The party will comply with all applicable legal and
                        regulatory requirements, regulations, notices, and guidelines issued by the relevant government
                        and
                        regulatory body and relevant licensed financial institution of the country where the Platform
                        Services
                        are
                        being provided, including the applicable laws on Prevention of Money Laundering and Countering
                        the
                        Financing
                        of Terrorism and KYC regulations of the country; and </p>

                    <p>15.1.3. The party has access to all necessary know-how,
                        expertise and experience to perform its obligations under these MMBOS Standard Ts&amp;Cs. </p>
                </div>
                <p>15.2. Each party
                    warrants to the other party that the Platform Services, when used in accordance with the MMBOS
                    Agreement
                    and
                    these MMBOS Standard Ts&Cs, will not breach any laws, statutes or regulations applicable under local
                    law
                    in
                    the Territory. </p>

                <p>15.3. We warrant to You that: </p>
                <div class="indent">
                    <p>15.3.1. The Platform and Platform Services will conform in all
                        material respects with the Platform Services Specification; </p>

                    <p>15.3.2. The Platform will incorporate security
                        features reflecting the requirements of good industry practice.</p>
                </div>

                <p>15.4. You warrant to Us that: </p>
                <div class="indent">
                    <p>15.4.1. Your
                        software and services that utilise the MMMBOS Platform and Platform Services will conform in all
                        material
                        respects with the Platform Services Specification; </p>

                    <p>15.4.2 Your software and services will incorporate
                        security features reflecting the requirements of good industry practice. </p>
                </div>

                <p>15.5. All of the parties'
                    warranties and representations in respect of the subject matter of the MMBOS Agreement and these
                    MMBOS
                    Standard Ts&amp;Cs are expressly set out in the MMBOS Agreement and these MMBOS Standard Ts&amp;Cs
                    respectively. To
                    the maximum extent permitted by applicable law, no other warranties or representations concerning
                    the
                    subject matter of the MMBOS Agreement and these MMBOS Standard Ts&Cs will be implied into the MMBOS
                    Agreement and these MMBOS Standard Ts&amp;Cs or any related contract. </p>
            </div>
            <h4>16. Acknowledgements and warranty limitations </h4>
            <div class="outer-indent">
                <p>

                    16.1. You acknowledge that complex software is never wholly
                    free from defects, errors and bugs; and subject to the other provisions of the MMBOS Agreement and
                    these
                    MMBOS Standard Ts&Cs , we give no warranty or representation that the Platform Services will always
                    be
                    wholly free from defects, errors and bugs. </p>

                <p>16.2. You acknowledge that complex software is never entirely
                    free from security vulnerabilities; and subject to the other provisions of the MMBOS Agreement and
                    these
                    MMBOS Standard Ts&Cs, we give no warranty or representation that the Platform Services will always
                    be
                    entirely secure. </p>

                <p>16.3. You acknowledge that the Platform Services are designed to be compatible only with
                    that software and those systems specified as compatible in the Platform Services Specification; and
                    we
                    do
                    not warrant or represent that the Platform Services will be compatible with any other software or
                    systems.
                </p>

            </div>

            <h4>17. Indemnities</h4>
            <div class="outer-indent">
                <p>17.1. Each party shall indemnify and shall keep indemnified the other party against any and
                    all liabilities, damages, losses, costs and expenses (including legal expenses and amounts
                    reasonably
                    paid
                    in settlement of legal claims) suffered or incurred by the other party and arising directly or
                    indirectly as
                    a result of any breach by a party to the MMBOS Agreement or these MMBOS Standard Ts&amp;Cs
                    ("Indemnity
                    Event"). </p>

                <p>17.2. Each party must: </p>
                <div class="indent">
                    <p> 17.2.1. Upon becoming aware of an actual or potential Indemnity Event, notify the
                        other party; </p>

                    <p>17.2.2. Provide the other party with all such assistance as may be reasonably requested by the
                        other party in relation to the Indemnity Event; </p>

                    <p>17.2.3. Allow the other party the exclusive conduct of all
                        disputes, proceedings, negotiations and settlements with third parties relating to the Indemnity
                        Event;
                        and; </p>

                    <p>17.2.4. Not admit liability to any third party in connection with the Indemnity Event or settle
                        any
                        disputes
                        or proceedings involving a third party and relating to the Indemnity Event without the prior
                        written
                        consent
                        of the other party </p>
                </div>
            </div>

            <h4>18. Limitations and exclusions of liability </h4>
            <div class="outer-indent">

                <p>18.1. Except as expressly and specifically provided in this
                    agreement: </p>
                <div class="indent">
                    <p> 18.1.1. You assume sole responsibility for results obtained from the use of the Services and the
                        Documentation by You, and for conclusions drawn from such use. We shall have no liability for
                        any damage
                        caused by errors or omissions in any information, instructions or scripts provided to Us by You
                        in
                        connection with the Services, or any actions taken by Us at your direction; </p>

                    <p>18.1.2. all warranties,
                        representations, conditions and all other terms of any kind whatsoever implied by statute or
                        common law
                        are,
                        to the fullest extent permitted by applicable law, excluded from this agreement; and </p>

                    <p>18.1.3. the Services
                        and the Documentation are provided to You on an "as is" basis. </p>
                </div>
                <p>18.2. Nothing in this agreement excludes the
                    liability of Us: </p>
                <div class="indent">
                    <p>18.2.1. for death or personal injury caused by our negligence; or </p>

                    <p>18.2.2. for fraud or
                        fraudulent misrepresentation. </p>
                </div>
                <p>18.3. Subject to clause 18.1 and clause: </p>
                <div class="indent">
                    <p>18.3.1. we shall not be liable
                        whether in tort (including for negligence or breach of statutory duty), contract,
                        misrepresentation,
                        restitution or otherwise for any loss of profits, loss of business, depletion of goodwill and/or
                        similar
                        losses or loss or corruption of data or information, or pure economic loss, or for any special,
                        indirect
                        or
                        consequential loss, costs, damages, charges or expenses however arising under this agreement;
                        and </p>

                    <p>
                        18.3.2. our total aggregate liability in contract (including in respect of the indemnity at
                        clause 17,
                        tort (including negligence or breach of statutory duty), misrepresentation, restitution or
                        otherwise,
                        arising in connection with the performance or contemplated performance of this agreement shall
                        be
                        limited to the total amount paid by You to Us under the MMBOS Agreement and these MMBOS Standard
                        Ts&Cs
                        in the Twelve (12) month period preceding the commencement of the event or events.
                    </p>
                </div>
                <p>18.4. However, in the event of a dispute reaching to the Court or arbitration, as the case may be, it
                    is
                    agreed that the aggregate liability of each party to the other party under these the MMBOS Agreement
                    and
                    MMBOS Standard Ts&amp;Cs shall not exceed the greater of: </p>
                <div class="indent">
                    <p>18.4.1. The total amount paid and payable by You to Us
                        up to the date that those liabilities are confirmed by court/arbitration centre. </p>

                    <p>18.4.2. The total amount
                        paid and payable by Us to You up to the date that those liabilities are confirmed by
                        court/arbitration
                        centre. </p>
                </div>
                <p> 18.5. Nothing in this agreement excludes the liability of the Partner for any breach, infringement
                    or misappropriation of the Provider’s Intellectual Property Rights”. </p>

            </div>

            <h4>19. Non-solicitation of personnel </h4>
            <div class="outer-indent">
                <p>Either party must not, without the prior written consent of the party,
                    either during the Term or within the period of six (6) months following the end of the Term, engage,
                    employ
                    or solicit for engagement or employment any employee or subcontractor of the other who has been
                    involved
                    in
                    any way in the negotiation or performance of the MMBOS Agreement or these MMBOS Standard Ts&amp;Cs .
                </p>

            </div>
            <h4> 20. Assignment </h4>
            <div class="outer-indent">
                <p>20.1. Either party must not assign, transfer or otherwise deal with the contractual rights
                    and/or obligations under the MMBOS Agreement or these MMBOS Standard Ts&Cs without the prior written
                    consent
                    of other Party, such consent not to be unreasonably withheld or delayed, provided that each party
                    may
                    assign
                    the entirety of its rights and obligations under the MMBOS Agreement and these MMBOS Standard Ts&Cs
                    to
                    any
                    Affiliate of said party or to any successor to all or a substantial part of the business of the said
                    party
                    from time to time. </p>
            </div>

            <h4>21. ForceMajeure: </h4>
            <div class="outer-indent">
                <p>
                    21.1. The Parties shall have no liability to the other Party under this agreement if it is prevented
                    from or delayed in performing its obligations under this agreement, or from carrying on its
                    business, by
                    acts, events, omissions or accidents beyond its reasonable control, including, without limitation,
                    strikes, lock-outs or other industrial disputes (whether involving the workforce of the Supplier or
                    any
                    other party), failure of a utility service or transport or telecommunications network, act of God,
                    war,
                    riot, civil commotion, malicious damage, compliance with any law or governmental order, rule,
                    regulation
                    or direction, accident, breakdown of plant or machinery, fire, flood,storm or default of suppliers
                    or
                    sub-contractors (“<b>Force Majeure Event</b>”), provided that the other Party is notified of such an
                    event and its expected duration.
                </p>

                <p>
                    21.2. The Parties hereby acknowledge that while current events related to the COVID-19 pandemic are
                    known, future impacts of the outbreak are unforeseeable and shall be considered a Force Majeure
                    Event to
                    the extent that they prevent the performance of a Party's obligations under this Agreement.
                </p>

                <p>
                    21.3. Notwithstanding the foregoing, should a Force Majeure Event suffered by a Party extend beyond
                    three (3) monthsperiod, the other Party may then terminate these MMBOS Standard Ts&Cs and the MMBOS
                    Agreement, by prior written notice of 3 (three) days to the non-performing party.
                </p>
            </div>
            <h4>22. No waivers </h4>
            <div class="outer-indent">
                <p>
                    22.1. No breach of any provision of the MMBOS Agreement or these MMBOS Standard Ts&Cs will be waived
                    except with the express written consent of the party not in breach.
                </p>
                <p>
                    22.2. No waiver of any breach of any provision of the MMBOS Agreement or these MMBOS Standard Ts&Cs
                    shall be construed as a further or continuing waiver of any other breach of that provision or any
                    breach
                    of any other provision of the MMBOS Agreement or these MMBOS Standard Ts&amp;Cs.
                </p>
            </div>
            <h4>23. Severability</h4>
            <div class="outer-indent">
                <p>
                    23.1. If a provision of the MMBOS Agreement or these MMBOS Standard Ts&Cs is determined by any court
                    or
                    other competent authority to be unlawful and/or unenforceable, the other provisions will continue in
                    effect. The parties shall use reasonable endeavours and good faith to agree upon a new provision
                    that
                    shall as nearly as possible have the same commercial effect as the void, invalid, illegal or
                    unenforceable provision.
                </p>

                <p>
                    23.2. If any unlawful and/or unenforceable provision of the MMBOS Agreement or these MMBOS Standard
                    Ts&Cs would be lawful or enforceable if part of it were deleted, that part will be deemed deleted,
                    and
                    the rest of the provisions will continue in effect.
                </p>
            </div>

            <h4>24. Third party rights </h4>
            <div class="outer-indent">
                <p>24.1. The MMBOS Agreement and these MMBOS Standard Ts&Cs are for the benefit of the parties and are
                    not
                    intended to benefit or be enforceable by any third party. </p>

                <p> 24.2. The exercise of the parties' rights under the MMBOS Agreement and these MMBOS Standard
                    Ts&amp;Cs
                    is not subject to the consent of any third party. </p>

                <p>24.3. A person who is not a party to the MMBOS Agreement or these MMBOS Standard Ts&amp;Cs shall have
                    no
                    right under the Contracts (Rights of Third Parties) Act to enforce any term of the MMBOS Agreement
                    or
                    these MMBOS Standard Ts&amp;Cs . </p>
            </div>

            <h4> 25. Variation </h4>
            <div class="outer-indent">

                <p>The MMBOS Agreement and these MMBOS Standard Ts&Cs may not be varied except by means of a written
                    document signed by or on behalf of each party. </p>

            </div>

            <h4>26. No Partnership or Joint Venture </h4>

            <div class="outer-indent">

                <p>Nothing in the MMBOS Agreement or these MMBOS Standard Ts&Cs shall constitute or be construed as
                    constituting a partnership or joint venture between the parties or shall authorise one party to
                    enter
                    into contractual relationship or incur obligations on behalf of the other party. </p>

            </div>
            <h4>
                27. Entire agreement </h4>
            <div class="outer-indent">
                <p>
                    27.1. The main body of these MMBOS Standard Ts&Cs and the Schedules are incorporated into the MMBOS
                    Agreement signed by the parties and shall constitute the entire agreement between the parties in
                    relation to the subject matter of these MMBOS Standard Ts&Cs and the MMBOS Agreement, and shall
                    supersede all previous agreements, arrangements and understandings between the parties in respect of
                    that subject matter.
                </p>
                <p>
                    27.2. Neither party will have any remedy in respect of any misrepresentation (whether written or
                    oral)
                    made to it upon which it relied in entering into these MMBOS Standard Ts&amp;Cs.
                </p>
            </div>

            <h4>28. Law and Dispute Resolution </h4>
            <div class="outer-indent">
                <p> 28.1. These MMBOS Standard Ts&Cs and all amendments (if any) thereto shall be governed exclusively
                    by
                    and construed in accordance with the substantive laws of the Territory. </p>

                <p>
                    28.2. Any disputes relating to
                    these MMBOS Standard Ts&amp;Cs shall be subject to Arbitration. </p>

                <p>28.3. If there is any dispute in connection
                    with these MMBOS Standard Ts&amp;Cs , the parties will resolve the dispute through negotiation and
                    conciliation. In case the negotiation and conciliation are not successful, the dispute shall be
                    referred
                    by either party and finally settled by the Singapore International Arbitration Centre in Singapore.
                    The
                    arbitration panel shall include three (3) arbitrators to be appointed in accordance with the
                    Arbitration
                    Rules of the Singapore International Arbitration Centre (‘SIAC’). The arbitration shall take place
                    in
                    Singapore and the language of the arbitration shall be English. </p>

            </div>

            <h4>29. Interpretation</h4>
            <div class="outer-indent">
                <p>
                    29.1. References in these MMBOS Standard Ts&Cs to "calendar months" are to the 12 named periods
                    (January, February and so on) into which a year is divided.
                </p>
            </div>
            <h3>SCHEDULE X: ROLES AND RESPONSIBILITIES OF PARTIES </h3>

            <h4>1. Partner Roles and Responsibilities</h4>

            <p>You shall: </p>

            <div class="outer-indent">

                <p>1.1. Appoint a dedicated and suitably qualified project manager and project team to assist Us in
                    the implementation of MMBOS </p>

                <p>1.2. Comply with Our and Network brand guidelines for the use of logo and
                    trademarks, where applicable; </p>

                <p>1.3. Operate a call centre or service centre for Your end users, unless agreed
                    otherwise in writing; </p>

                <p>1.4. Handle fraud management, financial reporting/settlement, reconciliation
                    settlement and customer management as instructed by Us; </p>

                <p>1.5. Operate and execute KYC and AML monitoring in
                    compliance with the requirements from Us according to the rules of the Network(s) and local
                    regulations. </p>

                <p>1.6. Provide and establish secure communication channels to access Our MMBOS features and functions,
                    including but not limited to SSL, VPN, inter-country connects etc. </p>

                <p>1.7. Promptly provide Us with copies of
                    original settlement statements and transactions if not covered by Our systems, if necessary, and in
                    order to
                    determine revenues, expenses and profits; </p>

                <p>1.8. Provide Us within Fourteen (14) days with information
                    required for regulatory purposes e.g. taxation, compliance, AML and other regulatory requirements;
                </p>

                <p>1.9. Provide Customer Service and online interface if instructed by Us; and </p>

                <p> 1.10. Provide prefund and collateral
                    where if necessary for Partner program purposes, as described in Schedule 2 (Pricing Schedule). You
                    may use
                    Our name and logo to identify You as a Partner of Ours or as set forth in the Agreement. Your use of
                    Our
                    name and logo does not create any ownership right therein and all rights granted to You are reserved
                    by Us. </p>
            </div>

            <h4>2.Provider Roles and Responsibilities</h4>

            <p>We shall: </p>

            <div class="outer-indent">

                <p>
                    1.1. Ensure setup, implementation and operation of MMBOS in accordance with Service Level Agreements
                    (“SLA”) that is part of these MMBOS Standard Ts&amp;Cs .
                </p>

                <p>
                    1.2. Provide both UAT/Pre-Prod and Production environments for Your integration into the MMBOS
                    during implementation phase.
                </p>

                <p>
                    1.3. Provide You with helpdesk contact and support for UAT/Pre-Prod and Production environment
                    issues according to the SLA attached to these MMBOS Standard Ts&amp;Cs
                </p>

                <p>
                    1.4 Follow the your standard design manual for the use of the Your logo and name or contact You for
                    approval of the same.
                </p>



            </div>
            <p>
                We may use your name and logo to identify You as a customer of ours or as set forth in the Agreement.
                Our use of the name and logo does not create any ownership right therein and all rights granted to Us
                are reserved by You.
            </p>

        </div>
    </div>
</div>