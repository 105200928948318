<div>
    <div class="bannerbg corporatebannerbg">
        <div class="container">
            <div class="row">
                <div class="col-lg-5">
                    <div class="bannertext mt-4">
                        <h1>Reduce Your Corporate Expense Management Costs By More Than 30%</h1>
                        <p class="mt-4">Reduce time, effort, and overheads of manual cash processing and bring efficiency to your business by digitizing corporate expense management.</p>
                    </div>
                </div>
                <div class="col-lg-7">
                    <div class="bannerimg rellax" data-rellax-speed="2" data-rellax-xs-speed="-10" data-rellax-mobile-speed="3" data-rellax-tablet-speed="-8">
                        <img src="assets/images/corporate-expense-management.png" alt="Corporate expense management" />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="corporate-bottombg">
        <div class="container">
            <div class="row">
                <div class="col-lg-6">
                    <div class="corporate-bottombg-box">
                        <span><img src="assets/images/the-problam.png" alt="The problam" /></span>
                        <h3>The Problem</h3>
                        <p>Expense management today is often cumbersome and time consuming, with manual form filling and missing receipts leading to unnecessary waste of time and money.</p>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="corporate-bottombg-box">
                        <span><img src="assets/images/matchmove-solution.png" alt="MatchMove Solution" /></span>
                        <h3>MatchMove Solution</h3>
                        <p>Corporates use MatchMove to digitize salaries, incentives, and expense payouts to employees and vendors.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="corporate-key-benefits">
        <div class="container">
            <h1>Key Benefits</h1>
            <div class="row">
                <div class="col-md-6 col-lg-3">
                    <div class="corporate-key-benefits-box">
                        <img src="assets/images/corporate-key-benefits-icon1.png" alt="Key benefits" />
                        <h3>Faster And More Efficient Way To Manage Salary, Incentives, And Expense Payouts</h3>
                        <p>Multipurpose corporate expense cards help to digitize and manage business funds across categories; from payroll cards to travel cards, all within one wallet.</p>
                    </div>
                </div>
                <div class="col-md-6 col-lg-3">
                    <div class="corporate-key-benefits-box">
                        <img src="assets/images/corporate-key-benefits-icon2.png" alt="Key benefits" />
                        <h3>Facilitate A Better User Experience</h3>
                        <p>Digital wallets and open loop cards provide a frictionless payment solution.</p>
                    </div>
                </div>
                <div class="col-md-6 col-lg-3">
                    <div class="corporate-key-benefits-box">
                        <img src="assets/images/corporate-key-benefits-icon3.png" alt="Key benefits" />
                        <h3>Analyze And Monitor Employee Spend Patterns</h3>
                        <p>Real-time analytics of employee spend patterns helps corporates track and manage expenses better.</p>
                    </div>
                </div>
                <div class="col-md-6 col-lg-3">
                    <div class="corporate-key-benefits-box">
                        <img src="assets/images/corporate-key-benefits-icon4.png" alt="Key benefits" />
                        <h3>Earn More Money</h3>
                        <p>Corporates earn money every time users spend using their corporate expense card.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="go-digital-bg mb-5">
        <div class="container">
            <h1 class="headingsty02">Use Case</h1>
            <div class="go-digital-bg-inner usecase-bg-inner">
                <div class="row">
                    <div class="col-md-6 pr-0">
                        <div class="go-digital-img"><img src="assets/images/usecaseimg.jpg" alt="Use case" /></div>
                    </div>
                    <div class="col-md-6 pl-0">
                        <div class="usecase-bg-inner-content">
                            <h2>SPEND MANAGEMENT PLATFORM</h2>
                            <p class="mt-3 mb-2"><img src="assets/images/spenmo-logo.svg" class="xpensiologo" alt="Xpensio" /></p>
                            <h4>Spend management with smart corporate cards</h4>
                            <h3>Benefits</h3>
                            <ul>
                                <li>Spend approvals and real-time visibility</li>
                                <li>Automated accounting system</li>
                                <li>Reduce manual reconciliation and increase productivity</li>
                            </ul>
                            <p class="mt-3 mb-0 text-left"><a href="success-story" class="btnstyl1">Learn more</a></p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="go-digital-bg-inner usecase-bg-inner mt-5">
                <div class="row">
                    <div class="col-md-6 pr-0">
                        <div class="go-digital-img"><img src="assets/images/expense-management-case2.jpg" alt="Expense management" /></div>
                    </div>
                    <div class="col-md-6 pl-0">
                        <div class="usecase-bg-inner-content" style="padding: 25px 50px;">
                            <h2>SUBSCRIPTION MANAGEMENT PLATFORM</h2>
                            <p class="mt-3"><img src="assets/images/subtralogo-black.png" class="xpensiologo" alt="Subtra" /></p>
                            <h4>Purchase and manage business subscriptions, from a single window.</h4>
                            <h3>Benefits</h3>
                            <ul>
                                <li>Centralized subscription management</li>
                                <li>Track and project your subscription spend</li>
                                <li>Dynamic spend control with flexible card limits</li>
                            </ul>
                            <p class="mt-3 mb-0 text-left"><a href="success-story" class="btnstyl1">Learn more</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
