<div>
    <div class="bannerbg">
        <div class="container">
            <div class="row">
                <div class="col-lg-5">
                    <div class="bannertext mt-5">
                        <h1>A Bank in Any App<sup>TM</sup></h1>
                        <p>MatchMove makes it easy to put essential financial services in any app with our <em>Spend.Send.Lend.</em><sup style="font-size: 9px; top: -13px;">TM</sup>solutions.</p>
                        <p class="my-3"><a href="contact" class="btnstyl1">Contact Us</a></p>
                    </div>
                </div>
                <div class="col-lg-7">
                    <div class="bannerimg rellax" data-rellax-speed="5" data-rellax-xs-speed="-5" data-rellax-mobile-speed="3" data-rellax-tablet-speed="-8"><img src="assets/images/banner.png" alt="Home banner" /></div>
                </div>
            </div>
        </div>
    </div>
    <div class="easy-integration-midrow">
        <div class="container">
            <div class="row">
                <div class="col-lg-3">
                    <div class="easy-integration-midrowtext">
                        <h1>Customers and partners in more than 6 countries</h1>
                    </div>
                </div>
                <div class="col-lg-9">
                    <div class="userlogos">
                        <owl-carousel [options]="carouselOptions" [carouselClasses]="['owl-theme', 'row', 'sliding']">
                            <div class="item" *ngFor="let image of images;let i= index"><img class="slide-image" src="{{image.image}}" alt="{{image.alternate}}" /></div>
                        </owl-carousel>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="easy-integration-bg">
        <div class="container">
            <div class="easy-integration-toprow">
                <div class="row">
                    <div class="col-md-12">
                        <h1>Easy integration with platforms and marketplaces</h1>
                    </div>
                    <div class="col-md-12">
                        <div id="integrationlogos">
                            <div class="integrationlogosbox"><img src="assets/images/php-logo.png" alt="Php" /></div>
                            <div class="integrationlogosbox"><img src="assets/images/logo2.png" alt="Web" /></div>
                            <div class="integrationlogosbox"><img src="assets/images/ios-logo.png" alt="IOS" /></div>
                            <div class="integrationlogosbox"><img src="assets/images/wp-logo.png" alt="Wp" /></div>
                            <div class="integrationlogosbox"><img src="assets/images/andriod-logo.png" alt="Andriod" /></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="security-compliance">
        <div class="container">
            <div class="row">
                <div class="col-md-5">
                    <div class="security-compliance-content">
                        <h1>Security and compliance at the core</h1>
                        <p>We use best-in-class security tools, technology, and banking industry practices to maintain the highest levels of security.</p>
                        <p>Our services are fully compliant and licensed to operate within national and international regulations.</p>
                        <p><img src="assets/images/licensedby02.png" alt="licensed by MAS" /></p>
                    </div>
                </div>
                <div class="col-md-7">
                    <div class="security-compliance-img"><img src="assets/images/security-compliance-img.png" alt="Security compliance" /></div>
                </div>
            </div>
        </div>
    </div>
    <div class="go-digital-bg mt-5">
        <div class="container">
            <div class="go-digital-bg-inner">
                <div class="play-area">
                    <a href="#" data-toggle="modal" data-target="#videoModal">
                        <div class="play-btn"><i class="fa fa-play" aria-hidden="true"></i></div>
                    </a>
                </div>
                <div class="modal" id="videoModal">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <!-- Modal body -->
                            <div class="modal-body">
                                <!-- <video width="100%" height="500" controls autoplay muted> -->
                                <video width="100%" height="500" controls muted>
                                    <source src="https://assets.mmvpay.com/corporate/branding/MatchMove/media/videos/banner-video.mp4" type="video/mp4" />
                                    <source src="https://assets.mmvpay.com/corporate/branding/MatchMove/media/videos/banner-video.mp4" type="video/ogg" />
                                    Your browser does not support HTML video.
                                </video>
                            </div>
                            <!-- Modal footer -->
                            <div class="modal-footer">
                                <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 pr-0">
                        <div class="go-digital-img"><img src="assets/images/go-digital.jpg" alt="Go digital" /></div>
                    </div>
                    <div class="col-md-6 pl-0">
                        <div class="go-digital-content padd120">
                            <h1>Go digital. Go cashless.</h1>
                            <p>No need to manually check payments and create financial reports.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="app-card-bg">
        <div class="container">
            <div class="app-card-tabs">
                <ul class="nav nav-tabs">
                    <li><a class="active" data-toggle="tab" href="#tab1">Virtual Account</a></li>
                    <li><a data-toggle="tab" href="#tab4" class="">International Remittance</a></li>
                    <li><a data-toggle="tab" href="#tab6" class="">Loan Disbursement</a></li>
                </ul>
                <div class="tab-content">
                    <div id="tab1" class="tab-pane fade in active show">
                        <div class="app-card-content">
                            <div class="row">
                                <div class="col-lg-12 col-md-12">
                                    <h2>Offer your customers cash balances on a safe and secure platform</h2>
                                    <p>Works better than cash and can be paired with virtual and physical cards</p>
                                    <p><a href="features" class="homeread">Read More</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="tab4" class="tab-pane fade">
                        <div class="app-card-content">
                            <h2>Make remittance across the world</h2>
                            <p>Enable real-time digital cash transfers globally through digital wallets and prepaid cards</p>
                            <p><a href="remittance.html" class="homeread">Read More</a></p>
                        </div>
                    </div>
                    <div id="tab6" class="tab-pane fade">
                        <div class="app-card-content">
                            <h2>Provide loans directly to small businesses and consumers through automated credit processes</h2>
                            <p>Build a customer-centric platform to disburse instant funds</p>
                            <p><a href="loan-disbursement.html" class="homeread">Read More </a></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="go-digital-bg mb-5">
        <div class="container">
            <div class="apple-pay-bg-inner">
                <div class="row">
                    <div class="col-md-6 pr-0">
                        <div class="go-digital-img apple-img"><img src="assets/images/applepay-banner.jpg" alt="Apple Pay Banner" /></div>
                    </div>
                    <div class="col-md-6 pl-0">
                        <div class="apple-pay-content">
                            <h1>
                                Apple Pay <br/>
                                is now available <br/>
                                on MatchMove.
                            </h1>
                            <img src="assets/images/mm-apple-pay-logo.png" alt="MatchMove and Apple Pay Logo" />
                            <a href="/apple-pay.html" class="btnstyl1">Find out more</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="most-trusted-brands">
        <div class="container">
            <h1>
                Powering digital payments for some <br />
                of the most trusted brands
            </h1>
            <owl-carousel [options]="carouselOptionsBrand" [carouselClasses]="['owl-theme', 'row', 'sliding']">
                <div class="item" *ngFor="let data of brandData;let i= index">
                    <div class="trusted-brand">
                        <div class="trusted-brand-head">
                            <img src="{{data.image}}" alt="{{data.name}}" />
                            <h4>{{data.name}}</h4>
                            <h4>{{data.designation}}</h4>
                            <span class="brandlogo">
                                <img src="{{data.logo}}" lt="{{data.name}}" />
                            </span>
                        </div>
                        <p>{{data.info}}</p>
                    </div>
                </div>
            </owl-carousel>
        </div>
    </div>
    <div class="featured-in">
        <div class="container">
            <h1>As featured in</h1>
            <ul>
                <li>
                    <a href="https://www.techinasia.com/" target="_blank"> <img src="assets/images/techinasia.png" alt="Techinasia" /></a>
                </li>
                <li>
                    <a href="https://sg.finance.yahoo.com/" target="_blank"> <img src="assets/images/yahoo-finance.png" alt="Yahoo finance" /></a>
                </li>
                <li>
                    <a href="https://www.todayonline.com/" target="_blank"> <img src="assets/images/todayonline.png" alt="Today online" /></a>
                </li>
                <li>
                    <a href="https://www.straitstimes.com/global" target="_blank"><img src="assets/images/straitstimes.png" alt="Straits times" /></a>
                </li>
                <li>
                    <a href="https://e27.co/" target="_blank"><img src="assets/images/e27.png" alt="e27" /></a>
                </li>
                <li>
                    <a href="https://www.bloomberg.com/asia" target="_blank"><img src="assets/images/bloomberg.png" alt="Bloomberg" /></a>
                </li>
                <li>
                    <a href="https://www.businesstimes.com.sg/" target="_blank"><img src="assets/images/businesstimes.png" alt="Business times" /></a>
                </li>
                <li>
                    <a href="https://sbr.com.sg/" target="_blank"><img src="assets/images/sbr.png" alt="sbr" /></a>
                </li>
            </ul>
        </div>
    </div>
</div>