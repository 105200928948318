<div class="media-bottombg pt-0">
    <div class="container">
        <div class="media-relasttop">
            <h4>Privacy Policy</h4>
        </div>
        <div class="termsbg privacybg">
            <h3>PART 1: Introduction</h3>
            <p>
                Your privacy is important to us, so we have developed a Privacy Policy that covers how we collect, use, disclose, transfer and store your information. Please take a moment to familiarize yourself with our privacy practices
                and let us know if you have any questions.
            </p>
            <p>This Privacy Policy describes your privacy rights regarding our collection, use, storage, sharing and protection of your personal information.</p>
            <p>
                Definitions, Description, and Interpretation. Headings and sub-headings are inserted for convenience only and do not affect the construction or interpretation of these Privacy Policy. Words denoting the singular shall (where
                appropriate) include the plural and vice versa, and words denoting a gender include every gender. The words &quot;including&quot; and &quot;include&quot; are not words of limitation. References to persons shall be construed
                to include bodies incorporated or unincorporated.
            </p>
            <p>In this Privacy Policy, unless the context otherwise requires, the following definitions shall apply:</p>
            <ul class="c1">
                <li>
                    &quot;Cards&quot; &quot; including the virtual account and/or the physical and/or Cards which is provided to you upon sign-up through our services and completion of registration or Know-Your-Customer (KYC) process with
                    us or our partners. The Cards are regarded as a stored value facility/debit under respective Country laws, and different types of payment products and services may be available to you from time to time.
                </li>
                <li>&quot;Country&quot; means the country or territory where you are residing and the Cards are issued.</li>
                <li>
                    &quot;MatchMove&reg;&quot; we, us, ourselves refers to MatchMove Pay Pte Ltd, together with its employees, directors, affiliates companies, successors, and assignees. MatchMove Pay Pte Ltd (CRN: 200902936W) is a company
                    duly established and operating under the laws of Singapore, has its registered office at 137 Telok Ayer Street, #03-03, Singapore 068602. It is Licensed, Authorised and Regulated by the Monetary Authority of Singapore
                    (MAS) under the Money Changing and Remittance Act (Chapter 187) for the provision of remittance services with Registration/License number: 01331 issuing under Section 30 of the Money Changing and Remittance Act (Chapter
                    187). Please note that such licensing does NOT guarantee the performance of the remittance licensee and you take the risk of any loss suffered from the remittance.
                </li>
                <li>
                    &quot;MatchMove&reg; Wallet&quot; &quot; refers to Wallet application on mobile devices, cards, wearables, websites or portals that you can access from mobile devices or computers. myWallet is a mobile application-based
                    money and card management owned by and offered to you by MatchMove.
                </li>
                <li>&quot;You&quot; &quot; yourself refers to the Wallet and Cards users whether in their capacity as Spenders, Senders, Recipients, or visitors or users to any of MatchMove&reg;s websites, mobile apps and/or its APIs.</li>
            </ul>
            <p>
                MatchMove Pay Pte Ltd and its affiliates (as applies) is the controller of the personal data. Personal data will be typically stored by MatchMove for a period of seven [7] years (unless specificed otherwise). Our Data
                Protection Office (DPO) can be contacted at <a href="mailto:security@matchmove.com">security@matchmove.com</a>, including any questions regarding this policy and our privacy practices.
            </p>
            <p>
                You accept this Privacy Policy and consent to the collection, use or disclosure of personal data about you by us when you voluntarily provide the personal data to sign up for, access, or use any products, services, content,
                features, technologies or functions offered by MatchMove. We reserve the right to amend this Privacy Policy at any time and for whatever reason we may deem proper, and any such amendment shall be effective upon notice to you
                by publication or other means of communication, electronic or otherwise. You are advised to check this Privacy Policy available on the MatchMove website from time to time for any updates or changes that may impact you. This
                is unless you object to those amendments by manifesting the intention to terminate your membership/agreement in writing within five (5) days from notice of the amendment. Your failure to notify us about this intention to
                terminate your membership/agreement as provided, and your continued use of MatchMove&reg; Wallet, Cards and related services, or both, shall be taken as acceptance by you of the amendments.
            </p>
            <div class="termsbg-padd">
                <h3>2. How information about you is collected</h3>
                <p>
                    <span class="numbox">2.1</span>
                    <span class="conbox">
                        When you use MatchMove&reg; Wallet or use our related services, we collect information sent to the operation system by your computer, mobile phone or other access device. The information sent to us may include data
                        on the pages you access, your computer IP address, device identifiers, the type of operating system you are using, your location, mobile network information, standard web log data and other information.
                        MatchMove&reg; Wallet web log and application data may include the browser type and/or mobile devices you are using and traffic to and from the application and/or MatchMove&reg; Wallet. When you use MatchMove&reg;
                        Wallet or use our related services, we also collect information about your transactions and your activities.
                    </span>
                </p>
                <p>
                    <span class="numbox">2.2</span>
                    <span class="conbox">In addition, if you use MatchMove&reg; Wallet or related services, we may collect the following types of information:</span>
                </p>
                <ul class="c2">
                    <li>Contact information, such as your name, address, mobile number, email and other similar information.</li>
                    <li>Detailed personal information such as your date of birth or national ID number.</li>
                </ul>
                <p>
                    <span class="numbox">2.3</span>
                    <span class="conbox">We may also obtain information about you from third parties such as credit bureaus and identity verification services.</span>
                </p>
                <p>
                    <span class="numbox">2.4</span>
                    <span class="conbox">
                        You may choose to provide us with access to certain personal information stored by third parties such as social media sites (e.g., Facebook and Twitter). The information we may receive varies by site and is
                        controlled by that site. By associating an account managed by a third party with your MatchMove&reg; Wallet , and authorizing us to have access to this information, you agree that we may collect, store and use this
                        information in accordance with this Privacy Policy.
                    </span>
                </p>
                <p>
                    <span class="numbox">2.5</span>
                    <span class="conbox">
                        In order to help protect you from fraud and misuse of your personal information, we may collect information about your use and interaction with the MatchMove&reg; Wallet provided by us. For example, we may evaluate
                        your computer, mobile phone or other access devices to identify any malicious software or activity.
                    </span>
                </p>
                <p>
                    <span class="numbox">2.6</span>
                    <span class="conbox">
                        We may also collect additional information from or about you in other ways, such as through contact with our customer support team, results when you respond to a survey and from interactions with other websites and
                        services offered by us.
                    </span>
                </p>
            </div>
            <div class="termsbg-padd">
                <h3>3. How Cookies are used</h3>
                <p>
                    <span class="numbox">3.1</span>
                    <span class="conbox">
                        When you access MatchMove&reg; Wallet or related services, we (including companies we work with) may place small data files on your computer or other devices. These data files may be cookies, pixel tags, cache,
                        &quot;Flash cookies,&quot; or other local storage provided by your browser or associated applications (collectively &quot;Cookies&quot;). We shall use these technologies to; recognize you as a customer; customize
                        MatchMove&reg; Wallet related services, content, and advertising; measure promotional effectiveness; help ensure that your account security is not compromised; mitigate risk and prevent fraud; and to promote trust
                        and safety across our services.
                    </span>
                </p>
                <p>
                    <span class="numbox">3.2</span>
                    <span class="conbox">
                        We use both session and persistent Cookies. Session Cookies expire and no longer have any effect when you log out of your account or close your browser or your wallet application on your mobile devices. Persistent
                        Cookies remain on your browser or application until you erase them or they expire.
                    </span>
                </p>
                <p>
                    <span class="numbox">3.3</span>
                    <span class="conbox">
                        We encode our Cookies so that we can interpret the information stored in them. You are free to decline those Cookies if your browser or browser add-on permits, but doing so may interfere with your use of the Cards
                        website and our related services. Refer to the help section of your browser, browser extensions, or installed applications for instructions on blocking, deleting, or disabling Cookies.
                    </span>
                </p>
                <p>
                    <span class="numbox">3.4</span>
                    <span class="conbox">
                        You may encounter myWallet application or MatchMove&reg; Wallet cookies or pixel tags on websites or mobile devices that we do not control. For example, if you view a web page/link created by a third party or use an
                        application developed by a third party, there may be a cookie or pixel tag placed by the web page or application. Likewise, these third parties may place their own cookies or pixel tags that are not subject to our
                        control and this Privacy Policy does not cover their use.
                    </span>
                </p>
            </div>
            <div class="termsbg-padd">
                <h3>4. How personal information is protected and stored</h3>
                <p>
                    <span class="numbox">4.1</span>
                    <span class="conbox">
                        Throughout this policy, we use the term &quot;personal information&quot; to describe information that can be associated with a specific person and can be used to identify that person. We do not consider personal
                        information to include information that has been made anonymous so that it does not identify a specific user.
                    </span>
                </p>
                <p>
                    <span class="numbox">4.2</span>
                    <span class="conbox">
                        We store and process your personal information on our servers in Asia, Europe and elsewhere in the world where our facilities are located. We shall protect your information using physical, technical, and
                        administrative security measures to reduce the risks of loss, misuse, unauthorized access, disclosure and alteration. Some of the safeguards are firewalls and data encryption, physical access controls to data
                        centers, and information access authorization controls. These are assured via compliance and certification to industry and regulatory standards by internal audit and via independent third parties.
                    </span>
                </p>
            </div>
            <div class="termsbg-padd">
                <h3>5. How the collected personal information is used</h3>
                <p>
                    <span class="numbox">5.1</span>
                    <span class="conbox">The primary purpose in collecting personal information is to provide you with a secure, smooth, efficient, and customized experience. Your personal information may be used to:</span>
                </p>
                <ul class="c2">
                    <li>provide MatchMove&reg; Wallet and related services and customer support;</li>
                    <li>process transactions and send notices about your transactions;</li>
                    <li>resolve disputes, collect fees, and troubleshoot problems;</li>
                    <li>prevent potentially prohibited or illegal activities, and enforce the Terms and Conditions;</li>
                    <li>customize, measure, and improve MatchMove&reg; Wallet and related services and the content, layout, and operation of our websites and applications;</li>
                    <li>deliver targeted marketing, service update notices, and promotional offers based on your communication preferences;</li>
                    <li>contact you at any telephone number, by placing a voice call or through text (SMS) or email messaging, as authorized by the MatchMove&reg; Wallet Terms and Conditions;</li>
                    <li>compare information for accuracy and verify it with third parties.</li>
                </ul>
            </div>
            <div class="termsbg-padd">
                <h3>6. Marketing</h3>
                <p>
                    <span class="numbox">6.1</span>
                    <span class="conbox">
                        We shall not sell or rent your personal information to third parties for their marketing purposes without your explicit consent. We may combine your information with information we collect from other companies and
                        use it to improve and personalize MatchMove&reg; Wallet and related services, content, and advertising.
                    </span>
                </p>
                <p>
                    <span class="numbox">6.2</span>
                    <span class="conbox">We may call or send a text message (SMS) to you at a mobile phone number that you have provided.</span>
                </p>
                <p>
                    <span class="numbox">6.3</span>
                    <span class="conbox">
                        If you do not wish to receive marketing communications or participate in ad-customization programs, simply indicate your preference by following the directions that may be provided within the communication or
                        advertisement.
                    </span>
                </p>
            </div>
            <div class="termsbg-padd">
                <h3>7. How personal information is shared with other MatchMove&reg; Wallet, Cards users</h3>
                <p>
                    <span class="numbox">7.1</span>
                    <span class="conbox">
                        To process your payments or other transactions, we may share some of your personal information, including your contact information, date of sign-up, and the number of transactions you have conducted, with the person
                        or company that you are conducting transactions with. In addition, this and other information may also be shared with third parties when you use these third parties to access and use MatchMove&reg; Wallet and related
                        services. Unless you have agreed to it, these third parties are not allowed to use this information for any purpose other than to enable Cards and related services.
                    </span>
                </p>
                <p>
                    <span class="numbox">7.2</span>
                    <span class="conbox">
                        If you are buying goods or services and pay through MatchMove&reg; Wallet we may also provide the Merchant with your shipping and billing address to help complete your transaction. The Merchant is not allowed to use
                        this information to market its services to you unless you have agreed to it. If an attempt to pay your Merchant fails, or is later invalidated, we may also provide your Merchant with details of the unsuccessful
                        transactions.
                    </span>
                </p>
            </div>
            <div class="termsbg-padd">
                <h3>8. How personal information is shared with other parties</h3>
                <p>
                    <span class="numbox">8.1</span>
                    <span class="conbox">We may share your personal information with:</span>
                </p>
                <ul class="c2">
                    <li>
                        Our subsidiaries and affiliates &quot; to provide joint content, products, and services (like registration, transactions and customer support), to help detect and prevent potentially illegal acts and violations of
                        policies, and to guide decisions about products, services, and communications. Our subsidiaries and affiliates shall use this information to send you marketing communications only if you have requested their
                        services.
                    </li>
                    <li>
                        Service providers under contract who help with our business operations such as fraud prevention, bill collection, marketing, and technology services. These contracts dictate that these service providers only use your
                        information in connection with the services they perform for us and not for their own benefit.
                    </li>
                    <li>
                        Financial institutions that we partner with to jointly create and offer a product. These financial institutions may only use this information to market Cards-related products unless you have given consent for other
                        uses.
                    </li>
                    <li>Credit bureaus and collection agencies to report account information, as permitted by law.</li>
                    <li>
                        Companies that we plan to merge with or be acquired by. (Should such a combination occur, we shall require that the new combined entity follow this privacy policy with respect to your personal information. If your
                        personal information could be used contrary to this policy, you will receive prior notice).
                    </li>
                    <li>
                        Law enforcement, government officials, or other third parties pursuant to a subpoena, court order, or other legal processes or requirements applicable to us or one of our affiliates; when we need to do so to comply
                        with any law and/or rules; or when we believe, at our sole discretion, that the disclosure of personal information is necessary to prevent physical harm or financial loss, to report suspected illegal activity or to
                        investigate violations of the Terms and Conditions.
                    </li>
                    <li>Other third parties with your consent or direction to do so.</li>
                </ul>
                <p>
                    <span class="numbox">8.2</span>
                    <span class="conbox">
                        We shall not sell or rent any of your personal information to third parties for their marketing purposes and only share your personal information with third parties as described in this Privacy Policy.
                    </span>
                </p>
            </div>
            <div class="termsbg-padd">
                <h3>9. How you can access or change your personal information</h3>
                <p class="p-0">
                    You can review and edit your personal information at any time by logging into your account and reviewing your account settings and profile or by calling Customer Service number provided on our website from time to time.
                    You can also close your account by calling Customer Service. If you close your Cards and MatchMove&reg; Wallet, we shall mark your account in our database as &quot;Closed&quot; but may retain personal information from
                    your account as required by laws and/or to collect any fees owed, resolve disputes, troubleshoot problems, assist with any investigations, prevent fraud, enforce the Terms and Conditions, or take other actions as
                    required or permitted by law.
                </p>
                <p class="p-0">For wallet registration or transactions within the EU, data subjects have the following rights:</p>
                <ul class="c1">
                    <li>to request access to your information and information related to our use and processing of your information;</li>
                    <li>to request the correction of your information (we may seek to verify the accuracy of the personal information before rectifying it);</li>
                    <li>in certain situations to ask us to erase, block or restrict the personal information we hold about you, or object to particular ways in which we are using your personal information; and</li>
                    <li>in certain situations, you can also ask us to send the personal information you have given us to a third party.</li>
                    <li>to ask to obtain a copy of, or reference to, the safeguards under which your personal information is transferred outside of the EU.</li>
                    <li>
                        to withdraw your consent to our use of your information at any time where we rely on your consent to use or process that information. This will not affect the lawfulness of our processing of your data prior to your
                        withdrawal;
                    </li>
                    <li>
                        to object to the processing of your information for certain purposes, namely:
                        <ul>
                            <li>to us using or processing your information where we use or process it in order to carry out a task in the public interest or for our legitimate interests;</li>
                            <li>
                                to object to us using or processing your information for direct marketing purposes. You may also exercise your right to object to us using or processing your information for direct marketing purposes by
                                clicking the unsubscribe link contained at the bottom of any marketing email we send to you and updating your preferences, or as a subscriber by visiting the preference centre on our website and changing your
                                preferences.
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
            <div class="termsbg-padd">
                <h3>10. Security Guidelines</h3>
                <p>
                    <span class="numbox">10.1</span>
                    <span class="conbox">
                        Your role in safeguarding your Personal Data and Account Information <br />
                        You are responsible for keeping your account information and password confidential. Failure to do so exposes you to the risks of fraud and loss. MatchMove will not be responsible for losses suffered by customers as a
                        result of:
                    </span>
                </p>
                <ul class="c2">
                    <li>Input errors or misuse of Cards and MatchMove&reg; Wallet&quot;s services;</li>
                    <li>Negligent handling or sharing of your Password;</li>
                    <li>Leaving a computer or mobile device unattended during an online session.</li>
                    <li>Failure to immediately report known incidents of unauthorized account access.</li>
                </ul>
                <p>
                    <span class="numbox">10.2</span>
                    <span class="conbox">We strongly recommend that you observe the following best privacy and security practices at all times:</span>
                </p>
                <ul class="c2">
                    <li>Managing your Personal Data and Account Information and Password</li>
                    <li>
                        Your Personal Data and Account Information and Password identify you when you use our services. If any of these become invalid or ceases to be effective, it is imperative that you inform us by calling the Customer
                        Service number for the respective services.
                    </li>
                    <li>Ensure that no one can see your Password when you log into our system.</li>
                    <li>Ensure that you keep your Password confidential at all times and do not divulge it to anyone.</li>
                    <li>
                        Do not allow anyone (without exception) to use your Personal Data and Account Information and Password, as you are responsible for all transactions undertaken with your Personal Data and Account Information and
                        Password.
                    </li>
                    <li>Do not base your password on your Personal Data and Account Information such as your telephone number, birth date or the like.</li>
                    <li>Memorize your Password and do not record it anywhere.</li>
                    <li>Change your Password regularly.</li>
                    <li>Do not use the same character more than twice for your Password.</li>
                    <li>Do not recycle your recently used Password.</li>
                    <li>Change your Password IMMEDIATELY if you suspect that someone knows it.</li>
                    <li>The same Password should not be used for different websites, applications or services particularly when they relate to different entities.</li>
                    <li>Do not select the option on your browser for storing or retaining user name and Password.</li>
                </ul>
                <p>
                    <span class="numbox">10.3</span>
                    <span class="conbox">Take precautions against your security code keystrokes being captured</span>
                </p>
                <p>
                    Exercise the necessary precautions to protect your personal computer against viruses and other malicious programs. Aside from damaging and/or destroying data, viruses and malicious programs can capture your Password
                    keystrokes and other personal information and send them to another person without your consent. Precautions that you can take include the following:
                </p>
                <ul class="c2">
                    <li>Ensure that you install an effective personal firewall as well as anti-virus, anti-spyware and anti-Trojan-horse software. These should be updated regularly.</li>
                    <li>Do not download any software from a website that is of doubtful origin.</li>
                    <li>Do not open any email or attachment that is from a source unknown to you. When in doubt, delete such email without opening it.</li>
                </ul>
                <p>
                    <span class="numbox">10.4</span>
                    <span class="conbox">Inform us immediately of suspected invasion of your privacy or of unauthorized transactions.</span>
                </p>
                <p>
                    If you suspect that your privacy has been invaded or that your account(s) has/have been accessed by unauthorized persons, please change your Password immediately and contact us by calling Customer Service. Check your
                    transaction records to see if any transaction has been performed without your authorization. Should there be any unauthorized or unusual transactions, please notify us immediately. c. You should check your transaction
                    records regularly and notify us promptly of any errors. At the minimum, you should check your transaction records at least once at the beginning of each month.
                </p>
                <p>
                    Effective date: 25 May 2018 <br />
                    Last updated: 5 Nov 2020
                </p>
            </div>
        </div>
    </div>
</div>
