<div>
    <span *ngIf="!isStory" class="btomscroll"><a href="#section1"><i class="fa fa-angle-up" aria-hidden="true"></i></a></span>
    <div class="footer pt-5">
        <div class="container">
            <div class="awardslogo">
                <owl-carousel [options]="carouselOptions" [carouselClasses]="['owl-theme', 'row', 'sliding']">
                    <div class="item" *ngFor="let image of images;let i= index">
                        <img class="slide-image" src="{{image.image}}" alt="{{image.alternate}}">
                    </div>
                </owl-carousel>
                <p class="mt-4">MatchMove is the proud winner of multiple industry awards. We have also been recognized
                    as a
                    leader in fintech innovation by various global authorities, financial publications, and
                    institutions.
                </p>
            </div>

            <div class="footersocial">
                <a href="https://www.linkedin.com/company/matchmove" target="_blank"><i class="fa fa-linkedin"
                        aria-hidden="true"></i></a>
                <a href="https://www.facebook.com/MatchMove" target="_blank"><i class="fa fa-facebook"
                        aria-hidden="true"></i></a>
            </div>

            <div class="footerbottom">Copyright &copy; 2009-{{ year }} MatchMove Pay Pte Ltd ("MatchMove"). All rights reserved. MatchMove is a company registered in Singapore (registration number 200902936W) and is a Major Payment Institution (licence number PS20200239) regulated by the Monetary Authority of Singapore. MatchMove and related entities operate in multiple countries worldwide.</div>
        </div>
    </div>

    <div id="cookie-law-info-bar"><span>This website uses cookies to improve your experience. We'll assume you're ok
            with
            this, but you can opt-out if you wish. <a role='button' tabindex='0' class="cli_settings_button"
                style="margin:5px 20px 5px 20px;">Cookie settings</a><a role='button' tabindex='0'
                data-cli_action="accept" id="cookie_action_close_header"
                class="medium cli-plugin-button cli-plugin-main-button cookie_action_close_header cli_action_button"
                style="display:inline-block;  margin:5px; ">ACCEPT</a></span></div>
    <div id="cookie-law-info-again" style="display:none;"><span id="cookie_hdr_showagain">Privacy & Cookies
            Policy</span>
    </div>
    <div class="cli-modal" id="cliSettingsPopup" tabindex="-1" role="dialog" aria-labelledby="cliSettingsPopup"
        aria-hidden="true">
        <div class="cli-modal-dialog" role="document">
            <div class="cli-modal-content cli-bar-popup">
                <button type="button" class="cli-modal-close" id="cliModalClose">
                    <svg class="" viewBox="0 0 24 24">
                        <path
                            d="M19 6.41l-1.41-1.41-5.59 5.59-5.59-5.59-1.41 1.41 5.59 5.59-5.59 5.59 1.41 1.41 5.59-5.59 5.59 5.59 1.41-1.41-5.59-5.59z">
                        </path>
                        <path d="M0 0h24v24h-24z" fill="none"></path>
                    </svg>
                    <span class="wt-cli-sr-only">Close</span> </button>
                <div class="cli-modal-body">
                    <div class="cli-container-fluid cli-tab-container">
                        <div class="cli-row">
                            <div class="cli-col-12 cli-align-items-stretch cli-px-0">
                                <div class="cli-privacy-overview">
                                    <h4>Privacy Overview</h4>
                                    <div class="cli-privacy-content">
                                        <div class="cli-privacy-content-text">This website uses cookies to improve your
                                            experience while you
                                            navigate through the website. Out of these cookies, the cookies that are
                                            categorized as necessary
                                            are stored on your browser as they are essential for the working of basic
                                            functionalities of the
                                            website. We also use third-party cookies that help us analyze and understand
                                            how
                                            you use this
                                            website. These cookies will be stored in your browser only with your
                                            consent.
                                            You also have the
                                            option to opt-out of these cookies. But opting out of some of these cookies
                                            may
                                            have an effect on
                                            your browsing experience.</div>
                                    </div>
                                    <a class="cli-privacy-readmore" data-readmore-text="Show more"
                                        data-readless-text="Show less"></a>
                                </div>
                            </div>
                            <div class="cli-col-12 cli-align-items-stretch cli-px-0 cli-tab-section-container">
                                <div class="cli-tab-section">
                                    <div class="cli-tab-header"> <a role="button" tabindex="0"
                                            class="cli-nav-link cli-settings-mobile" data-target="necessary"
                                            data-toggle="cli-toggle-tab"> Necessary </a> <span
                                            class="cli-necessary-caption">Always Enabled</span> </div>
                                    <div class="cli-tab-content">
                                        <div class="cli-tab-pane cli-fade" data-id="necessary">
                                            <p>Necessary cookies are absolutely essential for the website to function
                                                properly. This category
                                                only includes cookies that ensures basic functionalities and security
                                                features of the website.
                                                These cookies do not store any personal information.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="cli-tab-section">
                                    <div class="cli-tab-header"> <a role="button" tabindex="0"
                                            class="cli-nav-link cli-settings-mobile" data-target="non-necessary"
                                            data-toggle="cli-toggle-tab"> Non-necessary </a>
                                        <div class="cli-switch">
                                            <input type="checkbox" id="wt-cli-checkbox-non-necessary"
                                                class="cli-user-preference-checkbox" data-id="checkbox-non-necessary"
                                                checked />
                                            <label for="wt-cli-checkbox-non-necessary" class="cli-slider"
                                                data-cli-enable="Enabled" data-cli-disable="Disabled"><span
                                                    class="wt-cli-sr-only">Non-necessary</span></label>
                                        </div>
                                    </div>
                                    <div class="cli-tab-content">
                                        <div class="cli-tab-pane cli-fade" data-id="non-necessary">
                                            <p>Any cookies that may not be particularly necessary for the website to
                                                function and is used
                                                specifically to collect user personal data via analytics, ads, other
                                                embedded contents are
                                                termed as non-necessary cookies. It is mandatory to procure user consent
                                                prior to running these
                                                cookies on your website.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
