<div class="bannerbg corporatebannerbg subtrabannerbg">
    <div class="container">
        <div class="row">
            <div class="col-lg-5">
                <div class="bannertext mt-4">
                    <h4 class="text03">Subscription Management Platform</h4>
                    <p><img src="assets/images/subtralogo.png" alt="Subtra logo" /></p>
                    <p class="mt-4">
                        Subtra is a Singapore headquartered company that has built a centralized subscription management
                        platform, allowing users to seamlessly purchase, manage, and automate their company's SaaS, PaaS
                        and IaaS stacks.
                        Subtra's partnership with MatchMove enables its customers to manage and pay for various online
                        services such as Zoom, Slack, Amazon Web Services, G-Suite, etc.
                    </p>
                </div>
            </div>
        </div>
    </div>

    <div class="subtrabannerimg rellax" data-rellax-speed="5" data-rellax-xs-speed="-5" data-rellax-mobile-speed="3"
        data-rellax-tablet-speed="-8"><img src="assets/images/subtrabanner.png" alt="Subtra banner" /></div>
</div>

<div class="corporate-bottombg">
    <div class="container">
        <div class="go-digital-bg-inner oursolutionbg">
            <div class="row">
                <div class="col-md-6 pr-0">
                    <div class="go-digital-img"><img src="assets/images/oursolution.jpg" alt="Our solution" /></div>
                </div>
                <div class="col-md-6 pl-0">
                    <div class="usecase-bg-inner-content" style="padding-top: 25px; padding-bottom: 25px;">
                        <h1>Our Solution</h1>
                        <p>
                            MatchMove rapidly delivered its Banking-as-a-Service platform to power Subtra's card-issuing
                            capabilities, including setting fixed or flexible spending limits. Leveraging on MatchMove's
                            solutions, Subtra enables
                            its customers to make payments in dual currencies via virtual wallets and cards and automate
                            their subscription spends altogether. By using MatchMove's API, Subtra saved considerable
                            staff-hours in compliance
                            checks and in the building of an e-wallet with instant authentication capabilities. With
                            robust spend controls, usage analytics and team management, Subtra helps companies save up
                            to 30% of their total
                            subscription spend.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>