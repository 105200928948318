<div class="media-bottombg pt-0">
  <div class="container">
    <div class="termsbg">
      <p class="MsoNormal" align="center" style="text-align: center">
        <b
          ><span lang="EN-SG" style="font-size: 15pt"
            >STANDARD MMSHOP TERMS AND CONDITIONS</span
          ></b
        >
      </p>

      <p class="MsoNormal" style="text-align: justify; border: none">
        <span style="font-size: 11pt"
          >These Standard MMSHOP Terms and Conditions, the MMSHOP Agreement,
          S</span
        ><span style="font-size: 10pt"
          >chedule 1, Schedule 2, Schedule 3 and Schedule 4
          <span
            >and all recitals, clauses, annexures, exhibits, appendices and
            schedules as amended or supplemented from time to time</span
          >
          shall collectively be referred to as this “<b>Agreement</b>” and shall
          govern the rights and obligations of the Parties hereto.
        </span>
      </p>

      <p class="MsoNormal" style="text-align: justify">
        <b><span lang="EN-SG" style="font-size: 14pt">BACKGROUND</span></b>
      </p>

      <p
        class="MsoNormal"
        style="margin-left: 28.35pt; text-align: justify; text-indent: -28.35pt"
      >
        <span lang="EN-SG" style="font-size: 11pt">A.      Shopmatic is </span
        ><span style="font-size: 11pt"
          >the owner of an online international platform offering the entire
          ecosystem for companies and individuals who wish to create their own
          ecommerce, increase their online presence and/or benefit from reliable
          technology, integrated services and solutions, among other benefits </span
        ><span lang="EN-SG" style="font-size: 11pt">(the “</span
        ><b
          ><span lang="EN-SG" style="font-size: 11pt"
            >Shopmatic Platform</span
          ></b
        ><span lang="EN-SG" style="font-size: 11pt">”).</span>
      </p>

      <p
        class="MsoBodyText"
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          text-align: justify;
          text-indent: -28.35pt;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt"
          >B.      The Partner and Shopmatic have decided to engage in a
          mutually beneficial relationship, in which the Partner desires to
          offer Shopmatic’s products on Shopmatic Platform to its customers and
          Shopmatic wishes to collaborate with the Partner by providing its
          services to the Partner’s Customers by means of a co-branded
          initiative</span
        ><span style="font-size: 11pt">, </span
        ><span lang="EN-SG" style="font-size: 11pt"
          >in accordance with the terms and subject to the conditions as set out
          in this Agreement.</span
        >
      </p>
      <p></p>
      <p
        class="CorpAgrL3"
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          text-indent: -28.35pt;
          line-height: normal;
        "
      >
        <b
          ><span lang="EN-SG" style="font-size: 14pt"
            >1.       SCOPE OF ENGAGEMENT</span
          ></b
        >
      </p>

      <p
        class="CorpAgrL3"
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 0.5in;
          text-indent: -0.5in;
          line-height: normal;
        "
      >
        <b><span lang="EN-SG" style="font-size: 11pt">&nbsp;</span></b>
      </p>

      <p
        class="MsoNormal"
        style="margin-left: 28.35pt; text-align: justify; text-indent: -28.35pt"
      >
        <span lang="EN-SG" style="font-size: 11pt"
          >1.1     In consideration of the mutual benefits and obligations to be
          undertaken by each Party under this Agreement, each Party agrees to
          mutually collaborate with each other in the manner as set out in this
          Agreement.
        </span>
      </p>

      <p
        class="MsoNormal"
        style="margin-left: 28.35pt; text-align: justify; text-indent: -28.35pt"
      >
        <b
          ><span lang="EN-SG" style="font-size: 14pt"
            >2.       NON-EXCLUSIVITY</span
          ></b
        >
      </p>

      <p
        class="MsoNormal"
        style="margin-left: 28.35pt; text-align: justify; text-indent: -28.35pt"
      >
        <span lang="EN-SG" style="font-size: 11pt"
          >2.1     There shall be no exclusivity applicable under this Agreement
          and each Party may enter into similar agreements with other similar
          entities, irrespective of whether such arrangements are within the
          same territory targeted by each Party or within the same customer
          profile, and in any of such events, neither Party shall not be
          construed in any way whatsoever to be in breach of the terms and
          conditions of this Agreement.</span
        >
      </p>

      <p
        class="CorpAgrL3"
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          text-indent: -28.35pt;
          line-height: normal;
        "
      >
        <b
          ><span lang="EN-SG" style="font-size: 14pt"
            >3.       MINIMUM TERM AND TERM</span
          ></b
        >
      </p>

      <p
        class="MsoNormal"
        style="text-align: justify; text-autospace: ideograph-numeric"
      >
        <span
          lang="EN-SG"
          style="font-size: 11pt; display: none; background: white"
          >&nbsp;</span
        >
      </p>

      <p
        class="CorpAgrL3"
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          text-indent: -28.35pt;
          line-height: normal;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt; background: white"
          >3.1     This Agreement shall come into effect on the date as set out
          in the recital of the MMSHOP Agreement. This Agreement shall be valid
          and shall remain in force for a duration of three (3) years from the
          Effective Date (“<b>Term</b>”). Upon expiry of such Term, this
          Agreement shall be automatically renewed for a successive Term, unless
          otherwise earlier terminated in accordance with this Agreement. The
          <b>Minimum Term</b> of this Agreement shall be for a period of two (2)
          years beginning from the Effective Date. References to “Minimum Term”
          and “Term” shall mean the duration of this Agreement as provided in
          this Clause.</span
        >
      </p>

      <p
        class="CorpAgrL3"
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          text-indent: -28.35pt;
          line-height: normal;
        "
      >
        <b
          ><span lang="EN-SG" style="font-size: 11pt; background: white"
            >&nbsp;</span
          ></b
        >
      </p>

      <p
        class="CorpAgrL3"
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          text-indent: -28.35pt;
          line-height: normal;
        "
      >
        <b
          ><span lang="EN-SG" style="font-size: 14pt; background: white"
            >4.       MAINTAINENCE AND SUPPORT SERVICES</span
          ></b
        >
      </p>

      <p
        class="CorpAgrL3"
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          text-indent: -28.35pt;
          line-height: normal;
        "
      >
        <b
          ><span lang="EN-SG" style="font-size: 11pt; background: white"
            >&nbsp;</span
          ></b
        >
      </p>

      <p
        class="CorpAgrL3"
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          text-indent: -28.35pt;
          line-height: normal;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt; background: white"
          >4.1     Shopmatic shall provide maintenance and support services
          during the Term, in accordance with Schedule 4 (“<b
            >Support Services</b
          >”).  
        </span>
      </p>

      <p
        class="CorpAgrL3"
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          text-indent: -28.35pt;
          line-height: normal;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt; background: white"
          >&nbsp;</span
        >
      </p>

      <p
        class="CorpAgrL3"
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          text-indent: -28.35pt;
          line-height: normal;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt; background: white"
          >4.2     Shopmatic reserves the right to suspend the provision the
          Support Services at any time, if any amount/s due to be paid by the
          Partner to Shopmatic under this Agreement is overdue. Shopmatic shall
          issue a written communication to the Partner setting out the details
          of the payment/amount overdue and shall exercise the right to suspend
          Support Services if such payment is not made within fourteen (14) days
          from the date of the written communication of the overdue
          payment.</span
        >
      </p>

      <p
        class="CorpAgrL3"
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          text-indent: -28.35pt;
          line-height: normal;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt; background: white"
          >&nbsp;</span
        >
      </p>

      <p
        class="CorpAgrL3"
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          text-indent: -28.35pt;
          line-height: normal;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt; background: white"
          >4.3     Where any updates and/or upgrades are required, the Partner
          shall allow and provide full assistance and co-operation to Shopmatic
          to install such upgrades and updates. Further, in the event any action
          is required at the Partner’s end to implement/execute such upgrades
          and updates, the Partner shall complete the same forthwith without any
          delays.</span
        >
      </p>

      <p
        class="CorpAgrL3"
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          text-indent: -28.35pt;
          line-height: normal;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt; background: white"
          >&nbsp;</span
        >
      </p>

      <p
        class="CorpAgrL3"
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          text-indent: -28.35pt;
          line-height: normal;
        "
      >
        <b
          ><span lang="EN-SG" style="font-size: 14pt; background: white"
            >5.       PAYMENT AND TAXES</span
          ></b
        >
      </p>

      <p
        class="CorpAgrL3"
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          text-indent: -28.35pt;
          line-height: normal;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt; background: white"
          >&nbsp;</span
        >
      </p>

      <p
        class="CorpAgrL3"
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          text-indent: -28.35pt;
          line-height: normal;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt; background: white"
          >5.1     Each Party shall issue invoices for the Fees and amounts due
          to be paid by the other Party as per the provisions of this Agreement
          from time to time in accordance with Schedule 2.
        </span>
      </p>

      <p
        class="CorpAgrL3"
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          text-indent: -28.35pt;
          line-height: normal;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt; background: white"
          >&nbsp;</span
        >
      </p>

      <p
        class="CorpAgrL3"
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          text-indent: -28.35pt;
          line-height: normal;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt; background: white"
          >5.2     In the event a Party (‘Defaulting Party”) fails to make the
          payment of any amount due to the other Party under this Agreement
          within thirty (30) days of the date of the invoice, such Defaulting
          Party shall be liable to pay a late payment interest of two percent
          (2%) per month, effective from the date of late payment up to and
          including the date of total payment to the other Party and pro rata in
          case of a partial month.
        </span>
      </p>

      <p
        class="CorpAgrL3"
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          text-indent: -28.35pt;
          line-height: normal;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt; background: white"
          >&nbsp;</span
        >
      </p>

      <p
        class="CorpAgrL3"
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          text-indent: -28.35pt;
          line-height: normal;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt; background: white"
          >5.3     Shopmatic reserves the right offset any outstanding payments
          due from the Partner to Shopmatic against any other amounts that many
          be owed by Shopmatic to the Partner under this Agreement or anu other
          agreement(s).</span
        >
      </p>

      <p
        class="CorpAgrL3"
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          text-indent: -28.35pt;
          line-height: normal;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt; background: white"
          >&nbsp;</span
        >
      </p>

      <p
        class="CorpAgrL3"
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          text-indent: -28.35pt;
          line-height: normal;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt; background: white"
          >5.4     All sums payable by one Party to the other Party under this
          Agreement shall be paid in full, free of all deductions and
          withholdings of any kind except where such withholding or deduction is
          required by law.</span
        >
      </p>

      <p
        class="CorpAgrL3"
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          text-indent: -28.35pt;
          line-height: normal;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt; background: white"
          >&nbsp;</span
        >
      </p>

      <p
        class="CorpAgrL3"
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          text-indent: -28.35pt;
          line-height: normal;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt; background: white"
          >5.5     All payments and Fees excludes applicable taxes. Where any
          applicable taxes are payable, the Parties agree that the Fees and/or
          payments will be increased by an amount equivalent to the taxes as
          applicable.
        </span>
      </p>

      <p
        class="CorpAgrL3"
        style="
          margin-bottom: 0in;
          margin-bottom: 0in;
          margin-top: 0in;
          text-indent: -27pt;
          line-height: normal;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt; background: white"
          >&nbsp;</span
        >
      </p>

      <p
        class="CorpAgrL3"
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          text-indent: -28.35pt;
          line-height: normal;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt; background: white"
          >5.6     In case any service provider or vendor of Shopmatic involved
          in the services under this Agreement introduces or increases their
          fees/charges to Shopmatic, Shopmatic shall be entitled to increase the
          Charges accordingly by notifying the Partner thirty (30) days in
          advance.</span
        >
      </p>

      <p
        class="CorpAgrL3"
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 0.5in;
          text-indent: -0.5in;
          line-height: normal;
        "
      >
        <b><span lang="EN-SG" style="font-size: 11pt">&nbsp;</span></b>
      </p>

      <p
        class="CorpAgrL3"
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          text-indent: -28.35pt;
          line-height: normal;
        "
      >
        <b
          ><span lang="EN-SG" style="font-size: 14pt"
            >6.       REPRESENTATIONS AND WARRANTIES</span
          ></b
        >
      </p>

      <p
        class="CorpAgrL3"
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 0.5in;
          text-indent: -0.5in;
          line-height: normal;
        "
      >
        <b><span lang="EN-SG" style="font-size: 11pt">&nbsp;</span></b>
      </p>

      <p
        class="MsoNormal"
        style="text-align: justify; text-autospace: ideograph-numeric"
      >
        <span style="font-size: 11pt; display: none">&nbsp;</span>
      </p>

      <p
        class="CorpAgrL3"
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          text-indent: -28.35pt;
          line-height: normal;
        "
      >
        <span style="font-size: 11pt"
          >6.1     Each Party hereby represents and warrants to other Party
          that, as at the date of this Agreement, the following representation
          and warranties are true and correct:</span
        >
      </p>

      <p
        class="CorpAgrL3"
        style="margin: 0in; text-indent: 0in; line-height: normal"
      >
        <span style="font-size: 11pt">&nbsp;</span>
      </p>

      <p
        class="CorpAgrL3"
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 56.7pt;
          text-indent: -28.35pt;
          line-height: normal;
        "
      >
        <span style="font-size: 11pt"
          >(a)      each Party is duly incorporated in its country of
          incorporation;</span
        >
      </p>

      <p
        class="CorpAgrL3"
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 1in;
          text-indent: -0.5in;
          line-height: normal;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt">&nbsp;</span>
      </p>

      <p
        class="CorpAgrL3"
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 56.7pt;
          text-indent: -28.35pt;
          line-height: normal;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt"
          >(b)      each Party the power to enter into and perform this
          Agreement and all necessary actions have been taken to authorise the
          execution, delivery and performance of its obligations under this
          Agreement;</span
        >
      </p>

      <p
        class="CorpAgrL3"
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 1in;
          text-indent: -0.5in;
          line-height: normal;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt">&nbsp;</span>
      </p>

      <p
        class="CorpAgrL3"
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 56.7pt;
          text-indent: -28.35pt;
          line-height: normal;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt"
          >(c)      each Party has all the requisite power and authority to
          carry on its business as it is being conducted, which includes the
          required expertise to carry out their obligations in accordance with
          this Agreement;</span
        >
      </p>

      <p
        class="CorpAgrL3"
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 1in;
          text-indent: -0.5in;
          line-height: normal;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt">&nbsp;</span>
      </p>

      <p
        class="CorpAgrL3"
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 56.7pt;
          text-indent: -28.35pt;
          line-height: normal;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt"
          >(d)      each Party owns or possesses all permits, approvals,
          governmental authorizations, licenses, registrations, consents and
          property rights that are necessary to operate its business as is being
          currently conducted;
        </span>
      </p>

      <p
        class="CorpAgrL3"
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 1in;
          text-indent: -0.5in;
          line-height: normal;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt">&nbsp;</span>
      </p>

      <p
        class="CorpAgrL3"
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 56.7pt;
          text-indent: -28.35pt;
          line-height: normal;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt"
          >(e)      this Agreement constitutes a legal, valid and binding
          obligation of each Party enforceable in accordance with its terms;
          and</span
        >
      </p>

      <p
        class="CorpAgrL3"
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 1in;
          text-indent: -0.5in;
          line-height: normal;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt">&nbsp;</span>
      </p>

      <p
        class="CorpAgrL3"
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 56.7pt;
          text-indent: -28.35pt;
          line-height: normal;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt"
          >(f)       the entry into and performance of this Agreement by each
          Party does not result in a breach of any law or constitute a breach of
          any obligation (including without limitation, any statutory,
          contractual or fiduciary obligation) or default under any agreement or
          undertaking, by which it is or was bound.</span
        >
      </p>

      <p
        class="CorpAgrL3"
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 1in;
          text-indent: -0.5in;
          line-height: normal;
        "
      >
        <b><span lang="EN-SG" style="font-size: 11pt">&nbsp;</span></b>
      </p>

      <p
        class="CorpAgrL3"
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          text-indent: -28.35pt;
          line-height: normal;
        "
      >
        <span style="font-size: 11pt"
          >6.2     During the Term of this Agreement, each Party shall forthwith
          notify the other Party of any change or breach of the representations
          and warranties made in this Clause.
        </span>
      </p>

      <p
        class="CorpAgrL3"
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          text-indent: -28.35pt;
          line-height: normal;
        "
      >
        <span style="font-size: 11pt">&nbsp;</span>
      </p>

      <p
        class="CorpAgrL3"
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          text-indent: -28.35pt;
          line-height: normal;
        "
      >
        <span style="font-size: 11pt"
          >6.3     Both Parties hereby acknowledge that all of the Parties’
          warranties and representations in respect of the subject matter of
          this Agreement are expressly set out in this Agreement. To the maximum
          extent permitted by applicable law, no other warranties or
          representations concerning the subject matter of this Agreement will
          be implied or read into this Agreement or any related contract or
          agreement.
        </span>
      </p>

      <p
        class="CorpAgrL3"
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          text-indent: -28.35pt;
          line-height: normal;
        "
      >
        <span style="font-size: 11pt">&nbsp;</span>
      </p>

      <p
        class="CorpAgrL3"
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          text-indent: -28.35pt;
          line-height: normal;
        "
      >
        <b
          ><span style="font-size: 14pt"
            >7.       ACKNOWLEDGEMENTS AND WARRANTY LIMITATIONS</span
          ></b
        >
      </p>

      <p
        class="CorpAgrL3"
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          text-indent: -28.35pt;
          line-height: normal;
        "
      >
        <b><span style="font-size: 11pt">&nbsp;</span></b>
      </p>

      <p
        class="CorpAgrL3"
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          text-indent: -28.35pt;
          line-height: normal;
        "
      >
        <span style="font-size: 11pt"
          >7.1     The Partner acknowledges that complex software is never
          wholly free from defects, errors and bugs; and subject to the other
          provisions of this Agreement, Shopmatic does give any warranty or
          representation that the Shopmatic Platform will always be wholly free
          from defects, errors and bugs.</span
        >
      </p>

      <p
        class="CorpAgrL3"
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          text-indent: -28.35pt;
          line-height: normal;
        "
      >
        <span style="font-size: 11pt">&nbsp;</span>
      </p>

      <p
        class="CorpAgrL3"
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          text-indent: -28.35pt;
          line-height: normal;
        "
      >
        <span style="font-size: 11pt"
          >7.2     The Partner acknowledges that complex software is never
          entirely free from security vulnerabilities; and subject to the other
          provisions of this Agreement, Shopmatic does not give any warranty or
          representation that the Shopmatic Platform will always be entirely
          secure.</span
        >
      </p>

      <p
        class="CorpAgrL3"
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          text-indent: -28.35pt;
          line-height: normal;
        "
      >
        <span style="font-size: 11pt">&nbsp;</span>
      </p>

      <p
        class="CorpAgrL3"
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          text-indent: -28.35pt;
          line-height: normal;
        "
      >
        <b><span style="font-size: 11pt">8.</span></b
        ><span style="font-size: 11pt">       </span
        ><b><span lang="EN-SG" style="font-size: 14pt">CONFIDENTIALITY</span></b
        ><span style="font-size: 11pt">  </span>
      </p>

      <p
        class="CorpAgrL3"
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          text-indent: -28.35pt;
          line-height: normal;
        "
      >
        <span style="font-size: 11pt">&nbsp;</span>
      </p>

      <p
        class="MsoNormal"
        style="
          margin-left: 28.35pt;
          text-align: justify;
          text-indent: -28.35pt;
          border: none;
        "
      >
        <span style="font-size: 11pt"
          >8.1     Either Party shall not, during the Term of this Agreement and
          for a period of two (2) years following expiration or termination of
          this Agreement for any reason, without the prior written approval of
          the other Party (such approval not to be unreasonably withheld), use
          for its own benefit or for the benefit of any other person, firm,
          partner or organisation or directly or indirectly divulge or disclose
          to any person any Confidential Information which has come, or may
          come, to that Party's knowledge during or in connection with this
          Agreement.</span
        >
      </p>

      <p
        class="MsoNormal"
        style="
          margin-left: 28.35pt;
          text-align: justify;
          text-indent: -28.35pt;
          border: none;
        "
      >
        <span style="font-size: 11pt"
          >8.2     The restrictions contained in Clause 8.1 do not apply
          to:</span
        >
      </p>

      <p
        class="MsoNormal"
        style="
          margin-left: 56.7pt;
          text-align: justify;
          text-indent: -28.35pt;
          border: none;
        "
      >
        <span style="font-size: 11pt"
          >(a)      any Confidential Information which is already or becomes
          available to the public generally (otherwise than through the
          receiving Party’s direct or indirect unauthorised disclosure);
        </span>
      </p>

      <p
        class="MsoNormal"
        style="
          margin-left: 56.7pt;
          text-align: justify;
          text-indent: -28.35pt;
          border: none;
        "
      >
        <span style="font-size: 11pt"
          >(b)      any Confidential Information which was made available to the
          receiving Party on a non-confidential basis prior to disclosure by the
          disclosing Party; or</span
        >
      </p>

      <p
        class="MsoNormal"
        style="
          margin-left: 56.7pt;
          text-align: justify;
          text-indent: -28.35pt;
          border: none;
        "
      >
        <span style="font-size: 11pt"
          >(c)      any use or disclosure authorised by the disclosing Party or
          as required by law.</span
        >
      </p>

      <p
        class="MsoNormal"
        style="
          margin-left: 28.35pt;
          text-align: justify;
          text-indent: -28.35pt;
          border: none;
        "
      >
        <span style="font-size: 11pt"
          >8.3     Each Party may disclose Confidential Information to its
          professional advisors who have a specific need to know such
          information, provided that such professional advisors are:
        </span>
      </p>

      <p
        class="MsoNormal"
        style="
          margin-left: 56.7pt;
          text-align: justify;
          text-indent: -28.35pt;
          border: none;
        "
      >
        <span style="font-size: 11pt"
          >(a)      informed of the confidential nature of the Confidential
          Information before disclosure, and
        </span>
      </p>

      <p
        class="MsoNormal"
        style="
          margin-left: 56.7pt;
          text-align: justify;
          text-indent: -28.35pt;
          border: none;
        "
      >
        <span style="font-size: 11pt"
          >(b)      required to comply with the provisions of this clause in
          respect of such information and have entered into a similar
          confidentiality undertaking with the relevant Party.</span
        >
      </p>

      <p
        class="MsoNormal"
        style="
          margin-left: 28.35pt;
          text-align: justify;
          text-indent: -28.35pt;
          border: none;
        "
      >
        <span style="font-size: 11pt"
          >8.4     Upon the request of the disclosing Party, the receiving Party
          shall, within seven (7) days of receipt of such request, return or
          destroy all Confidential Information and any notes, correspondence,
          documents or other records containing Confidential Information,
          including all copies thereof. The receiving Party shall certify the
          fact of having destroyed the Confidential Information in writing to
          the disclosing Party.</span
        >
      </p>

      <p
        class="MsoNormal"
        style="
          margin-left: 28.35pt;
          text-align: justify;
          text-indent: -28.35pt;
          border: none;
        "
      >
        <span style="font-size: 11pt"
          >8.5     Notwithstanding Clause 8.4, upon expiration or termination of
          this Agreement for any reason, the receiving Party shall be entitled
          to retain archival copies of Confidential Information received by the
          disclosing Party (a) which are generated by its automated computer
          archival back-up system; (b) for legal, regulatory and/or compliance
          purposes; and/or (c) insofar such Confidential Information has been
          incorporated in notes, reports, spreadsheets or similar relating to
          internal case studies or training materials; provided that all such
          retained Confidential Information shall remain subject to the
          confidentiality obligations as set out in this Clause.</span
        >
      </p>

      <p
        class="MsoNormal"
        style="
          margin-left: 28.35pt;
          text-align: justify;
          text-indent: -28.35pt;
          border: none;
        "
      >
        <span style="font-size: 11pt"
          >8.6     If either Party becomes legally compelled to disclose any
          Confidential Information by any court of competent jurisdiction or by
          any judicial, governmental, taxation or other supervisory or
          regulatory authority, stock exchange or similar body, or pursuant to
          any applicable rule or regulation, or in connection with any
          litigation, arbitration, administrative or other proceeding, the
          receiving Party shall provide to the disclosing Party prompt written
          notice (if legally permissible) and use commercially reasonable
          efforts to assist the disclosing Party in seeking a protective order
          or another appropriate remedy.</span
        >
      </p>

      <p
        class="MsoNormal"
        style="
          margin-left: 28.35pt;
          text-align: justify;
          text-indent: -28.35pt;
          border: none;
        "
      >
        <span style="font-size: 11pt"
          >8.7     In no event shall the receiving Party be deemed to have
          acquired any right or interest in or to any Confidential Information
          disclosed in connection with this Agreement, and all Confidential
          Information and other rights therein shall at all times remain the
          property of the disclosing Party (or its licensers as the case may
          be).</span
        >
      </p>

      <p
        class="MsoNormal"
        style="margin-left: 28.35pt; text-align: justify; text-indent: -28.35pt"
      >
        <span style="font-size: 11pt"
          >8.8     “Confidential Information” means all information or data of a
          confidential or proprietary nature disclosed by the disclosing Party
          to the receiving Party in connection with this Agreement, relating to
          the products, services, business or proposed business, finances,
          transactions, trade secrets, commercial strategies, Intellectual
          Property Rights (defined below), </span
        ><span style="font-size: 11pt"
          >patents, copyrights, trademarks, formulas, processes, hardware and
          software designs, architecture and schematics, workflow processes,
          project process development plans or methodologies, source code or
          object code, user interfaces, database development work, hardware
          types and configurations, customer lists, supplier lists, personnel
          information, marketing plans, financial projections, pricing
          information, business plans, business methods, </span
        ><span style="font-size: 11pt"
          >materials, structures and other related documents and/or data of the
          disclosing Party.</span
        >
      </p>

      <h2
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          margin-bottom: 0.0001pt;
          text-align: justify;
          text-indent: -28.35pt;
          vertical-align: baseline;
        "
      >
        <span style="font-size: 11pt"
          >8.9     Except as otherwise agreed, the Parties must not imply that
          their products are sponsored or endorsed by the other Party (or
          otherwise embellish their relationship).</span
        >
      </h2>

      <h2
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          margin-bottom: 0.0001pt;
          text-align: justify;
          text-indent: -28.35pt;
          vertical-align: baseline;
        "
      >
        <span style="font-size: 11pt"
          >8.10   Without prejudice to any other rights or remedies that the
          Parties may have, the Parties agree that damages may not be an
          adequate remedy for any breach of this Clause 7 and that the Parties
          shall be entitled to seek the remedies of injunction, specific
          performance and/or other equitable relief for any threatened or actual
          breach of any confidentiality clauses herein.</span
        >
      </h2>

      <p
        class="MsoNormal"
        style="margin-left: 28.35pt; text-align: justify; text-indent: -28.35pt"
      >
        <b
          ><span lang="EN-SG" style="font-size: 14pt"
            >9.       INTELLECTUAL PROPERTY</span
          ></b
        >
      </p>

      <p
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          text-align: justify;
          text-indent: -28.35pt;
          vertical-align: baseline;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt"
          >9.1     During the Term of this Agreement, and subject to the other
          Party’s prior approval in writing, each Party grants to the other
          Party a limited, non-exclusive, non-transferable, non-sublicensable,
          world-wide, royalty-free, revocable right to access and use each
          other’s marks and logos in its respective website and social media
          solely to market and promote the relationship contemplated by this
          Agreement. Either Party may revoke the foregoing license at any time
          by giving the other Party written notice. Unless revoked sooner in
          accordance with this Clause, the foregoing license terminates
          automatically upon expiration or termination of this Agreement, as the
          case may be.</span
        >
      </p>

      <p
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 0.5in;
          text-align: justify;
          text-indent: -0.5in;
          vertical-align: baseline;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt">&nbsp;</span>
      </p>

      <p
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          text-align: justify;
          text-indent: -28.35pt;
          vertical-align: baseline;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt"
          >9.2     Either Party confirms and agrees that:
        </span>
      </p>

      <p
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 1in;
          text-align: justify;
          text-indent: -0.5in;
          vertical-align: baseline;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt">&nbsp;</span>
      </p>

      <p
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 56.7pt;
          text-align: justify;
          text-indent: -28.35pt;
          vertical-align: baseline;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt"
          >(a)      it shall use the other Party’s marks and logos in a lawful
          manner and only as permitted hereunder;
        </span>
      </p>

      <p
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 1in;
          text-align: justify;
          vertical-align: baseline;
        "
      >
        <span lang="EN-SG" style="font-size: 10pt">&nbsp;</span>
      </p>

      <p
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 56.7pt;
          text-align: justify;
          text-indent: -28.35pt;
          vertical-align: baseline;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt"
          >(b)      it shall use the other Party’s marks and logos in strict
          compliance with all formats, guidelines, standards and other
          requirements prescribed by such other Party;
        </span>
      </p>

      <p
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 1in;
          text-align: justify;
          vertical-align: baseline;
        "
      >
        <span lang="EN-SG" style="font-size: 10pt">&nbsp;</span>
      </p>

      <p
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 56.7pt;
          text-align: justify;
          text-indent: -28.35pt;
          vertical-align: baseline;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt"
          >(c)      the other Party’s marks and logos are and shall remain the
          sole property of such other Party; and
        </span>
      </p>

      <p
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 1in;
          text-align: justify;
          vertical-align: baseline;
        "
      >
        <span lang="EN-SG" style="font-size: 10pt">&nbsp;</span>
      </p>

      <p
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 56.7pt;
          text-align: justify;
          text-indent: -28.35pt;
          vertical-align: baseline;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt"
          >(d)      nothing in this Agreement confers any right of ownership in
          any marks or logos of the other Party.</span
        >
      </p>

      <p
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 0.5in;
          text-align: justify;
          text-indent: -0.5in;
          vertical-align: baseline;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt">&nbsp;</span>
      </p>

      <p
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          text-align: justify;
          text-indent: -28.35pt;
          vertical-align: baseline;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt"
          >9.3     For the purpose of this Agreement, “<b tyle="font-size: 15pt"
            >Intellectual Property Rights</b
          >” means all intellectual property rights throughout the world,
          attributable to any of the Parties, including (but not limited to)
          registered and unregistered copyright, rights in inventions (whether
          or not patentable), patents, know-how, </span
        ><span style="font-size: 11pt"
          >methodologies, processes, technologies or algorithms,</span
        ><span lang="EN-SG" style="font-size: 10pt">
          authors’ rights, rights of attribution, slogans, s</span
        ><span style="font-size: 11pt">ystems, software testing tools,</span
        ><span lang="EN-SG" style="font-size: 11pt">
          trade secrets, trademarks, service marks, brand names, design,
          database rights and rights in data, utility models, domain names and
          all similar rights and, in each case:</span
        >
      </p>

      <p
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 1in;
          text-align: justify;
          text-indent: -0.5in;
          vertical-align: baseline;
        "
      >
        <span style="font-size: 11pt">&nbsp;</span>
      </p>

      <p
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 56.7pt;
          text-align: justify;
          text-indent: -28.35pt;
          vertical-align: baseline;
        "
      >
        <span style="font-size: 11pt"
          >(a)      including any applications to protect or register such
          rights;
        </span>
      </p>

      <p
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 1in;
          text-align: justify;
          text-indent: -0.5in;
          vertical-align: baseline;
        "
      >
        <span style="font-size: 11pt">&nbsp;</span>
      </p>

      <p
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 56.7pt;
          text-align: justify;
          text-indent: -28.35pt;
          vertical-align: baseline;
        "
      >
        <span style="font-size: 11pt"
          >(b)      including all renewals and extensions of such rights or
          applications; and</span
        >
      </p>

      <p
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 56.7pt;
          text-align: justify;
          text-indent: -28.35pt;
          vertical-align: baseline;
        "
      >
        <span style="font-size: 11pt">&nbsp;</span>
      </p>

      <p
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 56.7pt;
          text-align: justify;
          text-indent: -28.35pt;
          vertical-align: baseline;
        "
      >
        <span style="font-size: 11pt"
          >(c)      whether vested, contingent or future.</span
        >
      </p>

      <p
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 56.7pt;
          text-align: justify;
          text-indent: -28.35pt;
          vertical-align: baseline;
        "
      >
        <span style="font-size: 11pt">&nbsp;</span>
      </p>

      <p
        class="MsoNormal"
        style="margin-left: 28.35pt; text-align: justify; text-indent: -28.35pt"
      >
        <b
          ><span lang="EN-SG" style="font-size: 14pt"
            >10.     MARKETING AND PUBLICITY</span
          ></b
        ><span style="font-size: 11pt">    </span>
      </p>

      <p
        class="MsoNormal"
        style="margin-left: 28.35pt; text-align: justify; text-indent: -28.35pt"
      >
        <span style="font-size: 11pt"
          >10.1   The Parties shall work together and cooperate with each other
          to create, implement and manage a joint marketing strategy to promote
          the Shopmatic Platform and the Partner, as well as any other services,
          benefits, or advantages that the Parties may mutually agree upon from
          time to time. The Parties shall discuss and mutually agree on the
          timelines, targets, milestones, operational processes, and any other
          aspects required for the success of their collaboration.</span
        >
      </p>

      <p
        class="MsoNormal"
        style="margin-left: 28.35pt; text-align: justify; text-indent: -28.35pt"
      >
        <span style="font-size: 11pt"
          >10.2   Nothing in Clause 10 shall be construed as limiting the
          obligations of the Parties under Clause 9.</span
        >
      </p>

      <p
        class="MsoNormal"
        style="margin-left: 28.35pt; text-align: justify; text-indent: -28.35pt"
      >
        <b
          ><span lang="EN-SG" style="font-size: 14pt"
            >11.     DATA PROTECTION</span
          ></b
        >
      </p>

      <p
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 0.5in;
          text-align: justify;
          text-indent: -0.5in;
          vertical-align: baseline;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt"
          >11.1      For the purpose of this Agreement, “Personal Data” shall
          mean data, whether true or not, about an individual who can be
          identified (a) from that data; or (b) from that data and other
          information to which a Party has or is likely to have access</span
        ><span style="font-size: 11pt">.</span>
      </p>

      <p
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 0.5in;
          text-align: justify;
          text-indent: -0.5in;
          vertical-align: baseline;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt">&nbsp;</span>
      </p>

      <p
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 0.5in;
          text-align: justify;
          text-indent: -0.5in;
          vertical-align: baseline;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt"
          >11.2      Each Party shall comply in all respects with its
          obligations under the personal data laws in relation to the management
          and protection of Personal Data collected, accessed, received and/or
          processed in connection with this Agreement.</span
        >
      </p>

      <p
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 0.5in;
          text-align: justify;
          text-indent: -0.5in;
          vertical-align: baseline;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt">&nbsp;</span>
      </p>

      <p
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 0.5in;
          text-align: justify;
          text-indent: -0.5in;
          vertical-align: baseline;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt"
          >11.3      The Partner warrants that it has the legal right to
          disclose the Personal Data to Shopmatic in connection with this
          Agreement.
        </span>
      </p>

      <p
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 0.5in;
          text-align: justify;
          text-indent: -0.5in;
          vertical-align: baseline;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt">&nbsp;</span>
      </p>

      <p
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          text-align: justify;
          text-indent: -28.35pt;
          vertical-align: baseline;
        "
      >
        <b
          ><span lang="EN-SG" style="font-size: 14pt"
            >12.     CUSTOMER DATA AND REFERRED PERSONS DATA</span
          ></b
        >
      </p>

      <p style="margin: 0in; text-align: justify; vertical-align: baseline">
        <b><span lang="EN-SG" style="font-size: 11pt">&nbsp;</span></b>
      </p>

      <p
        class="MsoNormal"
        style="margin-left: 28.35pt; text-align: justify; text-indent: -28.35pt"
      >
        <span lang="EN-SG" style="font-size: 11pt">12.1   </span
        ><span style="font-size: 11pt"
          >The Parties shall co own the information and data in relation to the
          customers who have accessed the Shopmatic Platform via the co-branded
          Landing Page as provided on the Partner’s website (“Referred Persons”
          and “Referred Persons Data”).  Either Party shall be free to use the
          Referred Person Data for the purposes as mentioned under this
          Agreement.</span
        >
      </p>

      <p
        class="MsoNormal"
        style="margin-left: 28.35pt; text-align: justify; text-indent: -28.35pt"
      >
        <span style="font-size: 11pt"
          >12.2   Regardless of the termination or expiration of this Agreement,
          Shopmatic shall have full and irrevocable ownership of all Customers’
          data and accounts to the Shopmatic Platform resulting from this
          Agreement and, accordingly, the Partner shall have full and
          irrevocable ownership of all Customers’ accounts relating to the
          Partner’s products resulting from this Agreement. For the avoidance of
          doubt, in case the Customer is a customer of both Shopmatic and the
          Partner at the same time, each Party shall be the sole owner for that
          Customer’s account under their particular products.</span
        >
      </p>

      <p
        class="Body1"
        style="margin-bottom: 0in; text-align: justify; line-height: normal"
      >
        <span lang="EN-SG" style="font-size: 11pt">&nbsp;</span>
      </p>

      <p
        class="CorpAgrL3"
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          text-indent: -28.35pt;
          line-height: normal;
        "
      >
        <b
          ><span lang="EN-SG" style="font-size: 14pt"
            >13.     EXPIRY OR TERMINATION</span
          ></b
        >
      </p>

      <p
        class="CorpAgrL3"
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          text-indent: 0in;
          line-height: normal;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt; background: white"
          >&nbsp;</span
        >
      </p>

      <p
        class="CorpAgrL3"
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          text-indent: -28.35pt;
          line-height: normal;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt; background: white"
          >13.1   </span
        ><span style="font-size: 11pt"
          >Either Party may terminate this Agreement by giving the other Party
          not less than sixty (60) days written notice of termination
          (“Termination Notice”) after the end of the Minimum Term</span
        ><span lang="EN-SG" style="font-size: 11pt; background: white">. </span>
      </p>

      <p
        class="CorpAgrL3"
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          text-indent: -28.35pt;
          line-height: normal;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt; background: white"
          >&nbsp;</span
        >
      </p>

      <p
        class="CorpAgrL3"
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          text-indent: -28.35pt;
          line-height: normal;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt; background: white"
          >13.2   The Partner will pay to Shopmatic Early Termination Fees by
          way of liquidated damages in a sum as specified in Schedule 2 in the
          event of the termination of this Agreement by the Partner during the
          Minimum Term.</span
        >
      </p>

      <p
        class="MsoNormal"
        style="margin-left: 28.35pt; text-align: justify; text-indent: -28.35pt"
      >
        <span style="font-size: 11pt"
          >13.3   Notwithstanding Clause 13.1, either Party may terminate this
          Agreement immediately in any of the following events:</span
        >
      </p>

      <p
        class="MsoHeading7"
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 56.7pt;
          margin-bottom: 0.0001pt;
          text-indent: -28.35pt;
          line-height: normal;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt; font-style: normal"
          >(a)      a Party is subject to judicial management</span
        ><span lang="EN-SG" style="font-size: 11pt; font-style: normal"> </span
        ><span lang="EN-SG" style="font-size: 11pt; font-style: normal"
          >or corporate voluntary arrangement;
        </span>
      </p>

      <p
        class="MsoHeading7"
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 1in;
          margin-bottom: 0.0001pt;
          text-align: justify;
          text-indent: -34.75pt;
          line-height: normal;
          page-break-after: auto;
        "
      >
        <span lang="EN-SG" style="font-size: 10pt; font-style: normal"
          >&nbsp;</span
        >
      </p>

      <p
        class="MsoHeading7"
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 56.7pt;
          margin-bottom: 0.0001pt;
          text-align: justify;
          text-indent: -28.35pt;
          line-height: normal;
          page-break-after: auto;
        "
      >
        <span lang="EN-SG" style="font-size: 10pt; font-style: normal"
          >(b)      a Party files for voluntary winding up or becomes or is
          deemed insolvent;</span
        >
      </p>

      <p
        class="MsoHeading7"
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 56.7pt;
          margin-bottom: 0.0001pt;
          text-align: justify;
          text-indent: -28.35pt;
          line-height: normal;
          page-break-after: auto;
        "
      >
        <span lang="EN-SG" style="font-size: 10pt; font-style: normal"
          >(c)      a Party is unable or admits inability to pay its or suspends
          or threatens to suspend making payment on any of its debts;</span
        >
      </p>

      <p
        class="MsoHeading7"
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 56.7pt;
          margin-bottom: 0.0001pt;
          text-align: justify;
          text-indent: -28.35pt;
          line-height: normal;
          page-break-after: auto;
        "
      >
        <span lang="EN-SG" style="font-size: 10pt; font-style: normal"
          >&nbsp;</span
        >
      </p>

      <p
        class="MsoHeading7"
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 56.7pt;
          margin-bottom: 0.0001pt;
          text-align: justify;
          text-indent: -28.35pt;
          line-height: normal;
          page-break-after: auto;
        "
      >
        <span lang="EN-SG" style="font-size: 10pt; font-style: normal"
          >(d)      a Party has an order made against it or a resolution passed
          for its administration, winding-up, liquidation or dissolution or any
          other corporate step or legal proceeding is taken with a view to the
          same (otherwise than for the purposes of a solvent amalgamation or
          reconstruction);
        </span>
      </p>

      <p
        class="MsoHeading7"
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 56.7pt;
          margin-bottom: 0.0001pt;
          text-align: justify;
          text-indent: -28.35pt;
          line-height: normal;
          page-break-after: auto;
        "
      >
        <span lang="EN-SG" style="font-size: 10pt; font-style: normal"
          >&nbsp;</span
        >
      </p>

      <p
        class="MsoHeading7"
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 56.7pt;
          margin-bottom: 0.0001pt;
          text-align: justify;
          text-indent: -28.35pt;
          line-height: normal;
          page-break-after: auto;
        "
      >
        <span lang="EN-SG" style="font-size: 10pt; font-style: normal"
          >(e)      a Party has an administrative receiver, receiver, manager,
          liquidator, administrator, trustee or similar officer appointed over
          all or any substantial part of its assets;
        </span>
      </p>

      <p
        class="MsoHeading7"
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 56.7pt;
          margin-bottom: 0.0001pt;
          text-align: justify;
          text-indent: -28.35pt;
          line-height: normal;
          page-break-after: auto;
        "
      >
        <span lang="EN-SG" style="font-size: 10pt; font-style: normal"
          >&nbsp;</span
        >
      </p>

      <p
        class="MsoHeading7"
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 56.7pt;
          margin-bottom: 0.0001pt;
          text-align: justify;
          text-indent: -28.35pt;
          line-height: normal;
          page-break-after: auto;
        "
      >
        <span lang="EN-SG" style="font-size: 10pt; font-style: normal"
          >(f)       a Party enters into or proposes any compromise, composition
          or arrangement with its creditors generally; or
        </span>
      </p>

      <p
        class="MsoHeading7"
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 56.7pt;
          margin-bottom: 0.0001pt;
          text-align: justify;
          text-indent: -28.35pt;
          line-height: normal;
          page-break-after: auto;
        "
      >
        <span lang="EN-SG" style="font-size: 10pt; font-style: normal"
          >&nbsp;</span
        >
      </p>

      <p
        class="MsoHeading7"
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 56.7pt;
          margin-bottom: 0.0001pt;
          text-align: justify;
          text-indent: -28.35pt;
          line-height: normal;
          page-break-after: auto;
        "
      >
        <span lang="EN-SG" style="font-size: 10pt; font-style: normal"
          >(g)      a Party suffers or carries out anything analogous to the
          foregoing in any applicable jurisdiction.</span
        >
      </p>

      <p class="MsoNormal">
        <span lang="EN-SG" style="font-size: 11pt">&nbsp;</span>
      </p>

      <p
        class="MsoNormal"
        style="margin-left: 28.35pt; text-align: justify; text-indent: -28.35pt"
      >
        <span lang="EN-SG" style="font-size: 11pt"
          >13.4   Notwithstanding Clause 13.1 hereinabove, Shopmatic may
          terminate this Agreement immediately at any time, by giving a written
          notice to the Partner if any amount due to be paid to Shopmatic by the
          Partner under this Agreement is unpaid by the due date and remains
          unpaid upon the date that the written notice is given.
        </span>
      </p>

      <p
        class="MsoNormal"
        style="margin-left: 28.35pt; text-align: justify; text-indent: -28.35pt"
      >
        <span style="font-size: 11pt"
          >13.5   Upon expiry of the Term or termination of this
          Agreement:</span
        >
      </p>

      <h6
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 1in;
          margin-bottom: 0.0001pt;
          text-align: justify;
          text-indent: -0.5in;
          line-height: normal;
          page-break-after: auto;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt"
          >(a)        each Party shall immediately cease all use of the
          Intellectual Property Rights (as defined below) belonging to the other
          Party;</span
        >
      </h6>

      <h6
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 1in;
          margin-bottom: 0.0001pt;
          text-align: justify;
          text-indent: -0.5in;
          line-height: normal;
          page-break-after: auto;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt"> </span>
      </h6>

      <p
        class="MsoNormal"
        style="margin-left: 1in; text-align: justify; text-indent: -0.5in"
      >
        <span lang="EN-SG" style="font-size: 11pt"
          >(b)        each Party shall immediately cease all use of the
          Confidential Information (as defined below) belonging to the other
          Party.</span
        >
      </p>

      <p
        class="MsoNormal"
        style="margin-left: 28.35pt; text-align: justify; text-indent: -28.35pt"
      >
        <span style="font-size: 11pt">13.6   </span
        ><span lang="EN-SG" style="font-size: 11pt"
          >Clause 5 (Payment and Taxes), Clause 8 (Confidentiality), Clause 11
          (Data Protection), Clause 12 (Customer Data and Referred Persons
          Data), Clause 14 (Indemnity), Clause 15 (Limitations and Exclusions of
          Liability), Clause 16 (Non-Solicitation of Personnel), Clause 17
          (Force Majeure), Clause 18 (Assignment), Clause 20 (Governing Law,
          Jurisdiction and Dispute Resolution), Clause 21 (Remedies and Waiver),
          Clause 22 (Severability), Clause 23 (Third Party Rights), Clause 24
          (Variation), Clause 25 (No Agency and No Power to Bind), Clause 26 (As
          Is Basis), Clause 27 (Entire Agreement), Clause 29
          (Interpretation).</span
        >
      </p>

      <p class="MsoNormal" style="margin-left: 28.35pt; text-align: justify">
        <span style="font-size: 11pt"
          >shall survive the expiration or termination of this Agreement.
        </span>
      </p>

      <p
        class="MsoNormal"
        style="margin-left: 28.35pt; text-align: justify; text-indent: -28.35pt"
      >
        <b
          ><span lang="EN-SG" style="font-size: 14pt"
            >14.     INDEMNITY</span
          ></b
        >
      </p>

      <p
        class="MsoNormal"
        style="
          margin-left: 0.5in;
          text-align: justify;
          text-indent: -0.5in;
          vertical-align: baseline;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt; display: none">&nbsp;</span>
      </p>

      <p
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          text-align: justify;
          text-indent: -28.35pt;
          vertical-align: baseline;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt"
          >14.1   Each Party agrees to indemnify, defend and hold harmless the
          other Party and its officers, directors, shareholders, employees and
          agents, and their respective successors and permitted assigns, from
          and against any claim, loss, damage, charges, liabilities, expenses
          and costs (including reasonable legal fees) incurred or suffered by
          the other Party and arising directly or indirectly as a result of any
          breach by a Party to this Agreement (“<b>Indemnity Event</b>”) and
          against any claims, suits, actions, proceedings instituted or likely
          to be instituted against Shopmatic, including (but not limited to)
          those arising in connection with:
        </span>
      </p>

      <p
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 1in;
          text-align: justify;
          text-indent: -0.5in;
          vertical-align: baseline;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt">&nbsp;</span>
      </p>

      <p
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 56.7pt;
          text-align: justify;
          text-indent: -28.35pt;
          vertical-align: baseline;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt"
          >(a)      gross negligence, misconduct or wilful default of the Party;
        </span>
      </p>

      <p
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 56.7pt;
          text-align: justify;
          text-indent: -28.35pt;
          vertical-align: baseline;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt">&nbsp;</span>
      </p>

      <p
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 56.7pt;
          text-align: justify;
          text-indent: -28.35pt;
          vertical-align: baseline;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt"
          >(b)      any misrepresentation, omission of any material fact or any
          misleading or incorrect information furnished by the Party;
        </span>
      </p>

      <p
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 56.7pt;
          text-align: justify;
          text-indent: -28.35pt;
          vertical-align: baseline;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt">&nbsp;</span>
      </p>

      <p
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 56.7pt;
          text-align: justify;
          text-indent: -28.35pt;
          vertical-align: baseline;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt"
          >(c)      any breach of the representations and warranties as set out
          in Clause 6; or
        </span>
      </p>

      <p
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 1in;
          text-align: justify;
          text-indent: -0.5in;
          vertical-align: baseline;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt">&nbsp;</span>
      </p>

      <p
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 56.7pt;
          text-align: justify;
          text-indent: -28.35pt;
          vertical-align: baseline;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt"
          >(d)      any third-party claim of infringement or misappropriation of
          Intellectual Property Rights.</span
        >
      </p>

      <p
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 1in;
          text-align: justify;
          text-indent: -0.5in;
          vertical-align: baseline;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt">&nbsp;</span>
      </p>

      <p
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          text-align: justify;
          text-indent: -28.35pt;
          vertical-align: baseline;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt"
          >14.2   Each Party must:</span
        >
      </p>

      <p
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          text-align: justify;
          text-indent: -28.35pt;
          vertical-align: baseline;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt">&nbsp;</span>
      </p>

      <p
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 56.7pt;
          text-align: justify;
          text-indent: -28.35pt;
          vertical-align: baseline;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt"
          >(a)      upon being aware of an actual or potential Indemnity Event,
          notify the other Party;</span
        >
      </p>

      <p
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 56.7pt;
          text-align: justify;
          text-indent: -28.35pt;
          vertical-align: baseline;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt">&nbsp;</span>
      </p>

      <p
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 56.7pt;
          text-align: justify;
          text-indent: -28.35pt;
          vertical-align: baseline;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt"
          >(b)      provide the other Party with all such assistance as may be
          reasonably requested by the other Party in relation to the Indemnity
          Event;</span
        >
      </p>

      <p
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 56.7pt;
          text-align: justify;
          text-indent: -28.35pt;
          vertical-align: baseline;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt">&nbsp;</span>
      </p>

      <p
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 56.7pt;
          text-align: justify;
          text-indent: -28.35pt;
          vertical-align: baseline;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt"
          >(c)      allow the other Party </span
        ><span lang="EN-SG" style="font-size: 11pt"
          >the exclusive conduct of all disputes<span
            >, proceedings, negotiations and settlements with third parties
            relating to the Indemnity Event; and</span
          ></span
        >
      </p>

      <p
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 56.7pt;
          text-align: justify;
          text-indent: -28.35pt;
          vertical-align: baseline;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt">&nbsp;</span>
      </p>

      <p
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 56.7pt;
          text-align: justify;
          text-indent: -28.35pt;
          vertical-align: baseline;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt"
          >(d)      not admit liability to any third party in connection with
          the Indemnity Event or settle any disputes or proceedings involving a
          third party and relating to the Indemnity Event without the prior
          written consent of the other Party.</span
        >
      </p>

      <p
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          text-align: justify;
          text-indent: -28.35pt;
          vertical-align: baseline;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt">&nbsp;</span>
      </p>

      <p
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          text-align: justify;
          text-indent: -28.35pt;
          vertical-align: baseline;
        "
      >
        <b
          ><span lang="EN-SG" style="font-size: 14pt"
            >15.     LIMITATIONS AND EXCLUSIONS OF LIABILITY</span
          ></b
        >
      </p>

      <p
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 56.7pt;
          text-align: justify;
          text-indent: -28.35pt;
          vertical-align: baseline;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt">&nbsp;</span>
      </p>

      <p
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          text-align: justify;
          text-indent: -28.35pt;
          vertical-align: baseline;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt"
          >15.1   Nothing in this Agreement shall exclude or in any way limit
          either Party’s liability for fraud, wilful misconduct, death or
          personal injury caused by its negligence or any other liability to the
          extent such liability may not be excluded or limited as a matter of
          law.</span
        >
      </p>

      <p
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 0.5in;
          text-align: justify;
          text-indent: -0.5in;
          vertical-align: baseline;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt">&nbsp;</span>
      </p>

      <p
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          text-align: justify;
          text-indent: -28.35pt;
          vertical-align: baseline;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt"
          >15.2   Notwithstanding any in this Agreement, Shopmatic’s liabilities
          and responsibilities to the Customers using the Shopmatic Platform
          shall be governed in accordance with the Shopmatic Agreements.
          Shopmatic shall not be liable for any loss or damage howsoever arising
          as a result of any use of the Shopmatic Platform by any party or as a
          result of any Shopmatic Platform maintenance (whether or not scheduled
          or emergency), or any unusual activity beyond the standard maintenance
          procedures and events beyond Shopmatic’s control, or any suspension
          and/or disruption of the Shopmatic Platform, or any server
          upgrade/downgrade, infrastructure changes, catastrophic events or
          disaster recovery efforts.</span
        >
      </p>

      <p
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          text-align: justify;
          text-indent: -28.35pt;
          vertical-align: baseline;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt">&nbsp;</span>
      </p>

      <p
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          text-align: justify;
          text-indent: -28.35pt;
          vertical-align: baseline;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt"
          >15.3   Shopmatic shall not be liable whether in tort (including for
          negligence or breach of statutory duty), contract, misrepresentation,
          restitution or otherwise for any loss or corruption of data or
          information, or pure economic loss, or for any special, indirect or
          consequential loss, costs, damages, charges or expenses however
          arising under this Agreement and the total aggregate liability
          (including in respect of the Indemnity, tort (including negligence or
          breach of statutory duty), misrepresentation, restitution or
          otherwise, arising in connection with the performance or contemplated
          performance of this Agreement shall be limited to the total amount
          paid by the Partner to Shopmatic under this Agreement in the twelve
          (12) month period preceding the commencement of the event/(s).
        </span>
      </p>

      <p
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          text-align: justify;
          text-indent: -28.35pt;
          vertical-align: baseline;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt">&nbsp;</span>
      </p>

      <p
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          text-align: justify;
          text-indent: -28.35pt;
          vertical-align: baseline;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt"
          >15.4   In the event of a dispute reaching to the Court or
          Arbitration, as the case may be, it is agreed that the aggregate
          liability of each Party to the other Party under this Agreement shall
          not exceed the greater of:</span
        >
      </p>

      <p
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          text-align: justify;
          text-indent: -28.35pt;
          vertical-align: baseline;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt">          </span>
      </p>

      <p
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 56.7pt;
          text-align: justify;
          text-indent: -28.35pt;
          vertical-align: baseline;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt"
          >(a)      the total amount paid and payable by the Partner to
          Shopmatic upto the date that those liabilities are confirmed by Court
          or Arbitration.</span
        >
      </p>

      <p
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 56.7pt;
          text-align: justify;
          text-indent: -28.35pt;
          vertical-align: baseline;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt">&nbsp;</span>
      </p>

      <p
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 56.7pt;
          text-align: justify;
          text-indent: -28.35pt;
          vertical-align: baseline;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt"
          >(b)      the total amount paid and payable by the Partner to
          Shopmatic up to the date that those liabilities are confirmed by Court
          or Arbitration.
        </span>
      </p>

      <p
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 0.5in;
          text-align: justify;
          text-indent: -0.5in;
          vertical-align: baseline;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt">&nbsp;</span>
      </p>

      <p
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          text-align: justify;
          text-indent: -28.35pt;
          vertical-align: baseline;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt"
          >15.5   Nothing in this Agreement excluded the liability of the
          Partner for any breach, infringement or misappropriation of
          Shopmatic’s Intellectual Property Rights.
        </span>
      </p>

      <p
        class="CorpAgrL3"
        style="margin: 0in; text-indent: 0in; line-height: normal"
      >
        <span lang="EN-SG" style="font-size: 11pt">&nbsp;</span>
      </p>

      <p
        class="CorpAgrL3"
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          text-indent: -28.35pt;
          line-height: normal;
        "
      >
        <b
          ><span lang="EN-SG" style="font-size: 14pt"
            >16.     NON -SOLICIATION OF PERSONNEL</span
          ></b
        >
      </p>

      <p
        class="CorpAgrL3"
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          text-indent: -28.35pt;
          line-height: normal;
        "
      >
        <b><span lang="EN-SG" style="font-size: 11pt">&nbsp;</span></b>
      </p>

      <p
        class="CorpAgrL3"
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          text-indent: -28.35pt;
          line-height: normal;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt"
          >16.1   Either Party must not, without the prior written consent of
          the other Party, either during the Term or within the period of six
          (6) months following the Term, engage, employ or solicit for
          engagement or employment, any employee or subcontractor of the other
          Party who has been involved in any ay in the negotiation or
          performance of this Agreement.
        </span>
      </p>

      <p
        class="CorpAgrL3"
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          text-indent: -28.35pt;
          line-height: normal;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt">&nbsp;</span>
      </p>

      <p
        class="CorpAgrL3"
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          text-indent: -28.35pt;
          line-height: normal;
        "
      >
        <b
          ><span lang="EN-SG" style="font-size: 14pt"
            >17.     FORCE MAJEURE</span
          ></b
        >
      </p>

      <p
        class="CorpAgrL3"
        style="margin: 0in; text-indent: 0in; line-height: normal"
      >
        <b><span lang="EN-SG" style="font-size: 11pt">&nbsp;</span></b>
      </p>

      <p
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          text-align: justify;
          text-indent: -28.35pt;
          vertical-align: baseline;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt"
          >17.1  
          <span
            >Neither Party shall be liable for any loss or damage arising from
            its failure to perform any of its obligations under this Agreement,
            if such failure is the result of circumstances outside its control
            including but not limited to the outbreak of war, fire, floods,
            storms, governmental act, pandemic, explosion, civil commotion,
            riot, malicious damage, compliance with any law or governmental
            order or rule, accident, breakdown of plant or machinery, labour
            disputes of whatever nature, failure of s utility service or
            transport or telecommunications network, obstruction of any public
            or private road or highway, default of suppliers or sub-contractors 
            or any other any act of God (each a “<b>Force Majeure Event</b>”),
            provided that the other Party is notified of such an event.</span
          ></span
        >
      </p>

      <p
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          text-align: justify;
          text-indent: -28.35pt;
          vertical-align: baseline;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt">&nbsp;</span>
      </p>

      <p
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          text-align: justify;
          text-indent: -28.35pt;
          vertical-align: baseline;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt"
          >17.2   The Parties hereby acknowledge that while current events
          related to the COVID-19 pandemic are known, future impact of the
          outbreak are unforeseeable and shall be considered a Force Majeure
          Event to the extent that they prevent the performance of a Party’s
          obligations under this Agreement.
        </span>
      </p>

      <p
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          text-align: justify;
          text-indent: -28.35pt;
          vertical-align: baseline;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt">&nbsp;</span>
      </p>

      <p
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          text-align: justify;
          text-indent: -28.35pt;
          vertical-align: baseline;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt"
          >17.3   Notwithstanding the foregoing, should a Force Majeure Event
          suffered by a Party extend beyond three (3) months period, the other
          Party may then terminate this Agreement by a prior written notice of
          three (3) days to the non-performing Party.
        </span>
      </p>

      <p
        class="CorpAgrL3"
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          text-indent: -28.35pt;
          line-height: normal;
        "
      >
        <b><span lang="EN-SG" style="font-size: 11pt">&nbsp;</span></b>
      </p>

      <p
        class="CorpAgrL3"
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          text-indent: -28.35pt;
          line-height: normal;
        "
      >
        <b
          ><span lang="EN-SG" style="font-size: 14pt"
            >18.     ASSIGNMENT</span
          ></b
        >
      </p>

      <p
        class="CorpAgrL3"
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          text-indent: -28.35pt;
          line-height: normal;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt">&nbsp;</span>
      </p>

      <p
        class="MsoNormal"
        style="margin-left: 28.35pt; text-align: justify; text-indent: -28.35pt"
      >
        <span lang="EN-SG" style="font-size: 11pt"
          >18.1   Either Party must not assign, transfer or otherwise deal with
          the contractual rights and/or obligations under this Agreement without
          the prior written consent of the other Party. Such consent will not be
          unreasonably withheld or delayed, provided that each Party may assign
          the entirety of its rights and obligations under this Agreement to any
          affiliate of the said Party or to any successor to all or a
          substantial part of the business of the said Party from time to time.
        </span>
      </p>

      <p
        class="CorpAgrL3"
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          text-indent: -28.35pt;
          line-height: normal;
        "
      >
        <b><span lang="EN-SG" style="font-size: 14pt">19.     NOTICES</span></b>
      </p>

      <p
        class="MsoNormal"
        style="
          margin-left: 0.5in;
          text-align: justify;
          text-indent: -0.5in;
          text-autospace: ideograph-numeric;
        "
      >
        <span
          lang="EN-SG"
          style="font-size: 11pt; display: none; background: white"
          >&nbsp;</span
        >
      </p>

      <p
        class="CorpAgrL3"
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          text-indent: -28.35pt;
          line-height: normal;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt; background: white"
          >19.1   Any notice, request, demand, waiver, consent, approval or
          other communication permitted or required under this Agreement shall
          be in
          <a name="_DV_C652"
            >writing and delivered through courier, registered post or by
            emails. This Clause</a
          ><a name="_DV_M454"></a> is not intended to govern the day-to-day
          business communications necessary between the Parties in performing
          their obligations under the terms of this Agreement.</span
        >
      </p>

      <p
        class="CorpAgrL3"
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 0.5in;
          text-indent: -0.5in;
          line-height: normal;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt; background: white"
          >&nbsp;</span
        >
      </p>

      <p
        class="CorpAgrL3"
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          text-indent: -28.35pt;
          line-height: normal;
        "
      >
        <a name="_Ref206922331"></a><a name="_DV_M455"></a
        ><span lang="EN-SG" style="font-size: 11pt; background: white"
          >19.2   Any notice under Clause 12.1 shall be delivered</span
        ><span lang="EN-SG" style="font-size: 11pt; background: white">
          to the details as set out in Clause 6.4 of the </span
        ><span style="font-size: 11pt">MMSHOP Agreement </span
        ><span lang="EN-SG" style="font-size: 11pt; background: white"
          >or such other address or email address as notified by a Party to the
          other Party pursuant to this Clause.</span
        >
      </p>

      <p
        class="CorpAgrL3"
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 0.5in;
          text-indent: -0.5in;
          line-height: normal;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt; background: white"> </span>
      </p>

      <p
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          text-align: justify;
          text-indent: -28.35pt;
          vertical-align: baseline;
        "
      >
        <span style="font-size: 11pt"
          >19.3   Any notice delivered in accordance with Clause 12.1 above
          shall be deemed served:</span
        >
      </p>

      <p
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 1in;
          text-align: justify;
          text-indent: -0.5in;
          vertical-align: baseline;
        "
      >
        <span style="font-size: 11pt">&nbsp;</span>
      </p>

      <p
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 56.7pt;
          text-align: justify;
          text-indent: -28.35pt;
          vertical-align: baseline;
        "
      >
        <span style="font-size: 11pt"
          >(a)      in the case of by courier, on the date of delivery;
        </span>
      </p>

      <p
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 1in;
          text-align: justify;
          text-indent: -0.5in;
          vertical-align: baseline;
        "
      >
        <span style="font-size: 11pt">&nbsp;</span>
      </p>

      <p
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 56.7pt;
          text-align: justify;
          text-indent: -28.35pt;
          vertical-align: baseline;
        "
      >
        <span style="font-size: 11pt"
          >(b)      in case of registered post, two (2) calendar days after
          posting; or</span
        >
      </p>

      <p
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 1in;
          text-align: justify;
          text-indent: -0.5in;
          vertical-align: baseline;
        "
      >
        <span style="font-size: 11pt">&nbsp;</span>
      </p>

      <p
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 56.7pt;
          text-align: justify;
          text-indent: -28.35pt;
          vertical-align: baseline;
        "
      >
        <span style="font-size: 11pt"
          >(c)      in case of email, on the date the email was sent;</span
        >
      </p>

      <p
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 0.5in;
          text-align: justify;
          text-indent: -0.5in;
          vertical-align: baseline;
        "
      >
        <span style="font-size: 11pt">&nbsp;</span>
      </p>

      <p
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          text-align: justify;
          vertical-align: baseline;
        "
      >
        <span style="font-size: 11pt"
          >provided that in case of the date of receipt not being a business
          day, such notice shall be deemed to have been received by the
          receiving Party on the next business day.</span
        >
      </p>

      <p
        class="MsoNormal"
        style="margin-left: 0.5in; text-align: justify; text-indent: -0.5in"
      >
        <b><span lang="EN-SG" style="font-size: 11pt">&nbsp;</span></b>
      </p>

      <p
        class="MsoNormal"
        style="margin-left: 28.35pt; text-align: justify; text-indent: -28.35pt"
      >
        <b
          ><span lang="EN-SG" style="font-size: 14pt"
            >20.     GOVERNING LAW, JURISDICTION AND DISPUTE RESOLUTION</span
          ></b
        >
      </p>

      <p
        class="MsoNormal"
        style="
          margin-left: 0.5in;
          text-align: justify;
          text-indent: -0.5in;
          vertical-align: baseline;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt; display: none">&nbsp;</span>
      </p>

      <p
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          text-align: justify;
          text-indent: -28.35pt;
          vertical-align: baseline;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt"
          >20.1   The Agreement shall be governed by and construed in accordance
          with Clause 11 in the MMSHOP Agreement.</span
        >
      </p>

      <p
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          text-align: justify;
          text-indent: -28.35pt;
          vertical-align: baseline;
        "
      >
        <i><span lang="EN-SG" style="font-size: 11pt">&nbsp;</span></i>
      </p>

      <p
        class="MsoNormal"
        style="margin-left: 28.35pt; text-align: justify; text-indent: -28.35pt"
      >
        <b
          ><span lang="EN-SG" style="font-size: 14pt"
            >21.     <span>REMEDIES AND WAIVER</span></span
          ></b
        >
      </p>

      <p
        class="MsoNormal"
        style="margin-left: 28.35pt; text-align: justify; text-indent: -28.35pt"
      >
        <span lang="EN-SG" style="font-size: 11pt"
          >21.1   No breach of any provision of this Agreement will be waived
          express with the express written consent of the Party not in
          breach.      
        </span>
      </p>

      <p
        class="MsoNormal"
        style="margin-left: 28.35pt; text-align: justify; text-indent: -28.35pt"
      >
        <span lang="EN-SG" style="font-size: 11pt"
          >21.2   Any remedy or right conferred to a Party under this Agreement
          shall be in addition to and without prejudice to all other rights and
          remedies available to it by law.
        </span>
      </p>

      <p
        class="MsoNormal"
        style="margin-left: 28.35pt; text-align: justify; text-indent: -28.35pt"
      >
        <span lang="EN-SG" style="font-size: 11pt"
          >21.3   Any waiver of any breach of this Agreement shall not be deemed
          or construed to be further or continuing waiver or to apply to any
          succeeding breach of the provision or of any other provision of this
          Agreement.
        </span>
      </p>

      <p
        class="MsoNormal"
        style="margin-left: 28.35pt; text-align: justify; text-indent: -28.35pt"
      >
        <span lang="EN-SG" style="font-size: 11pt"
          >21.4   No failure to exercise and no delay in exercising on the part
          of any of the Parties any right, power or privilege hereunder shall
          operate as a waiver thereof nor shall any single or partial exercise
          of any right, power or privilege preclude any other or further
          exercise thereof or the exercise of any other right, power or
          privilege.</span
        >
      </p>

      <p
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          text-align: justify;
          text-indent: -28.35pt;
          vertical-align: baseline;
        "
      >
        <b
          ><span lang="EN-SG" style="font-size: 14pt"
            >22.     <span>SEVERABILITY </span></span
          ></b
        >
      </p>

      <p
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          text-align: justify;
          text-indent: -28.35pt;
          vertical-align: baseline;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt">&nbsp;</span>
      </p>

      <p
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          text-align: justify;
          text-indent: -28.35pt;
          vertical-align: baseline;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt"
          >22.1   If any provision or any portion of any provision of this
          Agreement is determined by any court or other competent authority to
          be unlawful, invalid, illegal or unenforceable in any respect for any
          reason, only such limited part shall be considered deleted from this
          Agreement and the validity, legality and enforceability of any such
          provision in every other respect and of the remaining provisions of
          this Agreement shall not in any way be affected or impaired and will
          continue to be in effect.
        </span>
      </p>

      <p
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          text-align: justify;
          text-indent: -28.35pt;
          vertical-align: baseline;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt">&nbsp;</span>
      </p>

      <p
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          text-align: justify;
          text-indent: -28.35pt;
          vertical-align: baseline;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt"
          >22.2   The Parties shall use reasonable endeavours and good faith to
          agree upon new provision/(s) that that shall as nearly as possible
          have the same commercial effect as the void, invalid, illegal or
          unenforceable provision as mentioned in Clause 15.1 above.
        </span>
      </p>

      <p
        class="CorpAgrL3"
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          text-indent: -28.35pt;
          line-height: normal;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt">&nbsp;</span>
      </p>

      <p
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          text-align: justify;
          text-indent: -28.35pt;
          vertical-align: baseline;
        "
      >
        <b
          ><span lang="EN-SG" style="font-size: 14pt"
            >23.     <span>THIRD-PARTY RIGHTS</span></span
          ></b
        ><span lang="EN-SG" style="font-size: 11pt">. </span>
      </p>

      <p
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          text-align: justify;
          text-indent: -28.35pt;
          vertical-align: baseline;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt">&nbsp;</span>
      </p>

      <p
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          text-align: justify;
          text-indent: -28.35pt;
          vertical-align: baseline;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt"
          >23.1   The provisions of this Agreement are for the benefit of the
          Parties and are not intended to benefit or be enforceable by any third
          party.</span
        >
      </p>

      <p
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          text-align: justify;
          text-indent: -28.35pt;
          vertical-align: baseline;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt">&nbsp;</span>
      </p>

      <p
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          text-align: justify;
          text-indent: -28.35pt;
          vertical-align: baseline;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt"
          >23.2   the exercise of the Parties’ rights under this Agreement is
          not subject to the consent of any third party.</span
        >
      </p>

      <p
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          text-align: justify;
          text-indent: -28.35pt;
          vertical-align: baseline;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt">&nbsp;</span>
      </p>

      <p
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          text-align: justify;
          text-indent: -28.35pt;
          vertical-align: baseline;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt"
          >23.3   Nothing in this Agreement shall be deemed to confer any right
          to enforce any term of this Agreement on anyone not a party to this
          Agreement. This Agreement shall not be construed in any respect to be
          a contract or agreement in whole or in part for the benefit of or
          binding upon anyone not a party to this Agreement.</span
        >
      </p>

      <p
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          text-align: justify;
          text-indent: -28.35pt;
          vertical-align: baseline;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt">&nbsp;</span>
      </p>

      <p
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          text-align: justify;
          text-indent: -28.35pt;
          vertical-align: baseline;
        "
      >
        <b
          ><span lang="EN-SG" style="font-size: 14pt"
            >24.     VARIATION</span
          ></b
        >
      </p>

      <p
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          text-align: justify;
          text-indent: -28.35pt;
          vertical-align: baseline;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt">&nbsp;</span>
      </p>

      <p
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          text-align: justify;
          text-indent: -28.35pt;
          vertical-align: baseline;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt"
          >24.1   No variation of this Agreement shall be valid or effective
          unless it is in writing, refers to this Agreement and is duly signed
          or executed by, or on behalf of, each Party.</span
        >
      </p>

      <p
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          text-align: justify;
          text-indent: -28.35pt;
          vertical-align: baseline;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt">&nbsp;</span>
      </p>

      <p
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          text-align: justify;
          text-indent: -28.35pt;
          vertical-align: baseline;
        "
      >
        <b
          ><span lang="EN-SG" style="font-size: 14pt"
            >25.     NO AGENCY AND NO POWER TO BIND</span
          ></b
        >
      </p>

      <p
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          text-align: justify;
          text-indent: -28.35pt;
          vertical-align: baseline;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt">&nbsp;</span>
      </p>

      <p
        class="CorpAgrL3"
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          text-indent: -28.35pt;
          line-height: normal;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt">25.1</span
        ><b><span lang="EN-SG" style="font-size: 11pt">   </span></b
        ><span lang="EN-SG" style="font-size: 11pt"
          >Nothing in this Agreement or any document referred to in it
          constitutes or shall be deemed to create a joint venture or
          partnership, employer-employee, agency relationship or other
          co-operative entity between the Parties for any purpose whatsoever,
          and it shall be construed exclusively as a service agreement between
          independent contracting parties.</span
        >
      </p>

      <p
        class="CorpAgrL3"
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 0.5in;
          text-indent: -0.5in;
          line-height: normal;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt">&nbsp;</span>
      </p>

      <p
        class="CorpAgrL3"
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          text-indent: -28.35pt;
          line-height: normal;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt"
          >25.2   Neither Party shall have no power or authority to bind or
          impose any obligation on the other Party and shall not purport to do
          so or hold itself out as capable of doing so. Nothing in this
          Agreement shall authorise one Party to enter into contractual
          relationship or incus obligations on behalf of the other Party.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="margin-left: 28.35pt; text-align: justify; text-indent: -28.35pt"
      >
        <b
          ><span lang="EN-SG" style="font-size: 11pt"
            >26.     AS IS BASIS.
          </span></b
        >
      </p>

      <p
        class="MsoListParagraph"
        style="margin-left: 28.35pt; text-align: justify; text-indent: -28.35pt"
      >
        <span lang="EN-SG" style="font-size: 11pt"
          >26.1   The Shopmatic Platform is provided on an “as is” and “as
          available” basis. Shopmatic and its affiliates expressly disclaims all
          warranties of any kind, whether express or implied, including but not
          limited to the implied warranties of merchantability, fitness for a
          particular purpose and non-infringement. Shopmatic makes no warranty
          that the Shopmatic Platform shall meet the Partner’s requirements or
          that it will be uninterrupted, timely, secure or error-free.
        </span>
      </p>

      <p
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          text-align: justify;
          text-indent: -28.35pt;
          vertical-align: baseline;
        "
      >
        <b
          ><span lang="EN-SG" style="font-size: 14pt"
            >27.     ENTIRE AGREEMENT.
          </span></b
        >
      </p>

      <p
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          text-align: justify;
          text-indent: -28.35pt;
          vertical-align: baseline;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt">&nbsp;</span>
      </p>

      <p
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          text-align: justify;
          text-indent: -28.35pt;
          vertical-align: baseline;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt"
          >27.1   The Parties agree that this Agreement constitutes the entire
          agreement between them relating to the subject matter hereof and
          supersedes all previous agreements, understandings and arrangements
          between them, whether in writing or oral in respect of the subject
          matter hereof.</span
        >
      </p>

      <p
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          text-align: justify;
          text-indent: -28.35pt;
          vertical-align: baseline;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt">&nbsp;</span>
      </p>

      <p
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          text-align: justify;
          text-indent: -28.35pt;
          vertical-align: baseline;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt"
          >27.2   Neither Party will have any remedy in respect of any
          misrepresentation (whether written or oral) made to it upon which it
          relied in entering to this Agreement.</span
        >
      </p>

      <p
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 0.5in;
          text-align: justify;
          text-indent: -0.5in;
          vertical-align: baseline;
        "
      >
        <span lang="EN-SG" style="font-size: 11pt">&nbsp;</span>
      </p>

      <p
        class="MsoNormal"
        style="
          margin-left: 28.35pt;
          text-align: justify;
          text-indent: -28.35pt;
          border: none;
        "
      >
        <b><span style="font-size: 14pt">28.     COUNTERPARTS. </span></b>
      </p>

      <p
        class="MsoNormal"
        style="
          margin-left: 28.35pt;
          text-align: justify;
          text-indent: -28.35pt;
          border: none;
        "
      >
        <span style="font-size: 11pt"
          >28.1   This Agreement may be executed in any number of counterparts
          and transmitted by facsimile transmission, electronic mail in
          “portable document format (“pdf”) or similar form, or by any other
          electronic means that is intended to preserve the original graphic and
          pictorial appearance of a document, and will have the same effect as
          physical delivery of this Agreement bearing the original signature of
          each party, each of which when so executed shall be deemed an
          original, but all counterparts shall together constitute one and the
          same document.
        </span>
      </p>

      <p
        class="MsoNormal"
        style="
          margin-left: 28.35pt;
          text-align: justify;
          text-indent: -28.35pt;
          border: none;
        "
      >
        <b><span style="font-size: 14pt">29.     INTERPRETATION</span></b>
      </p>

      <p
        class="MsoNormal"
        style="
          margin-left: 28.35pt;
          text-align: justify;
          text-indent: -28.35pt;
          border: none;
        "
      >
        <span style="font-size: 11pt"
          >29.1   References in this Agreement to “calendar months or months”
          are to the twelve (12) named periods (January, February and so on)
          into which a year is divided.
        </span>
      </p>

      <p
        class="MsoNormal"
        style="
          margin-left: 28.35pt;
          text-align: justify;
          text-indent: -28.35pt;
          line-height: 115%;
          border: none;
        "
      >
        <span style="font-size: 11pt; line-height: 115%"
          >29.2   The headings to Clauses are inserted for convenience only and
          shall not affect the interpretation or construction of this
          Agreement;</span
        >
      </p>

      <p
        class="MsoNormal"
        style="
          margin-left: 28.35pt;
          text-align: justify;
          text-indent: -28.35pt;
          line-height: 115%;
          border: none;
        "
      >
        <span style="font-size: 11pt; line-height: 115%">29.3   </span
        ><span style="font-size: 11pt; line-height: 115%"
          >References to any statute or statutory provision shall include (i)
          any subordinate legislation made under it, (ii) any provision which it
          has modified or re-enacted (whether with or without modification), and
          (iii) any provision which subsequently supersedes it or re-enacts it
          (whether with or without modification);</span
        >
      </p>

      <p
        class="MsoNormal"
        style="
          margin-left: 28.35pt;
          text-align: justify;
          text-indent: -28.35pt;
          line-height: 115%;
          border: none;
        "
      >
        <span style="font-size: 11pt; line-height: 115%"
          >29.4   References to any act shall include any omissions in
          connection therewith and vice versa;</span
        >
      </p>

      <p
        class="MsoNormal"
        style="
          margin-left: 28.35pt;
          text-align: justify;
          text-indent: -28.35pt;
          line-height: 115%;
          border: none;
        "
      >
        <span style="font-size: 11pt; line-height: 115%"
          >29.5   References to the words “include” or “including” shall be
          construed without limitation;</span
        >
      </p>

      <p
        class="MsoNormal"
        style="
          margin-left: 28.35pt;
          text-align: justify;
          text-indent: -28.35pt;
          line-height: 115%;
          border: none;
        "
      >
        <span style="font-size: 11pt; line-height: 115%"
          >29.6   References to this Agreement or any other agreement, deed,
          instrument or document shall be construed as a reference to this
          Agreement, such other agreement, deed, instrument or document as the
          same may from time to time be amended, varied, supplemented or novated
          in accordance with the terms of this Agreement;</span
        >
      </p>

      <p
        class="MsoNormal"
        style="
          margin-left: 28.35pt;
          text-align: justify;
          text-indent: -28.35pt;
          line-height: 115%;
          border: none;
        "
      >
        <span style="font-size: 11pt; line-height: 115%"
          >29.7   Words imparting the singular shall include the plural and vice
          versa. Words imparting a gender include every gender and references to
          persons include an individual, company, corporation, firm or
          partnership; and
        </span>
      </p>

      <p
        class="MsoNormal"
        style="
          margin-left: 28.35pt;
          text-align: justify;
          text-indent: -28.35pt;
          line-height: 115%;
          border: none;
        "
      >
        <span style="font-size: 11pt; line-height: 115%"
          >29.8   The words “hereof”, “herein”, “hereby” and other words of
          similar import refer to this Agreement as a whole.
        </span>
      </p>
    </div>
  </div>
</div>
