import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-mmshop-terms',
  templateUrl: './mmshop-terms.component.html',
  styleUrls: ['./mmshop-terms.component.scss']
})
export class MmshopTermsComponent implements OnInit {

  constructor(private metaService: Meta, private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.route.data.subscribe((data) => {
      if (data?.robots) {
        this.metaService.addTag({
          name: 'robots',
          content: data.robots
        });
      }
    });
  }

}
