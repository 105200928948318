<div>
	<div class="bannerbg mediatopbannerbg">
		<div class="container">
			<div class="row padabout panel">
				<div class="col-lg-6">
					<div class="aboutbannertxt pt-0 padabouts">
						<h1 class="wow slideInDown">One of the world’s most disruptive fintech companies</h1>
						<div class="bannershow">
							<ul>
								<li>
									<div class="bannershowbox">
										<div class="banericons"><img src="assets/images/country.png" alt="Countries" /></div>
										<h3>6</h3>
                                        <span>Countries</span> 
                                    </div>
								</li>
								<li>
									<div class="bannershowbox">
										<div class="banericons padiocn"><img src="assets/images/transaction.png" alt="Transactions" /></div>
										<h3>50M+</h3>
                                        <span>Transactions</span>
                                    </div>
								</li>
								<li>
									<div class="bannershowbox">
										<div class="banericons padiocn"><img src="assets/images/transaction.png" alt="Partners" /> </div>
										<h3>50+</h3>
                                        <span>Partners</span>
                                    </div>
								</li>
								<li>
									<div class="bannershowbox">
										<div class="banericons padiocn"><img src="assets/images/segments.png" alt="Industries" /> </div>
										<h3>10+</h3>
                                        <span>Industries</span>
                                    </div>
								</li>
							</ul>
						</div>
					</div>
				</div>
				<div class="col-lg-6">
					<div class="aboutbannre"><img src="assets/images/AboutUs02.png" alt="About bannre" /></div>
				</div>
			</div>
		</div>
	</div>
	<div class="media-bottombg">
		<div class="container">
			<div style="clear: both;"></div>
			<section class="section section-steps panel" data-color="violet">
				<div class="media-relasttop wow slideInDown">
					<h4>Awards and Recognitions</h4> </div>
				<div class="padaward">
					<owl-carousel [options]="aboutCarouselOptions" [carouselClasses]="['owl-theme', 'row', 'sliding']">
						<div class="item" *ngFor="let award of awardLogos">
							<div class="awardbox"> <img src="{{award.image}}" alt={{award.alternate}} /> </div>
						</div>
					</owl-carousel>
				</div>
			</section>
			<div style="clear: both;"></div>
			<section class="section section-steps panel" data-color="violet">
				<div class="media-relasttop wow slideInDown padpater">
					<h4>Our Partners</h4> </div>
				<div class="padaward">
					<owl-carousel [options]="aboutCarouselOptions" [carouselClasses]="['owl-theme', 'row', 'sliding']">
						<div class="item" *ngFor="let partner of partnerLogos">
							<div class="awardbox"> <img src="{{partner.image}}" alt={{partner.alternate}} /> </div>
						</div>
					</owl-carousel>
				</div>
			</section>
			<div style="clear: both;"></div>
			<section class="padmob panel" data-color="indigo">
				<div class="row">
					<div class="col-lg-5 col-md-12">
						<div class="aboutshwinfoimg"><img src="assets/images/aboutimgs01.jpg" alt="About info" /></div>
					</div>
					<div class="col-lg-7 col-md-12">
						<div class="aboutinfomatter">
							<h4>The Business of Moving Money</h4>
							<p>Our solutions include the full suite of services to move money locally and globally in a safe and compliant manner. We offer fully managed services and developer-friendly features that connect consumers, merchants and financial institutions, enabling our clients to bring their innovative products and services from idea to market in record time.</p>
							<h4>Full end-to-end business architecture</h4>
							<p>We take care of all local and international regulatory, legal, and reporting compliance through key partnerships with regulators, card networks and financial institutions, enabling our clients to invest in creating amazing customer experiences.</p>
							<h4>We continuously release advanced tools, technologies, and services</h4>
							<p>We invest in continuous enhancements to our platform — enabling new features, services, and markets — while also keeping up to date with the changing regulatory and legal landscape. Our platform sits at the leading edge of financial technology, enabling our clients to maintain an edge in their own industries.</p>
						</div>
					</div>
				</div>
			</section>
		</div>
		<div style="clear: both;"></div>
		<section class="panel" data-color="indigo">
			<div class="matchmoveculsarinfo">
				<div class="container">
					<h4 class="wow slideInDown">The MatchMove Culture</h4>
					<div class="row">
						<div class="col-lg-3 col-md-6">
							<div class="aboutoverimg01"> <img src="assets/images/culture1.jpg" alt="Culture 1" style="width:100%">
								<div class="middle">
									<div class="abouthovecont">
										<p>We value diversity of thought leading to real innovation.</p>
									</div>
								</div>
							</div>
						</div>
						<div class="col-lg-3 col-md-6">
							<div class="aboutoverimg01"> <img src="assets/images/culture2.jpg" alt="Culture 2" style="width:100%">
								<div class="middle">
									<div class="abouthovecont">
										<p>We value constructive communication leading to real teamwork.</p>
									</div>
								</div>
							</div>
						</div>
						<div class="col-lg-3 col-md-6">
							<div class="aboutoverimg01"> <img src="assets/images/culture3.jpg" alt="Culture 3" style="width:100%">
								<div class="middle">
									<div class="abouthovecont">
										<p>We value contributions leading to real results.</p>
									</div>
								</div>
							</div>
						</div>
						<div class="col-lg-3 col-md-6">
							<div class="aboutoverimg01"> <img src="assets/images/culture4.jpg" alt="Culture 4" style="width:100%">
								<div class="middle">
									<div class="abouthovecont">
										<p>We value entrepreneurial thinking leading to creativity and new discoveries. </p>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="row padmter">
						<div class="col-lg-8">
							<div class="matchcluarmatter">
								<p>We are in the business of moving digital money; safely, quickly and legally. For anyone and anywhere, regardless of economic status or social context. To achieve this important mission and address this once-in-a-generation opportunity to impact millions of people positively using technology in a digital world, our people are all singularly focused on building the next generation of embedded Banking-as-a-Service, for all types of organisations, from every segment of the economy.</p>
								<p>Our performance-based culture is inclusive, results-driven and customer-centric, with diverse talent from Singapore and across the globe coming together as a team everyday to create new user experiences and innovations for our customers. </p>
								<p>We enable a merit-based working culture in which you will always be learning and developing your skills and experiences, whatever your age or background. We offer a unique opportunity to work with some of the most talented people in the FinTech industry, building game-changing technology that can be used safely by everyone. </p>
								<p>Come join us to create award-winning FinTech products and services. Come feel the excitement as we fuel the growth of our clients and their customer communities in ways they never imagined possible before. Come help us build a more financially inclusive digital future and make a positive difference in people’s lives, everyday, everywhere. </p>
							</div>
						</div>
						<div class="col-lg-4"> <a href="https://matchmove.darwinbox.com/ms/candidate/careers" target="_blank" class="viewpotionbtn">View Open Positions</a> </div>
					</div>
				</div>
			</div>
		</section>
		<div style="clear: both;"></div>
	</div>
</div>