import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from "@angular/common";
import {Title} from "@angular/platform-browser";

declare var Rellax: any;

@Component({
  selector: 'app-use-cases',
  templateUrl: './use-cases.component.html',
  styleUrls: ['./use-cases.component.scss']
})
export class UseCasesComponent implements OnInit {
  constructor(@Inject(PLATFORM_ID) private platformId: any, private titleService: Title) {
  }

  ngOnInit(): void {
    this.titleService.setTitle("Use Cases | MatchMove")

    if (isPlatformBrowser(this.platformId))
      new Rellax('.rellax', {
        breakpoints: [576, 768, 1024]
      });
  }
}
