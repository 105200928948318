import {Component, HostListener, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from "@angular/common";
import {Title} from "@angular/platform-browser";

declare function onPageScroll(): any;

declare var WOW: any;

@Component({
  selector: 'app-aboutus',
  templateUrl: './aboutus.component.html',
  styleUrls: ['./aboutus.component.scss']
})
export class AboutusComponent implements OnInit {
  awardLogos: any = [
    {
      id: 1,
      image: 'assets/images/logo-arwad01.jpg',
      alternate: 'Logo arwad 1',
    },
    {
      id: 2,
      image: 'assets/images/logo-arwad02.jpg',
      alternate: 'Logo arwad 2',
    },
    {
      id: 3,
      image: 'assets/images/logo-arwad03.jpg',
      alternate: 'Logo arwad 3',
    },
    {
      id: 4,
      image: 'assets/images/logo-arwad04.jpg',
      alternate: 'Logo arwad 4',
    },
    {
      id: 5,
      image: 'assets/images/logo-arwad05.jpg',
      alternate: 'Logo arwad 5',
    },
    {
      id: 6,
      image: 'assets/images/logo-arwad06.jpg',
      alternate: 'Logo arwad 6',
    },
    {
      id: 7,
      image: 'assets/images/logo-arwad07.jpg',
      alternate: 'Logo arwad 7',
    },
    {
      id: 8,
      image: 'assets/images/logo-arwad08.jpg',
      alternate: 'Logo arwad 8',
    },
    {
      id: 9,
      image: 'assets/images/logo-arwad09.jpg',
      alternate: 'Logo arwad 9',
    }
  ]

  constructor(@Inject(PLATFORM_ID) private platformId: any, private titleService: Title) {
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(event: any) {
    onPageScroll();
  }

  ngOnInit(): void {
    this.titleService.setTitle("About Us | MatchMove")

    if (isPlatformBrowser(this.platformId))
      new WOW().init();
  }

  partnerLogos: any = [
    {
      id: 1,
      image: 'assets/images/indusindbank.png',
      alternate: 'Indusind bank'
    },
    {
      id: 2,
      image: 'assets/images/aub.png',
      alternate: 'AUB'
    },
    {
      id: 3,
      image: 'assets/images/tpbank.png',
      alternate: 'Tp bank'
    },
    {
      id: 4,
      image: 'assets/images/bankbri.jpg',
      alternate: 'Bank bri'
    },
    {
      id: 5,
      image: 'assets/images/cimb.jpg',
      alternate: 'Cimb'
    },
    {
      id: 6,
      image: 'assets/images/mastercard.jpg',
      alternate: 'Mastercard'
    },
    {
      id: 7,
      image: 'assets/images/homesend.jpg',
      alternate: 'Homesend'
    },
    {
      id: 8,
      image: 'assets/images/transferto.jpg',
      alternate: 'Transferto'
    },
    {
      id: 9,
      image: 'assets/images/moneygram.jpg',
      alternate: 'Moneygram'
    }
  ]
  aboutCarouselOptions: any = {
    loop: true,
    margin: 15,
    autoplay: true,
    autoPlaySpeed: 3000,
    autoPlayTimeout: 3000,
    autoplayHoverPause: true,
    nav: true,
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 2
      },
      1000: {
        items: 4
      }
    }
  }
}
