import {Component, HostListener, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from "@angular/common";

declare function onPageScroll(): any;

declare function sliderShow(noOfSlides: any, slideImages: any): any;

@Component({
  selector: 'app-features',
  templateUrl: './features.component.html',
  styleUrls: ['./features.component.scss']
})

export class FeaturesComponent implements OnInit {
  constructor(@Inject(PLATFORM_ID) private platformId: any) {
  }
  noOfSlides: any = 3;
  slideImages: any = [
    'assets/images/dashboardslide1.png',
    'assets/images/dashboardslide3.png',
    'assets/images/dashboardslide2.png'
  ]
  @HostListener('window:scroll', ['$event'])
  onScroll(event: any) {
    onPageScroll();
  }
  ngOnInit(): void {
    // onPageScroll();
    if (isPlatformBrowser(this.platformId))
      sliderShow(this.noOfSlides, this.slideImages);
  }
}
