import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { DeveloperComponent } from './pages/developer/developer.component';
import { AboutusComponent } from './pages/company/aboutus/aboutus.component';
import { ContactComponent } from './pages/company/contact/contact.component';
import { TermsComponent } from './pages/company/terms/terms.component';
import { PrivacyPolicyComponent } from './pages/company/privacy-policy/privacy-policy.component';
import { FeaturesComponent } from './pages/solutions/features/features.component';
// use cases routes
import { SuccessStoriesComponent } from './pages/success-stories/success-stories.component';
import { UseCasesComponent } from './pages/use-cases/use-cases.component';
import { LegalComponent } from './pages/company/legal/legal.component';
import { MmshopTermsComponent } from './pages/company/mmshop-terms/mmshop-terms.component';
import { TermsPlatformComponent } from './pages/company/terms-platform/terms-platform.component';
import { LegalTerminologyComponent } from './pages/company/legal-terminology/legal-terminology.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'features', component: FeaturesComponent },
  { path: 'developers', component: DeveloperComponent },
  { path: 'about-us', component: AboutusComponent },
  { path: 'contact-us', component: ContactComponent },
  { path: 'terms', component: TermsComponent },
  { path: 'terms-platform-shopmatic', component: MmshopTermsComponent, data: {
      robots: 'noindex,nofollow'
    }
  },
  { path: 'terms-platform', component: TermsPlatformComponent, data: {
      robots: 'noindex,nofollow'
    }
  },
  { path: 'legal/terms_and_definitions', component: LegalTerminologyComponent, data: {
      robots: 'noindex,nofollow'
    }
  },
  { path: 'privacy', component: PrivacyPolicyComponent },
  { path: 'use-cases', component: UseCasesComponent },
  { path: 'success-stories', component: SuccessStoriesComponent },
  { path: 'success-stories', component: SuccessStoriesComponent },
  { path: 'legal/platform/terms_and_conditions', component: LegalComponent},
  { path: 'media', loadChildren: () => import('./pages/company/media/media.module').then(m => m.MediaModule) },

  // { path: '**', component: PageNotFoundComponent } for page not found
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy', initialNavigation: 'enabled', scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
