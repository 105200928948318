import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  title = 'Spend Send Lend with MatchMove';
  description = 'MatchMove is one of the world\'s fastest growing and most disruptive fintech companies in digital payments and next-generation banking. Put a bank in any App with the MatchMove Bank OS.';
  keywords = 'matchmove, spend, send, lend';
  images = [
    {
      id: 1,
      image: 'assets/images/userlogo1.png',
      alternate: 'home credit'
    },
    {
      id: 2,
      image: 'assets/images/userlogo2.png',
      alternate: 'irctc'
    },
    {
      id: 3,
      image: 'assets/images/userlogo3.png',
      alternate: 'spenmo'
    },
    {
      id: 4,
      image: 'assets/images/userlogo4.png',
      alternate: 'singapura'
    },
    {
      id: 5,
      image: 'assets/images/userlogo5.png',
      alternate: 'mobility'
    },
    {
      id: 6,
      image: 'assets/images/userlogo6.png',
      alternate: 'marine inovation'
    },
    {
      id: 7,
      image: 'assets/images/userlogo7.png',
      alternate: 'benifit'
    },
    {
      id: 8,
      image: 'assets/images/userlogo8.png',
      alternate: 'subtra'
    },
    {
      id: 9,
      image: 'assets/images/userlogo9.png',
      alternate: 'shopmatic'
    },
    {
      id: 10,
      image: 'assets/images/userlogo10.png',
      alternate: 'vyvo'
    },
    {
      id: 11,
      image: 'assets/images/userlogo11.png',
      alternate: ''
    },
    {
      id: 12,
      image: 'assets/images/userlogo12.png',
      alternate: 'just login'
    },
    {
      id: 13,
      image: 'assets/images/userlogo13.png',
      alternate: 'hello money'
    },
    {
      id: 14,
      image: 'assets/images/userlogo14.png',
      alternate: 'juan cash'
    },
    {
      id: 15,
      image: 'assets/images/userlogo15.png',
      alternate: 'xpensio'
    },
    {
      id: 16,
      image: 'assets/images/userlogo16.png',
      alternate: 'vt'
    },
    {
      id: 17,
      image: 'assets/images/userlogo17.png',
      alternate: 'tp bank'
    },
    {
      id: 18,
      image: 'assets/images/userlogo18.png',
      alternate: 'indus bank'
    }
  ]
  brandData = [
    {
      id: 1,
      image: 'assets/images/Isaq-ahmed-img.jpg',
      logo: 'assets/images/Isaq-ahmed-brand.png',
      name: 'Isaq Ahmed',
      designation: 'CTO',
      info: 'The MatchMove partnerships has enabled us to focus on our core business, as their platform enables the movement of digital cash easily and securely.'
    },
    {
      id: 2,
      image: 'assets/images/joe-ngoi.png',
      logo: 'assets/images/asiatop.png',
      name: 'Joe Ngoi',
      designation: 'Director',
      info: "Using MatchMove's proprietary Banking Wallet OS, AsiaTop was able to launch a co-branded prepaid cards solution for retail merchants, helping them offer loyalty to their customers through the platform.The MatchMove solution offers us a unique proposition to club loyalty and payments."
    },
    {
      id: 3,
      image: 'assets/images/kwa-kim-img.jpg',
      logo: 'assets/images/kwa-kim-brand.png',
      name: 'KC Kwa',
      designation: 'CEO',
      info: 'This partnership with MatchMove is a game changer as it will enable JustLogin to become the first HR Tech company in Singapore to digitize HR-related payments for SMEs on our platform. This is in line with  our employee-first strategy where we put employee experience at the centre of everything we do.'
    },
    {
      id: 4,
      image: 'assets/images/jeffreyli.jpg',
      logo: 'assets/images/jenfilogo.png',
      name: 'Jeffrey Liu',
      designation: 'CEO',
      info: 'The MatchMove platform and LightSpeed product allowed us to quickly deploy an end-to-end product to our client base. By offering the Jenfi Wallet and Jenfi MasterCard, the corporate partners we serve are able to seamlessly receive funds and transact, fulfilling our mission to provide productive growth capital to businesses.'
    },
    {
      id: 6,
      image: 'assets/images/anurag-avula.jpg',
      logo: 'assets/images/shopmatic-logo.svg',
      name: 'Anurag Avula',
      designation: 'Co-founder and CEO',
      info: 'We are excited to partner with MatchMove to offer integrated payment and financial solutions to aspiring e-commerce entrepreneurs on our platform. We will leverage the MatchMove network to accelerate our platform growth and provide our customers with rich banking-in-an-app experience that MatchMove offers.',
    }
  ]
  constructor(private titleService: Title, private metaService: Meta) { }
  carouselOptions = {
    loop: true,
    margin: 20,
    autoplay: true,
    autoPlaySpeed: 3000,
    autoPlayTimeout: 3000,
    navText: ["", ""],
    autoplayHoverPause: true,
    nav: true,
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 2
      },
      1000: {
        items: 4
      }
    }
  }
  carouselOptionsBrand = {
    loop: false,
    margin:15,
    autoplay: true,
    autoPlaySpeed: 3000,
    autoPlayTimeout: 3000,
    autoplayHoverPause: true,
    nav:true,
    responsive:{
        0:{
            items:1
        },
        600:{
            items:1
        },
        1000:{
            items:3
        }
    }
  }
  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.metaService.addTags([
      { name: 'keywords', content: this.keywords },
      { name: 'description', content: this.description },
    ]);
  }
}
