<div class="media-bottombg pt-0">
    <div class="media-relasttop">
        <h4>Developers</h4>
        <p class="text-center">MatchMove is built by developers, for developers.</p>
    </div>
    <div class="guide-bg" style="background: none;">
        <div class="container">
            <div class="row">
                <div class="col-lg-3 col-md-6">
                    <div class="guidebox">
                        <h2>Developer Guide</h2>
                        <img src="assets/images/developer-guide-img1.png" alt="Developer guide" />
                        <p>Comprehensive documentation on how to use the MatchMove platform, from APIs and SDKs to the ins-and-outs of offering virtual accounts, cards, remittances and lending.</p>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6">
                    <div class="guidebox">
                        <h2>API Explorer</h2>
                        <img src="assets/images/api-explorer-img1.png" alt="Api explorer" />
                        <p>Interactive documentation on all of MatchMove’s APIs.</p>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6">
                    <div class="guidebox">
                        <h2>Mobile</h2>
                        <img src="assets/images/mobile-img1.png" alt="Mobile" />
                        <p>Software Development Kits (SDKs) to jump-start integration with your own apps.</p>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6">
                    <div class="guidebox">
                        <h2>Managed Apps</h2>
                        <a href="https://lightspeed.matchmove.com/auth/signup" target="_blank"> <img src="assets/images/coreapi-img1.png" alt="Managed apps" /></a>
                        <p>Own-brand, fully managed apps to launch your own virtual account and app instantly.</p>
                    </div>
                </div>
                <div class="text-center w-100 mt-3"><a href="https://developer.matchmove.com/" target="_blank" class="btnstyl1">Read the docs</a></div>
            </div>
        </div>
    </div>
</div>
