<div>
    <div class="va-banner panel">
        <div class="va-banner-img"><img src="assets/images/virtual-account-banner.png" alt="" /></div>
        <div class="va-banner-content">
            <div class="container">
                <h1>
                    Offer your customers cash balances on<br />
                    a safe and secure platform
                </h1>
                <p>Works better than cash and can be paired with virtual or physical cards</p>
            </div>
        </div>
    </div>

    <div class="content-part part-tese">
        <div class="gallery_container"></div>
    </div>
    <div style="clear: both;"></div>

    <!-- =========================== Stesps-->
    <section class="section section-steps toppadsss panel" data-color="violet">
        <div class="container">
            <div class="section-head">
                <h2 class="section-title is-center">How it works</h2>
            </div>
            <div class="howitworkbg">
                <ul>
                    <li>
                        <span class="stepsnum">1</span>
                        <img src="assets/images/how-work-icon1.png" alt="How it works" />
                        <p>Customer buys from your app, website, or store</p>
                    </li>
                    <li>
                        <span class="stepsnum">2</span>
                        <img src="assets/images/how-work-icon2.png" alt="How it works" />
                        <p>You issue a virtual account instantly</p>
                    </li>
                    <li>
                        <span class="stepsnum">3</span>
                        <img src="assets/images/how-work-icon3.png" alt="How it works" />
                        <p>You deposit rewards, rebates, and refunds</p>
                    </li>
                    <li>
                        <span class="stepsnum">4</span>
                        <img src="assets/images/how-work-icon4.png" alt="How it works" />
                        <p>Customer uses those rewards, rebates, and refunds for repeat purchases</p>
                    </li>
                </ul>
            </div>

            <div class="key-benefits-bg mt-5">
                <div class="section-head">
                    <h2 class="section-title is-center">Key Benefits</h2>
                </div>
                <ul>
                    <li>
                        <img src="assets/images/key-benefits-icon1.png" alt="Key Benefits" />
                        <p>Digitize cash management instantly</p>
                    </li>
                    <li>
                        <img src="assets/images/key-benefits-icon2.png" alt="Key Benefits" />
                        <p>Manage cost effectively and reduce bank-related fees</p>
                    </li>
                    <li>
                        <img src="assets/images/key-benefits-icon3.png" alt="Key Benefits" />
                        <p>Create customer stickiness with rewards, rebates, and refunds</p>
                    </li>
                    <li>
                        <img src="assets/images/key-benefits-icon4.png" alt="Key Benefits" />
                        <p>Perform instant virtual account issuance</p>
                    </li>
                </ul>
            </div>
        </div>
    </section>

    <div style="clear: both;"></div>
    <section class="section is-sm padmob panel" data-color="indigo">
        <div class="container">
            <div class="section-head">
                <h2 class="section-title is-center">Use Cases</h2>
            </div>

            <div class="usecases-bg">
                <div class="row">
                    <div class="col-md-6">
                        <div class="casesimgbox">
                            <img src="assets/images/VirtualAcc-UseCase1.png" alt="Expense Management" />
                            <a href="use-case">Expense Management <i class="fa fa-long-arrow-right" aria-hidden="true"></i></a>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="casesimgbox">
                            <img src="assets/images/VirtualAcc-UseCase2.png" alt="Digital Banking" />
                            <a href="use-case">Digital Banking <i class="fa fa-long-arrow-right" aria-hidden="true"></i></a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="section-head my-5 float-left">
                <h2 class="section-title is-center">Success Stories</h2>
            </div>

            <div class="usecases-bg">
                <div class="row">
                    <div class="col-md-6">
                        <div class="casesimgbox">
                            <img src="assets/images/subtra.jpg" alt="Subtra" />
                            <a href="success-story">Subtra <i class="fa fa-long-arrow-right" aria-hidden="true"></i></a>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="casesimgbox">
                            <img src="assets/images/shopmatic.jpg" alt="Shopmatic" />
                            <a href="success-story">Shopmatic <i class="fa fa-long-arrow-right" aria-hidden="true"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <div class="poweryowrwork-bg" data-color="color-violet">
        <div class="container">
            <h2>Power your business with MatchMove</h2>
            <a href="contact.html" class="btnstyl1">Contact Us</a>
        </div>
    </div>
</div>
