import {Component, OnInit} from '@angular/core';
import {Location, LocationStrategy, PathLocationStrategy} from '@angular/common';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  providers: [Location, {provide: LocationStrategy, useClass: PathLocationStrategy}]
})

export class FooterComponent implements OnInit {
  isStory: any = false;
  href: string = "";
  year: number = (new Date()).getFullYear()

  constructor(private location: Location) {
  }

  ngOnInit(): void {
    if (this.location.path() === '/success-story') {
      this.isStory = true;
    }
  }
  images: any = [
    {
      id: 1,
      image: 'assets/images/awards-image/Awards_TOP-1.png',
      alternate: 'award-1'
    },
    {
      id: 2,
      image: 'assets/images/awards-image/Awards_1.jpg',
      alternate: 'award-2'
    },
    {
      id: 3,
      image: 'assets/images/awards-image/Awards_2.jpg',
      alternate: 'award-3'
    },
    {
      id: 4,
      image: 'assets/images/awards-image/Awards_3.jpg',
      alternate: 'award-4'
    },
    {
      id: 5,
      image: 'assets/images/awards-image/Awards_4.jpg',
      alternate: 'award-5'
    },
    {
      id: 6,
      image: 'assets/images/awards-image/Awards_5.jpg',
      alternate: 'award-6'
    },
    {
      id: 7,
      image: 'assets/images/awards-image/Awards_6.jpg',
      alternate: 'award-7'
    },
    {
      id: 8,
      image: 'assets/images/awards-image/Awards_7.jpg',
      alternate: 'award-8'
    },
    {
      id: 9,
      image: 'assets/images/awards-image/Awards_8.jpg',
      alternate: 'award-9'
    },
    {
      id: 10,
      image: 'assets/images/awards-image/logo-arwad0010.jpg',
      alternate: 'award-10'
    }
  ]
  carouselOptions: any = {
    loop: true,
    margin: 20,
    autoplay: true,
    autoPlaySpeed: 3000,
    autoPlayTimeout: 3000,
    navText: ["", ""],
    autoplayHoverPause: true,
    nav: false,
    responsive: {
      0: {
        items: 2
      },
      600: {
        items: 3
      },
      1000: {
        items: 8
      }
    }
  }
}
