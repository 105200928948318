import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { MarkdownModule } from 'ngx-markdown'

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderTopComponent } from './components/header-top/header-top.component';
import { OwlModule } from 'ngx-owl-carousel';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AboutusComponent } from './pages/company/aboutus/aboutus.component';
import { ContactComponent } from './pages/company/contact/contact.component';
import { TermsComponent } from './pages/company/terms/terms.component';
import { PrivacyPolicyComponent } from './pages/company/privacy-policy/privacy-policy.component';
import { DeveloperComponent } from './pages/developer/developer.component';
import { FeaturesComponent } from './pages/solutions/features/features.component';
import { SuccessStoriesComponent } from './pages/success-stories/success-stories.component';
import { UseCasesComponent } from './pages/use-cases/use-cases.component';
import { ScullyLibModule } from '@scullyio/ng-lib';
import { MmshopTermsComponent } from './pages/company/mmshop-terms/mmshop-terms.component';
import { TermsPlatformComponent } from './pages/company/terms-platform/terms-platform.component';
import { LegalTerminologyComponent } from './pages/company/legal-terminology/legal-terminology.component';
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    HeaderTopComponent,
    AboutusComponent,
    ContactComponent,
    TermsComponent,
    MmshopTermsComponent,
    PrivacyPolicyComponent,
    DeveloperComponent,
    FeaturesComponent,
    SuccessStoriesComponent,
    UseCasesComponent,
    TermsPlatformComponent,
    LegalTerminologyComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    BrowserAnimationsModule,
    OwlModule,
    MarkdownModule.forRoot(),
    ScullyLibModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
