<div class="media-bottombg pt-0">
  <div class="container">
    <div class="termsbg">
      <p
        class="MsoNormal"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0in;
          margin-left: 26.45pt;
          margin-bottom: 0.0001pt;
          font-size: 20px;
          font-weight: bold;
        "
      >
        <b style="font-weight: bold; color: black"
          ><u
            ><span
              >Standard<span style="letter-spacing: -0.25pt">&nbsp;</span
              >MMBOS<span style="letter-spacing: -0.25pt">&nbsp;</span
              >Terms<span style="letter-spacing: -0.25pt">&nbsp; </span
              >&amp;<span style="letter-spacing: -0.2pt"> &nbsp;</span
              >Conditions
            </span>
            </u
          ></b
        >
        
      </p>

      <p
        class="MsoBodyText"
        style="
          margin-top: 0.1pt;
          margin-right: 80.2pt;
          margin-bottom: 0in;
          margin-left: 0in;
          margin-bottom: 0.0001pt;
        "
      >
        <b style="font-weight: bold; color: black"><span>&nbsp;</span></b>
      </p>

      <p
        class="MsoNormal"
        style="
          margin-top: 4.75pt;
          margin-right: 80.2pt;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          margin-bottom: 0.0001pt;
        "
      >
        <span style="line-height: 106%"
          >These terms and conditions
          <b style="font-weight: bold; color: black">(“T&amp;C”)</b> govern the
          access and use of the MatchMove Banking Operating System
          <b style="font-weight: bold; color: black">(“MMBOS”)</b> by You
          <b style="font-weight: bold; color: black">(“Partner/You/Your”) </b
          >according to Your MMBOS Agreement
          <b style="font-weight: bold; color: black">(“Agreement”)</b> with
          MatchMove
          <span style="letter-spacing: -0.1pt"
            ><b style="font-weight: bold; color: black"
              >(&quot;Provider/We/Us&quot;)</b
            >.</span
          ></span
        >
      </p>

      <p
        class="MsoBodyText"
        style="
          margin-top: 0.35pt;
          margin-right: 80.2pt;
          margin-bottom: 0in;
          margin-left: 0in;
          margin-bottom: 0.0001pt;
        "
      >
        <span>&nbsp;</span>
      </p>

      <h4
        align="left"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0in;
          margin-left: 26.25pt;
          margin-bottom: 0.0001pt;
          text-align: left;
          text-indent: 0in;
        "
      >
        <span style="letter-spacing: -0.1pt">Background</span>
      </h4>

      <p
        class="MsoBodyText"
        style="
          margin-top: 0.05pt;
          margin-right: 80.2pt;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          margin-bottom: 0.0001pt;
          text-align: justify;
          text-indent: -0.4pt;
          line-height: 111%;
        "
      >
        <span style="line-height: 111%">&nbsp;</span>
      </p>

      <p
        class="MsoBodyText"
        style="
          margin-top: 0.05pt;
          margin-right: 80.2pt;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          margin-bottom: 0.0001pt;
          text-align: justify;
        "
      >
        <span
          >We have created Our exclusive MatchMove Banking Operating System
          <b style="font-weight: bold; color: black">(&quot;MMBOS&quot;)</b> as
          a certified platform-as-a-service, adhering to PCI-DSS and ISO 27001
          standards. This comprehensive system comprises a range of software and
          hardware technologies, API libraries, and secure connections to
          prominent global networks and financial institutions. It also
          encompasses technical support, management reporting tools, and secure
          switching capabilities
          <b style="font-weight: bold; color: black"
            >(&quot;MMBOS Platform&quot;</b
          >
          or
          <b style="font-weight: bold; color: black">&quot;Platform&quot;</b>).
          With this Platform, You can seamlessly integrate specific financial
          technology features into your digital services, complying with
          regulations for payments, cross-border remittances, collections,
          lending, insurance, and investments. You can then deploy these
          capabilities to Your approved Users, whether they are Your customers,
          Your employees, or Your business partners, and only according to the
          terms of the Agreement. This deployment can take place across various
          digital channels, including mobile apps, web pages, wearables, IoT
          devices, and more, and only as described in the Agreement.</span
        >
      </p>

      <p
        class="MsoBodyText"
        style="
          margin-top: 0.05pt;
          margin-right: 80.2pt;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          margin-bottom: 0.0001pt;
          text-align: justify;
          line-height: 111%;
        "
      >
        <span style="line-height: 111%">&nbsp;</span>
      </p>

      <b
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 27.7pt;
          text-indent: -28.35pt;
        "
        class="heading"
      >
        <span>1.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span
        ><span
          >Platform<span style="letter-spacing: -0.35pt">&nbsp; </span
          >Access<span style="letter-spacing: -0.35pt">&nbsp; </span
          ><span style="letter-spacing: -0.1pt">Services</span></span
        >
      </b>

      <p
        class="MsoNormal"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-align: justify;
          text-indent: -28.35pt;
        "
      >
        <span
          >1.1       You<span style="letter-spacing: -0.1pt"> may </span
          >access<span style="letter-spacing: -0.1pt"> &nbsp;</span>to<span
            style="letter-spacing: -0.1pt"
            >&nbsp; </span
          >the<span style="letter-spacing: -0.1pt"> &nbsp;</span>Platform<span
            style="letter-spacing: -0.1pt"
          >
            &nbsp;</span
          >in<span style="letter-spacing: -0.1pt">&nbsp; </span>two<span
            style="letter-spacing: -0.1pt"
          >
            &nbsp;</span
          >stages, which must always comply with the Agreement:</span
        >
      </p>

      <p
        class="MsoBodyText"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 92.15pt;
          text-align: justify;
          text-indent: -35.45pt;
        "
      >
        <span>1.1.1</span
        ><b style="font-weight: bold; color: black; font-size: 17px;"
          ><span
            >     UAT/Pre-Prod<span style="letter-spacing: -0.6pt">&nbsp; </span
            >Environment<span style="letter-spacing: -0.55pt">&nbsp; </span></span></b
        ><span
          >which enables You to develop and test Your intended products in a
          controlled or simulated environment with test data. You should be
          aware that the UAT/Pre-Prod environment undergoes regular updates and
          is not subject to the same SLA as the Live Production Environment.
          Additionally, certain services may be limited in the UAT/Pre-Prod
          environment due to Regulatory or Network requirements. You will have
          access to the UAT/Pre-Prod environment for a maximum period of
          sixty (60) days from the day it is made available to You. Prior to or upon the expiration of this period,
          You must provide a notice of deactivation
          <b style="font-weight: bold; color: black; font-size: 17px;"
            >(&quot;UAT Environment Deactivation Notice&quot;)</b
          >
          to us. Failure to provide such notice and/or Your continuing to use
          the UAT Environment will result in applicable fees as set out in the
          Schedule.
        </span>
      </p>

      <p
        class="MsoNormal"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 92.15pt;
          text-indent: -35.45pt;
        "
      >
        <span>1.1.2    </span
        ><b style="font-weight: bold; color: black; font-size: 17px;"
          ><span>Live Production Environment</span></b
        ><span>
          which is the real-world, global, digital environment where Your</span
        ><span style="letter-spacing: -0.55pt">&nbsp; </span
        ><span
          >Users<span style="letter-spacing: -0.55pt">&nbsp; </span>are<span
            style="letter-spacing: -0.55pt"
          >&nbsp;
          </span
          >onboarded<span style="letter-spacing: -0.55pt"> &nbsp;</span>according<span
            style="letter-spacing: -0.55pt"
          >&nbsp;
          </span
          >to<span style="letter-spacing: -0.55pt">&nbsp; </span>all<span
            style="letter-spacing: -0.6pt"
          >&nbsp;
          </span
          >Network<span style="letter-spacing: -0.55pt">&nbsp; </span>and local<span
            style="letter-spacing: -0.25pt"
          >&nbsp;
          </span
          >Regulatory<span style="letter-spacing: -0.25pt">&nbsp; </span
          >Requirements,<span style="letter-spacing: -0.25pt">&nbsp; </span>all<span
            style="letter-spacing: -0.25pt"
          >&nbsp;
          </span
          >data<span style="letter-spacing: -0.25pt">&nbsp; </span>is<span
            style="letter-spacing: -0.25pt"
          >&nbsp;
          </span
          >related<span style="letter-spacing: -0.25pt"> &nbsp;</span>to<span
            style="letter-spacing: -0.25pt"
          >&nbsp;
          </span
          >Your<span style="letter-spacing: -0.25pt">&nbsp; </span>real<span
            style="letter-spacing: -0.25pt"
          >&nbsp;
          </span
          >Users,<span style="letter-spacing: -0.25pt"> &nbsp;</span>and<span
            style="letter-spacing: -0.25pt"
          >&nbsp;
          </span
          >all User transactions are considered compliant by the
          Network and Regulator.  Our Live Production SLAs will be
          <span style="color: black; letter-spacing: -0.2pt"
            >effective. You may not conduct any form of stress-testing or
            load-testing on the Live Product Environment without Our written
            approval.
          </span></span
        >
      </p>

      <p
        class="MsoNormal"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 92.15pt;
          text-align: justify;
          text-indent: -35.45pt;
        "
      >
        <span
          >1.1.3   Before the Effective Launch of your App on the Live
          Production Environment, it is mandatory for Your App to undergo a
          successful Vulnerability Assessment and Penetration Testing
          <b style="font-weight: bold; color: black; font-size: 17px;">(“VAPT”)</b>. You are
          responsible for ensuring the ongoing security and integrity of Your
          App.
        </span>
      </p>

      <p
        class="MsoNormal"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-align: justify;
          text-indent: -28.35pt;
        "
      >
        <b class="heading"
          ><span
            >2.       Platform<span style="letter-spacing: -0.4pt"> &nbsp;</span
            ><span style="letter-spacing: -0.1pt">Services</span></span
          ></b
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-indent: -28.35pt;
        "
      >
        <span>2.1.<span>&nbsp;&nbsp;&nbsp; </span></span
        ><span
          >Under the Agreement, We<span style="letter-spacing: -0.4pt">&nbsp; </span
          >grant<span style="letter-spacing: -0.4pt"> &nbsp;</span>to<span
            style="letter-spacing: -0.4pt"
          >&nbsp;
          </span
          >You<span style="letter-spacing: -0.4pt">&nbsp; </span>a<span
            style="letter-spacing: -0.4pt"
          >&nbsp;
          </span
          >limited,<span style="letter-spacing: -0.4pt"> &nbsp;</span>revocable,<span
            style="letter-spacing: -0.4pt"
          >&nbsp;
          </span
          >non-transferable, non-exclusive<span style="letter-spacing: -0.4pt">
          </span
          >&nbsp;license to<span style="letter-spacing: -0.15pt">&nbsp; </span>use<span
            style="letter-spacing: -0.15pt"
          >&nbsp;
          </span
          >the<span style="letter-spacing: -0.15pt">&nbsp; </span>Platform<span
            style="letter-spacing: -0.15pt"
          >&nbsp;
          </span
          >Services<span style="letter-spacing: -0.15pt">&nbsp; </span>in<span
            style="letter-spacing: -0.15pt"
          >&nbsp;
          </span
          >accordance<span style="letter-spacing: -0.15pt">&nbsp; </span>with<span
            style="letter-spacing: -0.15pt"
          >&nbsp;
          </span
          >the<span style="letter-spacing: -0.15pt">&nbsp; </span>Documentation<span
            style="letter-spacing: -0.15pt"
          >&nbsp;
          </span
          >during<span style="letter-spacing: -0.15pt">&nbsp; </span>the<span
            style="letter-spacing: -0.15pt"
          >&nbsp;
          </span
          >Term in the Territory.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-indent: -28.35pt;
        "
      >
        <span>2.2<span>&nbsp;&nbsp;&nbsp;&nbsp; </span></span
        ><span
          >You may not sell, re-sell, rent or sub-license Your right to access
          and use the Platform Services, to anyone.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.25pt;
          margin-bottom: 0.1in;
          margin-left: 57.25pt;
          text-indent: -28.9pt;
        "
      >
        <span>2.3<span>&nbsp;&nbsp;&nbsp;&nbsp; </span></span
        ><span
          >You acknowledge and agree that the Documentation, Network, and local
          Regulator rules shall govern the availability of the Platform
          Services.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.25pt;
          margin-bottom: 0.1in;
          margin-left: 57.25pt;
          text-indent: -28.9pt;
        "
      >
        <span>2.4<span>&nbsp;&nbsp;&nbsp;&nbsp; </span></span
        ><span
          >You will ensure that persons using the Platform Services are strictly
          with Your authority only, and only by means of an Account that
          complies with Documentation and these T&amp;C. You will not permit any
          unauthorized person to access or use the Platform Services.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.25pt;
          margin-bottom: 0.1in;
          margin-left: 57.25pt;
          text-indent: -28.9pt;
        "
      >
        <span>2.5<span>&nbsp;&nbsp;&nbsp;&nbsp; </span></span
        ><span
          >You will not use the Platform Services in any way that causes, or may
          cause, damage to the Platform Services or, impairment of the
          availability or accessibility of the Platform Services.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.25pt;
          margin-bottom: 0.1in;
          margin-left: 57.25pt;
          text-indent: -28.9pt;
        "
      >
        <span>2.6<span>&nbsp;&nbsp;&nbsp;&nbsp; </span></span
        ><span
          >You will not upload any software or material that contains, or which
          you have reason to suspect contains malware, viruses, spywares,
          trojans, worms, ransomwares, other malicious, destructive, or any form
          of corrupting code, agent, program, or macros, damaging components,
          which may impair or corrupt the Platform’s data, or damage or
          interfere with the operation of the Platform Services.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.25pt;
          margin-bottom: 0.1in;
          margin-left: 57.25pt;
          text-indent: -28.9pt;
        "
      >
        <span>2.7<span>&nbsp;&nbsp;&nbsp;&nbsp; </span></span
        ><span
          >You will not take any action that imposes an unreasonable or
          disproportionately large load on the infrastructure of the Platform
          Services or systems or networks connected to the Platform Services or
          attack the Platform Services by means of a denial-of-service attack,
          or spam, or otherwise interfere with or disrupt the operation of any
          of the Services, or the servers or networks that host them.
        </span>
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.25pt;
          margin-bottom: 0.1in;
          margin-left: 57.25pt;
          text-indent: -28.9pt;
        "
      >
        <span>2.8<span>&nbsp;&nbsp;&nbsp;&nbsp; </span></span
        ><span>You must not use the Platform Services:</span>
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.25pt;
          margin-bottom: 0.1in;
          margin-left: 92.7pt;
          text-indent: -0.5in;
        "
      >
        <span>2.8.1<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span
        ><span
          >In any way that is unlawful, illegal, fraudulent, or harmful;
          or</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.25pt;
          margin-bottom: 0.1in;
          margin-left: 92.7pt;
          text-indent: -0.5in;
        "
      >
        <span>2.8.2<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span
        ><span
          >In connection with any unlawful, illegal, fraudulent, or harmful
          purpose or activity.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.25pt;
          margin-bottom: 0.1in;
          margin-left: 57.25pt;
          text-indent: -28.9pt;
        "
      >
        <span>2.9<span>&nbsp;&nbsp;&nbsp;&nbsp; </span></span
        ><span
          >For the avoidance of doubt, You have no right to access any of the
          software code (including object code, intermediate code, and source
          code) of the Platform, either during or after the Term. You may not
          use any means, indirect or direct, to access the software code or
          replicate the same functions or decompile the software. You shall
          ensure that Users authorized by You do not engage in such
          conduct.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.25pt;
          margin-bottom: 0.1in;
          margin-left: 57.25pt;
          text-indent: -28.9pt;
        "
      >
        <span>2.10<span>&nbsp;&nbsp; </span></span
        ><span
          >You acknowledge and agree that any delay in Your cooperation with Us,
          and the quality and/or completeness of the information furnished to Us
          by You in a timely manner, may impact the Platform Services provided
          under the Agreement.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-indent: -28.35pt;
        "
      >
        <b class="heading"
          ><span style="letter-spacing: -0.1pt"
            >3<span
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span
          ></b
        ><b class="heading"
          ><span
            >Roles and Responsibilities<span style="letter-spacing: -0.3pt">
            </span></span
        ></b>
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-indent: -28.35pt;
        "
      >
        <span lang="EN-SG"
          >3.1<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span
        ><span lang="EN-SG"
          >In order to access the Platform Services, You are required to:</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 92.15pt;
          text-indent: -35.45pt;
        "
      >
        <span style="letter-spacing: -0.05pt"
          >(i)<span
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span
        ><span lang="EN-SG"
          >Assign a skilled project manager and project team to aid Us in the
          implementation and support of Our Services. The language for all communication is English, unless otherwise agreed upon in writing.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 92.15pt;
          text-indent: -35.45pt;
        "
      >
        <span style="letter-spacing: -0.05pt"
          >(ii)<span
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span
        ><span lang="EN-SG"
          >Ensure that Your relevant personnel attend the initial workshops and
          training that We recommend, prior to the Launch Date.
        </span>
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 92.15pt;
          text-indent: -35.45pt;
        "
      >
        <span style="letter-spacing: -0.05pt"
          >(iii)<span
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span
        ><span lang="EN-SG"
          >Ensure that, if Your personnel working on Your Program change, Your
          new personnel are given the required internal training and handover
          or, procure the relevant training and VAS from Us, according to Our
          recommendations.
        </span>
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 92.15pt;
          text-indent: -35.45pt;
        "
      >
        <span style="letter-spacing: -0.05pt"
          >(iv)<span
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span
        ><span lang="EN-SG"
          >Manage fraud, execute financial reporting/settlement, perform
          reconciliation settlement, and maintain customer management, according to Our instructions.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 92.15pt;
          text-indent: -35.45pt;
        "
      >
        <span style="letter-spacing: -0.05pt"
          >(v)<span
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span
        ><span lang="EN-SG"
          >Implement and conduct KYC and AML surveillance in accordance with Our
          guidelines, as well as the Network and Regulations'
          requirements.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 92.15pt;
          text-indent: -35.45pt;
        "
      >
        <span style="letter-spacing: -0.05pt"
          >(vi)<span
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span
        ><span lang="EN-SG"
          >Promptly supply Us with copies of original settlement statements and
          transactions, if Our systems do not cover them, for the purpose of
          calculating revenues and costs.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 92.15pt;
          text-indent: -35.45pt;
        "
      >
        <span style="letter-spacing: -0.05pt"
          >(vii)<span
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span
        ><span lang="EN-SG"
          >Submit to Us within seven (7) days, any information required for
          Regulatory reasons, including but not limited to, taxation,
          compliance, AML and any other Regulatory demands.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 92.15pt;
          text-indent: -35.45pt;
        "
      >
        <span style="letter-spacing: -0.05pt"
          >(viii)<span
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span
        ><span lang="EN-SG"
          >Offer a customer service helpline and/or online interface for Your
          Users, if directed by Us.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 92.15pt;
          text-indent: -35.45pt;
        "
      >
        <span style="letter-spacing: -0.05pt"
          >(ix)<span
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span
        ><span lang="EN-SG"
          >Prefund Your Programs sufficiently, as and when needed, ensuring at
          all times that there are adequate funds for Your authorised Users to
          perform transactions using Your App.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 92.15pt;
          text-indent: -35.45pt;
        "
      >
        <span style="letter-spacing: -0.05pt"
          >(x)<span
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span
        ><span
          >For Programs where prefunding is required, You will maintain a
          prefund balance that will be based on the expected business volume for
          Your Program (“Prefund Amount”).  You will deposit the Prefund Amount
          into the designated Pool Account as instructed by Us. You and We will agree on a
          minimum amount will be maintained in the account (“Minimum Prefund
          Amount”). The Minimum Prefund Amount for Your Program must be always
          maintained by You, without requiring any notice or intimation from Us,
          and will be in the local currency of the Territory or USD, as
          applicable. You are aware that We are required to halt any
          transactions or top-up/(s) if the Prefund Amount falls below the
          Minimum Prefund Amount. You accept that Your maintaining an adequate Prefund Amount
          is necessary for Us to:&nbsp;</span
        >
      </p>

      <p
        class="MsoNormal"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 120.5pt;
          text-align: justify;
          text-indent: -28.35pt;
        "
      >
        <span>a.         Authorize transactions and/or</span>
      </p>

      <p
        class="MsoNormal"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 120.5pt;
          text-align: justify;
          text-indent: -28.35pt;
        "
      >
        <span
          >b.         Enable top-ups to the End User Account or Card
          balance</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 92.15pt;
          text-indent: -35.45pt;
        "
      >
        <span style="letter-spacing: -0.05pt"
          >(xi)<span
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span
        ><span lang="EN-SG"
          >Develop and sustain only secure communication channels to access the
          Platform Services, including but not limited to SSL, VPN,
          inter-country connections, and so on.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 92.15pt;
          text-indent: -35.45pt;
        "
      >
        <span style="letter-spacing: -0.05pt"
          >(xii)<span
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span
        ><span lang="EN-SG"
          >Undertake a VAPT before the initial deployment in the Live Production
          Environment, and subsequently every twelve (12) months, or whenever there is a
          major modification to the software or Your product's protocols,
          whichever comes first. You will present us with the VAPT results
          promptly and only distribute Your App with Our written consent.</span
        ><span>
          You have the option to request Us to manage these VAPT tests on Your
          behalf, or You may engage the services of an accredited third party
          approved by Us. It is important to note that You bear full
          responsibility for the quality, integrity, and security of Your
          App and products and software.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 92.15pt;
          text-indent: -35.45pt;
        "
      >
        <span style="letter-spacing: -0.05pt"
          >(xiii)<span
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span
        ><span lang="EN-SG"
          >Adhere to Our and the Network's brand guidelines for logo and
          trademark usage, where applicable.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 85.05pt;
          text-indent: 0in;
        "
      >
        <span lang="EN-SG">&nbsp;</span>
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-indent: -28.35pt;
        "
      >
        <span lang="EN-SG"
          >3.2<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span
        ><span
          >We<span style="letter-spacing: -0.2pt">&nbsp; </span
          ><span style="letter-spacing: -0.1pt">shall:</span></span
        >
      </p>

      <p
        class="MsoBodyText"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 85.05pt;
          text-indent: -28.35pt;
        "
      >
        <span
          >(i)<span
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span
        ><span
          >Setup, implement and operate the Platform for Your Program(s), in
          accordance with the Agreement and our SLA.</span
        >
      </p>

      <p
        class="MsoBodyText"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 85.05pt;
          text-indent: -28.35pt;
        "
      >
        <span>(ii)<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span
        ><span
          >Where required, provide<span style="letter-spacing: 1.3pt"> &nbsp;</span
          >You<span style="letter-spacing: 1.3pt"> &nbsp;</span>with<span
            style="letter-spacing: 1.3pt"
          >&nbsp;
          </span
          >standard and time-limited support for accessing the UAT/Pre-Prod and
          Production Environment. Should You require additional support, You can
          procure the relevant services from
          <b style="font-weight: bold; color: black"
            >www.matchmovevas.com.</b
          ></span
        >
      </p>

      <p
        class="MsoBodyText"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 85.05pt;
          text-indent: -28.35pt;
        "
      >
        <span>(iii)<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span
        ><span
          >Use all reasonable endeavors to ensure that Platform Services are
          provided according to the initial plan and SLA indicated in the
          Agreement. A delay in You performing Your obligations may result in a
          delay in the provision of the Platform Access Services, for which We
          will not be liable.</span
        >
      </p>

      <p
        class="MsoBodyText"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 85.05pt;
          text-indent: -28.35pt;
        "
      >
        <span>(iv)<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span
        ><span
          >Maintain a helpdesk and designate a Customer Growth Manager to
          support You.
        </span>
      </p>

      <p
        class="MsoBodyText"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 85.05pt;
          text-indent: -28.35pt;
        "
      >
        <span>(v)<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span
        ><span
          >Provide Maintenance Services and Support Services, maintain Service
          Levels, provide Updates and Upgrades where required, and deliver VAS,
          all according to the SLA.</span
        >
      </p>

      <p
        class="MsoBodyText"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-align: justify;
          text-indent: -28.35pt;
        "
      >
        <span>3.3<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span
        ><b style="font-weight: bold; color: black"
          ><span>Availability SLA</span></b
        >
      </p>

      <p
        class="MsoBodyText"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 92.15pt;
          text-indent: -35.45pt;
        "
      >
        <span
          >(i)<span
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span
        ><b style="font-weight: bold; color: black"><span>“Uptime”</span></b
        ><span>
          means the percentage of time, during a given period, when the Platform
          Services are available to You, at the gateway between the public
          Internet and Our hosting services provider for the Platform
          Services.</span
        >
      </p>

      <p
        class="MsoBodyText"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 92.15pt;
          text-indent: -35.45pt;
        "
      >
        <span
          >(ii)<span
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span
        ><span
          >We measure Uptime using the following method: Quarterly availability
          = (Total number of minutes in a particular quarter –Number of minutes
          of full outage of platform processing) / (Total number of minutes in a
          particular quarter) x 100</span
        >
      </p>

      <p
        class="MsoBodyText"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 92.15pt;
          text-indent: -35.45pt;
        "
      >
        <span
          >(iii)<span
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span
        ><span
          >We will use all commercially reasonable endeavors to ensure that the
          uptime for the Platform Services is at least 99.5% during each
          calendar quarter
          <b style="font-weight: bold; color: black">(“Target Uptime”)</b
          >.</span
        >
      </p>

      <p
        class="MsoBodyText"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 92.15pt;
          text-indent: -35.45pt;
        "
      >
        <span
          >(iv)<span
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span
        ><span
          >When calculating Uptime, the following shall not be considered:</span
        >
      </p>

      <p
        class="MsoBodyText"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 120.5pt;
          text-align: justify;
          text-indent: -28.35pt;
        "
      >
        <span
          >a.<span
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span
        ><span>A Force Majeure Event.</span>
      </p>

      <p
        class="MsoBodyText"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 120.5pt;
          text-align: justify;
          text-indent: -28.35pt;
        "
      >
        <span
          >b.<span
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span
        ><span>A fault or &nbsp;</span>
        <span>failure of the Your computer systems or networks.</span>
      </p>

      <p
        class="MsoBodyText"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 120.5pt;
          text-align: justify;
          text-indent: -28.35pt;
        "
      >
        <span
          >c.<span
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span
        ><span>Any breach by the You of this Agreement.</span>
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0.1in;
          margin-left: 120.5pt;
          text-indent: -28.35pt;
        "
      >
        <span
          >d.<span
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span
        ><span
          >When We are performing scheduled maintenance or urgent security
          hotfixes.</span
        >
      </p>

      <p
        class="MsoBodyText"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 120.5pt;
          text-align: justify;
          text-indent: -28.35pt;
        "
      >
        <span
          >e.<span
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span
        ><span
          >Unauthorized and malicious internet attacks by unknown third parties
          e.g., DDOS attacks; or</span
        >
      </p>

      <p
        class="MsoBodyText"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 120.5pt;
          text-align: justify;
          text-indent: -28.35pt;
        "
      >
        <span
          >f.<span
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span
        ><span
          >Unverifiable or untraceable end-user feedback or experience.</span
        >
      </p>

      <p
        class="MsoBodyText"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-align: justify;
          text-indent: -28.35pt;
        "
      >
        <span>3.4<span>&nbsp;&nbsp;&nbsp;&nbsp; </span></span
        ><b style="font-weight: bold; color: black"
          ><span>Service Credits</span></b
        >
      </p>

      <p
        class="MsoBodyText"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 92.15pt;
          text-indent: -35.45pt;
        "
      >
        <span>3.4.1<span>&nbsp;&nbsp;&nbsp;&nbsp; </span></span
        ><span
          >For each calendar quarter in which the actual Platform Services
          Uptime falls below the Target Uptime, You will be entitled to receive
          Service Credits, as the exclusive form of compensation. Service
          Credits will accumulate at a rate of 0.5 man-days, per hour below the
          Target Uptime.
        </span>
      </p>

      <p
        class="MsoBodyText"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 92.15pt;
          text-indent: -35.45pt;
        "
      >
        <span>3.4.2<span>&nbsp;&nbsp;&nbsp;&nbsp; </span></span
        ><span
          >If You have any overdue payments due to Us, You will not be eligible
          to receive any Service Credits.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-indent: -28.3pt;
        "
      >
        <b class="heading"
          ><span
            >4.<span
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span
          ></b
        ><b class="heading"
          ><span
            >Maintenance, Releases, and Upgrades<span style="letter-spacing: -0.55pt"> </span
            ></span
          ></b
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-indent: -28.3pt;
        "
      >
        <span>4.1<span>&nbsp;&nbsp;&nbsp;&nbsp; </span></span
        ><span
          >We shall provide specific Maintenance Services to You in accordance
          with the
          <span style="letter-spacing: -0.1pt">Documentation.</span></span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 57.35pt;
          text-indent: -29pt;
        "
      >
        <span>4.2<span>&nbsp;&nbsp;&nbsp;&nbsp; </span></span
        ><span
          >We  conduct  Maintenance  Services  periodically  on  the  Platform  to  ensure  its  optimal  functioning, performance,   and   security.   These   Maintenance   Services   may   necessitate   temporary   service interruptions and downtime. We will make reasonable efforts to provide advance notice to You, whenever feasible.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-indent: -28.35pt;
        "
      >
        <span>4.3<span>&nbsp;&nbsp;&nbsp;&nbsp; </span></span
        ><span
          >We  retain  the  right  to  periodically  release  Upgrades  to  the  Platform  with  the  aim  of  enhancing  its functionality and performance. These Upgrades may encompass bug fixes, performance improvements and the introduction of new features. We shall make reasonable efforts to inform You in advance of such upcoming Releases, including any anticipated impact on Your applications or services.
        </span>
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-indent: -28.35pt;
        "
      >
        <span>4.4<span>&nbsp;&nbsp;&nbsp;&nbsp; </span></span
        ><span
          >Our goal is to implement Upgrades in a way that prioritizes backward compatibility whenever feasible. You  can  find  detailed  information  about  these  modifications  in  the  documentation  provided  at: <a href="https://developer.matchmove.com/docs/optimus-prime/ZG9jOjQwNjE1ODcw-api-upgrades">https://developer.matchmove.com/docs/optimus-prime/ZG9jOjQwNjE1ODcw-api-upgrades</a>
        </span>
      </p>
      
      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-indent: -28.35pt;
        "
      >
        <span>4.5<span>&nbsp;&nbsp;&nbsp;&nbsp; </span></span
        ><span
          >Where Updates and Upgrades are required by Regulators, or Networks or for security reasons, You shall allow, and fully assist Us to install such Updates and Upgrades without delay. For other Updates and Upgrades,  You  should  implement  such  Updates  and  Upgrades  within  thirty  (30)  days  of  receipt  of instructions from Us. We will not be responsible for any consequences if You do not comply.
        </span>
      </p>
      
      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-indent: -28.35pt;
        "
      >
        <span>4.6<span>&nbsp;&nbsp;&nbsp;&nbsp; </span></span
        ><span
          >If You have any overdue payments due to Us, We have the right to immediately suspend the provision of Maintenance Services, after providing seven (7) days written notice.
        </span>
      </p>

      <p
        class="MsoListParagraph heading"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-indent: -28.35pt;
        "
      >
        <span>4.7<span>&nbsp;&nbsp;&nbsp;&nbsp; </span></span
        ><b style="font-weight: bold; color: black"
          ><span>API Deprecation</span></b
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 92.15pt;
          text-indent: -35.45pt;
        "
      >
        <span>4.7.1<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span
        ><span
          >We reserve the right to classify specific APIs or features of the Platform as 'Deprecated APIs.'</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 92.15pt;
          text-indent: -35.45pt;
        "
      >
        <span>4.7.2<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span
        ><span
          >We will make reasonable efforts to give a minimum notice period of ninety (90) days prior to deprecating the APIs. During this time, we will provide comprehensive information During this time,  we  will  provide  comprehensive  information  about  the  Deprecated  APIs,  the  reasons behind their deprecation and recommended alternative solutions or migration paths.</span
        >
      </p>
      
      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 92.15pt;
          text-indent: -35.45pt;
        "
      >
        <span>4.7.3<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span
        ><span
          >As  the  owner  of  Your  App  or  other  services  that  rely  on  the  Platform  Services,  it  is  Your responsibility  to  ensure  migration  away  from  the  Deprecated  APIs  within  the  specified  notice period.  Please  note  that  failure  to  comply  with  the  migration  may  result  in  the  disruption  or termination of the affected Platform functionality without any additional notice.</span
        >
      </p>
      
      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 92.15pt;
          text-indent: -35.45pt;
        "
      >
        <span>4.7.4<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span
        ><span
          >If an API is deprecated, it is Your responsibility to make the required adjustments to Your App or  services  to  ensure  compatibility  and  uninterrupted  usage  of  the  Platform  Services  after Upgrades and Releases. We will provide You with Documentation and guidelines to assist You throughout this process.</span
        >
      </p>

      <p
        class="MsoListParagraph heading"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-indent: -28.35pt;
        "
      >
        <span>4.8<span>&nbsp;&nbsp;&nbsp;&nbsp; </span></span
        ><b style="font-weight: bold; color: black"
          ><span>Breaking Changes</span></b
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 92.15pt;
          text-indent: -35.45pt;
        "
      >
        <span>4.8.1<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span
        ><span
          >We  maintain  the  right  to  introduce  Breaking  Changes  to  the  Platform  as  part  of  Upgrades  or Releases.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 92.15pt;
          text-indent: -35.45pt;
        "
      >
        <span>4.8.2<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span
        ><span
          >We will make reasonable efforts to minimize the occurrence of Breaking Changes. However, there  may  be  instances  where  Breaking  Changes  become  necessary  to  enhance  the performance, security, or functionality of the Platform.</span
        >
      </p>
      
      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 92.15pt;
          text-indent: -35.45pt;
        "
      >
        <span>4.8.3<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span
        ><span
          >We will provide You with advance notice concerning any planned Breaking Changes, including details about the nature of the changes and the expected impact on existing integrations.</span
        >
      </p>
      
      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 92.15pt;
          text-indent: -35.45pt;
        "
      >
        <span>4.8.4<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span
        ><span
          >It will be Your responsibility to implement the necessary modifications to Your App or services to  accommodate  the  Breaking  Changes  within  a  period  of  three  (3)  months  from  the  date  of notice.</span
        >
      </p>
      
      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 92.15pt;
          text-indent: -35.45pt;
        "
      >
        <span>4.8.5<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span
        ><span
          >In the event of Breaking Changes, You are responsible for incorporating and implementing the necessary  adjustments  to  Your  applications  or  services  to  ensure  compatibility  with  and uninterrupted usage of the Platform following Upgrades and Releases. We will furnish You with documentation and guidelines to assist You in this process.</span
        >
      </p>

      <p
        class="MsoListParagraph heading"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-indent: -28.35pt;
        "
      >
        <span>4.9<span>&nbsp;&nbsp;&nbsp;&nbsp; </span></span
        ><b style="font-weight: bold; color: black"
          ><span>Exceptional Scenarios: Security, Regulatory or Network Requirements.</span></b
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 92.15pt;
          text-indent: -35.45pt;
        "
      >
        <span>4.9.1<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span
        ><span
          >In   exceptional   circumstances,   such   as   security   vulnerabilities,   changes   in   Regulatory Requirements,  or  alterations  imposed  by  Network  Requirements,  We  may  be  required  to implement Breaking Changes to the Platform. In such cases, We will notify You, and You will be required to comply with the Breaking Changes within a one (1) month timeframe from the date of notice.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 92.15pt;
          text-indent: -35.45pt;
        "
      >
        <span>4.9.2<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span
        ><span
          >You are expected to make reasonable efforts to implement the necessary modifications to Your App or services within the specified timeframe to ensure continued compliance with security or regulatory requirements.</span
        >
      </p>
      
      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 92.15pt;
          text-indent: -35.45pt;
        "
      >
        <span>4.9.3<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span
        ><span
          >We  do  not  assume  liability  for  any  damages  or  losses  suffered  by  You  due  to  Maintenance, Upgrades, Releases, or Deprecation activities. This includes any disruptions, errors, or loss of data that may occur during these processes.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-indent: -28.35pt;
        "
      >
        <b class="heading"
          ><span
            >5.<span
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span
          ></b
        ><b class="heading"
          ><span
            >Support<span style="letter-spacing: -0.35pt">&nbsp; </span
            ><span style="letter-spacing: -0.1pt">Services</span></span
          ></b
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-indent: -28.35pt;
        "
      >
        <span>5.1<span>&nbsp;&nbsp;&nbsp;&nbsp; </span></span
        ><span
          >We  will  provide  specific  Program  onboarding  and  other  Support  Services  to  You,  for  a  fixed period, in accordance with the Documentation and Schedules.
        </span>
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-indent: -28.35pt;
        "
      >
        <span>5.2<span>&nbsp;&nbsp;&nbsp;&nbsp; </span></span
        ><span
          >You have the option to request additional Platform Services or Value-Added Services (“VAS”). We  may  also  recommend  them  as  necessary  for  the  successful  launch  and  growth  of  Your Program. In certain cases, We may determine that your Program requires exceptional Support Services, and We will advise You to procure the relevant VAS to address such requirements.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-indent: -28.35pt;
        "
      >
        <span>5.3<span>&nbsp;&nbsp;&nbsp;&nbsp; </span></span
        ><span
          >Helpdesk availability will be from 9:00 a.m. SGT to 10:30 p.m. SGT
          (excluding weekends and public holidays).</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-indent: -28.35pt;
        "
      >
        <span>5.4<span>&nbsp;&nbsp;&nbsp;&nbsp; </span></span
        ><span
          >If You have any overdue payments due to Us, We have the right to
          immediately suspend the provision of Support Services, after providing
          seven (7) days written notice.
        </span>
      </p>

      <p
        class="MsoBodyText"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-align: justify;
          text-indent: -28.35pt;
        "
      >
        <b class="heading"
          ><span
            >6.<span
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span
          ></b
        ><b class="heading"
          ><span>Support SLAs for Production Environment</span></b
        >
      </p>

      <table
        class="MsoTableGrid"
        border="1"
        cellspacing="0"
        cellpadding="0"
        style="margin-left: 56.7pt; border-collapse: collapse; border: none; width: 84%"
      >
        <tr>
          <td
            width="66"
            valign="top"
            style="
              width: 49.4pt;
              border: solid windowtext 1pt;
              padding: 0in 5.4pt 0in 5.4pt;
            "
          >
            <p class="MsoNormal" style="margin-bottom: 0.1in">
              <span>Priority</span>
            </p>
          </td>
          <td
            width="76"
            valign="top"
            style="
              width: 56.7pt;
              border: solid windowtext 1pt;
              border-left: none;
              padding: 0in 5.4pt 0in 5.4pt;
            "
          >
            <p class="MsoNormal" style="margin-bottom: 0.1in">
              <span>Severity</span>
            </p>
          </td>
          <td
            width="255"
            valign="top"
            style="
              width: 191.35pt;
              border: solid windowtext 1pt;
              border-left: none;
              padding: 0in 5.4pt 0in 5.4pt;
            "
          >
            <p class="MsoNormal" style="margin-bottom: 0.1in">
              <span>1<sup>st</sup> Response SLA (During Working Hours)</span>
            </p>
          </td>
          <td
            width="151"
            valign="top"
            style="
              width: 113.4pt;
              border: solid windowtext 1pt;
              border-left: none;
              padding: 0in 5.4pt 0in 5.4pt;
            "
          >
            <p class="MsoNormal" style="margin-bottom: 0.1in">
              <span>Resolution</span>
            </p>
          </td>
        </tr>
        <tr>
          <td
            width="66"
            valign="top"
            style="
              width: 49.4pt;
              border: solid windowtext 1pt;
              border-top: none;
              padding: 0in 5.4pt 0in 5.4pt;
            "
          >
            <p class="MsoNormal" style="margin-bottom: 0.1in">
              <span>Critical</span>
            </p>
          </td>
          <td
            width="76"
            valign="top"
            style="
              width: 56.7pt;
              border-top: none;
              border-left: none;
              border-bottom: solid windowtext 1pt;
              border-right: solid windowtext 1pt;
              padding: 0in 5.4pt 0in 5.4pt;
            "
          >
            <p class="MsoNormal" style="margin-bottom: 0.1in">
              <span>P1</span>
            </p>
          </td>
          <td
            width="255"
            valign="top"
            style="
              width: 191.35pt;
              border-top: none;
              border-left: none;
              border-bottom: solid windowtext 1pt;
              border-right: solid windowtext 1pt;
              padding: 0in 5.4pt 0in 5.4pt;
            "
          >
            <p class="MsoNormal" style="margin-bottom: 0.1in">
              <span>15 mins</span>
            </p>
          </td>
          <td
            width="151"
            valign="top"
            style="
              width: 113.4pt;
              border-top: none;
              border-left: none;
              border-bottom: solid windowtext 1pt;
              border-right: solid windowtext 1pt;
              padding: 0in 5.4pt 0in 5.4pt;
            "
          >
            <p class="MsoNormal" style="margin-bottom: 0.1in">
              <span>4 hours (RCA &lt;= 4 days)</span>
            </p>
          </td>
        </tr>
        <tr>
          <td
            width="66"
            valign="top"
            style="
              width: 49.4pt;
              border: solid windowtext 1pt;
              border-top: none;
              padding: 0in 5.4pt 0in 5.4pt;
            "
          >
            <p class="MsoNormal" style="margin-bottom: 0.1in">
              <span>High</span>
            </p>
          </td>
          <td
            width="76"
            valign="top"
            style="
              width: 56.7pt;
              border-top: none;
              border-left: none;
              border-bottom: solid windowtext 1pt;
              border-right: solid windowtext 1pt;
              padding: 0in 5.4pt 0in 5.4pt;
            "
          >
            <p class="MsoNormal" style="margin-bottom: 0.1in">
              <span>P2</span>
            </p>
          </td>
          <td
            width="255"
            valign="top"
            style="
              width: 191.35pt;
              border-top: none;
              border-left: none;
              border-bottom: solid windowtext 1pt;
              border-right: solid windowtext 1pt;
              padding: 0in 5.4pt 0in 5.4pt;
            "
          >
            <p class="MsoNormal" style="margin-bottom: 0.1in">
              <span>15 mins</span>
            </p>
          </td>
          <td
            width="151"
            valign="top"
            style="
              width: 113.4pt;
              border-top: none;
              border-left: none;
              border-bottom: solid windowtext 1pt;
              border-right: solid windowtext 1pt;
              padding: 0in 5.4pt 0in 5.4pt;
            "
          >
            <p class="MsoNormal" style="margin-bottom: 0.1in">
              <span>2 days</span>
            </p>
          </td>
        </tr>
        <tr>
          <td
            width="66"
            valign="top"
            style="
              width: 49.4pt;
              border: solid windowtext 1pt;
              border-top: none;
              padding: 0in 5.4pt 0in 5.4pt;
            "
          >
            <p class="MsoNormal" style="margin-bottom: 0.1in">
              <span>Medium</span>
            </p>
          </td>
          <td
            width="76"
            valign="top"
            style="
              width: 56.7pt;
              border-top: none;
              border-left: none;
              border-bottom: solid windowtext 1pt;
              border-right: solid windowtext 1pt;
              padding: 0in 5.4pt 0in 5.4pt;
            "
          >
            <p class="MsoNormal" style="margin-bottom: 0.1in">
              <span>P3</span>
            </p>
          </td>
          <td
            width="255"
            valign="top"
            style="
              width: 191.35pt;
              border-top: none;
              border-left: none;
              border-bottom: solid windowtext 1pt;
              border-right: solid windowtext 1pt;
              padding: 0in 5.4pt 0in 5.4pt;
            "
          >
            <p class="MsoNormal" style="margin-bottom: 0.1in">
              <span>1 Hour</span>
            </p>
          </td>
          <td
            width="151"
            valign="top"
            style="
              width: 113.4pt;
              border-top: none;
              border-left: none;
              border-bottom: solid windowtext 1pt;
              border-right: solid windowtext 1pt;
              padding: 0in 5.4pt 0in 5.4pt;
            "
          >
            <p class="MsoNormal" style="margin-bottom: 0.1in">
              <span>2 days</span>
            </p>
          </td>
        </tr>
        <tr>
          <td
            width="66"
            valign="top"
            style="
              width: 49.4pt;
              border: solid windowtext 1pt;
              border-top: none;
              padding: 0in 5.4pt 0in 5.4pt;
            "
          >
            <p class="MsoNormal" style="margin-bottom: 0.1in">
              <span>Low</span>
            </p>
          </td>
          <td
            width="76"
            valign="top"
            style="
              width: 56.7pt;
              border-top: none;
              border-left: none;
              border-bottom: solid windowtext 1pt;
              border-right: solid windowtext 1pt;
              padding: 0in 5.4pt 0in 5.4pt;
            "
          >
            <p class="MsoNormal" style="margin-bottom: 0.1in">
              <span>P4</span>
            </p>
          </td>
          <td
            width="255"
            valign="top"
            style="
              width: 191.35pt;
              border-top: none;
              border-left: none;
              border-bottom: solid windowtext 1pt;
              border-right: solid windowtext 1pt;
              padding: 0in 5.4pt 0in 5.4pt;
            "
          >
            <p class="MsoNormal" style="margin-bottom: 0.1in">
              <span>1 Hour</span>
            </p>
          </td>
          <td
            width="151"
            valign="top"
            style="
              width: 113.4pt;
              border-top: none;
              border-left: none;
              border-bottom: solid windowtext 1pt;
              border-right: solid windowtext 1pt;
              padding: 0in 5.4pt 0in 5.4pt;
            "
          >
            <p class="MsoNormal" style="margin-bottom: 0.1in">
              <span>Resource Availability</span>
            </p>
          </td>
        </tr>
      </table>

      <p
        class="MsoBodyText"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-align: justify;
        "
      >
        <b style="font-weight: bold; color: black"><span>&nbsp;</span></b>
      </p>

      <p
        class="MsoBodyText"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-align: justify;
        "
      >
        <span>Definitions for Severity:</span>
      </p>

      <p
        class="MsoBodyText"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-align: justify;
        "
      >
        <span>P1: Service Disruption and major financial impact </span>
      </p>

      <p
        class="MsoBodyText"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-align: justify;
        "
      >
        <span>P2: Impact to a limited set Users</span>
      </p>

      <p
        class="MsoBodyText"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-align: justify;
        "
      >
        <span
          >P3: Data request, impact to 1-2 Users, information, or any other
          trivial request</span
        >
      </p>

      <p
        class="MsoBodyText"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-align: justify;
        "
      >
        <span>P4: General Enquiries</span>
      </p>

      <p
        class="MsoBodyText"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-align: justify;
        "
      >
        <i
          ><span
            >Please note that, due to the nature of testing environments, there
            are <u>no</u> SLAs applicable for the UAT/Pre-Environment.
          </span></i
        >
      </p>

      <p
        class="MsoBodyText"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-align: justify;
          text-indent: -28.35pt;
        "
      >
        <b class="heading"
          ><span
            >7.<span
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span
          ></b
        ><b class="heading"
          ><span style="letter-spacing: -0.1pt">Customizations </span></b
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 57.35pt;
          text-indent: -29pt;
        "
      >
        <span>7.1<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span
        ><span
          >We<span style="letter-spacing: -0.6pt"> &nbsp;</span>may<span
            style="letter-spacing: -0.55pt"
          >&nbsp;
          </span
          >agree<span style="letter-spacing: -0.55pt">&nbsp; </span>with<span
            style="letter-spacing: -0.55pt"
          >&nbsp;
          </span
          >You,<span style="letter-spacing: -0.55pt">&nbsp; </span>in writing<span
            style="letter-spacing: -0.55pt"
            >, &nbsp;</span
          >during<span style="letter-spacing: -0.55pt"> &nbsp;</span>the<span
            style="letter-spacing: -0.55pt"
          >&nbsp;
          </span
          >Term,&nbsp;<span style="letter-spacing: -0.55pt"> &nbsp;</span>to design,<span
            style="letter-spacing: -0.55pt"
          >&nbsp;
          </span
          >develop,<span style="letter-spacing: -0.55pt"> </span><span
            style="letter-spacing: -0.55pt"
          >&nbsp;
          </span
          >and implement Customizations in accordance with a specification and
          project plan and pricing.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 57.35pt;
          text-indent: -29pt;
        "
      >
        <span>7.2<span>&nbsp;&nbsp;&nbsp;&nbsp; </span></span
        ><span
          >Unless We agree with You in writing, all Intellectual Property Rights
          in the Customizations shall be Our exclusive property.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-indent: -28.35pt;
        "
      >
        <span>7.3<span>&nbsp;&nbsp;&nbsp;&nbsp; </span></span
        ><span
          >When<span style="letter-spacing: -0.25pt"> &nbsp;</span>a<span
            style="letter-spacing: -0.25pt"
          >&nbsp;
          </span
          >Customization<span style="letter-spacing: -0.25pt">&nbsp; </span>is<span
            style="letter-spacing: -0.25pt"
          >&nbsp;
          </span
          >made<span style="letter-spacing: -0.25pt"> &nbsp;</span>available<span
            style="letter-spacing: -0.25pt"
          >&nbsp;
          </span
          >by<span style="letter-spacing: -0.25pt">&nbsp; </span>Us<span
            style="letter-spacing: -0.25pt"
          >&nbsp;
          </span
          >to<span style="letter-spacing: -0.25pt"> &nbsp;</span>You,<span
            style="letter-spacing: -0.25pt"
          >&nbsp;
          </span
          >the<span style="letter-spacing: -0.25pt"> &nbsp;</span>Customization<span
            style="letter-spacing: -0.25pt"
          >&nbsp;
          </span
          >shall<span style="letter-spacing: -0.25pt"> &nbsp;</span>form part of the
          Platform, and accordingly Your right to use the Customization shall be
          governed by the Agreement.</span
        >
      </p>

      <p
        class="MsoNormal"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-indent: -28.35pt;
        " 
      >
        <b class="heading">
          <span
            >8.<span
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span
          ><span>Change Requests </span>
        </b>
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-indent: -28.35pt;
        "
      >
        <span>8.1<span>&nbsp;&nbsp;&nbsp;&nbsp; </span></span
        ><span
          >You have the option to submit a Change Request for alterations or
          modifications to the specifications or configurations of the Platform
          Services, as were initially agreed upon in the Schedules. These Change
          Requests can be made for both the Pre-Prod and Live Environments of
          the Platform through the services portal found at
          <b style="font-weight: bold; color: black; font-size: 18px;">support.matchmove.com</b>,
          however, the final decision to implement these Change Requests
          lies solely with Us. If there are any Charges or timelines associated with
          Your Change Requests, We will inform You, and Your written
          acceptance is required before the requested changes can be
          implemented.</span
        >
      </p>

      <p
        class="MsoNormal"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-indent: -28.35pt;
        "
      >
        <b class="heading">
          <span
            >9.<span
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span
          ><span
            >Partner<span style="letter-spacing: -0.35pt">&nbsp; </span
            ><span style="letter-spacing: -0.2pt">Data</span></span
          >
        </b>
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-indent: -28.35pt;
        "
      >
        <span>9.1<span>&nbsp;&nbsp;&nbsp;&nbsp; </span></span
        ><span
          >You grant Us a royalty-free, worldwide, non-exclusive license to
          utilize, copy, replicate, store, distribute, publish, export, modify,
          edit, and translate Your Data to the extent reasonably necessary for
          fulfilling our responsibilities towards You, as well as for compliance
          with Regulatory requirements and Network regulations. This license
          also encompasses further product development and enhancement, as well
          as the exercise of Our rights under this Agreement. It is important
          for You to note that, as a regulated entity, We may be obligated by
          law to disclose or transfer Your Data to Regulator(s), government
          bodies, and authorities. We will notify You of any such
          disclosures.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-indent: -28.35pt;
        "
      >
        <span>9.2<span>&nbsp;&nbsp;&nbsp;&nbsp; </span></span
        ><span
          >You warrant to Us that Your Data will not infringe the Intellectual
          Property Rights or other<span style="letter-spacing: -0.3pt"> &nbsp;</span
          >legal<span style="letter-spacing: -0.3pt"> &nbsp;</span>rights<span
            style="letter-spacing: -0.3pt"
          >&nbsp;
          </span
          >of<span style="letter-spacing: -0.3pt"> &nbsp;</span>any<span
            style="letter-spacing: -0.3pt"
          >&nbsp;
          </span
          >person<span style="letter-spacing: -0.3pt"> &nbsp;</span>and<span
            style="letter-spacing: -0.3pt"
          >&nbsp;
          </span
          >will<span style="letter-spacing: -0.3pt">&nbsp; </span>not<span
            style="letter-spacing: -0.3pt"
          >&nbsp;
          </span
          >breach<span style="letter-spacing: -0.3pt">&nbsp; </span>the<span
            style="letter-spacing: -0.3pt"
          >&nbsp;
          </span
          >provisions<span style="letter-spacing: -0.3pt">&nbsp; </span>of<span
            style="letter-spacing: -0.3pt"
          >&nbsp;
          </span
          >any<span style="letter-spacing: -0.3pt">&nbsp; </span>law,<span
            style="letter-spacing: -0.3pt"
          >&nbsp;
          </span
          >statute, or regulation, in any jurisdiction and under any applicable
          law.</span
        >
      </p>

      <p
        class="MsoNormal"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-indent: -28.35pt;
        "
      >
        <b class="heading">
          <span>10.<span>&nbsp;&nbsp;&nbsp; </span></span
          ><span
            >Data <span style="letter-spacing: -0.2pt"> </span
            ><span style="letter-spacing: -0.1pt"> protection</span></span
          >
        </b>
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-indent: -28.35pt;
        "
      >
        <span>10.1<span>&nbsp;&nbsp; </span></span
        ><span
          >You<span style="letter-spacing: -0.6pt"> &nbsp;</span>warrant<span
            style="letter-spacing: -0.55pt"
          >&nbsp;
          </span
          >to<span style="letter-spacing: -0.55pt">&nbsp; </span>Us<span
            style="letter-spacing: -0.55pt"
          >&nbsp;
          </span
          >that<span style="letter-spacing: -0.55pt"> &nbsp;</span>You<span
            style="letter-spacing: -0.55pt"
          >&nbsp;
          </span
          >have<span style="letter-spacing: -0.55pt"> &nbsp;</span>the<span
            style="letter-spacing: -0.55pt"
          >&nbsp;
          </span
          >legal<span style="letter-spacing: -0.6pt">&nbsp; </span>right<span
            style="letter-spacing: -0.55pt"
          >&nbsp;
          </span
          >to<span style="letter-spacing: -0.55pt">&nbsp; </span>disclose<span
            style="letter-spacing: -0.55pt"
          >&nbsp;
          </span
          >Your Data<span style="letter-spacing: -0.55pt">&nbsp; </span>disclosed to
          You, and by You to Us, and that such disclosure will not affect the
          rights of any third party or Users.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 57.35pt;
          text-indent: -29pt;
        "
      >
        <span>10.2<span>&nbsp;&nbsp; </span></span
        ><span
          >You are aware of the security measures (both technical and
          organizational) that We have established against unlawful or
          unauthorized processing of Your Personal Data, and against loss or
          corruption of Partner Personal Data.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 57.35pt;
          text-indent: -29pt;
        "
      >
        <span>10.3<span>&nbsp;&nbsp; </span></span
        ><span
          >We<span style="letter-spacing: -0.25pt"> warrant that We </span
          >will<span style="letter-spacing: -0.25pt"> only </span>process<span
            style="letter-spacing: -0.25pt"
          >&nbsp;
          </span
          >the<span style="letter-spacing: -0.25pt"> &nbsp;</span>Your Data<span
            style="letter-spacing: -0.25pt"
          >&nbsp;
          </span
          >in<span style="letter-spacing: -0.25pt">&nbsp; </span>compliance<span
            style="letter-spacing: -0.25pt"
          >&nbsp;
          </span
          >with<span style="letter-spacing: -0.25pt">&nbsp; </span>all<span
            style="letter-spacing: -0.25pt"
          >&nbsp;
          </span
          >applicable
          <span style="letter-spacing: -0.1pt"
            >laws and this Agreement.</span
          ></span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 57.35pt;
          text-indent: -29pt;
        "
      >
        <span>10.4<span>&nbsp;&nbsp; </span></span
        ><span
          >We<span style="letter-spacing: -0.35pt"> warrant that We </span>will
          not<span style="letter-spacing: -0.35pt">&nbsp; </span>share<span
            style="letter-spacing: -0.35pt"
          >&nbsp;
          </span
          >any<span style="letter-spacing: -0.35pt"> of </span>Your Data<span
            style="letter-spacing: -0.35pt"
          >&nbsp;
          </span
          >with Our<span style="letter-spacing: -0.35pt">&nbsp; </span>other<span
            style="letter-spacing: -0.35pt"
          >&nbsp;
          </span
          ><span style="letter-spacing: -0.25pt"
            >MMBOS Platform partners.</span
          ></span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 57.35pt;
          text-indent: -29pt;
        "
      >
        <span>10.5<span>&nbsp;&nbsp; </span></span
        ><span
          >You should be aware that We may be required to disclose or transfer
          Your Data to government bodies and authorities, if required by law. We
          will inform You of such requirements, unless prohibited by the
          relevant authority.
        </span>
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-indent: -28.35pt;
        "
      >
        <b class="heading"><span>11.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b>
        <b class="heading"><span>Users and Accounts</span></b>
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-indent: -28.35pt;
        "
      >
        <span lang="EN-SG">11.1<span>&nbsp;&nbsp;&nbsp; </span></span
        ><span lang="EN-SG"
          >You are permitted to create User Accounts for individuals or
          businesses in accordance with the relevant national Regulations,
          Network rules, and Our instructions.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-indent: -28.35pt;
        "
      >
        <span lang="EN-SG">11.2<span>&nbsp;&nbsp;&nbsp; </span></span
        ><span lang="EN-SG"
          >Administrator Accounts may only be assigned to Your authorized
          employees or contractors who are currently engaged in active
          employment or services contracts with You. Transfer or sharing of
          access to Administrator Accounts is prohibited. You are fully
          responsible for the consequences of any actions performed by Your
          employees or contractors.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-indent: -28.35pt;
        "
      >
        <span lang="EN-SG">11.3<span>&nbsp;&nbsp;&nbsp; </span></span
        ><span lang="EN-SG"
          >Your App must prominently display the End User Terms of Service
          provided by Us in the Documentation. While you have the flexibility to
          include additional terms for your users, You must not remove any
          clauses from the provided End User Terms of Service without obtaining
          Our written consent.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-indent: -28.35pt;
        "
      >
        <span lang="EN-SG">11.4<span>&nbsp;&nbsp;&nbsp; </span></span
        ><span
          >You acknowledge and agree to assume responsibility for any
          surcharges, special fees, network fees, or assessments related to
          transactions performed by Your User(s) using a card or Account. This
          includes transactions such as overseas transactions, multicurrency
          wallets, transit travel, fuel transactions, no_auth transactions, and
          erroneous money transfers, among others. It is important for You to
          clearly outline these expenses in Your agreement with Your User(s), as
          they have the potential to result in substantial cost increments to
          You.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-indent: -28.35pt;
        "
      >
        <span lang="EN-SG">11.5<span>&nbsp;&nbsp;&nbsp; </span></span
        ><span lang="EN-SG"
          >You are responsible for informing and educating Your Users about all
          the amounts that will be charged to them and are payable by them under
          this Agreement. You will be held liable for any deficit and/or default
          in such payments to Us on behalf of Your User(s).</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-indent: -28.35pt;
        "
      >
        <b class="heading"><span>12.<span>&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b>
        <b class="heading"><span
            >Your<span style="letter-spacing: -0.2pt"> &nbsp;</span
            ><span style="letter-spacing: -0.15pt">App</span></span
          ></b
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-indent: -28.35pt;
        "
      >
        <span lang="EN-SG">12.1<span>&nbsp;&nbsp;&nbsp; </span></span
        ><span lang="EN-SG"
          >You have the option to incorporate the capabilities of the MMBOS
          Platform into an existing or new mobile or web app
          <b style="font-weight: bold; color: black"
            >(&quot;App&quot; or &quot;Your App&quot;)</b
          >
          developed either:
        </span>
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 92.15pt;
          text-indent: -35.45pt;
        "
      >
        <span lang="EN-SG">12.1.1<span>&nbsp;&nbsp;&nbsp;&nbsp; </span></span
        ><span lang="EN-SG">By You directly; or </span>
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 92.15pt;
          text-indent: -35.45pt;
        "
      >
        <span lang="EN-SG">12.1.2<span>&nbsp;&nbsp;&nbsp;&nbsp; </span></span
        ><span lang="EN-SG">By an agent appointed by You.</span>
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-indent: -28.35pt;
        "
      >
        <span lang="EN-SG">12.2<span>&nbsp;&nbsp;&nbsp;&nbsp; </span></span
        ><span lang="EN-SG"
          >You are responsible for ensuring that the development and usage of
          the App always comply with the terms of the Agreement.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-indent: -28.35pt;
        "
      >
        <span lang="EN-SG">12.3<span>&nbsp;&nbsp;&nbsp;&nbsp; </span></span
        ><span lang="EN-SG"
          >Whether the App is developed by You or Your agent, You make the
          following representations and warranties:
        </span>
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 92.15pt;
          text-indent: -35.45pt;
        "
      >
        <span lang="EN-SG">12.3.1<span>&nbsp;&nbsp; </span></span
        ><span lang="EN-SG"
          >Your App will always be compatible with the integration of Platform
          capabilities and will not hinder or negatively impact the Platform
          capabilities in any way.
        </span>
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 92.15pt;
          text-indent: -35.45pt;
        "
      >
        <span lang="EN-SG">12.3.2<span>&nbsp;&nbsp; </span></span
        ><span lang="EN-SG"
          >Your App will not infringe upon any intellectual property rights of
          third parties.
        </span>
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 92.15pt;
          text-indent: -35.45pt;
        "
      >
        <span lang="EN-SG">12.3.3<span>&nbsp;&nbsp; </span></span
        ><span lang="EN-SG"
          >No third-party claims will be brought against us due to any acts or
          omissions related to Your App or You.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-indent: -28.35pt;
        "
      >
        <span lang="EN-SG">12.4<span>&nbsp;&nbsp;&nbsp;&nbsp; </span></span
        ><span lang="EN-SG"
          > Whether the technical integration between Your App and the Platform
          is performed by You or Your agent, You warrant the following:
        </span>
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 92.15pt;
          text-indent: -35.45pt;
        "
      >
        <span lang="EN-SG">12.4.1<span>&nbsp; </span></span
        ><span lang="EN-SG"
          >You and/or your agent performing the integration will solely and
          exclusively use the Platform, Documentation, software code, and any
          other features and functionalities provided for the purpose of
          carrying out the integration as described in the Schedules.
        </span>
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 92.15pt;
          text-indent: -35.45pt;
        "
      >
        <span lang="EN-SG">12.4.2<span>&nbsp; </span></span
        ><span lang="EN-SG"
          >No attempts will be made to create copies, modifications,
          alterations, or derivatives of the Platform.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 92.15pt;
          text-indent: -35.45pt;
        "
      >
        <span lang="EN-SG">12.4.3<span>&nbsp; </span></span
        ><span
          >The integration and ongoing access to the Platform will not introduce
          any bugs, viruses, or cause any damage to the Platform capabilities.
        </span>
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 92.15pt;
          text-indent: -35.45pt;
        "
      >
        <span lang="EN-SG">12.4.4<span>&nbsp; </span></span
        ><span
          >No third-party claims will be brought against Us on account of any
          acts or omissions related to the integration process.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 92.15pt;
          text-indent: -35.45pt;
        "
      >
        <span lang="EN-SG">12.4.5<span>&nbsp; </span></span
        ><span
          >Your software and services will incorporate security features that
          align with the requirements of good industry practices.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 92.15pt;
          text-indent: -35.45pt;
        "
      >
        <span lang="EN-SG">12.4.6<span>&nbsp; </span></span
        ><span
          >Your software, services systems, and Your App utilizing the Platform
          and Platform Services will always conform with the terms of the
          Agreement.
        </span>
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-indent: -28.35pt;
        "
      >
        <span>12.5<span>&nbsp;&nbsp;&nbsp;&nbsp; </span></span
        ><b style="font-weight: bold; color: black"
          ><span>Content in Your App</span></b
        ><span>
          - All content in Your App (“Content”) must comply
          with these T&amp;C.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-indent: -28.35pt;
        "
      >
        <span>12.6<span>&nbsp;&nbsp;&nbsp;&nbsp; </span></span
        ><b style="font-weight: bold; color: black"
          ><span>Unlawful Content</span></b
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 92.15pt;
          text-indent: -35.45pt;
        "
      >
        <span>12.6.1<span>&nbsp;&nbsp; </span></span
        ><span
          >Content must not be illegal or unlawful, must not infringe any
          person's legal rights, and must not be capable of giving rise to legal
          action against any person (in each case in any jurisdiction and under
          any applicable law).
        </span>
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 92.15pt;
          text-indent: -35.45pt;
        "
      >
        <span>12.6.2<span>&nbsp;&nbsp; </span></span
        ><span>Content must not:</span>
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 120.5pt;
          text-indent: -28.35pt;
        "
      >
        <span
          >a.<span
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span
        ><span>Be libelous or maliciously false.</span>
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 120.5pt;
          text-indent: -28.35pt;
        "
      >
        <span
          >b.<span
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span
        ><span>Be obscene or indecent.</span>
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 120.5pt;
          text-indent: -28.35pt;
        "
      >
        <span
          >c.<span
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span
        ><span
          >Infringe any copyright, moral right, database right, trademark right,
          design right, right in passing off, or other intellectual property
          right.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 120.5pt;
          text-indent: -28.35pt;
        "
      >
        <span
          >d.<span
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span
        ><span
          >Constitute negligent advice or contain any negligent statement.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 120.5pt;
          text-indent: -28.35pt;
        "
      >
        <span
          >e.<span
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span
        ><span
          >Constitute an incitement to commit a crime, instructions for the
          commission of a crime or the promotion of criminal activity.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 120.5pt;
          text-indent: -28.35pt;
        "
      >
        <span
          >f.<span
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span
        ><span
          >Be in contempt of any court, or in breach of any court order.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 120.5pt;
          text-indent: -28.35pt;
        "
      >
        <span
          >g.<span
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span
        ><span
          >Constitute a breach of racial or religious hatred or discrimination
          legislation.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 120.5pt;
          text-indent: -28.35pt;
        "
      >
        <span
          >h.<span
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span
        ><span>Constitute a breach of official secrets legislation. </span>
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 120.5pt;
          text-indent: -28.35pt;
        "
      >
        <span
          >i.<span
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span
        ><span
          >Constitute a breach of any contractual obligation owed to any person;
          or</span
        >
      </p>

      <p
        class="MsoNormal"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 92.15pt;
          text-indent: -35.45pt;
        "
      >
        <span
          >12.6.3  You must ensure that Content is not and has never been the
          subject of any threatened or actual legal proceedings or other similar
          complaint.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-indent: -28.35pt;
        "
      >
        <span>12.7<span>&nbsp;&nbsp; </span></span
        ><b style="font-weight: bold; color: black"
          ><span>Graphic Material</span></b
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 92.15pt;
          text-indent: -35.45pt;
        "
      >
        <span>12.7.1<span>&nbsp;&nbsp;&nbsp;&nbsp; </span></span
        ><span
          >Content must be appropriate for all persons who have access to or are
          likely to access Your App.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 92.15pt;
          text-indent: -35.45pt;
        "
      >
        <span>12.7.2<span>&nbsp;&nbsp;&nbsp;&nbsp; </span></span
        ><span
          >Content must not depict violence in an explicit, graphic, or
          gratuitous manner.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 92.15pt;
          text-indent: -35.45pt;
        "
      >
        <span>12.7.3<span>&nbsp;&nbsp;&nbsp;&nbsp; </span></span
        ><span>Content must not be pornographic or sexually explicit.</span>
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-indent: -28.35pt;
        "
      >
        <span>12.8<span>&nbsp;&nbsp; </span></span
        ><b style="font-weight: bold; color: black"
          ><span>Factual Accuracy</span></b
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 92.15pt;
          text-indent: -35.45pt;
        "
      >
        <span>12.8.1<span>&nbsp;&nbsp; </span></span
        ><span
          >Content must not be untrue, false, inaccurate, or misleading.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 92.15pt;
          text-indent: -35.45pt;
        "
      >
        <span>12.8.2<span>&nbsp;&nbsp; </span></span
        ><span
          >Statements of fact contained in Content and relating to persons
          (legal or natural) must be true; and statements of opinion contained
          in Content and relating to persons (legal or natural) must be
          reasonable, must be honestly held and must indicate the basis of the
          opinion.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-indent: -28.35pt;
        "
      >
        <span>12.9<span>&nbsp;&nbsp; </span></span
        ><b style="font-weight: bold; color: black"
          ><span>Negligent Advice</span></b
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 92.15pt;
          text-indent: -35.45pt;
        "
      >
        <span>12.9.1<span>&nbsp;&nbsp; </span></span
        ><span
          >Unless specifically allowable in Territory, Content must not consist
          of or contain any legal, financial, investment, taxation, accountancy,
          medical or other professional advice, and, unless authorized by Us in
          writing, You must not use the Services to provide any professional
          advisory services.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 92.15pt;
          text-indent: -35.45pt;
        "
      >
        <span>12.9.2<span>&nbsp;&nbsp; </span></span
        ><span
          >Content must not consist of or contain any advice, instructions or
          other information that may be acted upon and could, if acted upon,
          cause death, illness or personal injury, damage to property, or any
          other loss or damage.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-indent: -28.35pt;
        "
      >
        <span>12.10<span>&nbsp;&nbsp; </span></span
        ><b style="font-weight: bold; color: black"><span>Etiquette</span></b>
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 92.15pt;
          text-indent: -35.45pt;
        "
      >
        <span>12.10.1<span>&nbsp;&nbsp; </span></span
        ><span
          >Content must not be offensive, deceptive, threatening, abusive,
          harassing, menacing, hateful, discriminatory, or inflammatory.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 92.15pt;
          text-indent: -35.45pt;
        "
      >
        <span>12.10.2<span>&nbsp;&nbsp; </span></span
        ><span
          >You must ensure that Content does not duplicate other content
          available through the Services unless you have permission to do
          so.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-indent: -28.35pt;
        "
      >
        <span>12.11<span>&nbsp;&nbsp; </span></span
        ><b style="font-weight: bold; color: black"
          ><span>Marketing and Spam</span></b
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 103.46pt;
          text-indent: -0.6in;
        "
      >
        <span>12.11.1<span>&nbsp;&nbsp; </span></span
        ><span
          >You must not, without our written permission, use the Platform Services
          for any purpose relating to the marketing, advertising, promotion,
          sale or supply of any product, service, or commercial offering, which
          are not in accordance with the intended use as described in the
          Schedule.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 103.46pt;
          text-indent: -0.6in;
        "
      >
        <span>12.11.2<span>&nbsp;&nbsp; </span></span
        ><span
          >Content must not constitute or contain spam, and you must not use the
          Platform Services to store or transmit spam - which for these purposes
          shall include all unlawful marketing communications and unsolicited
          commercial communications.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 103.46pt;
          text-indent: -0.6in;
        "
      >
        <span>12.11.3<span>&nbsp;&nbsp; </span></span
        ><span
          >You must not use the Platform Services to promote or operate any
          chain letters, Ponzi schemes, pyramid schemes, matrix programs,
          &quot;get rich quick&quot; schemes or similar letters, schemes, or
          programs.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-indent: -28.35pt;
        "
      >
        <span>12.12<span>&nbsp;&nbsp; </span></span
        ><b style="font-weight: bold; color: black"
          ><span>Gambling</span></b
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 103.46pt;
          text-indent: -0.6in;
        "
      >
        <span>12.12.1<span>&nbsp;&nbsp; </span></span
        ><span
          >You must not use the Platform Services for any purpose relating to
          gambling, gaming, betting, lotteries, sweepstakes, prize competitions
          or any gambling-related activity, unless explicitly authorized to do
          so in the Territory, and agreed by Us in writing.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-indent: -28.35pt;
        "
      >
        <span>12.13<span>&nbsp;&nbsp; </span></span
        ><b style="font-weight: bold; color: black"><span>Monitoring</span></b>
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 103.46pt;
          text-indent: -0.6in;
        "
      >
        <span>12.13.1<span>&nbsp;&nbsp; </span></span
        ><span
          >You should be aware that We may actively monitor the Content and the
          use of the Platform Services for purposes of meeting our obligations
          under this Agreement.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 108.55pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-indent: -28.35pt;
        "
      >
        <span>12.14<span>&nbsp;&nbsp; </span></span
        ><b style="font-weight: bold; color: black"><span>Data Mining</span></b>
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 103.46pt;
          text-indent: -0.6in;
        "
      >
        <span>12.14.1<span>&nbsp;&nbsp; </span></span
        ><span
          >You must not conduct any systematic or automated data scraping, data
          mining, data extraction or data harvesting, or other systematic or
          automated data collection activity, by means of or in relation to the
          Platform Services.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 108.55pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-indent: -28.35pt;
        "
      >
        <span>12.15<span>&nbsp;&nbsp; </span></span
        ><b style="font-weight: bold; color: black"><span>Hyperlinks</span></b>
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 103.46pt;
          text-indent: -0.6in;
        "
      >
        <span>12.15.1<span>&nbsp;&nbsp; </span></span
        ><span
          >You must not link to any material using or by means of the Platform
          Services that would, if it were made available through the Platform
          Services, breach the provisions of this Agreement.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-indent: -28.35pt;
        "
      >
        <b class="heading"><span>13.<span>&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b>
        <b class="heading"><span>Compliance and Audit</span></b
        >
      </p>

      <p
        class="MsoNormal"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-align: justify;
          text-indent: -28.35pt;
        "
      >
        <span lang="EN-SG">13.1   </span
        ><span
          >You agree to adhere to all Regulatory Requirements imposed by the
          Regulators from time to time. Your access to the Platform and ongoing
          use of the Platform Services will be subject to the applicable
          Regulatory and Network rules and guidelines.</span
        >
      </p>

      <p
        class="MsoNormal"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-align: justify;
          text-indent: -28.35pt;
        "
      >
        <span lang="EN-SG"
          >13.2 You acknowledge that We retain the right to audit Your
          records, solely in relation to Your Program, and in accordance with
          Regulatory Requirements. You are obligated to fully cooperate with Us
          during such audits.</span
        >
      </p>

      <p
        class="MsoNormal"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-align: justify;
          text-indent: -28.35pt;
        "
      >
        <span lang="EN-SG"
          >13.3   If We determine that a potential security breach, data leak,
          loss, or compromise has occurred on Your systems, website, or Your
          App, which may impact your compliance with this Agreement, You consent
          to allowing a qualified cybersecurity auditor approved by Us, to
          conduct a security audit of Your systems and facilities. You will
          cooperate fully with any requests for information or assistance made
          by Us or the auditor as part of this security audit. The auditor will
          provide a report, if any, to both You and Us. Please note that We may
          be obligated to share the report with the Regulators or Networks. If
          any compromise is confirmed, all costs associated with this activity, and any remediation,
          will be Your responsibility.</span
        >
      </p>

      <p
        class="MsoNormal"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-align: justify;
          text-indent: -28.35pt;
        "
      >
        <b class="heading"
          ><span style="letter-spacing: -0.1pt">14.     Intellectual</span></b
        ><b class="heading"
          ><span style="letter-spacing: 0.6pt">&nbsp; </span></b
        ><b class="heading"
          ><span style="letter-spacing: -0.1pt">Property </span></b
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-indent: -28.35pt;
        "
      >
        <span lang="EN-SG">14.1<span>&nbsp;&nbsp; </span></span
        ><span lang="EN-SG"
          >We hold full ownership of all Intellectual Property related to MMBOS,
          including Services, Documentation, and ongoing Upgrades and new
          features. You do not possess any rights to our patents, copyrights,
          database rights, trade secrets, trade names, trademarks (whether
          registered or unregistered), or any other rights or licenses
          pertaining to the services or the Documentation.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-indent: -28.35pt;
        "
      >
        <span lang="EN-SG">14.2<span>&nbsp;&nbsp; </span></span
        ><span lang="EN-SG"
          >You maintain full ownership of all Intellectual Property that belongs
          to You or was created by You. For avoidance of doubt, Your App and any
          of Your other associated Intellectual Property that is not owned by
          Us, is owned by You.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-indent: -28.35pt;
        "
      >
        <span lang="EN-SG">14.3<span>&nbsp;&nbsp; </span></span
        ><span lang="EN-SG"
          >Nothing within the Agreement shall operate to assign or transfer any
          Intellectual Property rights from Us to You, or from You to Us.</span
        >
      </p>

      <p
        class="MsoBodyText"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-indent: -28.35pt;
        "
      >
        <b class="heading"
          ><span
            >15<span
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span
          ></b
        ><b class="heading"
          ><span
            >Charges<span style="letter-spacing: -0.35pt">,&nbsp;</span
            ><span style="letter-spacing: -0.25pt"> </span>Payments, and
            Taxes</span
          ></b
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-indent: -28.35pt;
        "
      >
        <span lang="EN-SG">15.1<span>&nbsp;&nbsp;&nbsp; </span></span
        ><span lang="EN-SG"
          >We will generate electronic invoices periodically for the
          Charges.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-indent: -28.35pt;
        "
      >
        <span lang="EN-SG">15.2<span>&nbsp;&nbsp; </span></span
        ><span lang="EN-SG"
          >All payments must be made through electronic transfer to the
          designated bank account specified in the invoice within seven (7) days
          of the invoice date (&quot;Due Date&quot;). You are responsible for
          any payment charges, including remittance fees, bank charges, as well
          as fees from intermediary banks and beneficiary banks.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-indent: -28.35pt;
        "
      >
        <span lang="EN-SG">15.3<span>&nbsp;&nbsp; </span></span
        ><span lang="EN-SG"
          >Any payments due to You from Us, where applicable, will be made after
          deducting all Charges owed by You to Us, for the relevant period. We
          retain the right to offset such amounts against the Charges You owe
          Us. If there is any remaining amount due to Us after settling the net
          charges, You are responsible for paying Us according to the Agreement.
          In case of any failure to do so, You authorize us to deduct any
          outstanding balance from Your prefund balance.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-indent: -28.35pt;
        "
      >
        <span lang="EN-SG">15.4<span>&nbsp;&nbsp; </span></span
        ><span lang="EN-SG"
          >If You fail to make payment by the Due Date, You will be liable for
          late payment interest of two (2)% per month, calculated daily from the
          Due Date until the date of payment.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-indent: -28.35pt;
        "
      >
        <span lang="EN-SG">15.5<span>&nbsp;&nbsp; </span></span
        ><span lang="EN-SG"
          >If the amounts owed to Us remain overdue for more than thirty (30) days, We
          may, at our discretion, implement a service attenuation process, or
          degrade the SLA, until the outstanding amount is fully paid.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-indent: -28.35pt;
        "
      >
        <span lang="EN-SG">15.6<span>&nbsp;&nbsp; </span></span
        ><span lang="EN-SG"
          >If any amounts owed to Us by You remain unpaid for sixty (60) days
          from the Due Date, We reserve the right to terminate this Agreement
          and pursue legal and commercial remedies for complete
          restitution.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-indent: -28.35pt;
        "
      >
        <span lang="EN-SG">15.7<span>&nbsp;&nbsp; </span></span
        ><span lang="EN-SG"
          >All payable sums must be paid in full, without any deductions or
          withholdings, except when required by law.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-indent: -28.35pt;
        "
      >
        <span lang="EN-SG">15.8<span>&nbsp;&nbsp; </span></span
        ><span lang="EN-SG"
          >If any taxes are applicable, the Charges will be adjusted to include
          the equivalent amount of the applicable taxes.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-indent: -28.35pt;
        "
      >
        <span lang="EN-SG">15.9<span>&nbsp;&nbsp; </span></span
        ><span lang="EN-SG"
          >You acknowledge that Our ability to provide our Services relies on
          our proprietary Platform, which incorporates additional and
          complex services from external systems and providers. If these
          external parties adjust their costs to Us, We may review and adjust
          our Charges accordingly. We will provide you with a thirty (30) days'
          notice of any changes to our Charges. Your continued use of the
          Platform after the thirty (30) days' notice constitutes Your
          acceptance of the revised Charges.</span
        >
      </p>

      <p
        class="MsoNormal"
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-indent: -28.35pt;
        "
      >
        <b class="heading"
          ><span>16.       Confidentiality Obligations</span></b
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-indent: -28.35pt;
        "
      >
        <span lang="EN-SG" style="letter-spacing: -0.1pt"
          >16.1<span>&nbsp;&nbsp;&nbsp;&nbsp; </span></span
        ><span lang="EN-SG" style="letter-spacing: -0.1pt"
          >Both Parties, You and We, mutually agree to the following:
        </span>
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 92.7pt;
          text-indent: -0.5in;
        "
      >
        <span lang="EN-SG" style="letter-spacing: -0.1pt"
          >16.1.1<span>&nbsp;&nbsp;&nbsp; </span></span
        ><span lang="EN-SG" style="letter-spacing: -0.1pt"
          >Maintain strict confidentiality of each other's Confidential
          Information, including access granted to the development portal and
          any associated contents or platforms.
        </span>
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 92.7pt;
          text-indent: -0.5in;
        "
      >
        <span lang="EN-SG" style="letter-spacing: -0.1pt"
          >16.1.2<span>&nbsp;&nbsp;&nbsp; </span></span
        ><span lang="EN-SG" style="letter-spacing: -0.1pt"
          >Refrain from disclosing the Confidential Information of either Party
          to any third party without obtaining prior written consent from the
          other Party.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-indent: -28.35pt;
        "
      >
        <span lang="EN-SG" style="letter-spacing: -0.1pt"
          >16.2<span>&nbsp;&nbsp;&nbsp;&nbsp; </span></span
        ><span lang="EN-SG" style="letter-spacing: -0.1pt"
          >This does not impose obligations upon a Party regarding the other
          Party's Confidential Information if:
        </span>
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 92.7pt;
          text-indent: -0.5in;
        "
      >
        <span lang="EN-SG" style="letter-spacing: -0.1pt"
          >16.2.1<span>&nbsp;&nbsp; </span></span
        ><span lang="EN-SG" style="letter-spacing: -0.1pt"
          >The Party already possesses the Confidential Information prior to its
          disclosure under this Agreement and is not bound by any other
          confidentiality obligations; or
        </span>
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 92.7pt;
          text-indent: -0.5in;
        "
      >
        <span lang="EN-SG" style="letter-spacing: -0.1pt"
          >16.2.2<span>&nbsp;&nbsp; </span></span
        ><span lang="EN-SG" style="letter-spacing: -0.1pt"
          >The Confidential Information becomes publicly known without any
          involvement or fault of the Party.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-indent: -28.35pt;
        "
      >
        <span lang="EN-SG" style="letter-spacing: -0.1pt"
          >16.3<span>&nbsp;&nbsp; </span></span
        ><span lang="EN-SG" style="letter-spacing: -0.1pt"
          >The restrictions do not apply if the disclosure of any Party's
          Confidential Information is required in compliance with applicable
          laws or regulations, a judicial or governmental order or request, or
          disclosure requirements related to the listing of a Party's stock on a
          recognized stock exchange.</span
        >
      </p>

      <p
        class="MsoNormal"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-indent: -28.35pt;
        "
      >
        <b class="heading">
          <span style="letter-spacing: -0.1pt">17.       Publicity </span>
        </b>
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-indent: -28.35pt;
        "
      >
        <span lang="EN-SG" style="letter-spacing: -0.1pt"
          >17.1<span>&nbsp;&nbsp;&nbsp; </span></span
        ><span lang="EN-SG" style="letter-spacing: -0.1pt"
          >Both Parties, You and We, mutually agree that neither party will make
          any disclosures pertaining to these Terms and Conditions and the
          Agreement, including disclosures in press releases, public
          announcements, and marketing materials, without obtaining the prior
          written consent of the other Party. However, such consent will not be
          unreasonably withheld.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-indent: -28.35pt;
        "
      >
        <span lang="EN-SG" style="letter-spacing: -0.1pt"
          >17.2<span>&nbsp;&nbsp;&nbsp; </span></span
        ><span lang="EN-SG" style="letter-spacing: -0.1pt"
          >Both Parties, You and We, mutually agree not to make any disparaging
          or harmful statements about the other Party, or utilize abusive or
          defamatory language towards any personnel of the other Party, whether
          in public or private settings, including on social media.</span
        >
      </p>

      <p
        class="MsoNormal"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-indent: -28.35pt;
        "
      >
        <b class="heading"><span style="letter-spacing: -0.1pt"
            >18.       Representations and Warranties</span
          ></b
        >
      </p>

      <p
        class="MsoNormal"
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-indent: -28.35pt;
        "
      >
        <span style="letter-spacing: -0.1pt"
          >18.1     Both Parties, You and Us, collectively affirm that we:
        </span>
      </p>

      <p
        class="MsoNormal"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 92.15pt;
          text-align: justify;
          text-indent: -35.45pt;
        "
      >
        <span style="letter-spacing: -0.1pt"
          >18.1.1 &nbsp;&nbsp; Will adhere to all applicable legal and regulatory
          requirements, regulations, notices, and guidelines issued by the
          relevant government and regulatory bodies, as well as the relevant
          licensed financial institutions in the country where the Platform
          Services are being provided. This includes compliance with laws
          regarding the Prevention of Money Laundering and Countering the
          Financing of Terrorism, as well as KYC (Know Your Customer)
          regulations.
        </span>
      </p>

      <p
        class="MsoNormal"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 92.15pt;
          text-align: justify;
          text-indent: -35.45pt;
        "
      >
        <span style="letter-spacing: -0.1pt"
          >18.1.2 &nbsp; Possess the requisite knowledge, expertise, experience,
          and resources to fulfill our respective obligations under this
          Agreement.</span
        >
      </p>

      <p
        class="MsoNormal"
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-indent: -28.35pt;
        "
      >
        <b class="heading"
          ><span>19     Your Responsibility </span></b
        >
      </p>

      <p
        class="MsoBodyText"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-align: justify;
          text-indent: -28.35pt;
        "
      >
        <span
          >19.1  You shall be responsible, with no liability to Us, if:</span
        >
      </p>

      <p
        class="MsoBodyText"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 92.15pt;
          text-align: justify;
          text-indent: -35.45pt;
        "
      >
        <span
          >19.1.1  Your access or use of the Services or Platform is
          inconsistent with the Documentation.</span
        >
      </p>

      <p
        class="MsoBodyText"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 92.15pt;
          text-align: justify;
          text-indent: -35.45pt;
        "
      >
        <span
          >19.1.2  There is any unauthorized access of servers, infrastructure,
          or Data used in connection with the Services or Platform.</span
        >
      </p>

      <p
        class="MsoBodyText"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 92.15pt;
          text-align: justify;
          text-indent: -35.45pt;
        "
      >
        <span
          >19.1.3  There are interruptions to or cessation of Services due to
          Your actions or circumstances attributed to You.</span
        >
      </p>

      <p
        class="MsoBodyText"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 92.15pt;
          text-align: justify;
          text-indent: -35.45pt;
        "
      >
        <span
          >19.1.4  There are any errors, inaccuracies, omissions, or losses in
          or to any Data provided to Us; or from Content provided by You.</span
        >
      </p>

      <p
        class="MsoBodyText"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-align: justify;
          text-indent: -28.35pt;
        "
      >
        <b class="heading"
          ><span
            >20.    Errors, Reconciliation and Settlements of final
            accounts</span
          ></b
        ><span> </span>
      </p>

      <p
        class="MsoBodyText"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-align: justify;
          text-indent: -28.35pt;
        "
      >
        <span
          >20.1 You should be aware that Your ability to recover funds that
          You may have lost due to an error may be very limited or even
          impossible, particularly if such error is not caused by Us or if the
          funds are no longer available.</span
        >
      </p>

      <p
        class="MsoBodyText"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-align: justify;
          text-indent: -28.35pt;
        "
      >
        <span
          >20.2  For any transaction errors, We will work with You and the
          Networks and relevant service providers to rectify such transaction
          error in accordance with the applicable Regulations and Network Rules.
          If you do not promptly communicate any transaction error to Us for our
          review, and in any case, within fifteen (15) days after the occurrence
          of such error, You will waive your right to make any claim against Us
          for any amounts related to the error.</span
        >
      </p>

      <p
        class="MsoBodyText"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-align: justify;
          text-indent: -28.35pt;
        "
      >
        <span lang="EN-SG">20.3  </span
        ><span
          >If this Agreement or Your Program comes to an end, You are responsible for any remaining settlement and chargeback costs imposed by Our banking partners or the Network, that relate to Your Program. It is  Your  obligation  to  prepare  and  provide  any  required  documentation  for  the  proper  closure  of  the Program, as reasonably requested by Us. This is necessary to fulfill any chargeback and settlement risk liabilities, if any, owed to Our banking partners and the Network. In this regard, We reserve the right to withhold or offset any final payments or other obligations due to You under this Agreement.</span
        >
      </p>

      <p
        class="MsoBodyText"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-align: justify;
          text-indent: -28.35pt;
        "
      >
        <span lang="EN-SG"
          >20.4  You should note that, except for the Charges paid to the
          Provider under this Agreement, all other funds used for the Program,
          whether located in the Pool Account, any escrow account, or various
          User accounts, are under the supervision and control of the issuing
          banking partner, and according to conditions set by the Regulator. If
          this Agreement or Your Program concludes, Our responsibility regarding
          these funds is strictly limited to offering reasonable support in
          assisting You in retrieving the funds; and these funds will be
          returned either to You or directly to the end User, as required by the
          local laws of the Territory.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-indent: -28.35pt;
        "
      >
        <b class="heading"
          ><span>21.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b
        ><b class="heading"
          ><span
            >Acknowledgements <span style="letter-spacing: -0.45pt"> </span
            > and<span style="letter-spacing: -0.45pt"> </span> Warranty<span
              style="letter-spacing: -0.45pt"
            >
            </span
            ><span style="letter-spacing: -0.1pt"> Limitations</span></span
          ></b
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-indent: -28.35pt;
        "
      >
        <span>21.1<span>&nbsp;&nbsp; </span></span
        ><span
          >You are aware that complex<span style="letter-spacing: -0.5pt">
          </span
          > software<span style="letter-spacing: -0.6pt"> </span> is<span
            style="letter-spacing: -0.45pt"
          >
          </span
          > never<span style="letter-spacing: -0.5pt"> </span> wholly<span
            style="letter-spacing: -0.5pt"
          >
          </span
          > free<span style="letter-spacing: -0.5pt"> </span> from<span
            style="letter-spacing: -0.55pt"
          >
          </span
          > defects,<span style="letter-spacing: -0.5pt"> </span> errors,<span
            style="letter-spacing: -0.5pt"
          >
          </span
          > and bugs; and We give no warranty or representation that the Platform
          Services will always be wholly free from defects, errors, and
          bugs.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-indent: -28.35pt;
        "
      >
        <span>21.2<span>&nbsp;&nbsp; </span></span
        ><span
          >You are aware that complex software is never entirely free from
          security vulnerabilities; <span style="letter-spacing: -0.1pt"> </span
          > and<span style="letter-spacing: -0.1pt"> </span> We<span
            style="letter-spacing: -0.1pt"
          >
          </span
          > give<span style="letter-spacing: -0.1pt"> </span> no<span
            style="letter-spacing: -0.1pt"
          >
          </span
          > warranty<span style="letter-spacing: -0.1pt"> </span> or<span
            style="letter-spacing: -0.1pt"
          >
          </span
          > representation<span style="letter-spacing: -0.1pt"> </span> that<span
            style="letter-spacing: -0.1pt"
          >
          </span
          > the<span style="letter-spacing: -0.1pt"> </span> Platform<span
            style="letter-spacing: -0.1pt"
          >
          </span
          > Services will always be entirely secure.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-indent: -28.35pt;
        "
      >
        <b class="heading"
          ><span>22.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b
        ><b class="heading"
          ><span style="letter-spacing: -0.1pt">Regulatory Notice on Safeguarding of Funds</span></b
        >
      </p>

      <p
        class="MsoNormal"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-align: justify;
          text-indent: -28.35pt;
        "
      >
        <span style="letter-spacing: -0.1pt"
          >22.1 &nbsp;&nbsp;Your funds received by Us will be safeguarded as relevant money (“relevant money” as defined in Section 23 of the Payment Services Act 2019). All relevant money will be held by the Us on Your behalf in a separate safeguarding account held with a safeguarding institution in Singapore;.
        </span>
      </p>

      <p
        class="MsoNormal"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-align: justify;
          text-indent: -28.35pt;
        "
      >
        <span style="letter-spacing: -0.1pt"
          >22.2 &nbsp;&nbsp;You acknowledge that in the unlikely event of insolvency of the safeguarding institution, there is a risk You may not be able to fully recover Your relevant money.
        </span>
      </p>
      
      <p
        class="MsoNormal"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-align: justify;
          text-indent: -28.35pt;
        "
      >
        <span style="letter-spacing: -0.1pt"
          >22.3 &nbsp;&nbsp;Notice for non-Singapore residents: MatchMove Pay Pte Ltd is licensed by the Monetary Authority of Singapore to provide e-money issuance services. We are required to inform You that this does not mean You will be able to recover all the money You paid to MatchMove Pay Pte Ltd if the business fails.
        </span>
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-indent: -28.35pt;
        "
      >
        <b class="heading"
          ><span>23.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b
        ><b class="heading"
          ><span style="letter-spacing: -0.1pt">Indemnities</span></b
        >
      </p>

      <p
        class="MsoNormal"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-align: justify;
          text-indent: -28.35pt;
        "
      >
        <span style="letter-spacing: -0.1pt"
          >23.1 &nbsp;&nbsp;Both Parties, You and We, shall indemnify and hold each
          other harmless from and against all liabilities, damages, losses,
          costs, and expenses (including legal expenses and amounts reasonably
          paid to settle legal claims) incurred by the other Party directly
          because of any breach by a Party of their obligations,
          representations, and warranties under this Agreement (&quot;Indemnity
          Event&quot;).
        </span>
      </p>

      <p
        class="MsoNormal"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-align: justify;
        "
      >
        <span style="letter-spacing: -0.1pt">You and We, both, are required to:</span>
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 93.35pt;
          text-indent: -0.5in;
        "
      >
        <span>23.1.1<span>&nbsp;&nbsp;&nbsp;&nbsp; </span></span
        ><span
          >Upon becoming aware of an actual or potential Indemnity Event, notify
          the other Party.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 93.35pt;
          text-indent: -0.5in;
        "
      >
        <span>23.1.2<span>&nbsp;&nbsp;&nbsp;&nbsp; </span></span
        ><span
          >Provide the other Party with all such assistance as may be reasonably
          requested by the other party in relation to the Indemnity Event.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 93.35pt;
          text-indent: -0.5in;
        "
      >
        <span>23.1.3<span>&nbsp;&nbsp;&nbsp;&nbsp; </span></span
        ><span
          >Not admit liability to any third party in connection with the
          Indemnity Event or settle any disputes or proceedings involving a
          third Party and relating to the Indemnity Event without the prior
          written consent of the other Party.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 93.35pt;
          text-indent: -0.5in;
        "
      >
        <span>23.1.4<span>&nbsp;&nbsp;&nbsp;&nbsp; </span></span
        ><span
          >This<span style="letter-spacing: -0.45pt"> </span> Clause<span
            style="letter-spacing: -0.3pt"
          >
          </span
          > shall<span style="letter-spacing: -0.3pt"> </span> survive<span
            style="letter-spacing: -0.3pt"
          >
          </span
          > the<span style="letter-spacing: -0.3pt"> </span
          > termination/expiry<span style="letter-spacing: -0.3pt"> </span
          > of<span style="letter-spacing: -0.3pt"> </span> this<span
            style="letter-spacing: -0.3pt"
          >
          </span
          ><span style="letter-spacing: -0.1pt"> Agreement.</span></span
        >
      </p>

      <p
        class="MsoNormal"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-indent: -28.35pt;
        "
      >
        <b class="heading">
          <span>24.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span
          ><span
            >Limitations <span style="letter-spacing: -0.35pt"> </span> and<span
              style="letter-spacing: -0.3pt"
            >
            </span
            > Exclusions<span style="letter-spacing: -0.35pt"> </span> of<span
              style="letter-spacing: -0.3pt"
            >
            </span
            ><span style="letter-spacing: -0.1pt"> Liability</span></span
          >
        </b>
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-indent: -28.35pt;
        "
      >
        <span>24.1<span>&nbsp;&nbsp; </span></span
        ><span
          >YOU AND WE, THE PARTIES, ACKNOWLEDGE AND AGREE THAT THE COMMERCIAL
          PERFORMANCE OF THE PROPOSED PRODUCTS USING MMBOS DEPENDS ON NUMEROUS
          FACTORS BEYOND THE PARTIES’ CONTROL. THE PLATFORM SERVICES AND THE
          DOCUMENTATION ARE PROVIDED BY US ON AN &quot;AS IS&quot; AND
          “AS-AVAILABLE” BASIS. PARTIES DO NOT MAKE ANY, AND EXPRESSLY DISCLAIM,
          ALL REPRESENTATIONS AND WARRANTIES AS TO THE PROFITS, REVENUES,
          ROYALTIES, FITNESS FOR A PARTICULAR PURPOSE, MECHANTABILITY, OR
          POTENTIAL SUCCESS OF THE PLATFORM AND NO LIABILITY SHALL BE IMPOSED
          UPON THE OTHER PARTY BASED ON ANY CLAIM THAT (i) MORE SALES OR REVEVUE
          COULD HAVE BEEN MADE OR EARNED AND/OR (ii) BETTER PRICES, RATES,
          COMMISSIONS OR TRANSACTIONS COULD HAVE BEEN OBTAINED.
        </span>
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-indent: -28.35pt;
        "
      >
        <span>24.2<span>&nbsp;&nbsp; </span></span
        ><span
          >We  shall  not  be  held  responsible  for  any  damages,  harm,  or  losses  resulting  from  or  connected  to unauthorized  access,  hacking,  tampering,  or  misuse  of  the  Platform  or  Services  by  Your  Users.  This includes any incidents related to Your Account, Your Data, or any failure on Your part to employ anti-fraud measures, appropriate security controls, or other data security measures.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-indent: -28.35pt;
        "
      >
        <span>24.3<span>&nbsp;&nbsp; </span></span
        ><span
          >You and We, the Parties, acknowledge and agree that these limitations are crucial to this Agreement. Without these limitations, the financial terms of this Agreement would be significantly altered. However, Parties mutually agree that claims arising from a deliberate and intentional breach of this Agreement by either Party shall remain valid.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-indent: -28.35pt;
        "
      >
        <span>24.4<span>&nbsp;&nbsp; </span></span
        ><span>Nothing in this Agreement will:</span>
      </p>

      <p
        class="MsoNormal"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 85.05pt;
          text-indent: -28.35pt;
        "
      >
        <span
          >24.4.1 limit or exclude any liability for death or personal injury
          resulting from negligence.</span
        >
      </p>

      <p
        class="MsoNormal"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 85.05pt;
          text-indent: -28.35pt;
        "
      >
        <span
          >24.4.2 limit or exclude any liability for fraud or fraudulent
          misrepresentation; or</span
        >
      </p>

      <p
        class="MsoNormal"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 85.05pt;
          text-indent: -28.35pt;
        "
      >
        <span
          >24.4.3 exclude any liabilities that may not be excluded under
          applicable law.</span
        >
      </p>

      <p
        class="MsoNormal"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-align: justify;
          text-indent: -28.35pt;
        "
      >
        <span
          >24.5 &nbsp;&nbsp; Except as expressly and specifically provided in this
          Agreement, You are solely responsible for results obtained from the
          use of the Services and the Documentation by You, and for conclusions
          drawn from such use. We shall have no liability for any damage caused
          by errors or omissions in any information, instructions or scripts
          provided to Us by You, in connection with the Services, or any actions
          taken by Us at Your direction.</span
        >
      </p>

      <p
        class="MsoNormal"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-indent: -28.35pt;
        "
      >
        <span>24.6 </span
        ><span lang="EN-SG"
          > &nbsp;&nbsp; The total cumulative liability of each Party to the other Party,
          whether arising from this Agreement, tort (including negligence or
          breach of statutory duty), misrepresentation, restitution, or any
          other legal grounds, in connection with the performance or anticipated
          performance of this Agreement, shall be limited to the total amount
          paid by, and owed, from You to Us under the Agreement in the preceding
          twelve (12) month period.</span
        >
      </p>

      <p
        class="MsoNormal"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-align: justify;
          text-indent: -28.35pt;
        "
      >
        <span lang="EN-SG"
          >24.7     Nothing in this Agreement shall exclude Your liability for
          any breach, infringement, or misappropriation of Our Intellectual
          Property Rights.</span
        >
      </p>

      <p
        class="MsoBodyText"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 57.35pt;
          text-align: justify;
          text-indent: -29pt;
        "
      >
        <b class="heading"
          ><span style="letter-spacing: -0.1pt">25.       Assignment</span></b
        >
      </p>

      <p
        class="MsoBodyText"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-align: justify;
          text-indent: -28.35pt;
        "
      >
        <span
          >25.1 &nbsp;&nbsp;<span style="letter-spacing: 2pt"></span>You and We, will not
          assign, transfer, or otherwise deal with the contractual rights and/or
          obligations without the prior written consent of other, such consent
          not to be unreasonably withheld or delayed.</span
        >
      </p>

      <p
        class="MsoNormal"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-indent: -28.35pt;
        "
      >
        <b class="heading">
          <span>26.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span
          ><span
            >Force <span style="letter-spacing: -0.35pt"> </span
            ><span style="letter-spacing: -0.1pt"> Majeure</span></span
          >
        </b>
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-indent: -28.35pt;
        "
      >
        <span>26.1<span>&nbsp;&nbsp; </span></span
        ><span
          >Neither You nor We will have any liability under this Agreement, if
          either Party is prevented<span style="letter-spacing: -0.5pt"> </span
          > from<span style="letter-spacing: -0.5pt"> </span> or<span
            style="letter-spacing: -0.5pt"
          >
          </span
          > delayed<span style="letter-spacing: -0.5pt"> </span> in<span
            style="letter-spacing: -0.5pt"
          >
          </span
          > performing<span style="letter-spacing: -0.5pt"> </span> the
          obligations<span style="letter-spacing: -0.5pt"> </span> under<span
            style="letter-spacing: -0.5pt"
          >
          </span
          > this<span style="letter-spacing: -0.5pt"> </span> Agreement,<span
            style="letter-spacing: -0.5pt"
          >
          </span
          > or<span style="letter-spacing: -0.5pt"> </span> from carrying on its
          business, by acts, events, omissions or accidents beyond its
          reasonable control, including, without limitation, strikes, lock-outs
          or other industrial disputes (whether or not involving the workforce
          any Party), failure of a utility service or transport or
          telecommunications network, act of God, war, riot, civil<span
            style="letter-spacing: -0.25pt"
          >
          </span
          > commotion,<span style="letter-spacing: -0.25pt"> </span
          > malicious<span style="letter-spacing: -0.3pt"> </span> damage,<span
            style="letter-spacing: -0.25pt"
          >
          </span
          > compliance<span style="letter-spacing: -0.3pt"> </span> with<span
            style="letter-spacing: -0.3pt"
          >
          </span
          > any<span style="letter-spacing: -0.3pt"> </span> law<span
            style="letter-spacing: -0.3pt"
          >
          </span
          > or<span style="letter-spacing: -0.25pt"> </span> governmental<span
            style="letter-spacing: -0.25pt"
          >
          </span
          > order, rule, regulation or direction, accident, breakdown of plant or
          machinery, pandemic, epidemic,<span style="letter-spacing: -0.55pt">
          </span
          > fire,<span style="letter-spacing: -0.55pt"> </span> flood,<span
            style="letter-spacing: -0.55pt"
          >
          </span
          > storm<span style="letter-spacing: -0.55pt"> </span> or<span
            style="letter-spacing: -0.55pt"
          >
          </span
          > default<span style="letter-spacing: -0.55pt"> </span> of<span
            style="letter-spacing: -0.5pt"
          >
          </span
          > suppliers<span style="letter-spacing: -0.55pt"> </span> or<span
            style="letter-spacing: -0.55pt"
          >
          </span
          > sub-contractors<span style="letter-spacing: -0.55pt"> </span> (“<b
            style="font-weight: bold; color: black"
            >Force<span style="letter-spacing: -0.55pt"> </span> Majeure Event</b
          >”), provided that the other Party is promptly notified of such an
          event.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-indent: -28.35pt;
        "
      >
        <span>26.2<span>&nbsp;&nbsp; </span></span
        ><span
          >Notwithstanding the foregoing, should a Force Majeure Event, suffered
          by You or Us, extend<span style="letter-spacing: -0.45pt"> </span
          > beyond<span style="letter-spacing: -0.45pt"> a </span> three<span
            style="letter-spacing: -0.45pt"
          >&nbsp;
          </span
          >(3)<span style="letter-spacing: -0.45pt"> </span> months<span
            style="letter-spacing: -0.45pt"
          >
          </span
          > period,<span style="letter-spacing: -0.45pt"> </span> either
          Party<span style="letter-spacing: -0.45pt"> </span> may<span
            style="letter-spacing: -0.45pt"
          >
          </span
          > then<span style="letter-spacing: -0.45pt"> </span> terminate<span
            style="letter-spacing: -0.45pt"
          >
          </span
          > the Agreement, by giving written notice of 3 (three) days.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-indent: -28.35pt;
        "
      >
        <b class="heading"
          ><span>27.       No Waivers</span></b
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-indent: -28.35pt;
        "
      >
        <span
          >27.1     No breach of any provision of the Agreement will be waived
          except with the express written consent of the Party not in
          breach.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-indent: -28.35pt;
        "
      >
        <span
          >27.2     Any waiver of any breach of any provision of the Agreement
          shall not be construed as a further or continuing waiver of any other
          breach.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-indent: -28.35pt;
        "
      >
        <b class="heading"
          ><span>28.       Severability and Survival</span></b
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-indent: -28.35pt;
        "
      >
        <span
          >28.1 &nbsp;&nbsp; If a provision of the Agreement is determined by any court
          or other competent authority to be unlawful and/or unenforceable, the
          other provisions will continue to remain in effect.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-indent: -28.35pt;
        "
      >
        <span
          >28.2 &nbsp; Upon the termination or expiry of this Agreement, and
          regardless of the cause of the termination of the MMBOS Agreement, the
          general provisions of this Agreement shall cease to have effect on the
          Parties, except the following shall survive and continue to have
          effect:
        </span>
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-indent: 0in;
        "
      >
        <span>MMBOS Agreement: All Clauses </span>
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-indent: 0in;
        "
      >
        <span
          >MMBOS Standard T&amp;C: Clauses 9, 10, 12, 13, 14, 15, 17, 18,
          19, 20, 21, 22, 23, 25, 26, 27, 28, 29, 30, and 31.</span
        >
      </p>

      <p
        class="MsoNormal"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-indent: -28.35pt;
        "
      >
        <b class="heading"
          ><span>29.       Third Party Rights and No Agency</span></b
        >
      </p>

      <p
        class="MsoNormal"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-indent: -28.35pt;
        "
      >
        <span
          >29.1     The Agreement is for the benefit of You and Us and is not intended to benefit or be enforceable by any third party.</span
        >
      </p>
      
      <p
        class="MsoNormal"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-indent: -28.35pt;
        "
      >
        <span
          >29.2     This  Agreement  does  not  create  a  partnership,  joint  venture,  or  agency  relationship  between  You and Us. Neither You nor We have the authority to obligate or enter contracts on behalf of the other.</span
        >
      </p>

      <p
        class="MsoNormal"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-indent: -28.35pt;
        "
      >
        <b class="heading"
          ><span>30.       Updates to Terms and Conditions</span></b
        >
      </p>

      <p
        class="MsoNormal"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-align: justify;
          text-indent: -28.35pt;
        "
      >
        <span
          >30.1 &nbsp; We  retain  the  right  to  modify  these  Terms  and  Conditions  to  align  with  evolving  factors,  including Regulations, Network rules, Platform enhancements, cybersecurity measures, or the introduction of new Platform  features  or  Services.  We  recommend  visiting  this  site  regularly  to  stay  informed  about  any updates. By continuing to use the Platform Services for more than thirty (30) days after any updates to these T&C, You are indicating Your acceptance of the revised Terms and Conditions.</span
        >
      </p>

      <p
        class="MsoBodyText"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-align: justify;
          text-indent: -28.35pt;
        "
      >
        <b class="heading"
          ><span
            >31<span
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span
          ></b
        ><b class="heading"
          ><span
            >Law <span style="letter-spacing: -0.25pt"> </span> and<span
              style="letter-spacing: -0.2pt"
            >
            </span
            > Dispute<span style="letter-spacing: -0.2pt"> </span
            ><span style="letter-spacing: -0.1pt"> Resolution</span></span
          ></b
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-indent: -28.35pt;
        "
      >
        <span>31.1<span>&nbsp;&nbsp;&nbsp;&nbsp; </span></span
        ><span
          >If <span style="letter-spacing: -0.45pt"> </span> any<span
            style="letter-spacing: -0.45pt"
          >
          </span
          > part<span style="letter-spacing: -0.45pt"> &nbsp;</span>of<span
            style="letter-spacing: -0.45pt"
          >
          </span
          > the Agreement is<span style="letter-spacing: -0.45pt"> </span
          > not<span style="letter-spacing: -0.45pt"> </span> enforceable<span
            style="letter-spacing: -0.45pt"
          >
          </span
          > in<span style="letter-spacing: -0.45pt"> </span> the<span
            style="letter-spacing: -0.45pt"
          >
          </span
          > Territory,<span style="letter-spacing: -0.45pt"> </span> the<span
            style="letter-spacing: -0.45pt"
          >
          </span
          > same shall be deemed to be severed from the Agreement, and the
          remaining Agreement shall exist in full effect.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-indent: -28.35pt;
        "
      >
        <span>31.2<span>&nbsp;&nbsp; </span></span
        ><span
          >In the event of any dispute arising from the Agreement, the Parties
          will make their best efforts to resolve the dispute through
          negotiation. If a resolution cannot be reached through negotiation,
          either Party may refer the dispute to the Singapore International
          Arbitration Centre (SIAC) in Singapore for final settlement. The
          arbitration panel will consist of three (3) arbitrators appointed in
          accordance with the Arbitration Rules of the SIAC. The arbitration
          proceedings will be conducted in Singapore, and the language used in
          the arbitration will be English.</span
        >
      </p>

      <p
        class="MsoNormal"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-indent: -28.35pt;
        "
      >
        <b class="heading"
          ><span>32.       Interpretation</span></b
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-indent: -28.35pt;
        "
      >
        <span>32.1<span>&nbsp;&nbsp;&nbsp;&nbsp; </span></span
        ><span>In this Agreement:</span>
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 92.7pt;
          text-indent: -0.5in;
        "
      >
        <span>32.1.1<span>&nbsp;&nbsp; </span></span
        ><span
          >The headings of each clause are for convenience only and shall not
          affect the construction or interpretation of any of the provisions of
          the Agreement.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 92.7pt;
          text-indent: -0.5in;
        "
      >
        <span>32.1.2<span>&nbsp;&nbsp; </span></span
        ><span
          >No rule of construction applies to the disadvantage of a Party
          because that Party was responsible for the preparation of this
          Agreement.
        </span>
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 92.7pt;
          text-indent: -0.5in;
        "
      >
        <span>32.1.3<span>&nbsp;&nbsp; </span></span
        ><span style="color: black; letter-spacing: 0.15pt; background: white"
          >Unless expressly stated otherwise, the use of the term
          &quot;including&quot;, “particularly” or &quot;such as&quot; and
          similar expressions are not to be interpreted or used as limiting the
          generality of the text and are not to be considered as words of
          limitation.&nbsp;</span
        >
      </p>

      <p
        class="MsoNormal"
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-indent: -28.35pt;
        "
      >
        <b class="heading"
          ><span>33.       Definitions</span></b
        >
      </p>

      <p
        class="MsoNormal"
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
        "
      >
        <span
          >Unless <span style="letter-spacing: -0.4pt"> </span> expressly<span
            style="letter-spacing: -0.35pt"
          >
          </span
          > stated<span style="letter-spacing: -0.4pt"> </span> otherwise<span
            style="letter-spacing: -0.35pt"
            >:</span
          ></span
        >
      </p>

      <p
        class="MsoNormal"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 65.7pt;
          text-indent: -36.35pt;
        "
      >
        <span
          >33.1    &quot;Account&quot; means a verified digital account
          enabling a User to access, and use, the Platform Services and <span
            style="letter-spacing: -0.05pt"
          >
          </span
          > includes<span style="letter-spacing: -0.05pt"> </span> both<span
            style="letter-spacing: -0.05pt"
          >
          </span
          > authorized<span style="letter-spacing: -0.05pt"> </span
          > Administrator<span style="letter-spacing: -0.05pt"> </span
          > Accounts<span style="letter-spacing: -0.05pt"> </span> and<span
            style="letter-spacing: -0.05pt"
          >
            authorized </span
          > User<span style="letter-spacing: -0.05pt"> </span> accounts.</span
        >
      </p>

      <p
        class="MsoNormal"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 65.7pt;
          text-indent: -36.35pt;
        "
      >
        <span
          >33.2    &quot;Affiliate&quot;<span style="letter-spacing: -0.45pt">
          </span
          > means<span style="letter-spacing: -0.5pt"> </span> an<span
            style="letter-spacing: -0.5pt"
          >
          </span
          > entity<span style="letter-spacing: -0.5pt"> </span> that<span
            style="letter-spacing: -0.45pt"
          >
          </span
          > Controls,<span style="letter-spacing: -0.45pt"> </span> is<span
            style="letter-spacing: -0.5pt"
          >
          </span
          > Controlled<span style="letter-spacing: -0.5pt"> </span> by,<span
            style="letter-spacing: -0.45pt"
          >
          </span
          > or<span style="letter-spacing: -0.45pt"> </span> is<span
            style="letter-spacing: -0.5pt"
          >
          </span
          > under<span style="letter-spacing: -0.45pt"> </span> common<span
            style="letter-spacing: -0.5pt"
          >&nbsp;
          </span
          >Control with the relevant entity and with respect to You, and includes
          entities that operate under the same brand as You.</span
        >
      </p>

      <p
        class="MsoNormal"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 65.7pt;
          text-indent: -36.35pt;
        "
      >
        <span
          >33.3    “AML&quot; means anti money laundering and refers to a set
          of procedures, laws or regulations<span
            style="letter-spacing: -0.35pt"
          >
          </span
          > designed<span style="letter-spacing: -0.35pt"> </span> to<span
            style="letter-spacing: -0.35pt"
          >
          </span
          > stop<span style="letter-spacing: -0.35pt"> </span> the<span
            style="letter-spacing: -0.35pt"
          >
          </span
          > practice<span style="letter-spacing: -0.35pt"> </span> of<span
            style="letter-spacing: -0.35pt"
          >
          </span
          > generating<span style="letter-spacing: -0.35pt"> </span> income<span
            style="letter-spacing: -0.35pt"
          >
          </span
          > or<span style="letter-spacing: -0.35pt"> </span> transferring<span
            style="letter-spacing: -0.35pt"
          >
          </span
          > money through illegal means.</span
        >
      </p>

      <p
        class="MsoNormal"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 65.7pt;
          text-indent: -36.35pt;
        "
      >
        <span
          >33.4    “API”<span style="letter-spacing: -0.4pt"> </span> means<span
            style="letter-spacing: -0.45pt"
          >
          </span
          > a<span style="letter-spacing: -0.45pt"> </span> set<span
           style="letter-spacing: -0.4pt"
          >
          </span
          > of<span style="letter-spacing: -0.4pt"> </span> functions<span
            style="letter-spacing: -0.45pt"
          >
          </span
          > and<span style="letter-spacing: -0.45pt"> </span> procedures on the Platform <span
            style="letter-spacing: -0.45pt"
          >
          </span
          > that<span style="letter-spacing: -0.4pt"> </span> allow<span
            style="letter-spacing: -0.45pt"
          >
          </span
          > the<span style="letter-spacing: -0.45pt"> </span> creation<span
            style="letter-spacing: -0.45pt"
          >
          </span
          > of<span style="letter-spacing: -0.4pt"> </span> applications which
          access the features or data of an operating system, application, or
          other <span style="letter-spacing: -0.1pt"> services.</span></span
        >
      </p>

      <p
        class="MsoNormal"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 65.7pt;
          text-indent: -36.35pt;
        "
      >
        <span
          >33.5    “BIN&quot; means the Bank Identification Numbers which
          uniquely identifies the financial institution issuing <span
            style="letter-spacing: -0.45pt"
          >
          </span
          > the<span style="letter-spacing: -0.5pt"> </span> virtual<span
            style="letter-spacing: -0.45pt"
          >
          </span
          > or<span style="letter-spacing: -0.5pt"> </span> physical<span
            style="letter-spacing: -0.45pt"
          >
          </span
          > card,<span style="letter-spacing: -0.5pt"> </span> where<span
            style="letter-spacing: -0.45pt"
          >
          </span
          > such<span style="letter-spacing: -0.5pt"> </span> a<span
            style="letter-spacing: -0.45pt"
          >
          </span
          > card<span style="letter-spacing: -0.5pt"> </span> may,<span
            style="letter-spacing: -0.45pt"
          >
          </span
          > for<span style="letter-spacing: -0.5pt"> </span> example,<span
            style="letter-spacing: -0.45pt"
          >
          </span
          > be<span style="letter-spacing: -0.5pt"> </span> a<span
            style="letter-spacing: -0.45pt"
          >
          </span
          > charge card, debit card, credit card, prepaid card, or an electronic
          gift card.</span
        >
      </p>

      <p
        class="MsoNormal"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 65.7pt;
          text-indent: -36.35pt;
        "
      >
        <span style="letter-spacing: -0.1pt">33.6     “Breaking Changes" refers to any changes or modifications made to the APIs that have the potential to affect the compatibility or functionality of existing API integrations on the Platform. These changes have the potential to disrupt or require adjustments to the way the APIs are currently integrated and utilized.</span
        >
      </p>
      
      <p
        class="MsoNormal"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 65.7pt;
          text-indent: -36.35pt;
        "
      >
        <span style="letter-spacing: -0.1pt">33.7     &quot;Business</span
        ><span style="letter-spacing: -0.15pt"> &nbsp;</span
        ><span style="letter-spacing: -0.1pt"
          >Day(s)&quot; means any weekday</span
        ><span>
          <span style="letter-spacing: -0.1pt"
            >&nbsp; and other than a public holiday in Singapore, or, and only if agreed in writing, in the Territory.</span
          ></span
        >
      </p>

      <p
        class="MsoNormal"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 65.7pt;
          text-indent: -36.35pt;
        "
      >
        <span
          >33.8     &quot;Business Hours&quot; means the hours of 09:00 to 17:00,
          in Singapore, on a Business
          <span style="letter-spacing: -0.2pt">Day, unless agreed otherwise in writing.</span></span
        >
      </p>

      <p
        class="MsoNormal"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 65.7pt;
          text-indent: -36.35pt;
        "
      >
        <span style="letter-spacing: -0.2pt">33.9     </span><span>“Card”</span
        ><span> </span
        ><span
          > means a prepaid/debit card that is issued under a specific BIN. It is
          a payment instrument provided by Us or facilitated through Us,
          enabling Your End Users to access funds that are either preloaded onto
          the Card or linked to a designated account. The cardholder's spending
          is restricted to the available funds.</span
        >
      </p>

      <p
        class="MsoNormal"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 56.7pt;
          text-align: justify;
          text-indent: -28.35pt;
        "
      >
        <span style="letter-spacing: -0.2pt">33.10  </span
        ><span
          >&quot;Charges&quot;<span style="letter-spacing: -0.35pt"> &nbsp;</span
          >means<span style="letter-spacing: -0.1pt">:</span></span
        >
      </p>

      <p
        class="MsoNormal"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 92.15pt;
          text-indent: -35.45pt;
        "
      >
        <span style="color: black"
          >33.10.1    The<span style="letter-spacing: -0.35pt"> </span
          > amounts<span style="letter-spacing: -0.25pt"> and fees </span
          >specified<span style="letter-spacing: -0.25pt"> </span> in<span
            style="letter-spacing: -0.25pt"
          >
            the relevant </span
          >Schedule<span style="letter-spacing: -0.25pt">&nbsp; </span>of<span
            style="letter-spacing: -0.25pt"
          >&nbsp;
          </span
          >the<span style="letter-spacing: -0.25pt">&nbsp; </span>Agreement;<span
            style="letter-spacing: -0.25pt"
          >
            and</span
          ></span
        >
      </p>

      <p
        class="MsoNormal"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 92.15pt;
          text-indent: -35.45pt;
        "
      >
        <span
          >33.10.2    The<span style="letter-spacing: -0.3pt"> </span
          > amounts<span style="letter-spacing: -0.2pt"> </span> as<span
            style="letter-spacing: -0.2pt"
          >
          </span
          > may<span style="letter-spacing: -0.2pt"> </span> be<span
            style="letter-spacing: -0.2pt"
          >
          </span
          > agreed from time to time,<span style="letter-spacing: -0.2pt"> </span
          > in<span style="letter-spacing: -0.15pt"> </span> writing,<span
            style="letter-spacing: -0.2pt"
          >
          </span
          > by You and Us.</span
        >
      </p>

      <p
        class="MsoNormal"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 65.7pt;
          text-indent: -36.35pt;
        "
      >
        <span
          >33.11 &quot;Control(s)&quot; means the legal power to control
          directly or indirectly the management of an entity (and
          &quot;Controlled&quot; should be construed accordingly).</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 65.7pt;
          text-indent: -36.35pt;
        "
      >
        <span style="letter-spacing: -0.1pt">33.12  &quot;Closed</span
        ><span style="letter-spacing: -0.25pt"> </span
        ><span style="letter-spacing: -0.1pt"> Loop”</span
        ><span style="letter-spacing: -0.15pt"> &nbsp;</span
        ><span style="letter-spacing: -0.1pt">means transactions</span
        ><span style="letter-spacing: -0.15pt"> </span
        ><span style="letter-spacing: -0.1pt"> on the</span
        ><span style="letter-spacing: -0.15pt"> </span
        ><span style="letter-spacing: -0.1pt"> Platform that</span
        ><span style="letter-spacing: -0.15pt"> </span
        ><span style="letter-spacing: -0.1pt"> do</span
        ><span style="letter-spacing: -0.05pt"> </span
        ><span style="letter-spacing: -0.1pt"> not</span
        ><span style="letter-spacing: -0.15pt"> </span
        ><span style="letter-spacing: -0.1pt"> pass through</span
        ><span style="letter-spacing: -0.15pt"> </span
        ><span style="letter-spacing: -0.1pt"> another Network.</span>
      </p>

      <p
        class="MsoNormal"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 65.7pt;
          text-indent: -36.35pt;
        "
      >
        <span style="letter-spacing: -0.1pt">33.13  &quot;Confidential</span
        ><span style="letter-spacing: 0.6pt"> </span
        ><span style="letter-spacing: -0.1pt"> Information&quot;</span
        ><span style="letter-spacing: 0.65pt"> </span
        ><span style="letter-spacing: -0.1pt"> means:</span>
      </p>

      <p
        class="MsoNormal"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 92.15pt;
          text-indent: -35.45pt;
        "
      >
        <span
          >33.13.1  Any information disclosed by a Party to the other Party
          during the Term (whether disclosed in writing, orally or otherwise),
          including the following:</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 120.5pt;
          text-indent: -28.35pt;
        "
      >
        <span
          >a.         Information at the time of
          <span style="letter-spacing: -0.1pt"> disclosure</span> was<span
            style="letter-spacing: -0.35pt"
          >
          </span
          > marked<span style="letter-spacing: -0.3pt"> </span> or<span
            style="letter-spacing: -0.3pt"
          >
          </span
          > described<span style="letter-spacing: -0.3pt"> </span> as <span
            style="letter-spacing: -0.3pt"
          >
          </span
          >&quot;Confidential&quot;
          <span style="letter-spacing: -0.25pt">or </span>should have been
          reasonably understood by the other Party to be<span
            style="letter-spacing: 2pt"
          >
          </span
          ><span style="letter-spacing: -0.1pt"> confidential.</span></span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 120.5pt;
          text-indent: -28.35pt;
        "
      >
        <span
          >b.         Partner<span style="letter-spacing: -0.45pt"> </span
          ><span style="letter-spacing: -0.1pt"> Data.</span></span
        >
      </p>

      <p
        class="MsoNormal"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 120.5pt;
          text-indent: -28.35pt;
        "
      >
        <span style="letter-spacing: -0.1pt"
          >c.          Platform Documentation</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 120.5pt;
          text-indent: -28.35pt;
        "
      >
        <span
          >d.         The<span style="letter-spacing: -0.4pt"> </span> terms<span
            style="letter-spacing: -0.25pt"
          >
          </span
          > of<span style="letter-spacing: -0.25pt"> </span> this Agreement<span
            style="letter-spacing: -0.2pt"
            >.</span
          ></span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 65.7pt;
          text-indent: -36.35pt;
        "
      >
        <span
          >33.14  &quot;Customization&quot;<span style="letter-spacing: 1.35pt">
          </span
          > means<span style="letter-spacing: 1.35pt"> </span> a<span
            style="letter-spacing: 1.35pt"
          >
          </span
          > customization<span style="letter-spacing: 1.35pt"> </span> of<span
            style="letter-spacing: 1.35pt"
          >
          </span
          > the<span style="letter-spacing: 1.35pt"> </span> Platform<span
            style="letter-spacing: 1.3pt"
          >
          </span
          > Services,<span style="letter-spacing: 1.35pt"> </span> whether<span
            style="letter-spacing: 1.35pt"
          >
          </span
          > made through the development, configuration, or integration of
          software, or otherwise.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 65.7pt;
          text-indent: -36.35pt;
        "
      >
        <span
          >33.15  “Deprecation&quot; or &quot;Deprecated API”
          refers to an API that is no longer recommended for use because We have discontinued support for it. This decision may be made to either retire a feature, introduce a new feature, or due to the API's lack of adoption.
          </span>
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 65.7pt;
          text-indent: -36.35pt;
        "
      >
        <span
          >33.16  “Documentation&quot; or &quot;Platform Documentation”
          means<span style="letter-spacing: -0.55pt"> </span> the<span
            style="letter-spacing: -0.55pt"
          >
          </span
          > documentation<span style="letter-spacing: -0.55pt"> </span
          > produced<span style="letter-spacing: -0.55pt"> </span> by<span
            style="letter-spacing: -0.55pt"
          >
          </span
          > Us<span style="letter-spacing: -0.55pt"> </span> for<span
            style="letter-spacing: -0.55pt"
          >
          </span
          > the<span style="letter-spacing: -0.6pt"> </span> Platform<span
            style="letter-spacing: -0.55pt"
          >
          </span
          > Services and made available to You.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 65.7pt;
          text-indent: -36.35pt;
        "
      >
        <span
          >33.17  &quot;Effective<span style="letter-spacing: -0.35pt"> </span
          > Date&quot;<span style="letter-spacing: -0.25pt"> </span> means<span
            style="letter-spacing: -0.25pt"
          >
          </span
          > the<span style="letter-spacing: -0.25pt"> </span> date<span
            style="letter-spacing: -0.2pt"
          >
          </span
          > of<span style="letter-spacing: -0.25pt"> </span> execution<span
            style="letter-spacing: -0.25pt"
          >
          </span
          > of<span style="letter-spacing: -0.25pt"> </span> the<span
            style="letter-spacing: -0.25pt"
          >
          </span
          ><span style="letter-spacing: -0.1pt"> Agreement.</span></span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 65.7pt;
          text-indent: -36.35pt;
        "
      >
        <span
          >33.18  “Effective Launch Date” or “Launch Date” means sixty (60)
          days after the UAT/Pre- Production version of the Platform is made
          accessible to You, or the public launch of Your Program, whichever is
          sooner. For avoidance of doubt, public launch shall mean the day Your
          first User utilizes any of the Platform features in the Live
          Production environment.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 65.7pt;
          text-indent: -36.35pt;
        "
      >
        <span
          >33.19  “End users" means the consumers or individuals for whom Your App or service is specifically designed. These End Users directly utilize or interact with the Platform Services.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 65.7pt;
          text-indent: -36.35pt;
        "
      >
        <span
          >33.20  &quot;Intellectual Property Rights&quot; means all
          intellectual property rights, wherever in the world, whether
          registrable or unregistrable, registered, or unregistered, including
          any application or right of application for such rights. include
          copyright and related rights, database rights, confidential
          information, trade secrets, know-how, business names, trade names,
          trademarks, service marks, passing off rights, unfair competition
          rights, patents, utility models, and rights in designs.</span
        >
      </p>

      <p
        class="MsoNormal"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 65.7pt;
          text-indent: -36.35pt;
        "
      >
        <span
          >33.21  &quot;Maintenance Services&quot; means the general maintenance
          of the Platform and Platform Services, and the application of Updates
          and Upgrades and security
          <span style="letter-spacing: -0.1pt">patches.</span></span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 65.7pt;
          text-indent: -36.35pt;
        "
      >
        <span
          >33.22  &quot;Network&quot; means a major national or international
          networks, and/or, service providers, such as American Express,<span
            style="letter-spacing: -0.15pt"
          >
          </span
          > Mastercard,<span style="letter-spacing: -0.15pt"> </span> Visa,<span
            style="letter-spacing: -0.15pt"
          >
          </span
          > JCB,<span style="letter-spacing: -0.15pt"> </span> Discover,<span
            style="letter-spacing: -0.15pt"
          >
          </span
          > UnionPay, or any other global network contracted by Provider to
          switch and settle<span style="letter-spacing: -0.1pt"> </span
          > transactions<span style="letter-spacing: -0.1pt"> </span> between<span
            style="letter-spacing: -0.1pt"
          >
          </span
          > merchants<span style="letter-spacing: -0.1pt"> </span> and/or<span
            style="letter-spacing: -0.1pt"
          >
          </span
          > users,<span style="letter-spacing: -0.1pt"> </span> according<span
            style="letter-spacing: -0.1pt"
          >
          </span
          > to<span style="letter-spacing: -0.1pt"> </span> its<span
            style="letter-spacing: -0.1pt"
          >
          </span
          > own<span style="letter-spacing: -0.1pt"> </span> standards and
          protocols.</span
        >
      </p>

      <p
        class="MsoNormal"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 65.7pt;
          text-indent: -36.35pt;
        "
      >
        <span
          >33.23  &quot;Open<span style="letter-spacing: -0.35pt"> </span
          > Loop&quot;<span style="letter-spacing: -0.25pt"> </span> means<span
            style="letter-spacing: -0.25pt"
          >
          </span
          > transactions<span style="letter-spacing: -0.25pt"> </span> on<span
            style="letter-spacing: -0.25pt"
          >
          </span
          > the<span style="letter-spacing: -0.25pt"> </span> Platform<span
            style="letter-spacing: -0.25pt"
          >
          </span
          > that<span style="letter-spacing: -0.25pt"> </span> go<span
            style="letter-spacing: -0.25pt"
          >
          </span
          > through<span style="letter-spacing: -0.25pt"> </span> a<span
            style="letter-spacing: -0.2pt"
          >
          </span
          ><span style="letter-spacing: -0.1pt"> Network.</span></span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 65.7pt;
          text-indent: -36.35pt;
        "
      >
        <span
          >33.24  “Party” or “Parties” means the entities that have executed the
          Agreement.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 65.7pt;
          text-indent: -36.35pt;
        "
      >
        <span
          >33.25  &quot;<span style="color: black"
            >Partner/Your Data&quot; means all data, works and materials
            uploaded to or stored by You on the Platform, or transmitted by the
            Platform to You, or supplied by You to Us for uploading to or
            transmission by, or storage on, the Platform.</span
          ></span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 65.7pt;
          text-indent: -36.35pt;
        "
      >
        <span
          >33.26  &quot;Partner Personal Data&quot; means Personal Data of Your
          User(s) that is processed by Us, on behalf of You.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 65.7pt;
          text-indent: -36.35pt;
        "
      >
        <span
          >33.27  &quot;Platform<span style="letter-spacing: -0.25pt"> </span
          > Access<span style="letter-spacing: -0.25pt"> </span
          > Services&quot;<span style="letter-spacing: -0.25pt"> </span
          > means<span style="letter-spacing: -0.25pt"> </span> the<span
            style="letter-spacing: -0.25pt"
          >
            specific </span
          > configuration<span style="letter-spacing: -0.25pt"> </span> of<span
            style="letter-spacing: -0.25pt"
          >
            the Platform </span
          > for<span style="letter-spacing: -0.25pt"> </span> You<span
            style="letter-spacing: -0.25pt"
          >
          </span
          > to<span style="letter-spacing: -0.25pt"> </span> access<span
            style="letter-spacing: -0.25pt"
          >
          </span
          > in accordance with Documentation, and the relevant Schedules in the
          Agreement, and these T&amp;C.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 65.7pt;
          text-indent: -36.35pt;
        "
      >
        <span
          >33.28   &quot;Platform Services&quot; means all or part of the
          Platform as specified in the Platform Services Specification in the
          Schedule in the Agreement, which will be made available by Us to
          You.</span
        >
      </p>

      <p
        class="MsoNormal"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 65.7pt;
          text-indent: -36.35pt;
        "
      >
        <span
          >33.29  &quot;Platform Services Defect&quot; means a defect, error or
          bug in the Platform having a material adverse effect on the operation,
          functionality, or performance of the Platform Services, but excluding
          any defect, error or bug caused by or arising because of:</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 92.7pt;
          text-indent: -0.5in;
        "
      >
        <span>33.29.1<span>&nbsp;&nbsp; </span></span
        ><span
          >any act or omission by You, or any person authorized by You, to use
          the Platform or Platform Services.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 92.15pt;
          text-indent: -34.85pt;
        "
      >
        <span>33.29.2<span>&nbsp;&nbsp; </span></span
        ><span
          >any<span style="letter-spacing: -0.45pt"> </span> use<span
            style="letter-spacing: -0.45pt"
          >
          </span
          > of<span style="letter-spacing: -0.45pt"> </span> the<span
            style="letter-spacing: -0.45pt"
          >
          </span
          > Platform<span style="letter-spacing: -0.45pt"> </span> Services<span
            style="letter-spacing: -0.45pt"
          >
          </span
          > contrary<span style="letter-spacing: -0.45pt"> </span> to<span
            style="letter-spacing: -0.45pt"
          >
          </span
          > the<span style="letter-spacing: -0.45pt"> </span> Documentation,
          whether by You or by any person authorized by You.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 92.15pt;
          text-indent: -34.85pt;
        "
      >
        <span>33.29.3<span>&nbsp;&nbsp; </span></span
        ><span
          >a<span style="letter-spacing: -0.2pt"> </span> failure<span
            style="letter-spacing: -0.2pt"
          >
          </span
          > on<span style="letter-spacing: -0.2pt"> </span> your<span
            style="letter-spacing: -0.2pt"
          >
          </span
          > part<span style="letter-spacing: -0.2pt"> </span> to<span
            style="letter-spacing: -0.2pt"
          >
          </span
          > perform<span style="letter-spacing: -0.2pt"> </span> Your<span
            style="letter-spacing: -0.2pt"
          >
          </span
          > obligations<span style="letter-spacing: -0.2pt"> </span> in<span
            style="letter-spacing: -0.2pt"
          >
          </span
          > these T&amp;C or the Agreement; and/or</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 92.15pt;
          text-indent: -34.85pt;
        "
      >
        <span>33.29.4<span>&nbsp;&nbsp; </span></span
        ><span
          >an<span style="letter-spacing: -0.15pt"> </span> incompatibility<span
            style="letter-spacing: -0.15pt"
          >
          </span
          > between<span style="letter-spacing: -0.15pt"> </span> the<span
            style="letter-spacing: -0.15pt"
          >
          </span
          > Platform<span style="letter-spacing: -0.2pt"> </span> Services<span
            style="letter-spacing: -0.15pt"
          >
          </span
          > and<span style="letter-spacing: -0.15pt"> </span> any<span
            style="letter-spacing: -0.15pt"
          >
          </span
          > other system, network, application, program, hardware, or software,
          that is not specified as compatible in the Documentation.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 65.7pt;
          text-indent: -36.35pt;
        "
      >
        <span>33.30<span>&nbsp;&nbsp; </span></span
        ><span
          >“Platform Services Specification&quot; means the specification for
          the Platform and Platform Services set out in the Schedule of this
          Agreement.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 65.7pt;
          text-indent: -36.35pt;
        "
      >
        <span>33.31<span>&nbsp;&nbsp; </span></span
        ><span
          >&quot;Personal Data&quot; has the meaning given to personal data by
          law, including<span style="letter-spacing: -0.35pt"> </span> the<span
            style="letter-spacing: -0.35pt"
          >
          </span
          > Singapore<span style="letter-spacing: -0.35pt"> </span> Personal<span
            style="letter-spacing: -0.35pt"
          >
          </span
          > Data<span style="letter-spacing: -0.35pt"> </span> Protection<span
            style="letter-spacing: -0.35pt"
          >
          </span
          > Act,<span style="letter-spacing: -0.35pt"> </span> and<span
            style="letter-spacing: -0.35pt"
          >
          </span
          > other<span style="letter-spacing: -0.35pt"> </span> applicable data
          privacy laws in the Territory.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 65.7pt;
          text-indent: -36.35pt;
        "
      >
        <span>33.32<span>&nbsp;&nbsp; </span></span
        ><span
          >“Platform”<span style="letter-spacing: -0.55pt"> </span> or<span
            style="letter-spacing: -0.55pt"
          >
          </span
          > “MMBOS&quot;<span style="letter-spacing: -0.55pt"> </span> means<span
            style="letter-spacing: -0.55pt"
          >
          </span
          > the<span style="letter-spacing: -0.55pt"> </span> platform<span
            style="letter-spacing: -0.55pt"
          >
          </span
          > developed<span style="letter-spacing: -0.55pt"> </span> and<span
            style="letter-spacing: -0.55pt"
          >
          </span
          > managed<span style="letter-spacing: -0.55pt"> </span> by<span
            style="letter-spacing: -0.55pt"
          >
          </span
          > Us<span style="letter-spacing: -0.55pt"> </span> and<span
            style="letter-spacing: -0.55pt"
          >
          </span
          > used by You to provide the Platform Services, including the
          application and database software for the Platform Services, the
          system and server software used to provide the Platform Services, the
          computer hardware on which that application, database, system and
          server software is installed, the Documentation and Support Services,
          and the reporting tools for customer management, business management
          and infrastructure monitoring, and the UAT/Pre-Prod and Live
          Production System ;</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 65.7pt;
          text-indent: -36.35pt;
        "
      >
        <span>33.33<span>&nbsp;&nbsp; </span></span
        ><span
          >“Pool<span style="letter-spacing: -0.15pt"> </span> Account”<span
            style="letter-spacing: -0.15pt"
          >
          </span
          > means<span style="letter-spacing: -0.15pt"> </span> a<span
            style="letter-spacing: -0.15pt"
          >
          </span
          > non-operative<span style="letter-spacing: -0.15pt"> </span
          > current<span style="letter-spacing: -0.15pt"> </span> account<span
            style="letter-spacing: -0.15pt"
          >
          </span
          > opened<span style="letter-spacing: -0.15pt"> </span> for<span
            style="letter-spacing: -0.15pt"
          >
          </span
          > You<span style="letter-spacing: -0.15pt"> </span> by<span
            style="letter-spacing: -0.15pt"
          >
          </span
          > Us<span style="letter-spacing: -0.15pt">, </span> at<span
            style="letter-spacing: -0.15pt"
          >
          </span
          > a licensed bank selected by Us.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 65.7pt;
          text-indent: -36.35pt;
        "
      >
        <span>33.34<span>&nbsp;&nbsp; </span></span
        ><span
          >“Program” or “Your Program” means the products and services You offer
          to Your Users, and incorporates any feature or service of the
          Platform, as described in the relevant Schedule.
        </span>
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 65.7pt;
          text-indent: -36.35pt;
        "
      >
        <span>33.35<span>&nbsp;&nbsp; </span></span
        ><span>“RCA” means Root Cause Analysis.</span>
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 65.7pt;
          text-indent: -36.35pt;
        "
      >
        <span>33.36<span>&nbsp;&nbsp; </span></span
        ><span
          >“Release(s)” means software changes that updates or adds new functionality to Our Platform or Services.
          </span
        >
      </p>
      
      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 65.7pt;
          text-indent: -36.35pt;
        "
      >
        <span>33.37<span>&nbsp;&nbsp; </span></span
        ><span
          >“Regulator” means the central banks or national body/bodies
          responsible for developing<span style="letter-spacing: -0.6pt"> </span
          > regulations<span style="letter-spacing: -0.55pt"> </span
          > relevant<span style="letter-spacing: -0.55pt"> </span> to<span
            style="letter-spacing: -0.55pt"
          >
          </span
          > the<span style="letter-spacing: -0.55pt"> </span> MMBOS<span
            style="letter-spacing: -0.55pt"
          >
          </span
          > Platform<span style="letter-spacing: -0.55pt"> </span> and<span
            style="letter-spacing: -0.55pt"
          >
          </span
          > Services<span style="letter-spacing: -0.6pt"> </span> (“Regulations”)
          and enforcing such policies in the Territory (“Regulatory
          Requirements”).</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 65.7pt;
          text-indent: -36.35pt;
        "
      >
        <span>33.38<span>&nbsp;&nbsp; </span></span
        ><span
          >&quot;Schedule&quot;<span style="letter-spacing: -0.1pt">&nbsp; </span
          >means<span style="letter-spacing: -0.1pt"> &nbsp;</span>Schedules in the Agreement, and any subsequent additions that are agreed by Us, in writing, with You.<span style="letter-spacing: -0.1pt"></span><span
            style="letter-spacing: -0.1pt"
          >
          </span
          >
        </span>
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 65.7pt;
          text-indent: -36.35pt;
        "
      >
        <span>33.39<span>&nbsp;&nbsp; </span></span
        ><span
          >&quot;Services&quot; means any services that We provide to You, or
          have an agreement to provide to You, under the Agreement.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 65.7pt;
          text-indent: -36.35pt;
        "
      >
        <span>33.40<span>&nbsp;&nbsp; </span></span
        ><span
          >“Service Credits” means the value You have earned, or purchased,
          that can be used to procure VAS. Service Credits cannot be converted to cash or funds, under any circumstances.
        </span>
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 65.7pt;
          text-indent: -36.35pt;
        "
      >
        <span>33.41<span>&nbsp;&nbsp; </span></span
        ><span
          >“Service Level Agreement” or “SLAs” means the expected standard of
          service, performance metrics of the Platform, and remedies for
          non-compliance.
        </span>
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 65.7pt;
          text-indent: -36.35pt;
        "
      >
        <span>33.42<span>&nbsp;&nbsp; </span></span
        ><span
          >&quot;Services<span style="letter-spacing: -0.4pt"> </span> Order<span
            style="letter-spacing: -0.4pt"
          >
          </span
          > Form&quot;<span style="letter-spacing: -0.4pt"> </span> means<span
            style="letter-spacing: -0.4pt"
          >
          </span
          > an<span style="letter-spacing: -0.4pt"> </span> online<span
            style="letter-spacing: -0.4pt"
          >
          </span
          > order<span style="letter-spacing: -0.4pt"> </span> form<span
            style="letter-spacing: -0.4pt"
          >
          </span
          > published<span style="letter-spacing: -0.4pt"> </span> by<span
            style="letter-spacing: -0.4pt"
          >
          </span
          > Us,<span style="letter-spacing: -0.4pt"> </span> and<span
            style="letter-spacing: -0.4pt"
          >
          </span
          > available<span style="letter-spacing: -0.4pt"> </span> on Our support
          portal (https://support.matchmove.com) and completed and submitted by
          You.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 65.7pt;
          text-indent: -36.35pt;
        "
      >
        <span>33.43<span>&nbsp;&nbsp; </span></span
        ><span
          >&quot;Support<span style="letter-spacing: -0.3pt"> </span
          > Services&quot;<span style="letter-spacing: -0.3pt"> </span> means<span
            style="letter-spacing: -0.3pt"
          >
          </span
          > support<span style="letter-spacing: -0.3pt"> </span> in<span
            style="letter-spacing: -0.3pt"
          >
          </span
          > relation<span style="letter-spacing: -0.3pt"> </span> to<span
            style="letter-spacing: -0.3pt"
          >
          </span
          > the<span style="letter-spacing: -0.3pt"> </span> use<span
            style="letter-spacing: -0.3pt"
          >
          </span
          > of,<span style="letter-spacing: -0.3pt"> </span> and<span
            style="letter-spacing: -0.3pt"
          >
          </span
          > the<span style="letter-spacing: -0.3pt"> </span> identification<span
            style="letter-spacing: -0.3pt"
          >
          </span
          > and resolution of errors in the Platform Services.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 65.7pt;
          text-indent: -36.35pt;
        "
      >
        <span>33.44<span>&nbsp;&nbsp; </span></span
        ><span
          >&quot;Supported Web Browser&quot; means the current release of
          Microsoft Edge,<span style="letter-spacing: -0.05pt"> </span
          > Mozilla<span style="letter-spacing: -0.05pt"> </span> Firefox,<span
            style="letter-spacing: -0.05pt"
          >
          </span
          > Google<span style="letter-spacing: -0.05pt"> </span> Chrome<span
            style="letter-spacing: -0.05pt"
          >
          </span
          > or<span style="letter-spacing: -0.05pt"> </span> Apple<span
            style="letter-spacing: -0.05pt"
          >
          </span
          > Safari,<span style="letter-spacing: -0.05pt"> </span> or<span
            style="letter-spacing: -0.05pt"
          >
          </span
          > any<span style="letter-spacing: -0.05pt"> </span> other<span
            style="letter-spacing: -0.05pt"
          >
          </span
          > web browser that We agree with You in writing.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 65.7pt;
          text-indent: -36.35pt;
        "
      >
        <span>33.45 &nbsp;&nbsp; </span>
        <span>&quot;Supported  Mobile  Operating  System&quot;</span>
          <span style="letter-spacing: -0.35pt"> </span> means
          the latest release, as of the Effective Date, of 
          Google Android (Stock) and Apple iOS. For avoidance of doubt, and unless agreed otherwise in writing, it does not 
          include AOSP, Android One, Custom UI Skins, Android Go, Lineage OS, or any other variant of Google Android. 
      </p>
      
      
      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 65.7pt;
          text-indent: -36.35pt;
        "
      >
        <span>33.46<span>&nbsp;&nbsp; </span></span
        ><span
          >&quot;Term&quot;<span style="letter-spacing: -0.35pt"> </span
          > means<span style="letter-spacing: -0.2pt"> </span> the<span
            style="letter-spacing: -0.2pt"
          >
          </span
          > length of time<span style="letter-spacing: -0.1pt">
            for which You have access to the Platform Services, under the
            conditions of this Agreement.</span
          ></span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 65.7pt;
          text-indent: -36.35pt;
        "
      >
        <span>33.47<span>&nbsp;&nbsp; </span></span
        ><span style="letter-spacing: -0.1pt"
          >“Terminology means the terminology We use to describe the Charges
          related to the types of services provided on the Platform.  It is
          important to note that the Terminology is subject to change at our
          discretion to ensure clarity and accuracy. If there is there is any
          conflict between the Agreement and the Terminology, then the Agreement
          and T&amp;C will prevail.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 65.7pt;
          text-indent: -36.35pt;
        "
      >
        <span>33.48<span>&nbsp;&nbsp; </span></span
        ><span
          >“Territory&quot;<span style="letter-spacing: -0.2pt"> </span
          > means<span style="letter-spacing: -0.2pt"> </span> country<span
            style="letter-spacing: -0.2pt"
          >
          </span
          > or<span style="letter-spacing: -0.2pt"> </span> countries<span
            style="letter-spacing: -0.2pt"
          >
          </span
          > where<span style="letter-spacing: -0.2pt"> </span> the<span
            style="letter-spacing: -0.2pt"
          >
          </span
          > Services<span style="letter-spacing: -0.15pt"> </span> are<span
            style="letter-spacing: -0.2pt"
          >
          </span
          > valid<span style="letter-spacing: -0.2pt"> </span> and<span
            style="letter-spacing: -0.2pt"
          >
          </span
          > available,<span style="letter-spacing: -0.2pt"> </span> as specified
          in the Agreement.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 65.7pt;
          text-indent: -36.35pt;
        "
      >
        <span>33.49<span>&nbsp;&nbsp; </span></span
        ><span
          >&quot;Update&quot;<span style="letter-spacing: 1.05pt"> </span
          > means<span style="letter-spacing: 1.05pt"> </span> a<span
            style="letter-spacing: 1.05pt"
          >
          </span
          > hotfix,<span style="letter-spacing: 1.05pt"> </span> security<span
            style="letter-spacing: 1.05pt"
          >
          </span
          > patch<span style="letter-spacing: 1.05pt"> </span> or<span
            style="letter-spacing: 1.05pt"
          >
          </span
          > minor<span style="letter-spacing: 1.05pt"> </span> version<span
            style="letter-spacing: 1.05pt"
          >
          </span
          > update<span style="letter-spacing: 1.05pt"> </span> to<span
            style="letter-spacing: 1.05pt"
          >
          </span
          > any<span style="letter-spacing: 1.05pt"> </span> Platform software;
          and </span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 65.7pt;
          text-indent: -36.35pt;
        "
      >
        <span>33.50<span>&nbsp;&nbsp; </span></span
        ><span
          >&quot;Upgrade&quot;<span style="letter-spacing: -0.4pt"> </span
          > means<span style="letter-spacing: -0.25pt"> </span> a<span
            style="letter-spacing: -0.25pt"
          >
          </span
          > major<span style="letter-spacing: -0.25pt"> </span> version<span
            style="letter-spacing: -0.3pt"
          >
          </span
          > upgrade<span style="letter-spacing: -0.25pt"> </span> of<span
            style="letter-spacing: -0.25pt"
          >
          </span
          > any<span style="letter-spacing: -0.25pt"> </span> Platform<span
            style="letter-spacing: -0.25pt"
          >
          </span
          ><span style="letter-spacing: -0.1pt"> software.</span></span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 65.7pt;
          text-indent: -36.35pt;
        "
      >
        <span>33.51<span>&nbsp;&nbsp; </span></span
        ><span
          >“VAPT” means the Vulnerability Assessment and Penetration Test that
          must be satisfactorily passed by all Your Apps that connect to the
          MMBOS Platform.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 65.7pt;
          text-indent: -36.35pt;
        "
      >
        <span>33.52<span>&nbsp;&nbsp; </span></span
        ><span
          >“Value Added Services” or “VAS” refers to the additional services, features and benefits offered by Us at www.matchmovevas.com and are governed by the Agreement.
          </span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 65.7pt;
          text-indent: -36.35pt;
        "
      >
        <span>33.53<span>&nbsp;&nbsp; </span></span
        ><span
          >“Your App&quot; means the mobile and/or web-based application that is
          developed and serviced<span style="letter-spacing: -0.25pt"> </span
          > by<span style="letter-spacing: -0.25pt"> </span> You<span
            style="letter-spacing: -0.25pt"
          >
          </span
          > and is<span style="letter-spacing: -0.25pt"> </span> made<span
            style="letter-spacing: -0.25pt"
          >
          </span
          > available<span style="letter-spacing: -0.25pt"> </span> for<span
            style="letter-spacing: -0.25pt"
          >
          </span
          > general<span style="letter-spacing: -0.25pt"> </span
          > distribution<span style="letter-spacing: -0.25pt"> </span> by<span
            style="letter-spacing: -0.25pt"
          >
          </span
          > You.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0.1in;
          margin-left: 65.7pt;
          text-indent: -36.35pt;
        "
      >
        <span>33.54<span>&nbsp;&nbsp; </span></span
        ><span
          >&quot;Your Systems&quot; means Your hardware and software systems
          that interact, or may reasonably be expected to interact, with the
          Platform Services.</span
        >
      </p>

      <p
        class="MsoNormal"
        style="
          margin-top: 0in;
          margin-right: 80.2pt;
          margin-bottom: 0in;
          margin-left: 0.5in;
          margin-bottom: 0.0001pt;
          background: white;
        "
      >
        <span lang="EN-SG">&nbsp;</span>
      </p>

      <p class="MsoNormal" style="margin-left: 0.5in; background: white">
        <span lang="EN-SG">&nbsp;</span>
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 1.45pt;
          margin-right: 80.2pt;
          margin-bottom: 0in;
          font-style: italic;
          margin-bottom: 0.0001pt;
          text-indent: 0in;
          line-height: 110%;
        "
      >
      These T&C were updated on Oct 4, 2023.
        <span style="line-height: 110%">&nbsp;</span>
      </p>
      <p style='margin-top:7pt;margin-right:80.2pt;margin-bottom:
.1in;margin-left:0in'><i><span style='font-size:10.5pt'>3.5.4</span></i></p>
    </div>
  </div>
</div>
