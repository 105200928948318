import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-legal-terminology',
  templateUrl: './legal-terminology.component.html',
  styleUrls: ['./legal-terminology.component.scss']
})
export class LegalTerminologyComponent implements OnInit {

  constructor(private metaService: Meta, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.data.subscribe((data) => {
      if (data?.robots) {
        this.metaService.addTag({
          name: 'robots',
          content: data.robots
        });
      }
    });
  }

}
