import {AfterViewInit, Component, OnInit} from '@angular/core';
import {Title} from "@angular/platform-browser";

declare var hbspt: any

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit, AfterViewInit {

  constructor(private titleService: Title) {
  }

  ngOnInit(): void {
    this.titleService.setTitle("Contact Us | MatchMove")
  }

  ngAfterViewInit() {
    hbspt.forms.create({
      region: "na1",
      portalId: "2612807",
      formId: "29092ca8-394f-4cbd-bc67-925992c14b53",
      target: "#hubspotForm"
    })
  }
}
