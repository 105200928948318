<div class="media-bottombg pt-0">
    <div class="container">
        <div class="media-relasttop">
            <h1>Terms And Conditions</h1>
        </div>
        <div class="termsbg">
            <p><strong>TERMS AND CONDITIONS</strong></p>
            <p><strong>Part 1: Introduction</strong></p>
            <p>Introduction</p>
            <p>1.1 These terms and conditions [including all agreements and policies referenced in these terms and conditions or otherwise applicable to the use of specific features of the  MatchMove Mobile Wallet (“Wallet”) and related Services and Remittance Services (collectively (“Services”)) which are required to be agreed to and accepted before the use of such specific features] set out the general terms and conditions which will apply to the Wallet and Services that we may agree to provide to you from time to time. You agree that these terms and conditions constitute a binding and enforceable legal contract between you and us and our affiliates and subsidiaries. MatchMove is the issuer of Wallet and manages the associated funds across the Mastercard network.&nbsp;</p>
            <p>1.2 By completing the sign-up process, You are deemed to have expressly read, understood and accepted each and every term when you use the website, Wallet and Services. You agree to be bound by these terms and conditions and other specific rules and procedures as determined or as may be amended at our sole discretion. You are encouraged to check the for the revised terms and conditions and/or other specific rules and procedures that we may update at regular intervals.</p>
            <p>1.3 In this agreement, “We”, “we”, “us” “Us”, “our” or “Our” refers to MatchMove or MatchMove partner/s, acting on behalf of MatchMove as the case may be. “You”, “you”, “your” or “Your” refers to the Account User using the Wallet and Services.</p>
            <p>Definitions, Description and Interpretation</p>
            <p>Headings and subheadings are inserted for convenience only and do not affect the interpretation of these terms and conditions. In these terms and conditions, unless the context otherwise requires, the following definitions shall apply:</p>
            <p>&ldquo;<strong>Access Code</strong>&rdquo; means a password, code or any other arrangement that the Account User must keep secret, that may be required to authenticate any payment transaction or Account User, and may include any of the following:&nbsp;</p>
            <p>(a) personal identification number, password or code;&nbsp; or</p>
            <p>(b) internet banking authentication code;&nbsp; or</p>
            <p>(c) telephone banking authentication code;&nbsp; or</p>
            <p>(d) code generated by an Authentication Device;&nbsp; or</p>
            <p>(e) code sent by the MatchMove by phone text message such as SMS,&nbsp;</p>
            <p>but does not include a number printed on a Wallet (e.g. a security number printed on a credit card or debit card).&nbsp;</p>
            <p>&ldquo;<strong>Account Holder</strong>&rdquo; means any person in whose name a Wallet has been opened or to whom a Wallet has been issued and includes a joint Account Holder.&nbsp;</p>
            <p>&ldquo;<strong>Account User</strong>&rdquo; means&mdash;&nbsp;</p>
            <p>(a) any Account Holder; or&nbsp;</p>
            <p>(b) any person who is authorized in a manner in accordance with these terms and conditions, by MatchMove and any Account Holder of a Protected Account, to initiate, execute or both initiate and execute payment transactions using the Protected Account.&nbsp;</p>
            <p>&ldquo;<strong>Authentication Device</strong>&rdquo; means any device that is issued by the responsible Financial Institution (&ldquo;FI&rdquo;) to the Account User for the purposes of authenticating any payment transaction, Services initiated from a Wallet, including a device that is used to generate, receive or input any access code.&nbsp;</p>
            <p>&ldquo;Authorized Representative&rdquo; means the representative that an Organization has authorized to open a Wallet account and depending on authority limit granted the right to access and use the services on its behalf.</p>
            <p><strong>&ldquo;Country&rdquo;</strong> means the country or territory where you are residing and the Wallet is opened or otherwise in the country or territory in which you are present while using the Wallet or Services.</p>
            <p><strong>&ldquo;Electronic instruction&rdquo;</strong> means any instruction, notice, instruction or other communication which may, from time to time be given through website, telephone, email, or SMS by you. We, however, reserve the right to require the instruction to be accompanied by supporting document/s, before we may decide to act or not on the instruction.</p>
            <p><strong>&ldquo;Fees and charges</strong>&rdquo; refers to the fees and charges that applies to the wallet account and listed in the fees and charges as provided to you.&nbsp;</p>
            <p><strong>&ldquo;FAST Transfer&rdquo; </strong>refers to the service which enables you as the case may be, to transfer or receive money from or in your Protected Account to or from other user accounts and any other bank accounts/e-wallet accounts held with the banks and e-wallets providers which are part of the FAST network in Singapore.</p>
            <p><strong>&ldquo;MatchMove&rdquo;</strong> refers to MatchMove Pay Pte Ltd, the issuer of Wallet or Responsible Financial Institution (&ldquo;FI&rdquo;) holding a Major Payment Institution License or &ldquo;Regulated Entity&rdquo; issued by the Monetary Authority of Singapore (&ldquo;MAS&rdquo;), together with its employees, directors, affiliates, successors, and assigns. MatchMove Pay Pte Ltd (CRN: 200902936w) is a company duly established and operating under the laws of Singapore, has its registered office at 137 Telok Ayer Street, #0303, Singapore 068602. MatchMove is also Licensed, Authorised and Regulated by the Monetary Authority of Singapore (MAS) as a Major Payment Institution under the Payment Services Act (2019). Please note that such licensing does NOT guarantee the performance of the remittance licensee and you take the risk of any loss suffered from the remittance.&nbsp;</p>
            <p><strong>&ldquo;Mandate Currency&rdquo; </strong>refers to such Foreign Currency in which the mandate provided by you to the MatchMove is denominated.</p>
            <p><strong>&ldquo;Merchant&rdquo;</strong> refers to any merchants, vendors, persons, corporations or entity honoured to provide goods, products and services to you through wallet. You acknowledge that your purchases of those goods, products and services are transactions between you and merchants and not with us or other of our affiliates unless expressly designated as such in the listing of the products or services on our website/electronic catalogue. You agree and confirm that we make no representations or warranties in respect of the products, goods and services offered by Merchant, including, without limitation, the quality, accuracy, adequacy, timeliness, fitness for any purpose or completeness of any of the products, goods or services. You are advised to refer carefully to Merchant&rsquo;s terms and conditions, refund and return policy before making any purchase.</p>
            <p><strong>&ldquo;Multi Currency Wallet&rdquo; </strong>refers to the portion of the payment account that holds the total of each of the Permitted Foreign Currencies which are loaded onto the payment account. Whenever a Multi Currency Wallet has insufficient balance, funds will be automatically transferred from another Multi Currency Wallet(s).</p>
            <p><strong>&ldquo;Network&rdquo; refers to any global card association that is VISA, MasterCard, RuPay, American Express, UnionPay, JCB or any global network that provides and manages BIN of your Cards.</strong></p>
            <p><strong>&ldquo;Organisation&rdquo; </strong>means a company, partnership, association or other entity, organization or body corporate.</p>
            <p><strong>&ldquo;Permitted Foreign Currency&rdquo;</strong> means such Foreign Currencies as may be determined by the MatchMove that may be held by you under the Multi-Currency Wallet from time to time.</p>
            <p><strong>&ldquo;Protected Account&rdquo;</strong> means any Wallet that&mdash;&nbsp;</p>
            <p>(a) is held in the name of one or more persons, all of whom are either individuals or sole proprietors;&nbsp;</p>
            <p>(b) is capable of having a balance of not more than S$1000 (pre KYC verification) amount expressed in any other currency) or S$5,000 (after post KYC verification) (or equivalent amount expressed in any other currency) at any one time or such amount as prescribed by the Monetary Authority of Singapore (&ldquo;MAS&rdquo;); and&nbsp;</p>
            <p>(c) is capable of being used for electronic payment transactions.</p>
            <p>&ldquo;<strong>PDPA</strong>&rdquo; means the Personal Data Protection Act 2012 (No. 26 of 2012).</p>
            <p>&ldquo;<strong>Personal data</strong>&rdquo; shall have the meaning as set out in the PDPA.&nbsp;</p>
            <p><strong>&ldquo;Remittance Services&rdquo; means the money remittance services provided or facilitated by MatchMove from time to time through your Wallet.</strong></p>
            <p><strong>&ldquo;Wallet</strong>&rdquo; refers to the payment account/ and or Virtual Accounts which may be Multiple Currency Wallet and/or the physical card which is a Mastercard provided to you upon signup at Website and completion of Know Your Customer (&ldquo;KYC&rdquo;) process with us. In the event Account Holder selects a Multi Currency Wallet, the Account User shall also be governed by additional terms (&ldquo;Multi-Currency Wallet Terms&rdquo;). The payment account is regarded as a stored value facility under country laws, and different types of payment products and services may be available to you from time to time.</p>
            <p><strong>&ldquo;Website</strong>&rdquo; refers to our website, the address of which is website and mobile applications or portals that you can access from mobile devices. All website addresses and URL for your reference in these terms and conditions are subject to be changed from time to time at our sole discretion.</p>
            <p>&ldquo;<strong>Unauthorized Transaction</strong>&rdquo; in relation to any Protected Account, means any payment transaction initiated by any person without the actual or imputed knowledge and implied or express consent of an Account User of the Protected Account.&nbsp;</p>
            <p>&ldquo;<strong>Virtual Account</strong>&rdquo; is an account denominated in United States Dollars (USD), Singapore Dollars (SGD), or any other specified currency denomination provided to you by the banking partner of MatchMove. This Virtual Account serves as a financial instrument for the systematic processing and management of the specified currency denominated transactions within the operational framework of your business. You agree and confirm that the issuance and administration of the Virtual Account are subjected to the rules, policies, and requirements established by the banking partner and you agree and confirm that the operation and usage of the Virtual Account shall be governed by MatchMove’s terms and conditions. The Virtual Account is subject to applicable laws and regulation and service terms and please note that the product and services of the Virtual Account are not available in all geographic areas. The eligibility for Virtual Account is subject to final determination by the banking partner and MatchMove. </p>
            <p><strong>Part 2: Wallet and Services</strong></p>
            <p>Use of the Wallet and Services.</p>
            <p>3.1 Use of Wallet and Services are subject to the terms and conditions provided herein as may be amended from time to time. You signify agreement with these terms and conditions and assume liability for any and all charges and fees incurred on Wallet and Services, whether authorized or unauthorized. Wallet is not a credit card; hence usage shall be dependent on the funded balance linked to the Wallet.</p>
            <p>3.1.1 Use of Wallet and Services may be the part of the FAST bank transfer and/or Virtual Account transfer in Singapore.&nbsp;</p>
            <p>3.2 When you use the Wallet and Services, you are authorizing us to act as your agent only with respect to holding, sending, or receiving available balance.</p>
            <p>We may without prior reference to you, combine or consolidate any number of your accounts in your country or overseas (whether held alone or jointly, or under any style, name or form including trade names of sole proprietorships) and offset credit balances (whether matured or not) in these accounts against any liability.</p>
            <p>You agree that where such combination, consolidation and setoff require any conversion from one currency. into another, we have the right to convert one currency into another in any manner we may determine and at our prevailing rate of exchange.&nbsp;</p>
            <p>You agree that we have your required consent for FAST Transfer and/or Virtual Account transfer unless you notify us in writing within 3 days from the date of the creation of your Wallet. Please note that the FAST Transfer and/or Virtual Account transfer has associated fee and the FAST Transfer once applied is not reversible.&nbsp;</p>
            <p>3.3 Prohibitions</p>
            <p>You agree not to use&nbsp;Wallet and Services for (a) purchase of items or goods for which their importation into your residence country is disallowed or (b) or transfer and transfer above the prescribed limit of the Recipient&rsquo;s Country&rsquo;s applicable laws.</p>
            <p>You confirm that you shall not use Wallet and Services to conduct transactions in connection with any prohibited activities under applicable laws.</p>
            <p>Notwithstanding anything to the contrary herein we may limit the number of your purchases, transfers, and the transfer limit, and Services for transfer that may be approved in one day. If we detect any unusual or suspicious activity on the use of your Wallet, we may require you to contact us or temporarily suspend your privileges until we can verify the activity.</p>
            <p>Validity, replacement and refund.</p>
            <p>4.1 Unless terminated or cancelled earlier, Wallet and Services shall be valid for a period of time defined by us. Renewal or replacement of the Wallet will be at our sole discretion. Your Wallet shall be valid for the period (&ldquo;<strong>Validity Period</strong>&rdquo;) stated on the wallet and shall expire on the first day of the following month. You agree and understand that the Validity Period may not be extended.</p>
            <p>4.2 Inactive Wallet: where no access or payment transactions are made on Wallet for a continuous period of time exceeding that of which is mentioned in the fees and charges page on our website, and there is valid balance in Wallet, we may notify you by any notice methods mentioned herein and give you the option of maintaining your Wallet operational. If you do not respond to the notice within the given time noticed by us, from time to time, a dormancy fee can be charged and deducted from the remaining stored value on Wallet and thereafter for every month for which the Wallet remains inactive.</p>
            <p>4.3 If there is any unused balance in an Inactive Wallet when it expires, you shall no longer be entitled to use that unused balance and we may terminate this wallet and transfer the unused balance in accordance with applicable laws and may retain if permitted by law and/or report any unclaimed balance to MAS in accordance with the applicable laws.</p>
            <p>4.4 We shall not be obligated to process any refund of the available balance on the Wallet before the expiry date of the card. We shall only be obligated to refund you the remaining balance of your Wallet, if any, under the following conditions:</p>
            <p>A refund request is made to us in writing or when you contacted our helpdesk by calling +65 6271 1371.</p>
            <p>A refund processing fee shall apply for each refund (refer to FAQ in the Website) and shall be deducted from the remaining balance stored on Wallet. We shall only be obligated to process a refund if the remaining balance exceeds the refund processing fee. If there is insufficient balance to cover the refund processing fee, we shall notify you in writing of the unsuccessful refund; and</p>
            <p>Any refund shall be transferred to your registered bank account or any bank account notified to us by you. Upon any unsuccessful transfer, we shall be entitled to charge an additional transfer fee.</p>
            <p>Compromise of Wallet.</p>
            <p>5.1 The security and proper care of your Wallet, as well as the confidentiality of your security code shall be your sole responsibility. You accept that you must not give the Wallet account details to others or allow them to use it for charges, identification or any other purpose. If you do so, you will be liable for all charges made with the wallet as a result.</p>
            <p>5.2 You shall be relieved from any financial exposure resulting from fraudulent or unauthorized use of the Wallet and related services from the time the report of compromise of your Wallet account is received by us from the concerned person. Prior to the receipt of such a report, you expressly agree to be held liable to us for any and all transactions, purchases, transfers and charges made or incurred from the use of the compromised Wallet and Services. Should you fail to immediately report to us the compromise of your Wallet account upon discovery, we or our affiliated merchants shall be rendered free and harmless from any and all liabilities arising out of the Wallet account&rsquo;s compromise.</p>
            <p>5.3 You will be required to provide your name, address, Wallet number, identification document and other details for identification purposes. You agree to provide us all information and assistance reasonably requested in order to make a timely and complete investigation of the compromise, and we reserve the right to investigate the compromise. If your wallet&nbsp;is lost or stolen, we may issue you a replacement wallet account with a value equal to the available balance on your wallet at the time you notified us of the compromise, at our sole discretion.</p>
            <p>Reload and transaction.</p>
            <p>6.1 Subject to our approval, we may permit a one-time top-up of up to an amount of money as set forth in fees and charges table while your Wallet registration is being processed.</p>
            <p>6.2 Your Wallet is a reloadable account where you have completed all registration requirements as may be determined by us, meaning Know Your Customer (&ldquo;KYC&rdquo;) validated.</p>
            <p>6.3 Where Wallet has been designated as a reloadable account, the maximum remaining balance on the Wallet shall be up to a specific amount as set forth at our own discretion. You can receive, spend, transfer, remit or of if applicable FAST Transfer/and or Virtual Account transfer up to a limited amount per day and per month as defined in the fees and charges table on Website and where applicable stipulated by the relevant laws and regulations, i.e. Payments Services Act 2019. For example, for an individual, the transfer amount that has been transferred by you (whether pursuant to a single or multiple transaction requests) is subject to a daily limit of SGD 5,000 (Five Thousand Singapore Dollars) per transaction and not exceeding SGD 30,000 (Thirty Thousand Singapore Dollars) per annum worth of total transactions. Each time you use your Wallet, the amount of the transaction will be debited from your Wallet&rsquo;s&nbsp;available balance. You agree not to make a purchase, transfer or FAST Transfer or remit or other transaction in excess of your Wallet&rsquo;s available balance. We have the right to reject all of your requests to make any purchase, transfer or remit or any other transaction or if a fee deducted from your available balance exceeds the available balance on your Wallet.&nbsp;</p>
            <p>6.4 Transactions are indicated as pending, completed, failed or cancelled.</p>
            <p>&ldquo;pending&rdquo; indicates that a transaction is currently undergoing review by us and may not be completed or settled, at our sole discretion. &ldquo;pending&rdquo; may also indicate that the transaction is awaiting account confirmation or settlement from a third party.</p>
            <p>&ldquo;completed&rdquo; indicates that a transaction has cleared and the funds are available. If we determine that the transaction is related to a restricted or prohibited business or activity or that the transaction is the result of fraudulent activity, we may reverse or refund the transaction at any time. This refund shall be deposited into your Wallet&nbsp;account.</p>
            <p>&ldquo;cancelled&rdquo; indicates that a transaction has been stopped or reversed. This could be due to inaccurate or incomplete information about your wallet account, our inability to validate the legitimacy of a payment, or merchant, or another proprietary reason. You may contact us for more information. Although payments are cancelled, the removal of an authorization on a return of funds to a member&rsquo;s Wallet account may not be immediate, and we cannot guarantee availability within a specific timeframe.</p>
            <p>&ldquo;failed&rdquo; indicates that a transaction could not be processed due to inaccurate wallet account information, insufficient funds, or another transaction related reason.</p>
            <p>Transfer Service</p>
            <p>7.1 For Senders</p>
            <p>These terms and conditions (&ldquo;<strong>Terms</strong>&rdquo;) apply to and regulate your use of wallet&rsquo;s&nbsp;transfer service or Remittance Services (&ldquo;<strong>Transfer Amount Service</strong>&rdquo;). The Transfer Amount Service allows you to request (&ldquo;<strong>Transaction Request</strong>&rdquo;), using the transfer function in your&nbsp;wallet account (&ldquo;<strong>Account</strong>&rdquo;), that we carry out a transaction (&ldquo;<strong>Transfer Amount Transaction</strong>&rdquo;) to transfer an amount or if applicable FAST Transfer and/or Virtual Account transfer an amount as specified by you (&ldquo;<strong>Transfer Amount</strong>&rdquo;) from your Wallet or account to your designated recipient (&ldquo;<strong>Recipient</strong>&rdquo;).</p>
            <p>To use the Transfer Amount Service, you will need to:</p>
            <p>(i) have a valid and active Wallet or account; and</p>
            <p>(ii) have verified your identity (&ldquo;<strong>KYC</strong>&rdquo;) by us.</p>
            <p>If you wish to carry out a transfer amount transaction, you must submit a Transaction Request to initiate such Transfer Amount Transaction. When submitting a transaction request, you shall provide such information as may be requested by us (including the Recipient&rsquo;s contact details); and</p>
            <p>You are responsible for ensuring, and you warrant, the truthfulness, accuracy and completeness of all information provided in respect of such transaction request, including but not limited to the Recipient&rsquo;s contact details.&nbsp;</p>
            <p>Once a transaction request has been credited into the Recipient&rsquo;s account, you will not be able to withdraw, cancel or make any changes to such Transaction Request.</p>
            <p>We shall be entitled, at our absolute discretion, to reject or refuse to accept or process or to cancel any Transaction Request or Transfer Amount Transaction and shall not be required to give any reason for the same.</p>
            <p>Following the acceptance by us of a Transaction Request, the Recipient will, depending on the contact details provided by you, be notified by email, or through SMS, that you have transferred the credit to the Recipient&rsquo;s account.</p>
            <p>We may, in our absolute discretion, alter any limit or impose additional limits on the Transfer Amount(s) that may be transferred by you, whether on a per transaction basis or an aggregated basis, without prior notice to you.</p>
            <p>You acknowledge and agree that you are responsible for providing the correct Recipient&rsquo;s contact details to enable the Transfer Amount Transaction into the Recipient&rsquo;s account and ensuring that the Recipient safekeeps and maintains the confidentiality of his own account.</p>
            <p>You hereby instruct and authorise us to debit your account and transfer or if applicable FAST Transfer or procure the transfer of the Transfer Amount to the Recipient&rsquo;s account.</p>
            <p>The recipient must have a valid Wallet or account. The recipient must request an OTP to enable the Transfer Amount Transaction to be credited into his Wallet or account, must comply with such instructions as may be prescribed by us and have the KYC process done in order for the transfer amount to be credited into his/her Wallet or account.</p>
            <p>If the Transfer Amount Transaction is unsuccessful but the Transfer Amount has been debited from your Wallet or account, we shall arrange for the Transfer Amount to be credited back to your Wallet or account.</p>
            <p>You acknowledge and agree that, for the purposes of the Transfer Amount Service, Matchmove will be accessing and using the information in your Wallet or account, and you hereby consent to Matchmove accessing and using such information for the provision of the Transfer Amount Service and authorise us to contact your designated Recipients using such information (including through private emails via your Wallet or account) on your behalf.</p>
            <p>We reserve the right to impose charges or to revise at any time such charges for the use of the Transfer Amount Service, FAST Transfer, Virtual Account transfer and/or the account upon written notice to you. Such charges or revisions shall take effect from the date stated in the notice. Where you continue to use the account or submit any transaction request after such notification, you shall be deemed to have agreed to and accepted such charges or revisions to such charges.</p>
            <p>We expressly exclude any guarantee, representation, warranty, condition, term or undertaking of any kind, whether express or implied, statutory or otherwise, relating to or arising from the use of, the Transfer Amount Service or in relation to the processing of or any other matter relating to any Transaction Request or Transfer Amount Transaction. Without prejudice to the foregoing, the acceptance by us of your submission of a Transaction Request does not amount to a representation or warranty by us that it will, and does not impose any obligation on us to, process the Transfer Amount Transaction or transfer the transfer amount to the Recipient and we do not represent or warrant that:</p>
            <p>(i) the Transfer Amount has been successfully transferred to the Recipient&rsquo;s account; or</p>
            <p>(ii) the Transfer Amount has not been credited or has failed to be credited to the Recipient&rsquo;s account within 7 days from the date of the Transaction Request.</p>
            <p>(iii) the Transfer Amount Service will meet your requirements;</p>
            <p>(iv) the Transfer Amount Service will always be available, accessible, function or interoperate with any network infrastructure, system or such other services as wallet may offer from time to time; or</p>
            <p>(v) your use of the Transfer Amount Service or Wallet&rsquo;s processing of any Transaction Request or Transfer Amount Transaction will be uninterrupted, timely, secure or free of any virus or error.</p>
            <p>You acknowledge and agree that, to the extent permitted under applicable law, we shall not be liable to you for any and all losses, liabilities, costs, expenses, damages, claims, actions or proceedings of any kind whatsoever (whether direct, indirect or consequential) in respect of any matter of whatever nature and howsoever arising (whether in contract, tort, negligence or otherwise) in connection with:</p>
            <p>(i) the provision by us of or your use of the Transfer Amount Service and/or the Wallet or account;</p>
            <p>(ii) the processing of any Transaction Request or Transfer Amount Transaction;</p>
            <p>(iii) any Transfer Amount Transaction being unsuccessful or considered as unsuccessful or any Transfer Amount not having been transferred to the Recipient&rsquo;s wallet or account or transferred to a recipient other than the intended recipient, whether or not arising from: your negligence, misconduct or breach of any of these terms and conditions (including as a result of inaccurate information being provided by you);</p>
            <p>(iv) any failure, refusal, delay or error by any third party or third party system through whom or which any transfer amount transaction is made;</p>
            <p>(v) any unauthorised access of the Wallet or account;</p>
            <p>(vi) the use in any manner and/or for any purpose whatsoever by any person at any time whatsoever and from time to time of any information or data: (a) relating to you and/or any Recipient; (b) transmitted through your use of the Transfer Amount Service and/or the Wallet or account; and/or (c) obtained through your use of the Transfer Amount Service and/or the Wallet or account;</p>
            <p>(vii) any event the occurrence of which we are not able to control or avoid by the use of reasonable diligence; and/or</p>
            <p>(viii) the suspension, termination or discontinuance of the Transfer Amount Service.</p>
            <p>To the extent that any of the limitations set out above are not permitted by law, our liability to you arising from or in respect of each Transfer Amount Transaction, whether in contract, tort (including negligence or breach of statutory duty) or otherwise for any and all losses, damages or liabilities caused or arising from or in relation to our provision of the Transfer Amount Service and/or the Wallet or account and/or under or relating to these terms shall not exceed the value of such Transfer Amount Transaction.</p>
            <p>You represent and warrant that, in relation to each Transfer Amount Transaction, you have obtained the necessary consent of the relevant Recipient to disclose such Recipient&rsquo;s Personal data (including contact details) to us and for us to use and disclose such Recipient&rsquo;s Personal data (including contact details to contact the Recipient in the manner set out in paragraph 6) as required for the purposes of the Transfer Amount Service.</p>
            <p>We shall be entitled to exercise any of its rights and remedies under these terms and conditions governing online transaction services (including the right to withdraw, restrict, suspend, vary or modify Transfer Amount Service (whether in whole or in part).</p>
            <p>We will refund to you any benefit which we receive as a result of any breach of our agreement with you (this means that, for example, where a money transfer has failed in such circumstances we will refund to you the Transaction Amount and the Fees). We do not, in any event, accept responsibility for:</p>
            <p>any failure to perform your instructions as a result of circumstances which could reasonably be considered to be outside our control;</p>
            <p>malfunctions in communications facilities which cannot reasonably be considered to be under our control and that may affect the accuracy or timeliness of messages you send to us;</p>
            <p>any losses or delays in transmission of messages arising out of the use of any internet service provider or caused by any browser or other software which is not under our control; and</p>
            <p>errors on the website or with the Services caused by incomplete or incorrect information provided to us by you or a third party.</p>
            <p>7.2 For Recipients</p>
            <p>These terms and conditions (&ldquo;<strong>Terms</strong>&rdquo;) apply to and regulate your use of our virtual transfer amount service (&ldquo;<strong>Transfer Amount Service</strong>&rdquo;) to receive a specified amount of funds (&ldquo;<strong>Transfer Amount</strong>&rdquo;) from a customer (&ldquo;<strong>Sender</strong>&rdquo;) of MatchMove who has requested Wallet to transfer or remit the Transfer Amount to you (such request to transfer or remit, your request to receive and the transfer or remit to you of the Transfer Amount, the &ldquo;<strong>Transfer Amount Transaction</strong>&rdquo;). You are required to accept and agree to these terms and conditions before you will be able to receive the Transfer Amount which is the subject of the Transfer Amount Transaction.</p>
            <p>You may only receive the Transfer Amount by submitting a request using:</p>
            <p>(i) the unique URL which may be accessed via the email or SMS provided to you informing you of the Transfer Amount Transaction; or</p>
            <p>(ii) such other manner as may be prescribed by us in the email or SMS provided to you informing you of the Transfer Amount Transaction, and by complying with such instructions as may be set out therein.</p>
            <p>In order to receive the Transfer Amount, you must:</p>
            <p>(i) have a wallet account; and</p>
            <p>(ii) have passed the KYC process by us.</p>
            <p>You are responsible for ensuring, and you warrant, the truthfulness, accuracy and completeness of all information provided in your request to receive the transfer amount and/or when using the Transfer Amount Service, including but not limited to the details of your designated wallet account. In addition, you represent and warrant that you are the intended Recipient of the Transfer Amount and that you are the legal and beneficial holder of the designated wallet account to which the Transfer Amount is to be credited.</p>
            <p>We shall be entitled, at our absolute discretion, to reject or refuse to process any request to receive the Transfer Amount and shall not be required to give any reason for the same.</p>
            <p>Following verification of OTP, we shall endeavor to credit the Transfer Amount to your wallet account.</p>
            <p>If the Transfer Amount Transaction cannot be or is not completed for any reason, we shall not be liable to you in respect of the incomplete transfer amount transaction, including without limitation, your nonreceipt of the Transfer Amount.</p>
            <p>We expressly exclude any guarantee, representation, warranty, condition, term or undertaking of any kind, whether express or implied, statutory or otherwise, relating to or arising from the use of the Transfer Amount Service or in relation to the processing of or any other matter relating to a request to receive the Transfer Amount. Without prejudice to the foregoing, your possession or provision of the security code does not amount to a representation or warranty by us that it will, and does not impose any obligation on us to, credit the Transfer Amount to your wallet account and we do not represent or warrant that:</p>
            <p>(i) the one-time password (&ldquo;<strong>OTP</strong>&rdquo;) sent to your mobile is invalid or has ceased to be valid; or</p>
            <p>(ii) any information provided by you is invalid or incorrect;</p>
            <p>(iii) the Transfer Amount Service will meet your requirements;</p>
            <p>(iv) the Transfer Amount Service will always be available, accessible, function or interoperate with any network infrastructure, system or such other services as we may offer from time to time; or</p>
            <p>(v) your use of the Transfer Amount Service or our processing of a request to receive the Transfer Amount will be uninterrupted, timely, secure or free of any error, including without limitation that you will receive the transfer amount or that you will receive the Transfer Amount in a timely manner.</p>
            <p>You acknowledge and agree that, to the extent permitted under applicable law, we shall not be liable to you for any and all losses, liabilities, costs, expenses, damages, claims, actions or proceedings of any kind whatsoever (whether direct, indirect or consequential) in respect of any matter of whatever nature and howsoever arising (whether in contract, tort, negligence or otherwise) in connection with:</p>
            <p>(i) the provision by us of or your use of the Transfer Amount Service;</p>
            <p>(ii) the processing of a request to receive the Transfer Amount;</p>
            <p>(iii) any Transfer Amount Transaction being considered as incomplete or any Transfer Amount failing to be credited to your designated wallet account or transferred to a recipient other than you, whether or not arising from: (a) your negligence, misconduct or breach of any of these terms (including as a result of inaccurate information being provided by you); or (b) any failure, refusal, delay or error by any third party or third party system through whom or which the transfer amount transaction is made;</p>
            <p>(iv) the use in any manner and/or for any purpose whatsoever by any person at any time whatsoever and from time to time of any information or data relating to you, provided by you in the course of using the Transfer Amount Service; and/or obtained through your use of the Transfer Amount Service;</p>
            <p>(v) any event the occurrence of which wallet is not able to control or avoid by the use of reasonable diligence; and/or</p>
            <p>(vi) the suspension, termination or discontinuance of the Transfer Amount Service.</p>
            <p>We shall not be liable and you agree to indemnify use and keep us and our payment services indemnified against any consequences, claims, proceedings, losses, damages or expenses (including all legal costs on an indemnity basis) whatsoever and howsoever caused that may arise or be incurred by us in providing the Transfer Amount Service, whether or not arising from or in connection with and including but not limited to the following:</p>
            <p>(i) any improper or unauthorised use of the Transfer Amount Service by you;</p>
            <p>(ii) any act or omission by any relevant mobile or internet service provider;</p>
            <p>(iii) any delay or failure in any transmission, dispatch or communication facilities;</p>
            <p>(iv) any access (or inability or delay in accessing) and/or use of the Transfer Amount Service; or</p>
            <p>(v) any breach by you of any warranty under or provision of these terms.</p>
            <p>7.3 to the extent that any of the limitations set out above are not permitted by law, our liability to you arising from or in respect of the Transfer Amount Transaction, whether incontract, tort (including negligence or breach of statutory duty) or otherwise for any and all losses, damages or liabilities caused or arising from or in relation to the Transfer Amount Service and/or under or relating to these terms shall not exceed the value of the Transfer Amount Transaction.</p>
            <p>Transaction history.</p>
            <p>You may access the wallet app or&nbsp;website to view the transaction history. You acknowledge and agree that we are not under any obligation whatsoever to issue a monthly statement to you detailing the transactions conducted by you through the wallet system.</p>
            <p>Disputes and erroneous transactions.</p>
            <p>9.1 The details in the SMS/email confirmation message after every transaction and/or the entries in the transaction history are presumed true and correct unless you notify us in writing of any disputes thereon within twenty- four (24) hours from the time of transaction. If no dispute is reported within the mentioned period, all transactions and the entries in the transaction history are considered conclusively true and correct.</p>
            <p>9.2 Disputed transactions shall only be credited back to your Wallet once the claim/dispute has been properly processed, investigated, and there has been a clear finding that you are entitled to the credit.</p>
            <p>Multi Currency Wallet Terms.</p>
            <p>10.1 Where a Wallet is opened as a Multi-Currency Wallet, the following terms shall also apply: (a) you are permitted to deposit any Permitted Foreign Currency into the Multi-Currency Wallet without such Permitted Foreign Currency being converted to another currency; (b) in the event you wish to deposit Foreign Currency that is not a Permitted Foreign Currency into the Multi-Currency Wallet, you authorise us to convert such Foreign Currency into such Permitted Foreign Currency (based on such rates as may be determined by us from time to time) as we deem appropriate in our absolute discretion and deposit such funds into the Multi-Currency Wallet; (c) only one mandate should be provided for each Multi-Currency Wallet. If any Instructions are given to us are not denominated in the Mandate Currency, you authorise us to convert such Instructions into the Mandate Currency (based on such rates as may be determined by us from time to time) as we deem appropriate in our absolute discretion and we shall have the absolute discretion whether to act on such Instructions; and (d) in the event where there is more than one Mandate Currency and any Instructions are given to us are not denominated in any of the Mandate Currencies, you authorize us to convert such Instructions into any of the Mandate Currencies at our absolute discretion (based on such rates as may be determined by us from time to time) as we deem appropriate in our absolute discretion and we shall have the absolute discretion whether to act on such Instructions.</p>
            <p>10.2 The issuance and use of the Multi Currency Wallet is subject to compliance by the Account Holder of the provisions of applicable laws including but not limited to Exchange Control Act, rules, regulations and directions issued by MAS and/or other appropriate authority under any law or force or any amendments thereto from time to time. 10.3 The Multi Currency Wallet may be used, within the foreign entitlements as stipulated by MAS from time to time, by Account User going abroad for personal or business expenses, provided the total exchange drawn during the trip abroad does not exceed the entitlement. Import of goods so purchased abroad into Singapore, would be governed by the baggage rules/EXIM policy in force. The Multi Currency Wallet cannot be used for effecting remittances for which the release of exchange is not permissible under the extant regulations.</p>
            <p><strong>Part 3: General</strong></p>
            <p>Applications and activation. To help the government fight terrorism and money laundering, the applicable laws require us to obtain, verify and record information that identifies each person who signs up for an account with us to use Wallet and Services. What this means for you: when you sign up for an account, we will ask for your name, address, date of birth, nationality, an identification number and other identification documents that local applicable laws require us to obtain. We appreciate your understanding and cooperation.</p>
            <p>11.1 Only qualified members can use Wallet and Services by registering online at website;</p>
            <p>11.2 You represent and warrant to us that you:</p>
            <p>Are at least eighteen (18) years of age;</p>
            <p>Are eligible to register and use Wallet and Services and have the right, power, and ability to enter into and perform under these terms and conditions;</p>
            <p>Provide us with true, accurate, current and complete evidence of your identity, and promptly update your personal (for an individual) or corporate (for corporate customers) information if and when it changes. Upon application, you must fill up and complete the account opening form all requested details that establish your identity. As such, necessary documents, hard or soft copies must be provided to us to facilitate the account opening procedure. If you are a business entity, an Authorised Representative must establish an enterprise account on your behalf.</p>
            <p>Will update your personal (for an individual) or corporate (for corporate customers) information, including current email address; and&nbsp;</p>
            <p>Will not impersonate any other person, operate under an alias or otherwise conceal your identity. We will use the information provided by you, as well as the information that we receive from the use of the Wallet and Services, to facilitate your participation in the services and for other purposes consistent with our privacy policy.</p>
            <p>11.3 It is paramount that all funds deposited or transferred by you from time to time, are of legal origin, are not the proceeds of crime, including but not limited to, proceeds of drugs trafficking or dealings in other prohibited substances or proceeds of illegal arms trades, illegal gambling, prostitution, terror funds etc.&nbsp;</p>
            <p>11.4 We may, from time to time, at our sole discretion, require you to provide proof of identity (such as notarized copy of passport or other means of identity verification as we deem required under the circumstances) and may at our sole discretion suspend an account until such proof has been provided to our satisfaction.</p>
            <p>11.5 Necessary documentation to authenticate authorized personnel will be required from time to time and we reserved the right to seek further clarification and of such clarification cannot be obtained for any reason or whatsoever, we are entitled not to act on conflicting and incomplete instructions.</p>
            <p>11.6 Transaction limits. We may, from time to time, impose transaction limits (such as minimum transaction amounts) in connection with the Wallet or Services or vary the frequency or manner of use of the services.</p>
            <p>Fees.</p>
            <p>12.1 To be entitled to remittance services, wallet privileges and benefits, including related services, you shall pay the fees we may require. We reserve the right to and may at our sole discretion, charge and revise from time to time the privileges and benefits under Wallet or Services, including related fees. We shall, in most cases, give reasonable notice to you of the rates and other relevant information on any fees before they become effective provided that the variation is within our control. We reserve the right to amend charges of fees/commission and the continued use of the Wallet and Services shall be taken as acceptance by you of the terms, benefits and fees.</p>
            <p>12.2 Fees for services: in consideration of our agreement to provide the services, you shall pay to us the fees set out in (i) the estimate provided by us to you if and when you submit a request to us for such estimate; and (ii) the applicable notification by email/SMS from us and/or the Website (all fees set out therein to be collectively referred to as the &ldquo;<strong>Fees</strong>&rdquo;).</p>
            <p>12.3 You hereby acknowledge and agree that you shall be liable to pay any and all the surcharges or special fees or Network fees or assessments for transactions that are executed through your Wallet and/or Card including the transactions related to overseas transactions, multicurrency wallet, transit travel, fuel transactions, no_auth which could particularly impact us and significantly increase our costs on account of the Wallet and/or Card. </p>
            <p>12.4 All paid annual fees are non-refundable even if the privileges are suspended or terminated, or even if you cancel your account with us before its expiry date.</p>
            <p>12.5 If a transaction is made in a foreign currency (not the national currency of the place where the wallet is issued), we shall convert the transaction amount into the local currency according to our usual practice. Unless a particular rate is required by applicable law, you authorize us to choose a conversion rate that is acceptable to us for that date; provided that if the Wallet is a Multi Currency Wallet, Payment will be first drawn from those Multi Currency Wallet(s) which match the advertised currency of settlement of the merchant for such goods or services; further provided that in the event of insufficient balance in the relevant Multi Currency Wallet, the outstanding amount shall be settled in Singapore Dollars at a rate acceptable to us for that date.&nbsp;</p>
            <p>12.6 We may decline your transaction, if there are insufficient balance held in both Singapore Dollars and/or Permitted Foreign Currency within the Wallet and/or Multi Currency Wallet(s) to pay for a transaction. You are solely responsible for determining the balance held in Singapore Dollars and/or Permitted Foreign Currency, and for ensuring you have sufficient funds for all Wallet transactions.&nbsp;</p>
            <p>12.7 We may disclose the exchange rate when a currency conversion is performed by us. You must indemnify us for any shortfall and be responsible for any losses and risks arising from the conversion.</p>
            <p>12.8 Currently, the conversion rate we use for a transaction in a foreign currency is no greater than (a) the highest official conversion rate published by a government agency, or (b) the highest interbank conversion rate identified by us from customary banking sources on the conversion date or the prior business day. This conversion rate may differ from rates in effect on the day of your transactions.</p>
            <p>12.9 Mode of payment: all payments made hereunder shall be made in accordance with our instructions including instructions regarding the mode of payment and the currency of payment and shall be made net of all bank charges (including any charges which may be levied by the receiving bank), which shall be borne by you. Unless otherwise stipulated by us, all payments hereunder to us shall be made in Singapore dollars.</p>
            <p>12.10 Taxes &amp; charges: the fees are exclusive of all or any taxes, duties and charges imposed or levied by the appropriate local or overseas governmental agencies, financial institutions or other third parties in connection with the services or otherwise pursuant to these terms and conditions (including any applicable sales, use, value-added, transaction, goods and services or other similar taxes goods and services tax), all of which shall be borne by you. In the event that any amounts payable by you to us under these terms and conditions are subject to any withholding tax, you shall deduct such withholding taxes from payments due to us and forward the balance to us. You shall provide the necessary supporting documentation to us of the payment of such withholding taxes to enable us to obtain the credit for such tax payment in its country of incorporation.</p>
            <p>Your information and consent.</p>
            <p>13.1 We may be required by law to provide information about you and your transactions to government or other competent authorities as described in our privacy policy. You acknowledge and consent to our doing this.</p>
            <p>13.2 Singapore law requires all financial institutions to assist in the fight against money laundering activities and the funding of terrorism by obtaining, verifying, and recording identifying information about all customers. We may therefore require you to supply us with personal identifying information and we may also legally consult other sources to obtain information about you. In addition to the information that you submit, our systems are programmed to gather certain anonymous data to help us understand how the services are being used and how we can improve it. This automatically gathered data includes your computer&rsquo;s IP or &ldquo;internet protocol&rdquo; address, statistics about how visitors navigate through the services, and information provided through the use of &ldquo;cookies&rdquo;.</p>
            <p>13.3 Verification and checks. We will verify your residential address and personal details in order to confirm your identity. We may also pass your personal information to a third- party service provider(s), which may keep a record of that information. You can be rest assured that this is done only to confirm your identity. All information provided by you will be treated securely and strictly in accordance with applicable laws.</p>
            <p>13.4 By accepting these terms and conditions you authorise us (a) to deduct Fee and any balance unpaid amount due to us and in particular mentioned supra para 12.3 from your Wallet and/or Account; and (b) to make any inquiries we consider necessary to validate the information that you provide to us. We may do this directly, for example by asking you for additional information, requiring you to take steps to confirm ownership of your billing address or email address; or by verifying your information against third party databases; or through other sources.</p>
            <p>13.5 Privacy policy. You consent to our processing your personal information for the purposes of providing the service, including for verification purposes as set out in this clause. You also consent to the use of such data for communicating with you, and for statutory, accounting and archival purposes. You acknowledge that you have read and consented to our privacy policy. The privacy policy can be found by clicking here: <a href="about:blank">https://www.matchmove.com/privacy-policy/</a></p>
            <p>13.6 You agree that we may (a) record telephone conversations between you and us and (b) use such recordings, or electronic transcripts from such recordings, as evidence in any dispute or anticipated dispute. To ensure quality of customer service, you agree to allow us to monitor telephone calls between you and us from time to time.</p>
            <p>13.7 Consent to disclosure &amp; provision of information. Information relating to any access or use of the services or which have been collected from you may be shared by us with our service providers, agents or business partners and affiliates that we work with in connection with our business. Specifically, the information may be used for the following purposes and you consent to us and/or our personnel disclosing any information, whether in Singapore or otherwise:</p>
            <p>To any of our agents, subcontractors, partners or affiliates which have a legitimate business purpose for obtaining such information, including offering you products or services in connection with or to facilitate the use of the services;</p>
            <p>To any of our agents, personnel, subcontractors or any other third party as we may consider necessary in order to give effect to any electronic instructions or transaction or to comply with any order or request of any court or governmental or regulatory authority in any jurisdiction;</p>
            <p>To any of the following where such disclosure is reasonably regarded by us to be necessary to complete any transaction or to carry out the electronic instructions:</p>
            <p>To any person using the services purporting to be you; and</p>
            <p>To any information gathering or processing organisation or department conducting survey(s) on our behalf; and</p>
            <p>To third parties for the purpose of statistical or trade analysis.</p>
            <p>Electronic instructions and your responsibilities</p>
            <p>14.1 Security code</p>
            <p>Access to and use of password protected and/or secure areas or features of the services are restricted to authorised users only. You may not obtain or attempt to obtain unauthorised access to such parts of the services, or to any other protected information, through any means not intentionally made available by us for your specific use.</p>
            <p>You will be required to select or designate a password, personal or logon identification number and other code and/or adopt certain access or security procedures (such items to be referred to as &ldquo;security code&rdquo;) for use in connection with access to the services or use of the facilities provided through the services. You hereby agree to keep the security code confidential. You are to notify us immediately if you have knowledge that or have reason for suspecting that the confidentiality of the security code has been compromised or if there has been any unauthorised use of the security code.</p>
            <p>You agree to be bound by any access or use of the services (whether such access or use are authorised by you or not) which are referable to your security code. You agree and acknowledge that any use of or access to the services referable to your security code and any electronic instructions shall be deemed to be, as the case may be:</p>
            <p>(I) use of or access to the services by you; or</p>
            <p>(II) electronic instructions transmitted or validly issued by you. You therefore agree and acknowledge that any use of or access to the services and any information or data referable to your security code shall be deemed to be (a) use or access of the services by you; or (b) information or data transmitted or validly issued by you and you agree to be bound by any access or use (whether such access or use are authorised by you or not) referable to your security code, and you agree that we shall be entitled (but not obliged) to act upon, rely on or hold you solely responsible and liable in respect thereof as if the same were carried out or transmitted by you.</p>
            <p>14.2 We are under no obligation to investigate the authenticity or authority of persons effecting the electronic instructions or to verify the accuracy and completeness of the electronic instructions. Accordingly, we may treat the electronic instructions as your authentic and duly authorised instructions which are valid and binding on you notwithstanding any error, fraud, forgery, lack of clarity or misunderstanding in the terms of the electronic instructions.</p>
            <p>14.3 Any risk of misunderstanding, error, loss, damage or expense resulting from the use of the services are entirely at your own risk and we shall not be liable therefor.</p>
            <p>14.4 All electronic instructions will be deemed to be irrevocable and unconditional upon transmission through the services and we shall be entitled (but not obliged) to effect, perform or process such electronic instruction(s) without your further consent and without any further reference or notice to you. Nevertheless, in certain circumstances you may request to cancel or amend the electronic instructions which we shall endeavour to give effect to on a commercially reasonable effort basis. However, notwithstanding the foregoing, we are not obliged to give effect to any request to cancel or amend any electronic instruction.</p>
            <p>14.5 You acknowledge and agree that:</p>
            <p>We may at any time at our sole discretion and without stating reasons, require that you identify yourself by alternative means; require any electronic instructions to be confirmed through alternative means (in writing given in person at a branch, etc.);</p>
            <p>We may at any time decline to act on the electronic instructions at any time without prior notice or giving any reason, including to: (without incurring any responsibility for loss, liability or expense arising out of so declining to act refrain from acting promptly upon any electronic instructions) (a) verify the authenticity thereof (b) decline to act on the electronic instructions where they are ambiguous, incomplete or inconsistent with your other electronic instructions or instructions, information and/or data (c) decline to act where any electronic instructions would cause you to exceed your applicable transaction limits; or (d) decline to act on the electronic instructions where we did not receive authorisations from other banks or financial institutions in the form required by use; and</p>
            <p>Electronic instructions may not be processed immediately, around the clock or in a timely manner, and that we shall not be liable for any loss, damage or expense arising from any delay in processing or executing any electronic instructions.</p>
            <p>14.6 Notwithstanding any provision to the contrary in these terms and conditions, you acknowledge and agree that in the event that we discover or have been notified by the service providers that the funds to be or which have been sent under the services are to be or have been debited from any other internet banking account or card account you may have other than your internet banking account and/or your card account maintained with us, we may at any time, at our sole discretion and without stating reasons or notice, reverse any or all electronic instructions which you have transmitted or issued to, and which have been received by, us and/or impose an administration processing fee for such reversal and/or terminate these terms and conditions and your right to use the services.</p>
            <p>14.7 You hereby agree to indemnify and render us, our directors, officers, employees, agents and assign free and harmless from and against any claim, cause of action, suit, liability, and loss or damage of whatever nature that may arise as a result of or in connection with the use of your account and the transactions made with it in the following instances: (a) disruption, failure, or delay relating to or in connection with the use of your account due to circumstances beyond our control; fortuitous events such as, but not limited to, prolonged power outages, breakdown in computers and communication facilities, computer related errors, system errors, system enhancements, system migration and integration, typhoons, floods, public disturbances and calamities, any action taken by a Government or public authority including imposing embargo, export or trade restriction or other restriction or prohibition caused by a &ldquo;Epidemic&rdquo; or &ldquo;Pandemic&rdquo; outbreak, riots, strikes and work stoppages, delays by third-party vendors, any acts of Gods and unforeseen circumstances and other similar or related cases; (b) fraudulent or unauthorized utilization of your account due to unauthorized disclosure, or breach of its security or confidentiality with or without your participation; or (c) inaccurate, incomplete, or delayed information received by us due to disruption or failure of any communication facilities or electronic device used for your account.</p>
            <p>Suspension, cancellation, termination, nonrenewal.</p>
            <p>Your account and/or the Wallet, Services or any part thereof may be cancelled by us at any time without prior notice to you. After cancellation, account and/or the Wallet, Services or any part thereof may be reinstated in such manner and on these terms and conditions as we may at our absolute discretion determine.</p>
            <p>15.1 Discretion. Without giving any reason or prior notice, and without prejudice to the other provisions in these terms and conditions, we have absolute discretion to: (a) refuse approval of any proposed transaction even if the MatchMove wallet linked to the Wallet has sufficient balance; (b) terminate or cancel your right to use your account,Wallet and Services; (c) increase or decrease the transaction limit; (d) refuse reissuance, renewal, or replacement of Wallet; or (e) introduce, amend, vary, restrict, terminate, or suspend the benefits, related services, facilities, and privileges in respect of or in connection with your account, Wallet.</p>
            <p>15.2 Termination by us notwithstanding anything, we may close and revoke any one or all your account, Wallet and Services, with or without notice to you, if:</p>
            <p>You do not follow our instructions in connection with your Wallet account or Services or you do not comply with any applicable law;</p>
            <p>We believe you have (i) offered, promised or given any bribe; or (ii) agreed to receive or accepted any bribe;</p>
            <p>In our opinion, any Wallet account is not operated in a proper or regular manner;</p>
            <p>In our opinion, a business relationship with you is no longer appropriate;</p>
            <p>You have breached, any term in these terms and conditions, or any other agreement whether relating to the borrowing of funds or the granting of advances or credit or otherwise;</p>
            <p>You have given us untrue, inaccurate, incomplete or misleading information;</p>
            <p>You do not pay on time any amount due to us;</p>
            <p>You pass away or become incapacitated;</p>
            <p>You become insolvent or bankrupt or subject to judicial proceedings;</p>
            <p>Your assets are in jeopardy or subject to enforcement of a judgment by any party;</p>
            <p>Any legal proceeding (civil or legal) or action (including garnishee order, writ of seizure and sale, injunction) is commenced or levied against you, or you have been convicted of a crime, or you or any security provider has acted inappropriately;</p>
            <p>Anything happens which, in our opinion, may have a material or adverse effect on your financial condition, assets or compliance with the terms of these terms and conditions;</p>
            <p>Any other event of default occurs under any other agreements or arrangements between us;</p>
            <p>Any of the events or proceedings under this clause occurs in relation to any third party who has agreed to provide or is providing security;</p>
            <p>It would constitute a breach of our agreement with any other party;</p>
            <p>It is necessary for us to do so in order for us to meet any obligation, either in Singapore or elsewhere in the world, in connection with the prevention of fraud, money laundering, terrorist activity, bribery, corruption or tax evasion, or the enforcement of economic or trade sanctions; or</p>
            <p>It is unlawful for us to continue to provide wallet account or any service or allow the use of the card.</p>
            <p>15.3 You may, at any time, suspend, cancel, terminate or reactivate Wallet in accordance with these terms and conditions by following the required steps specified at http://www.website.com.sg/. You acknowledge that such actions may be charged a fixed amount for such changes.</p>
            <p>15.4 Upon cancellation or termination of your account, Wallet or any part thereof:</p>
            <p>All rights and benefits granted to you shall immediately be terminated and shall revert to us;</p>
            <p>You cannot and must not use your wallet;</p>
            <p>We may at our absolute discretion, decide not to act on any confirmation or request received by you after the effective date of termination;</p>
            <p>We may at our absolute discretion, decide not to act on any confirmation or request received by us between the date of notice given to you and the effective date of termination (if there is a lapse of time between the two dates).</p>
            <p>Intellectual property rights</p>
            <p>16.1 The MatchMove services and all intellectual property relating to them and contained in them (including but not limited to copyrights, patents, database rights, trademarks and service marks) are owned by MatchMove Pay Pte Ltd.</p>
            <p>16.2 The MatchMove services may be used only for the purposes permitted by these terms and conditions or described on this website. You are authorized solely to view and to retain a copy of the pages of the MatchMove website for your own personal use. You may not duplicate, publish, modify, create derivative works from, participate in the transfer or sale of, post on the internet, or in any way distribute or exploit the MatchMove website, the MatchMove services or any portion thereof for any public or commercial use without our express written permission. You may not: (a) use any robot, spider, scraper or other automated device to access the MatchMove website or the MatchMove services; and/or (b) remove or alter any copyright, trademark or other proprietary notice or legend displayed on the MatchMove website (or printed pages of the website). The name MatchMove and other names and indicia of ownership of our products and/or services referred to on the MatchMove website are our exclusive marks or the exclusive marks of other third parties. Other product, service and company names appearing on the website may be trademarks of their respective owners.</p>
            <p>16.3 Wallet and related Services, including services and products are our sole properties and/or its licensors and are subject to our existing policies, rules, and regulations. Wallet and related Services are protected by copyright, trade secret and other intellectual property laws. We and/or our licensors own the title, copyright and other worldwide intellectual property rights in all services relating to Wallet and related Services. Using the wallet and related Services do not grant you any rights to our trademarks or service marks.</p>
            <p>16.4 For the purpose of these terms and conditions, intellectual property rights shall mean all patent rights, copyright rights, moral rights, rights of publicity, trademark, trade dress and service mark rights, goodwill, trade secret rights and other intellectual property rights as may now exist or hereafter come into existence, and all applications and registrations, renewals and extensions thereof, under the laws of Singapore and any country, territory or other jurisdiction.</p>
            <p>16.5 You may choose to, or we may invite you to submit comments or ideas about Wallet and related Services, including but without limitation about how to improve any service or product. By submitting any idea, you agree that your disclosure is gratuitous, unsolicited and without restriction and shall not place us under any fiduciary or other obligations, and that we shall be free to use the idea without any additional compensation to you, and/or to disclose the idea on a nonconfidential basis or otherwise to anyone.</p>
            <p>Notices.</p>
            <p>17.1 All notices and documents required to be given by us to you shall be sent by any one of following methods:</p>
            <p>Ordinary or registered post to your last known address according to our record;</p>
            <p>Electronic mail to your last known electronic mail address according to our record;</p>
            <p>Posting notice or communication on the wallet website;</p>
            <p>Notices placed with or in any of the wallet written communications to you;</p>
            <p>Phone call to your last known phone number according to our records;</p>
            <p>Notices placed through any media; or</p>
            <p>Any manner of notification as we may at our absolute discretion determine.</p>
            <p>17.2 Unless otherwise expressly provided in writing, our notices and communications to you are effective:</p>
            <p>If sent by post to an address within your country, the following business day after posting;</p>
            <p>If sent by post to an address outside of your country, 5 business days after posting;</p>
            <p>If sent by fax, electronic mail or SMS, at the time of transmission;</p>
            <p>If sent by hand, at the time of delivery or when left at the address;</p>
            <p>If posted on our website, on the date of display or posting;</p>
            <p>If advertised in the newspaper, on the date of advertisement; and</p>
            <p>If broadcast via radio or television, on the date of broadcast.</p>
            <p>Promotional offers, advertisements, and surveys.</p>
            <p>You hereby consent that your Personal data will be collected, used and disclosed by us in accordance with the Personal Data Protection Act 2012, for the provision of all services. We may also send you marketing mailers by post or emails or via SMS text, telephone or fax. You hereby consent that we may disclose your Personal data to our business partners and third-party service providers for these purposes. Where there are joint account holders, you confirm that they have consented to our collection, use and disclosure of their Personal data. In this context, you agree that:</p>
            <p>18.1 We may inform you about promotional offers through mail, email, fax, SMS, telephone, or any other means of communication. You agree that we and our affiliates, and third parties selected by any of them can offer specially selected products and services to you through any means of communication provided above. For this purpose, you agree that we may transfer and disclose customer information to our affiliates and third parties selected by any of them.</p>
            <p>18.2 Third parties&rsquo; services and links to other websites. You may be offered services, products and promotions provided by third parties and not by us. If you decide to use these services, you shall be responsible for reviewing and understanding the terms and conditions associated with these services. You agree that we are not responsible for the performance of these services.</p>
            <p>18.3 Our website may contain links to third parties&rsquo; websites as a convenience to you. The inclusion of any website link does not imply an approval, endorsement, or recommendation by us. You agree that you access any such website at your own risk, and that the site is not governed by the terms and conditions contained in these terms and conditions. We expressly disclaim any liability for these websites.</p>
            <p>18.4 If you wish to be excluded from the recipient lists for our promotional offers, advertisements or surveys, or find any incorrect entry in the information held by us or in the information provided by us to third service providers, you have to immediately notify us by contacting our customer service at +65 97250731 (WhatsApp messaging only).</p>
            <p>Consent to broadcast and sending of push messages.</p>
            <p>You agree and consent to the sending by us and our affiliates of broadcast and push messages as well as notices and announcements to you via SMS/email blast. However, should you opt not to receive these messages, you may make a request to that effect by following the optout instructions regularly sent by us to you.</p>
            <p>Compliance.</p>
            <p>You shall comply with all laws and regulations related to the use of stored value facilities and the current anti-money laundering/counterterrorism financing legislation of your residence country or otherwise in the country or territory in which you are present while using the Wallet or Services.</p>
            <p>You shall comply with all laws and regulations related to the use of services and the current anti-money laundering/counterterrorism financing legislation required. For further information on anti-money laundering/counter terrorism financing legislation: <a href="about:blank">https://www.mas.gov.sg/regulation/anti-money-laundering/targeted-financial-sanctions</a></p>
            <p>Limitation of liability.</p>
            <p>The services are provided on an &ldquo;as is&rdquo; and &ldquo;as available&rdquo; basis. We do not warrant (i) the accuracy, adequacy or completeness of the services, and expressly disclaim any liability for errors, delays or omissions in the Website, or for any action taken in reliance on the Website; (ii) that the services will be provided uninterrupted or free from errors, or that any identified defect will be corrected, nor that the services and the Website are free from any computer virus or other malicious, destructive or corrupting code, agent, program or macros. No warranty of any kind, implied, express or statutory, including but not limited to the warranties of noninfringement of third party rights, title, merchantability, satisfactory quality or fitness for a particular purpose, is given in conjunction with the services. In particular, you should be aware that where services are initiated or accessed via SMS, your mobile network operator&rsquo;s confirmation that any SMS you sent has been sent to us or our service provider does not guarantee that we have executed, or even received, the electronic instructions contained within such SMS.</p>
            <p>We may, from time to time and without giving any reason or prior notice, upgrade, modify, suspend or discontinue the provision of or remove, whether in whole or in part, the services or any information, functionalities, services or products provided therein, and shall not be liable if any such upgrade, modification, suspension or removal prevents you from accessing the services or associated information, functionalities, services or products.</p>
            <p>Risks and expenses relating to the use of the internet and other telecommunication systems: we do not warrant the security of any information transmitted by you or to you through the services and you accept the risk that any information transmitted or received through the services may be accessed by unauthorised third parties. Transactions over the internet as well as messages sent via SMS may be subject to interruption, transmission blackout, delayed transmission due to internet traffic or incorrect data transmission due to the public nature of the internet and the SMS system. You shall be responsible for obtaining and using the necessary services, software and/or device, hardware and/or equipment necessary to obtain access to the services at your own risk and expense (including all costs levied by your mobile network operator).</p>
            <p>Exclusion of liability: we shall in no event be liable to you or any other person for any loss, damages or expenses whatsoever or howsoever caused and regardless of the form of action (including tort or strict liability) arising directly or indirectly in connection with any access, use or inability to use the services, or reliance on any materials, even if we or our agents or employees may have been advised of, or otherwise might have anticipated, the possibility of the same. We shall not at any time be liable for indirect or consequential losses, damages or expenses, even if foreseeable, within our contemplation or if we may have been advised of, or otherwise might have anticipated, the possibility of the same.</p>
            <p>Information available through the services: information displayed at the services or sent to you in connection with the services, may be from a variety of sources. All data and/or information contained in the services or sent to you in connection with the services is provided for informational purposes only. We make no representations as to the accuracy, timeliness, adequacy or commercial value of all such information and/or data. You should not act on data or information obtained through the services or sent to you in connection with the services, without first independently verifying its contents.</p>
            <p>Refund. We will refund to you any benefit which we receive as a result of any breach of our agreement with you (this means that, for example, where a money transfer has failed, in such circumstances we will refund to you the transaction amount and the service fee). We do not, in any event, accept responsibility for: (i) any failure to perform your instructions as a result of circumstances which could reasonably be considered to be outside our control; (ii) malfunctions in communications facilities which cannot reasonably be considered to be under our control and that may affect the accuracy or timeliness of messages you send to us; (iii) any losses or delays in transmission of messages arising out of the use of any internet service provider or caused by any browser or other software which is not under our control; and (iv) errors on the website or with the service caused by incomplete or incorrect information provided to us by you or a third party.</p>
            <p>In no event shall we be liable for damages whether caused by negligence on the part of our employees, suppliers or agents or otherwise, beyond the sum of SGD 500. In no event shall we or our agents be liable for any indirect, special, incidental, consequential, exemplary or punitive damages, or the like.</p>
            <p>Non-waiver of rights.</p>
            <p>No failure or delay on the part of us in exercising any right or power given here shall operate as a waiver of that right or power, and nor shall any partial or single exercise of any such rights or powers preclude any of the other rights or powers provided here. Moreover, no waiver by us or any of its rights or powers under this wallet agreement shall be deemed to have been made unless expressed in writing and signed by its duly authorized representative.</p>
            <p>Other means of communication.</p>
            <p>You undertake to notify us of any additional means of communicating with you aside from those disclosed in your wallet application. Pursuant to such undertaking, you authorize us at its discretion but without any obligation to do so, to secure information from third parties such as but not limited to utility companies, insurers, and financial intermediaries, and to receive information on how and where you can be contacted.</p>
            <p>Assignment of waiver.</p>
            <p>You agree that we, without notice to you, may assign, discount, or otherwise transfer part of all of its rights or obligations here or under any wallet transaction. In the event of such assignment, you irrevocably agree not to assert against the assignee setoff rights of any obligation that may be owed by us to you. You may not assign your rights and obligations under these terms and conditions without our prior consent.</p>
            <p>Your complaints.</p>
            <p>Any complaint regarding your Wallet and Services or their use, or both, shall be communicated to the wallet hotline or contact us or other means in accordance with these terms and conditions. If we deem necessary, we will conduct an investigation of the complaint for its prompt resolution and communicate its findings to you. You agree to fully cooperate with any such investigation by providing the necessary or required data, information, and documents. You must resolve any complaint against any Merchant or other party directly with them. You cannot setoff against us any claim you have against them. If there is a dispute with any Merchant in respect of a payment, a refund for the transaction will be made to you only after the Merchant has refunded the payment to us.</p>
            <p>No deposit insurance coverage.</p>
            <p>Funds stored on the MatchMove Mobile Wallet and stored value Card provided in conjunction with the Mobile Wallet are not insured deposits under the Deposit Insurance and Policy Owners&rsquo; Protection Schemes Act (Chapter 77B of the Singapore Statutes) and are ineligible for protection by the Deposit Insurance Scheme.&nbsp;</p>
            <p>Disclosure.</p>
            <p>We shall keep all your files and records in strictest confidence in accordance with the provisions of your residence country&rsquo;s legislation and its privacy policy.</p>
            <p>Severability clause.</p>
            <p>If any provision of the terms and conditions is found by an arbitrator or court of competent jurisdiction to be invalid, the parties nevertheless agree that the arbitrator or court should endeavour to give appropriately valid effect to the intention of the terms and conditions as reflected in the provision, and the other provisions of the terms and conditions shall remain in full force and effect.</p>
            <p>Dispute resolution.</p>
            <p>MatchMove and you shall attempt to resolve any controversy or claim arising out of or relating to the creation, performance, termination and/or breach of these terms and conditions in the first instance through good faith negotiation or mediation between the parties. Any claims or disputes arising from or related to these terms and conditions shall be resolved through binding arbitration via the Singapore international arbitration center located in Singapore and judgment upon the award rendered by the arbitrator(s) may be entered in any court having jurisdiction thereof. Notwithstanding the foregoing, and solely with respect to breaches or threatened breaches of these terms and conditions, either party to these terms and conditions may seek a temporary restraining order and injunction to protect its legal rights or interests, without the need to post bond in any court having jurisdiction.</p>
            <p>Miscellaneous article</p>
            <p>30.1 Governing laws. These terms and conditions and any and all amendments thereto shall be governed by and construed in accordance with the substantive laws of Singapore, without reference to its conflicts of law provisions.</p>
            <p>30.2 Act in good faith. We may act in any instruction we believe in good faith has been given by you according to the operating mandate.</p>
            <p>30.3 Dispute resolution. Any dispute arising from or relating to MatchMove services shall be resolved by final and binding arbitration. The arbitrator shall also decide what is subject to arbitration. The arbitration will be administered by Singapore International Arbitration Centre under (&ldquo;<strong>SIAC</strong>&rdquo;) in accordance with the arbitration rules of the SIAC (&ldquo;<strong>SIAC rules</strong>&rdquo;) for the time being in force, which rules are deemed to be incorporated by reference in this clause.</p>
            <p>30.4 Indemnity. You agree to indemnify us and all our servants, employees, nominees, directors and agents for any loss and embarrassment suffered by us (other than such loss and embarrassment arising from us or our employees&rsquo; and agents&rsquo; willful misconduct or negligence) in connection with:</p>
            <p>Any wallet account, Services or transaction;</p>
            <p>Acting on or carrying out or delaying or refusing to act on any instruction you give us;</p>
            <p>Searches and enquiries we make in connection with you, or a security provider;</p>
            <p>The provision of any service to you and the performance of our functions;</p>
            <p>Any service provided by any third party;</p>
            <p>Any default or the preservation or enforcement of our rights under the terms of these terms and conditions or any other applicable terms and conditions in connection with any wallet or related services or as a result of your noncompliance with any of these terms;</p>
            <p>Any action by us or any party against you relating to any Wallet, wallet account or Services;</p>
            <p>Any involvement by us in any proceeding of whatever nature for the protection of or in connection with the Wallet, wallet account or Services;</p>
            <p>Our compliance with any existing or future law or regulation or official directive in respect of any of these terms;</p>
            <p>Taxes payable by us in connection with your wallet account; and</p>
            <p>Any increased cost in our funding if there is a change in law or circumstances.</p>
            <p>30.5 Waiver. Any failure or delay by us in exercising or enforcing any right we have under the terms of these terms and conditions does not operate as a waiver of and does not prejudice or affect our right subsequently to act strictly in accordance with our rights.</p>
            <p>30.6 Illegality. We may close and revoke any Wallet, wallet account or Services with or without notice to you if, because of any change to any applicable law, regulation, regulatory requirement or judicial decision, or in our opinion, maintaining or performing any obligation under the terms in these terms and conditions becomes illegal, or we are otherwise prohibited from doing so. If this happens, you must pay us all liabilities on demand.</p>
            <p>30.7 General construction and interpretation. In our opinion: when we determine a matter in our opinion, the determination is made at our absolute discretion. When we act or refuse to act: on any matter including any instruction or transaction, we do not need to provide any reason for our act or refusal unless required by the law. Timing: if we receive any instruction on a non business day or after the specified clearance or cutoff times, we may treat the instruction as received on the following business day.</p>
            <p>30.8 Amendments. We reserve the right to amend these terms and conditions at any time and for whatever reason it may deem proper, and any such amendment shall be binding upon notice to you by publication or other means of communication, electronic or otherwise. This is unless you object to those amendments by manifesting the intention to terminate your membership in writing within five (5) days from notice of the amendment. Your failure to notify us about this intention to terminate your membership as provided, and your continued use of your Wallet and Services, or both, shall be taken as your conclusive acceptance of the amendments.</p>
            <p>30.9 Any external links to third-party websites on the website are provided as a convenience to you. These sites are not controlled by us in any way and we are not responsible for the accuracy, completeness, legality or any other aspect of these other sites including any content provided on them. You access such websites at your own risk.</p>
            <p>30.10 Subcontracting and delegation: we reserve the right to use any service providers, subcontractors and/or agents on such terms as we think appropriate.</p>
            <p>30.11 Force majeure: we shall not be liable for any non performance, error, interruption or delay in the performance of its obligations or in the services&rsquo; operation, or for any inaccuracy, unreliability or unsuitability of the materials or any of the services&rsquo; contents if this is due, in whole or in part, directly or indirectly to an event or failure which is beyond its reasonable control (which includes without limitation acts of god, natural disasters, acts of any government or authority, power failures, and the acts or a party for whom we is not responsible for).</p>
            <p>30.12 We take security very seriously at MatchMove, and we work hard, using state-of-the-art security measures, to make sure that your information remains secure. The MatchMove service is a safe and convenient way to send money to friends and family and to other people that you trust. However, we do advise you to consider very carefully before sending money to anyone that you do not know well. In particular, you should be very cautious of deals or offers that seem too good to be true they may be scams. If you are aware of anyone or any entity that is using the service inappropriately, please email us using our contact form. Similarly, if you receive any emails, purporting to be from us, which you suspect may be &ldquo;phishing&rdquo; (fake) emails, please forward them to us using our contact form.</p>
            <p>30.13 Contact information</p>
            <p>Any questions, notices, and requests for refunds or further information should be directed to us by contacting our customer service at +65 97250731 (WhatsApp messaging only), or write to:</p>
            <p>MatchMove Pay Pte. Ltd.,<br />137 Telok Ayer Street, #03-03, Singapore 068602<br />or send an email to contactus@matchmove.com</p>
            <p> Our Customer Service Team operates from Monday to Saturday, between 10:00 AM SGT and 7:00 PM SGT. Please note that any enquiries received on Sunday and Public Holidays will be addressed on the following business day. </p>
            <p><strong>ADDITIONAL TERMS FOR USER PROTECTION PURSUANT TO EPAYMENTS USER PROTECTION GUIDELINES ISSUED BY MONETARY AUTHORITY OF SINGAPORE&nbsp;</strong></p>
            <p><strong> Account Holder to provide contact information and monitor notifications&nbsp;</strong></p>
            <p>31.1 The Account Holder of a Protected Account shall be required to provide us with contact details as required by us in order to send the Account Holder transaction notifications. Where the Protected Account is a joint account, the Account Holders should jointly give instructions to us on whether we should send transaction notifications to any or all the Account Holders. The duties of the Account Holders in this Section will apply to all the Account Holders that the Regulated Entity has been instructed to send transaction notifications to.&nbsp;</p>
            <p>31.2 The Account Holder shall be required at a minimum to provide the following contact information which must be complete and accurate, to us:&nbsp;</p>
            <p>(a) where the Account Holder has opted to receive transaction notifications by SMS, his Singapore mobile phone number; or&nbsp;</p>
            <p>(b) where the Account Holder has opted to receive notification by email, his email address.&nbsp;</p>
            <p>31.3 It is the Account Holder&rsquo;s responsibility to enable transaction notification alerts on any device used to receive transaction notifications from us and to monitor the transaction notifications sent to the contact address. We may assume that the Account Holder will monitor such transaction notifications without further reminders or repeat notifications.&nbsp;</p>
            <p><strong> Transaction Notifications&nbsp;</strong></p>
            <p>32.1 We will provide Transaction Notifications that fulfil the following criteria to you that we have been instructed to send, in respect of all transactions (of any amount) made to or from your account (&ldquo;<strong>Notifiable Transaction</strong>&rdquo;).</p>
            <p>(a) the Transaction Notification will be sent to your contact address. If you have provided more than one contact address to us, the Transaction Notification will be sent to every contact address selected by you to receive such notifications.</p>
            <p>(b) the Transaction Notification will be sent on a real time basis for each transaction or on a batched basis at least once every 24 hours to consolidate every notifiable transaction made in the past 24 hours. We may but are not obligated to send both real time notifications and daily batched notifications to you.</p>
            <p>(c) the Transaction Notification will be conveyed to the Account Holder by way of SMS or email. An in-app notification will be accompanied by an SMS or email notification that meets the deadline in sub-paragraph (b) above.</p>
            <p>(d) the Transaction Notification will contain the following information, but we may omit any confidential information provided that the information provided to you still allows you to identify the transaction as being an authorized transaction or Unauthorized Transaction: (i) information that allows you to identify the Protected Account such as the Protected Account number; (ii) information that allows you to identify the Recipient whether by name or by other credentials such as the Recipient&rsquo;s account number; (iii) information that allows us to later identify you, the Protected Account, and the recipient account such as each account number or name; (iv) transaction amount; (v) transaction time and date; (vi) transaction type; (vii) if the transaction is for goods and services provided by a business, the trading name of the merchant and where possible, the merchant&rsquo;s reference number for the transaction.</p>
            <p><strong> Account user to protect access codes&nbsp;and access to protected account&nbsp;</strong></p>
            <p>33.1 You should not do any of the following:</p>
            <p>A voluntarily disclose any Access Code to any third party;</p>
            <p>B disclose the Access Code in a recognizable way on any Wallet, Authentication Device, or any container for the Wallet; or</p>
            <p>Ckeep a record of any Access Code in a way that allows any third party to easily misuse the Access Code.</p>
            <p>33.2 If you keep a record of any Access Code, you should make reasonable efforts to secure the record, including:</p>
            <p>A keeping the record in a secure electronic or physical location accessible or known only to the Account User; and</p>
            <p>B keeping the record in a place where the record is unlikely to be found by a third party.</p>
            <p>33.3 You shall at the minimum do the following where a device is used to access the Protected Account:</p>
            <p>A update the device&rsquo;s browser to the latest version available;</p>
            <p>B patch the device&rsquo;s operating systems with regular security updates provided by the operating system provider;</p>
            <p>C install and maintain the latest anti-virus software on the device, where applicable; and</p>
            <p>D use strong passwords, such as a mixture of letters, numbers and symbols.</p>
            <p>33.4 You should inform all other Account Users, if any, of the security instructions or advice provided by us. An Account User should follow security instructions or advice provided by us to you.</p>
            <p><strong>Report and provide information of Unauthorized Transactions</strong></p>
            <p>34.1 You should report any Unauthorized Transactions to us as soon as practicable after receipt of any Transaction Notification alert for any Unauthorized Transaction.&nbsp;</p>
            <p>34.2 Where you are not able to report the Unauthorized Transaction to us as soon as you receive any Transaction Notification alert for any Unauthorized Transaction, you should if we so request, provide us with reasons for the delayed report. This includes the time periods or circumstances where it would not be reasonable to expect us to monitor Transaction Notifications. The report should be made in any of the following ways:</p>
            <p>A by reporting the Unauthorized Transaction in the communications channel as set out in this agreement; or</p>
            <p>B by reporting the Unauthorized Transaction to us in any other way and where we acknowledge receipt of such a report.</p>
            <p>34.3 You should within a reasonable time provide us with any of the following information as requested by us:</p>
            <p>A the Protected Account affected;</p>
            <p>B your identification information;</p>
            <p>C the type of Authentication Device, Access Code and device used to perform the payment transaction;</p>
            <p>D the name or identity of any Account User for the account;</p>
            <p>E whether an account, Authentication Device, or Access Code was lost, stolen or misused and if so: (i) the date and time of the loss or misuse, (ii) the date and time that the loss or misuse, was reported to us, and (iii) the date, time and method that the loss or misuse, was reported to the police;</p>
            <p>F where any Access Code is applicable to the account, (i) how you or any Account User recorded the Access Code, and (ii) whether you or any Account User had disclosed the Access Code to anyone; and G any other information about the Unauthorized Transaction that is known to you.</p>
            <p>34.4 You should make a police report if we request such a report to be made to facilitate the claims investigation process.</p>
            <p><strong> Recipient credential information&nbsp;</strong></p>
            <p>35.1 Where transactions are made by way of internet banking, any mobile phone application or device arranged for by us for payment transactions, including a payment kiosk, we will provide an onscreen opportunity for any Account User of a Wallet to confirm the payment transaction and recipient credentials before we execute any authorised payment transaction. The onscreen opportunity will contain the following information:</p>
            <p>(a) information that allows the Account User to identify the Protected Account to be debited;</p>
            <p>(b) the intended transaction amount;</p>
            <p>(c) credentials of the intended recipient that is sufficient for the Account User to identify the recipient, which at the minimum should be the recipient&rsquo;s phone number, identification number, account number or name as registered for the purpose of receiving such payments; and</p>
            <p>(d) a warning to ask the Account User to check the information before executing the payment transaction.</p>
            <p><strong> Reporting channel&nbsp;</strong></p>
            <p>36.1 We will provide Account Holders of Protected Accounts with a reporting channel for the purposes of reporting unauthorised or erroneous transactions.&nbsp;</p>
            <p>36.2 The reporting channel should have all the following characteristics.&nbsp;</p>
            <p>(a) The reporting channel may be a manned phone line, phone number to receive text messages, online portal to receive text messages, or a monitored email address.&nbsp;</p>
            <p>(b) Any person who makes a report through the reporting channel will receive a written acknowledgement of his report through SMS or email.&nbsp;</p>
            <p>(c) We will not charge a fee to any person who makes a report through the reporting channel for the report or any service to facilitate the report.&nbsp;</p>
            <p>(d) The reporting channel shall be available at any time every calendar day, unless it is a manned phone line, in which case that reporting channel shall be available during business hours every business day.&nbsp;</p>
            <p><strong> Claim Investigation</strong></p>
            <p>37.1 We will while acting reasonably at our sole discretion assess any claim made by you in relation to any Unauthorized Transaction (&ldquo;<strong>Relevant Claim</strong>&rdquo;) for the purposes of assessing your liability. Where we have assessed that the relevant claim does not fall within Unauthorized Transaction, we will resolve such a claim in a fair and reasonable manner. We will communicate the claim resolution process and assessment to you in a timely and transparent manner.</p>
            <p>37.2. We may require that you furnish a police report in respect of Unauthorized Transaction claim, before we begin the claims resolution process. Upon enquiry by you, we will provide you with relevant information that we have of all the Unauthorized Transactions which were initiated or executed from a Protected Account, including transaction dates, transaction timestamps and parties to the transaction.</p>
            <p>37.3. We will complete an investigation of any relevant claim within twenty-one (21) business days for straightforward cases or forty-five (45) business days for complex cases. Complex cases may include cases where any party to the Unauthorized Transaction is resident overseas or where we have not received sufficient information from the Account Holder to complete the investigation. We will within these periods give each Account Holder that we have been instructed to send Transaction Notifications to in accordance with manner set out in these terms, a written or oral report of the investigation outcome and its assessment of your liability. We will seek acknowledgement (which need not be an agreement) from that Account Holder of the investigation report.</p>
            <p>37.4. Where you do not agree with our assessment of liability, or where we have assessed that the claim falls outside of Unauthorized Transaction, the parties may proceed to commence other forms of dispute resolution including mediation such as FIDReC,&nbsp;</p>
            <p>37.5. We will credit your Protected Account with the total loss arising from any Unauthorized Transaction as soon as we have completed our investigation and assessed that you are not liable for any loss arising from the Unauthorized Transaction. We will disclose this arrangement to you at the time you report the Unauthorized Transaction to us and inform you of the timeline for completing our investigation.</p>
            <p><strong> Liability for losses arising from Unauthorized Transactions</strong></p>
            <p>38.1 You are fully liable for actual loss arising from an Unauthorized Transaction where any Account User&rsquo;s recklessness was the primary cause of the loss. Recklessness would include the situation where any Account User deliberately did not comply with requirements under these terms. You are required to provide us with information we may reasonably require to assist us to determine whether or not any Account User was reckless.&nbsp;</p>
            <p>38.2 For the avoidance of doubt, where any Account User knew of and consented to a transaction (&ldquo;Authorised Transaction&rdquo;), such a transaction is not an Unauthorised Transaction, notwithstanding that the Account Holder may not have consented to the transaction. This would also include the situation where any Account User acts fraudulently to defraud any Account Holder or us. In such case, the Account Holder shall be solely liable for entire loss.</p>
            <p>38.3. You are not liable for any loss arising from an Unauthorized Transaction if the loss arises from, solely on account of, any action or omission by us and does not arise from any failure by any Account User to comply with any duty in this agreement.</p>
            <p>38.4. Any action or omission by us includes the following:</p>
            <p>(a) fraud or negligence by us, our employee, our agent or any outsourcing service provider contracted by us to provide our services through the Protected Account;</p>
            <p>(b) non-compliance by us or our employee with any requirement imposed by a regulatory authority on us in respect of our provision of any financial service;</p>
            <p>(c) non-compliance by us with any duty set out in this agreement.</p>
            <p>38.5. You are not liable for any loss arising from an Unauthorized Transaction that does not exceed $1,000, if the loss arises from any action or omission by any third party not referred to in paragraph 37.4 above and does not arise from any failure by any Account User to comply with any duty under this agreement.</p>
            <p>38.6 Where the Protected Account is a joint account, the liability for losses set out in this section applies jointly to each Account Holder in a joint account.</p>
            <p><strong> Specific duties in relation to Erroneous Transactions</strong></p>
            <p>39.1 Where you inform us in accordance with this section that you or an Account User has initiated a payment transaction from a Protected Account such that money has been placed with or transferred to the wrong recipient (&ldquo;<strong>Erroneous Transaction</strong>&rdquo;), we will inform the wrongful recipient&rsquo;s FI of the Erroneous Transaction promptly as much as practicable, to the FI of the wrong recipient and shall make reasonable efforts to recover the sum sent in error.&nbsp;</p>
            <p>39.2 For the purposes of paragraph above, reasonable efforts mean the following:</p>
            <p>(a) where we are the FI of Account Holder:</p>
            <p>(i) within two business days of receiving the necessary information from you under this section, we shall inform the recipient FI of the erroneous transaction;</p>
            <p>(ii) within seven business days of informing the recipient FI, we shall ask the recipient FI for the recipient&rsquo;s response and provide you with any new relevant information to allow you to assess if you should make a police report about the erroneous transaction.</p>
            <p>(b) where we are the FI of the wrong recipient:</p>
            <p>(i) within two business days of receiving the necessary information about any Erroneous Transaction, we will:</p>
            <p>Inform the recipient of the erroneous transaction and all necessary information that would allow the recipient to determine if the transaction was indeed erroneous;</p>
            <p>Ii. Ask the recipient for instructions on whether to send the sum sent in error back to you; and</p>
            <p>Iii. Inform the recipient that his retention or use of sums transferred to him erroneously where he has had notice of the erroneous transaction is an offence under the penal code.</p>
            <p>(ii) within five business days of receiving the necessary information about any erroneous transaction, we will:</p>
            <p>Ask the recipient for instructions whether to send the sum sent in error back to the Account Holder; and</p>
            <p>Ii. Inform the other FI about the recipient&rsquo;s response, including nil responses.</p>
            <p>For the purposes of assisting the FIs to recover sums sent in error, you should provide us with any of the following information as requested by us:</p>
            <p>(a) all such information that we may request as set out in section 33.3);</p>
            <p>(b) the recipient&rsquo;s unique identifier, including account number, identification number, name or other credentials entered by the Account User; and</p>
            <p>(c) the date, time, amount and purpose of the erroneous transaction insofar as such information is known to you.</p>

            <p class="p-0">
                To read our Service Level Agreement (Singapore) please click
                <a href="https://assets.mmvpay.com/corporate/legal/sla/Documentation_SLA_%20Singapore_v.3%205.7.19.pdf"
                    target="_blank">here</a>
                <br />
                To read our Service Level Agreement (India) please click
                <a href="https://assets.mmvpay.com/corporate/legal/sla/Documentation_SLA_%20India_v.3%205.7.19.pdf"
                    target="_blank">here</a>
            </p>
            <p class="p-0">
                Effective date: 02 June 2016 <br />
                Last updated: 10 May 2024
            </p>

        </div>
    </div>
</div>