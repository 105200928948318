
<div class="media-bottombg pt-0">
    <div class="container">
      <div class="termsbg">
<div class=WordSection1>

<p class=MsoNormal style='margin-right:11.85pt;text-align:justify;line-height:
normal'><b><span lang=EN style='font-size:15.0pt;font-family:"Calibri",sans-serif;
color:#303645'>Terminology </span></b></p>

<p class=MsoNormal style='margin-right:11.85pt;text-align:justify;line-height:
normal'><b><span lang=EN style='font-size:12.0pt;font-family:Roboto;color:#374151'>This
page offers an overview of the terms utilized by Us and/or commonly used in the
fintech and banking industries. It also outlines any essential actions that
need to be undertaken when utilizing the Platform Services.</span></b></p>

<p class=MsoNormal style='text-align:justify;line-height:normal'><span lang=EN
style='font-size:12.0pt;font-family:"Calibri",sans-serif;color:#303645'>“3DS”,
short for 3-D Secure, is an authentication protocol used in online transactions
to provide an additional layer of security. It is designed to protect the
cardholders and merchants from unauthorized use of payment cards by requiring
the cardholder to authenticate their identity using a password, one-time
passcode, or biometric verification during the transaction process, thereby
reducing the risk of fraudulent activities.</span></p>

<p class=MsoNormal style='text-align:justify;line-height:normal;border:none'><span
lang=EN style='font-size:12.0pt;font-family:"Calibri",sans-serif;color:#303645'>&quot;Account
limits”, refer to predefined restrictions or thresholds imposed on End User
accounts, restricting the maximum allowable transaction amounts, deposit and
payment limits, or other specified parameters to manage risk, ensure
compliance, and safeguard against potential fraudulent or illicit activities.</span></p>

<p class=MsoNormal style='text-align:justify;line-height:normal;border:none'><span
lang=EN style='font-size:12.0pt;font-family:"Calibri",sans-serif;color:#303645'>&quot;Adminet&quot;
is an exclusive enterprise-level tool provided to You. It serves various
purposes, including accessing comprehensive transaction information for each of
Your Users and executing specific actions to facilitate or disable the movement
of funds.</span></p>

<p class=MsoNormal style='text-align:justify;line-height:normal;border:none'><span
lang=EN style='font-size:12.0pt;font-family:"Calibri",sans-serif;color:#303645'>&quot;Apple
Pay - ECOM Transactions Fee&quot; is payable for all transactions executed as
an online ECOM transaction and includes payment transactions made using the
Provisioned Card through any Apple product that has been enabled to store
and/or transmit the card credentials of the cardholder. This will be payable on
the first Business Day following the end of the month.</span></p>

<p class=MsoNormal style='text-align:justify;line-height:normal;border:none'><span
lang=EN style='font-size:12.0pt;font-family:"Calibri",sans-serif;color:#303645'> &quot;Apple
Pay - POS Transactions Fee&quot; is payable for all transactions executed as a
Remote Transaction and includes payment transactions made using the Provisioned
Card through any Apple product that has been enabled to store and/or transmit
the card credentials of the cardholder. This will be payable on the first
Business Day following the end of the month.</span></p>


<p class=MsoNormal style='text-align:justify;line-height:normal'><span lang=EN
style='font-size:12.0pt;font-family:"Calibri",sans-serif'>In reference to
&quot;Apple Pay - ECOM Transactions Fee&quot; &amp;&nbsp;&quot;Apple Pay - POS
Transactions Fee&quot;, </span></p>


<p class=MsoNormal style='margin-top:0in;margin-right:11.85pt;margin-bottom:
0in;margin-left:.5in;margin-bottom:.0001pt;text-align:justify;line-height:normal'><i><span
lang=EN style='font-size:12.0pt;font-family:"Calibri",sans-serif'>&quot;Remote
Transaction&quot; means a remote payment transaction conducted through the
Apple Pay platform and triggered by User authentication on an Enabled Device
without the use of a contactless enabled point-of-sale device in accordance
with the Specifications. </span></i></p>

<p class=MsoNormal style='margin-top:0in;margin-right:11.85pt;margin-bottom:
0in;margin-left:.5in;margin-bottom:.0001pt;text-align:justify;line-height:normal'><i><span
lang=EN style='font-size:12.0pt;font-family:"Calibri",sans-serif'>&nbsp;</span></i></p>

<p class=MsoNormal style='margin-top:0in;margin-right:11.85pt;margin-bottom:
0in;margin-left:.5in;margin-bottom:.0001pt;text-align:justify;line-height:normal'><i><span
lang=EN style='font-size:12.0pt;font-family:"Calibri",sans-serif'>&quot;Provisioned
Card&quot; means an Eligible Card that has been provisioned to an Enabled
Device so that the Enabled Device may be used to make payments using such a
Provisioned Card.</span></i></p>

<p class=MsoNormal style='margin-top:0in;margin-right:11.85pt;margin-bottom:
0in;margin-left:.5in;margin-bottom:.0001pt;text-align:justify;line-height:normal'><i><span
lang=EN style='font-size:12.0pt;font-family:"Calibri",sans-serif'>&nbsp;</span></i></p>

<p class=MsoNormal style='margin-top:0in;margin-right:11.85pt;margin-bottom:
8.0pt;margin-left:.5in;line-height:107%'><i><span lang=EN style='font-size:
12.0pt;line-height:107%;font-family:"Calibri",sans-serif'>“Enabled Device”
means any Apple Product that has been enabled to store and/or transmit the Card
credentials of a Cardholder.</span></i></p>


<p class=MsoNormal style='text-align:justify;line-height:normal'><span lang=EN
style='font-size:12.0pt;font-family:"Calibri",sans-serif;color:#303645'> &quot;Apple
Pay - User Card Fee&quot; is a fee payable per month for all the active Cards
which have been provisioned on any Apple device for making the transactions.
This will be payable on the first Business Day following the end of the month.</span></p>

<p class=MsoNormal style='text-align:justify;line-height:normal'><span lang=EN
style='font-size:12.0pt;font-family:"Calibri",sans-serif;color:#303645'>&quot;ATM
Inquiry Fee&quot;: This will include ATM withdrawal charges, ATM inquiry
charges, and other such charges. This will be debited from the End User’s
account, as applicable on a per transaction basis. These are non-refundable charges,
and You must display the charges to the End User.</span></p>

<p class=MsoNormal style='text-align:justify;line-height:normal'><span lang=EN
style='font-size:12.0pt;font-family:"Calibri",sans-serif;color:#303645'>“Card
Issuance”, refers to the process of legally issuing Cards, to individuals or
entities. It involves the creation and distribution of physical or virtual
cards that grant Users access to funds or credit in accordance with applicable
laws, regulations, and contractual agreements. The card issuance process
ensures compliance with industry standards, security measures, while enabling
users to make authorized transactions within defined limits and under
agreed-upon terms.</span></p>

<p class=MsoNormal style='text-align:justify;line-height:normal'><span lang=EN
style='font-size:12.0pt;font-family:"Calibri",sans-serif;color:#303645'>&quot;Card
Network &amp; Processor Fee&quot; is payable on the volume of card transactions
processed on Our Platform, irrespective of the outcome (whether it is a
successful or a declined transaction) and covers for the costs incurred for
Network or processor charges. This will be payable on the first Business Day
following the end of the month calculated based on the volume processed for the
preceding month.</span></p>

<p class=MsoNormal style='text-align:justify;line-height:normal'><span lang=EN
style='font-size:12.0pt;font-family:"Calibri",sans-serif;color:#303645'>&quot;Cash
In and 7/11 Fees&quot; will be payable on all load transactions initiated and
processed via the 7/11 store. This fee will be debited directly from the End
User load amount, and the balance will be credited to the End User’s account.</span></p>

<p class=MsoNormal style='text-align:justify;line-height:normal'><span lang=EN
style='font-size:12.0pt;font-family:"Calibri",sans-serif;color:#303645'>“Count
Limit” refers to the maximum number of transactions allowed within a specific
timeframe, as defined by the applicable laws, regulations. It establishes a cap
on the quantity, ensuring compliance, managing risk, and preventing potential
abuses or fraudulent activities related to the specified activity or event.</span></p>

<p class=MsoNormal style='text-align:justify;line-height:normal;background:
white'><span lang=EN style='font-size:12.0pt;font-family:"Calibri",sans-serif;
color:#303645'>&quot;Consumer,&quot; refers to individuals who hold personal
payment accounts that are specifically issued for conducting payment
transactions unrelated to business activities. These accounts are intended
exclusively for personal use, encompassing consumer spending, purchases, and
personal remittances, while excluding any transactions conducted in the course
of corporate or business operations. Consumers, as defined, utilize these
accounts to engage in financial transactions and make payments for personal
purposes in accordance with applicable laws and regulations governing consumer
rights and financial transactions.</span></p>

<p class=MsoNormal style='text-align:justify;line-height:normal;background:
white'><span lang=EN style='font-size:12.0pt;font-family:"Calibri",sans-serif;
color:#303645'>“Corporate&quot; refers to a corporate entity or individual that
is granted a payment account specifically for conducting corporate or
business-related transactions. This includes employees of corporate companies
who receive their salaries and expense claims through these accounts. The scope
of corporate transactions includes (but not limited to)  activities such as
corporate expense management, payroll management, benefit management,
financing/lending management, crypto payments limited to FIAT to FIAT
transactions, as well as high net worth/wealth management.</span></p>

<p class=MsoNormal style='text-align:justify;line-height:normal'><span lang=EN
style='font-size:12.0pt;font-family:"Calibri",sans-serif;color:#303645'>“Cross-border
fees'' are charges applied to transactions that involve the movement of funds
between different countries or jurisdictions, compensating for the costs and
complexities associated with processing cross-border payments. These fees are
as charged by the network and have a markup component applied to cover the
costs incurred by Us. </span></p>

<p class=MsoNormal style='text-align:justify;line-height:normal'><span lang=EN
style='font-size:12.0pt;font-family:"Calibri",sans-serif;color:#303645'>“Cross-currency
fees'' are charges applied to transactions that involve the conversion of one
currency to another, compensating for the costs and risks associated with
currency conversion. These fees are as charged by the network and have a markup
component  applied to cover the costs incurred by Us. </span></p>

<p class=MsoNormal style='text-align:justify'><span lang=EN style='font-size:
12.0pt;line-height:115%;font-family:"Calibri",sans-serif;color:#303645'>“Deposit”
also referred as “Credit” in our API Documentation is the amount of money that
is deposited into the Account</span></p>

<p class=MsoNormal style='text-align:justify'><span lang=EN style='font-size:
12.0pt;line-height:115%;font-family:"Calibri",sans-serif;color:#303645'>“Deposit
Limit”, also referred as “Credit limit” in our API documentation refers to the
maximum amount of money that can be deposited into the Account, as set by
Provider and governed by applicable laws, regulations, or contractual
agreements, to manage risk, ensure compliance, and prevent potential illicit
activities.</span></p>

<p class=MsoNormal style='text-align:justify;line-height:normal'><span lang=EN
style='font-size:12.0pt;font-family:"Calibri",sans-serif;color:#303645'>“Domestic
collections” refer to the mechanism that enables the transfer of funds from one
bank account to another within the same country or domestic banking system. It
involves the collection of payments or funds owed to a recipient by initiating
transactions that debit the payer's bank account and credit the payee's bank
account. In Singapore, this service is provided using Our integration with FAST
(Fast and Secure Transfers) payment network. </span></p>

<p class=MsoNormal style='text-align:justify;line-height:normal'><span lang=EN
style='font-size:12.0pt;font-family:"Calibri",sans-serif;color:#303645'>“Domestic
payout” refers to transfer of funds from the assigned virtual account to
another bank account. In Singapore, this service is extended through our
integration with the FAST (Fast and Secure Transfers) network, enabling the
transfer of funds from Our issued virtual account to a recipient's bank account
within Singapore's domestic banking network. This method allows businesses or
individuals to transfer payments efficiently, enhancing convenience, speed, and
reliability while maintaining the highest level of security in domestic fund
transfers.</span></p>

<p class=MsoNormal style='margin-right:11.85pt;text-align:justify;line-height:
normal'><span lang=EN style='font-size:12.0pt;font-family:"Calibri",sans-serif;
color:#303645'>&quot;Early Termination Fee&quot; is payable by You to Us in the
event You terminate the Agreement before the expiry of the Minimum Term.</span></p>

<p class=MsoNormal style='text-align:justify;line-height:normal;background:
white'><span lang=EN style='font-size:12.0pt;font-family:"Calibri",sans-serif;
color:#303645'>“End users” refers to the ultimate consumers or individuals who
directly use or interact with Our product, Service, Platform, or technology,
typically for their personal or professional needs, rather than for resale or
further distribution. They are the individuals for whom the product or service
is designed and who derive direct value or benefit from its use.</span></p>

<p class=MsoNormal style='text-align:justify;line-height:normal'><span lang=EN
style='font-size:12.0pt;font-family:"Calibri",sans-serif;color:#303645'>“FAST”
(Fast and Secure Transfers) is an electronic funds transfer service that
enables near-instantaneous, secure, and convenient transfers between
participating banks and accounts. Domestic collections and payouts through FAST
allow individuals, businesses, and organizations to efficiently make or receive
payments, and transfer funds in a seamless and expedited manner from Our
virtual account to another bank account connected with the FAST network. It
leverages the interconnectedness of banks in Singapore to facilitate real-time
transfers, improving the speed and convenience of domestic financial
transactions.</span></p>

<p class=MsoNormal style='text-align:justify;line-height:normal'><span lang=EN
style='font-size:12.0pt;font-family:"Calibri",sans-serif;color:#303645'>“Forex
fee”, short for ‘foreign exchange fee’, is a charge applied to transactions
involving currency conversion, compensating for the costs and risks associated
with exchanging one currency for another. These fees are as charged by the
network and have a markup component  applied to cover the costs incurred by Us.
</span></p>

<p class=MsoNormal style='text-align:justify;line-height:normal'><span lang=EN
style='font-size:12.0pt;font-family:"Calibri",sans-serif;color:#303645'>&quot;Full
KYC” refers to a comprehensive KYC process mandated by Regulator or regulatory
authorities, wherein extensive customer information is obtained and verified to
ensure compliance with applicable regulations &quot;</span></p>

<p class=MsoNormal style='margin-right:11.85pt;text-align:justify;line-height:
normal'><span lang=EN style='font-size:12.0pt;font-family:"Calibri",sans-serif;
color:#303645'>&quot;Google Pay Transaction Fees&quot; is payable for all
transactions executed via Google Pay and includes payment transactions using
the provisioned card through any product that has been enabled to store and/or
transmit the card credentials of the cardholder via Google Pay. This will be
payable on the first Business Day following the end of the month.</span></p>

<p class=MsoNormal style='margin-right:11.85pt;text-align:justify;line-height:
normal'><span lang=EN style='font-size:12.0pt;font-family:"Calibri",sans-serif;
color:#374151'> &quot;Interchange Fee&quot; or &quot;Interchange Revenue&quot;
refers to the income generated from the interchange amount received from the
Network for processing card payments. This revenue will be divided and
distributed to You, based on Your qualifying tier, which is linked to the
settled Gross Transaction Volume (GTV) of all successful card transactions
processed through our Platform during the respective quarter. This will become
payable to You usually in the subsequent quarter. The calculation of this
amount will be performed by Us, and after reconciliation of Your invoice with
Our records and Network files.</span></p>

<p class=MsoNormal style='margin-right:11.85pt;text-align:justify;line-height:
normal'><span lang=EN style='font-size:12.0pt;font-family:"Calibri",sans-serif;
color:#374151'>&quot;Jupiter&quot; is an exclusive and optional
enterprise-level tool designed to support the monitoring of potentially
fraudulent activities within your program. It provides enhanced capabilities to
detect and address any suspicious or fraudulent behavior.</span></p>

<p class=MsoNormal style='margin-right:11.85pt;text-align:justify;line-height:
normal'><span lang=EN style='font-size:12.0pt;font-family:"Calibri",sans-serif;
color:#303645'>&quot;Just in Time Funding&quot; refers to a funding approach
where the balance management is handled at the Prefund Account level rather
than individual user accounts. In this model, there is no separate account for
each user. Instead, the funds or assets are consolidated into a Prefund
Account, and the balance is managed collectively. When a transaction or payment
needs to be made, the necessary funds are allocated from the Pre Fund Account
to cover the specific amount required. This method allows for more efficient
and streamlined management of funds, as there is no need for individual user
account balances to be tracked or maintained.</span></p>

<p class=MsoNormal style='margin-right:11.85pt;text-align:justify;line-height:
normal'><span lang=EN style='font-size:12.0pt;font-family:"Calibri",sans-serif;
color:#303645'>&quot;KYC Compliance Review per month&quot; includes the
&quot;KYC Compliance Review Fee&quot; that applies if You conduct KYC under a
Third-Party Reliance or KYC Outsourcing arrangement with Us. The process
involves recording and reviewing relevant data by our compliance team and
securely storing the data with Us, as mandated by Regulatory Requirements and
statutory guidelines.</span></p>

<p class=MsoNormal style='margin-right:11.85pt;text-align:justify;line-height:
normal'><span lang=EN style='font-size:12.0pt;font-family:"Calibri",sans-serif;
color:#303645'>&quot;KYC per month&quot; includes the &quot;KYC Fee&quot; that
is applicable when You choose to utilize Our services for conducting KYC
processes required for onboarding users onto our platform. This fee applies to
all Users who have been onboarded and have undergone online KYC on Our platform,
as determined by Us from time to time. The process involves recording and
reviewing relevant data by our compliance team and securely storing the data
with Us, in accordance with Regulatory Requirements and statutory guidelines.</span></p>

<p class=MsoNormal style='margin-right:11.85pt;text-align:justify;line-height:
normal'><span lang=EN style='font-size:12.0pt;font-family:"Calibri",sans-serif;
color:#303645'>&quot;Load card&quot; refers to the process of adding funds or
value to the prepaid card. It involves depositing or transferring a specified
amount of money onto the card, which can then be used by the cardholder to make
payments for goods or services. The act of loading a card complies with applicable
laws, regulations, and contractual agreements governing payment systems and
ensures that the cardholder has sufficient funds to facilitate authorized
purchases or transactions.</span></p>



<p class=MsoNormal style='margin-right:11.85pt;text-align:justify;line-height:
normal'><span lang=EN style='font-size:12.0pt;font-family:"Calibri",sans-serif;
color:#303645'>“Load limit” refers to the maximum amount of funds that can be
added or loaded onto a Card, as set by Us, and as governed by applicable laws,
regulations, or contractual agreements, to manage risk, ensure compliance, and
prevent potential illicit activities.</span></p>



<p class=MsoNormal style='margin-right:11.85pt;text-align:justify;line-height:
normal'><span lang=EN style='font-size:12.0pt;font-family:"Calibri",sans-serif;
color:#303645'>&quot;Mark Up Provisioning Fee&quot; is the fee charged directly
to the End User by debiting their Account balance, should You choose to
provision to add a markup on the transaction fee or the transaction value for
any cross border/ forex/ cross-currency transaction, executed using card or
remittance service. The amount will be computed based on the applicable
volume/transactions processed. This will include the following:</span></p>

<p class=MsoNormal style='margin-right:11.85pt;text-align:justify;line-height:
normal'><span lang=EN style='font-size:12.0pt;font-family:"Calibri",sans-serif;
color:#303645'>  a. Card transactions FX markup value - This will include the
markup charged on the transaction value for the Card transactions classified as
forex/cross-border/cross-currency transactions as requested by You. </span></p>

<p class=MsoNormal style='margin-right:11.85pt;text-align:justify;line-height:
normal'><span lang=EN style='font-size:12.0pt;font-family:"Calibri",sans-serif;
color:#303645'>  b. Remittance Transaction fee markup - This will include the
markup charged on the transaction fee for the remittance transactions executed
through Our Platform.</span></p>

<p class=MsoNormal style='margin-right:11.85pt;text-align:justify;line-height:
normal'><span lang=EN style='font-size:12.0pt;font-family:"Calibri",sans-serif;
color:#303645'>  c. Remittance Volume-based markup value - This will include
the markup charged on the transaction value for the remittance transactions
executed through Our Platform.</span></p>


<p class=MsoNormal style='margin-right:11.85pt;text-align:justify;line-height:
normal'><span lang=EN style='font-size:12.0pt;font-family:"Calibri",sans-serif;
color:#303645'>&quot;MIN KYC” refers to a simplified version of the standard
KYC process, where  a reduced set of customer information is collected to
comply with regulatory requirements while mitigating potential risks</span></p>



<p class=MsoNormal style='margin-right:11.85pt;text-align:justify;line-height:
normal'><span lang=EN style='font-size:12.0pt;font-family:"Calibri",sans-serif;
color:#303645'>&quot;Monthly Platform Subscription Fee&quot; is a monthly
recurring committed fee charged for continued access, availability, and use of
the Platform. The Monthly Platform Subscription Fee will be payable on the
first Business Day of the month.</span></p>



<p class=MsoNormal style='margin-right:11.85pt;text-align:justify;line-height:
normal'><span lang=EN style='font-size:12.0pt;font-family:"Calibri",sans-serif;
color:#303645'>“​​Non-shared balance”, refers to an arrangement where separate
and independent pools of funds and balances are maintained at account and card.
In this setup, the funds are not shared among accounts or cards, ensuring
individual control and ownership over the respective balances, and preventing
any collaboration or distribution of funds between accounts or cards. </span></p>



<p class=MsoNormal style='margin-right:11.85pt;text-align:justify;line-height:
normal'><span lang=EN style='font-size:12.0pt;font-family:"Calibri",sans-serif;
color:#303645'>&quot;Payment&quot; also referred to as “Debit” in our API
documentation refers to the utilization or transferring of funds from the
account. </span></p>



<p class=MsoNormal style='margin-right:11.85pt;text-align:justify;line-height:
normal'><span lang=EN style='font-size:12.0pt;font-family:"Calibri",sans-serif;
color:#303645'>&quot;Payment Limit&quot; also referred to as “Debit limit” in
our API Documentation refers to the maximum amount of funds that can be
utilized or transferred from the account within a specified timeframe or as
determined by relevant regulations, contractual agreements, or system rules.
This limit sets a boundary on the total value of payments that can be made,
helping manage risk, prevent fraud, ensure compliance, and maintain the overall
integrity and stability of the payment system</span></p>



<p class=MsoNormal style='margin-right:11.85pt;text-align:justify;line-height:
normal'><span lang=EN style='font-size:12.0pt;font-family:"Calibri",sans-serif;
color:#303645'>&quot;Person to Person (P2P)  limits&quot; is the maximum
thresholds or restrictions set for person-to-person (P2P) transactions within
Your Program as set on the Platform. These limits are  set to manage risk,
prevent fraud, ensure compliance with regulations, and maintain the security
and stability of Our payment system.</span></p>



<p class=MsoNormal style='margin-right:11.85pt;text-align:justify;line-height:
normal'><span lang=EN style='font-size:12.0pt;font-family:"Calibri",sans-serif;
color:#303645'>“Physical card” refers to the tangible payment card made of
plastic or other materials, issued in compliance to regulatory and network
guidelines, and can be  used for all transactions including POS/ ECOM/ ATM
withdrawal transactions. It contains the cardholder's information, such as the
card number, cardholder name, expiration date, and security features like a
magnetic stripe or chip.</span></p>



<p class=MsoNormal style='margin-right:11.85pt;text-align:justify;line-height:
normal'><span lang=EN style='font-size:12.0pt;font-family:"Calibri",sans-serif;
color:#303645'>&quot;Platform Access Fee&quot; is a one-time, non-refundable
upfront fee, which shall be paid in full by You to Us, upon signing of this
Agreement. We will commence the performance of our obligations under this
Agreement only upon receipt of the Platform Access Fee. The Platform Access
Fees include charges for integration, access to our proprietary embedded
finance platform and opted tools, as well as the ability to leverage our
regulatory licenses and partnerships with card networks and various third-party
service providers. It includes the initial kick-off workshop led by the project
implementation team, the assignment of a dedicated technical solution architect
and certain engineering resources for a period of 45 days from the Effective
Date. It includes the commissioning of the UAT/Pre-Prod stack configured
according to Schedule 1 and technical transition support to move to the Live
Production stack.</span></p>



<p class=MsoNormal style='margin-right:11.85pt;text-align:justify;line-height:
normal'><span lang=EN style='font-size:12.0pt;font-family:"Calibri",sans-serif;
color:#303645'>&quot;Remittance Transaction Fee&quot; is payable per
transaction on any remittance transaction involving the payment to an
individual or an entity by You or Your User on our Platform located in any
other country, other than the Territory. This fee is levied for every
transaction at the time of initiation and is debited from Your/Users Account.</span></p>



<p class=MsoNormal style='margin-right:11.85pt;text-align:justify;line-height:
normal'><span lang=EN style='font-size:12.0pt;font-family:"Calibri",sans-serif;
color:#303645'>&quot;Remote Transaction&quot; means a remote payment
transaction conducted through the Apple Pay platform and triggered by User
authentication on an Enabled Device without the use of a contactless enabled
point-of-sale device in accordance with the Specifications. &quot;Provisioned
Card&quot; means an Eligible Card that has been provisioned to an Enabled
Device so that the Enabled Device may be used to make payments using such a
Provisioned Card.</span></p>



<p class=MsoNormal style='margin-right:11.85pt;text-align:justify;line-height:
normal'><span lang=EN style='font-size:12.0pt;font-family:"Calibri",sans-serif;
color:#374151'>“Shared balance&quot; refers to an arrangement where the End
User has access to and can utilize a common pool of funds that are maintained
at the account level. These funds can be used for transactions on the account
or card. The concept of shared balance enables the sharing and distribution of
funds, facilitating collaborative financial management and expenditure tracking
among the account and associated cards.</span></p>


<p class=MsoNormal style='margin-right:11.85pt;text-align:justify;line-height:
normal'><span lang=EN style='font-size:12.0pt;font-family:"Calibri",sans-serif;
color:#303645'>&quot;Standard Funding&quot; refers to the process of assigning
and managing individual user accounts within our  Adminnet system. Each user is
provided with a separate account, and the balance of each account is maintained
independently, managed separately and can be used for various purposes or
transactions within the system.</span></p>



<p class=MsoNormal style='margin-right:11.85pt;text-align:justify;line-height:
normal'><span lang=EN style='font-size:12.0pt;font-family:"Calibri",sans-serif;
color:#303645'>&quot;Usage Subscription Pack&quot; is a monthly recurring fee
that is based on the number of users, card transactions, FAST transactions, and
the KYC method adopted by You. It follows a slab-based fixed fee structure,
along with an additional charge per unit (as outlined in Schedule 2). This fee
is determined by the usage tier You choose, and any additional units consumed
will incur an extra charge. </span><span lang=EN style='font-size:12.0pt;
font-family:"Calibri",sans-serif;color:#374151'>The Usage Subscription Pack
will be invoiced on the first Business Day of every month. Any additional units
consumed, if applicable, will be billed on the first Business Day of the
subsequent month. The Usage Subscription Pack becomes chargeable either from
the Launch Date or forty-five (45) days from the Effective Date, whichever
occurs earlier. The pack encompasses the following components:</span></p>

<p class=MsoNormal style='margin-right:11.85pt;text-align:justify;line-height:
normal'><span lang=EN style='font-size:12.0pt;font-family:"Calibri",sans-serif;
color:#303645'>  a. Users Onboarded per month: This includes the &quot;Users
Onboarding Fee&quot; for Users who have been onboarded, registered, and had
their account created as new Users within the month.</span></p>

<p class=MsoNormal style='margin-right:11.85pt;text-align:justify;line-height:
normal'><span lang=EN style='font-size:12.0pt;font-family:"Calibri",sans-serif;
color:#303645'>  b. Users on Platform: This includes the &quot;Users on
Platform Fee&quot; for all Users who have been onboarded and have the status of
&quot;Active&quot; on our Platform, regardless of whether they are transacting
or not, at the end of each month. Users with statuses other than
&quot;Active&quot; (such as &quot;Locked,&quot; &quot;Blocked,&quot; or
&quot;Pending&quot;) will not be considered for the calculation of the Users on
Platform fee.</span></p>

<p class=MsoNormal style='margin-right:11.85pt;text-align:justify;line-height:
normal'><span lang=EN style='font-size:12.0pt;font-family:"Calibri",sans-serif;
color:#303645'>  c. Open loop (via Card) / Closed loop Transactions per month:
This includes the &quot;Open Loop/Closed Loop Transactions Fee'' for any
transactions that have been processed through our platform. This includes open
loop spend transactions executed through the associated Network and closed loop
spend transactions, but excludes transactions related to load/payout via FAST,
remittance transactions, load via 7/11, USD VA collection facility, and P2P
transactions.</span></p>

<p class=MsoNormal style='margin-right:11.85pt;text-align:justify;line-height:
normal'><span lang=EN style='font-size:12.0pt;font-family:"Calibri",sans-serif;
color:#303645'>  d. </span><span lang=EN style='font-size:12.0pt;font-family:
"Calibri",sans-serif;color:#374151'>KYC per month: This component encompasses
the &quot;KYC Fee&quot; that is applicable when You opt to utilize Our services
for conducting KYC processes necessary for onboarding Users onto Our platform.
The fee is applicable to all Users who have completed the onboarding process
and undergone online KYC on our platform, as determined by Us periodically. The
KYC process involves recording and reviewing relevant data by Our compliance
team and securely storing the data with Us, in compliance with Regulatory
Requirements and statutory guidelines.</span></p>

<p class=MsoNormal style='margin-right:11.85pt;text-align:justify;line-height:
normal'><span lang=EN style='font-size:12.0pt;font-family:"Calibri",sans-serif;
color:#303645'>  e. KYC Compliance Review per month: This includes the
&quot;KYC Compliance Review Fee&quot; that applies to You if You conduct KYC
under a Third-Party Reliance or KYC Outsourcing arrangement with Us. The
process involves recording and reviewing relevant data by our compliance team
and securely storing the data with us, as mandated by Regulatory Requirements
and statutory guidelines.</span></p>

<p class=MsoNormal style='margin-right:11.85pt;text-align:justify;line-height:
normal'><span lang=EN style='font-size:12.0pt;font-family:"Calibri",sans-serif;
color:#303645'>  f. FAST Transaction Service: This includes the &quot;FAST
Transaction Service Fee&quot; that is applicable if You avail yourself of our
FAST services. This covers all FAST transactions executed on Our platform,
including load/pay out to any bank account.</span></p>



<p class=MsoNormal style='margin-right:11.85pt;text-align:justify;line-height:
normal'><span lang=EN style='font-size:12.0pt;font-family:"Calibri",sans-serif;
color:#303645'>“Unit Transactional Limit” refers to the maximum amount that can
be transacted in a single transaction. It sets the upper boundary for the value
of the transaction, helping to manage risk, ensure compliance, and mitigate
potential fraudulent or illicit activities associated with the specified unit
or instrument.</span></p>



<p class=MsoNormal style='margin-right:11.85pt;text-align:justify;line-height:
normal'><span lang=EN style='font-size:12.0pt;font-family:"Calibri",sans-serif;
color:#303645'>&quot;Unload card&quot; refers to the process of removing or
deducting funds from a payment card. It involves reducing the available balance
on the card by initiating a transaction to transfer or spend the funds
elsewhere. Unloading a card can be done through various means, such as making
purchases, withdrawing cash, or transfer of funds back to account. This process
allows the cardholder to utilize the remaining funds on the card or to manage
their finances.</span></p>



<p class=MsoNormal style='margin-right:11.85pt;text-align:justify;line-height:
normal;border:none'><span lang=EN style='font-size:12.0pt;font-family:"Calibri",sans-serif;
color:#303645'>“Unload limit” refers to the maximum amount of funds that can be
unloaded, removed or deducted from the  Card, as set by Us and as governed by
applicable laws, regulations, or contractual agreements, to manage risk, ensure
compliance, and prevent potential illicit activities.</span></p>



<p class=MsoNormal style='margin-right:11.85pt;text-align:justify;line-height:
normal'><span lang=EN style='font-size:12.0pt;font-family:"Calibri",sans-serif;
color:#303645'>“Virtual Card”: A virtual card is a digital representation of
the card used for online transactions, offering convenience and security
without the need for a physical card. It has card details that can be used to
make electronic payments and is subject to the same legal and regulatory
standards as physical cards.</span></p>



<p class=MsoNormal style='margin-right:11.85pt;text-align:justify;line-height:
normal'><span lang=EN style='font-size:12.0pt;font-family:"Calibri",sans-serif;
color:#303645'>“Volume Limit”, refers to the maximum quantity or value of
payment transactions allowed within a specific timeframe, ensuring compliance,
managing risk, and preventing potential fraud or abuse in the payment system.</span></p>





<p class=MsoNormal style='margin-right:11.85pt;text-align:justify;line-height:
normal'><b><span lang=EN style='font-size:12.0pt;font-family:"Calibri",sans-serif;
color:#374151'>The Terminology used on this page is for descriptive purposes
and should not be construed as having the same meaning as the legal Definitions
outlined in the Agreement.</span></b></p>

<p class=MsoNormal style='margin-right:11.85pt;text-align:justify;line-height:
normal'><b><span lang=EN style='font-size:12.0pt;font-family:"Calibri",sans-serif;
color:#374151'>&nbsp;</span></b></p>

<p class=MsoNormal style='margin-right:11.85pt;text-align:justify;line-height:
normal'><b><span lang=EN style='font-size:12.0pt;font-family:"Calibri",sans-serif;
color:#374151'>In the event of a conflict between the Terminology used here and
the Definitions provided in the Agreement, We reserve the right to determine
which interpretation shall prevail and be applicable.</span></b></p>

<p class=MsoNormal style='margin-right:11.85pt;text-align:justify;line-height:
normal'><b><span lang=EN style='font-size:12.0pt;font-family:Roboto;color:#374151'>&nbsp;</span></b></p>

<p class=MsoNormal style='margin-right:11.85pt;text-align:justify;line-height:
normal'><b><span lang=EN style='font-size:12.0pt;font-family:Roboto;color:#374151'>​​</span></b><b><span
lang=EN style='font-size:12.0pt;font-family:"Calibri",sans-serif;color:#374151'>We
modify this page to align with evolving factors, including Regulations, Network
rules, Platform enhancements, cybersecurity measures, or the introduction of
new Platform features or Services. We recommend visiting this site regularly to
stay informed about any updates. </span></b></p>

<p class=MsoNormal style='margin-right:11.85pt;text-align:justify;line-height:
normal'><b><span lang=EN style='font-size:12.0pt;font-family:"Calibri",sans-serif;
color:#374151'>&nbsp;</span></b></p>

<p class=MsoNormal style='margin-right:11.85pt;text-align:justify;line-height:
normal'><b><span lang=EN style='font-size:12.0pt;font-family:"Calibri",sans-serif;
color:#374151'>&nbsp;</span></b></p>

<p class=MsoNormal style='margin-right:11.85pt;text-align:justify;line-height:
normal'><b><span lang=EN style='font-size:12.0pt;font-family:"Calibri",sans-serif;
color:#374151'>This page was updated on June 1, 2023</span></b></p>

<p class=MsoNormal style='margin-right:11.85pt;text-align:justify;line-height:
normal'><b><span lang=EN style='font-size:12.0pt;font-family:"Calibri",sans-serif;
color:#374151'>&nbsp;</span></b></p>

</div>
</div>
</div>
</div>
