<app-header-top></app-header-top>
<div class="headerbottom" id="myHeader">
    <nav id="navbar_top" class="navbar navbar-expand-md navbar-light">
        <div class="container">
            <a class="navbar-brand" href="/"><img src="assets/images/logo.png" alt="Match move" /></a>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#mainnav"
                aria-controls="mainnav" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse" id="mainnav">
                <ul class="navbar-nav ml-auto">
                    <li><a href="#">Solutions</a> <i class="fa fa-angle-down" aria-hidden="true"></i>
                        <div class="subnav">
                            <ul>
                                <h3>Spend</h3>
                                <li><a href="/virtual-account.html">Virtual Account</a></li>
                                <hr />
                                <h3>Send</h3>
                                <li><a href="/remittance.html">International Remittance</a></li>
                                <hr />
                                <h3>Lend</h3>
                                <li><a href="/loan-disbursement.html">Loan Disbursement</a></li>
                            </ul>
                            <ul>
                                <h3>Use Cases</h3>
                                <li><a href="/corporate-expense-management.html">Expense Management</a></li>
                                <li><a href="/digital-banking.html">Digital Banking</a></li>
                                <li><a href="/loyalty-programs.html">Loyalty</a></li>
                                <li><a href="/send-money-overseas.html">Send Money Overseas</a></li>
                                <li><a href="/lending-and-repayment.html">Lending And Repayment</a></li>
                                <li><a href="/lending.html">SME Lending</a></li>
                            </ul>
                        </div>
                    </li>
                    <li><a href="/developers">Developers</a></li>
                    <li><a href="#">Company</a> <i class="fa fa-angle-down" aria-hidden="true"></i>
                        <div class="subnav subnavsmall">
                            <ul>
                                <li><a href="/about-us">About Us</a></li>
                                <li><a href="/media">Media</a></li>
                                <li><a href="/contact-us">Contact Us</a></li>
                                <h3 class="mt-3">Legal</h3>
                                <li><a href="/terms">Terms & Conditions</a></li>
                                <li><a href="/privacy">Privacy Policy</a></li>
                            </ul>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</div>
<script type="text/javascript">
    /* <![CDATA[ */
    cli_cookiebar_settings = '{"animate_speed_hide":"500","animate_speed_show":"500","background":"#FFF","border":"#b1a6a6c2","border_on":false,"button_1_button_colour":"#000","button_1_button_hover":"#000000","button_1_link_colour":"#fff","button_1_as_button":true,"button_1_new_win":false,"button_2_button_colour":"#333","button_2_button_hover":"#292929","button_2_link_colour":"#444","button_2_as_button":false,"button_2_hidebar":false,"button_3_button_colour":"#000","button_3_button_hover":"#000000","button_3_link_colour":"#fff","button_3_as_button":true,"button_3_new_win":false,"button_4_button_colour":"#000","button_4_button_hover":"#000000","button_4_link_colour":"#62a329","button_4_as_button":false,"font_family":"inherit","header_fix":false,"notify_animate_hide":true,"notify_animate_show":false,"notify_div_id":"#cookie-law-info-bar","notify_position_horizontal":"right","notify_position_vertical":"bottom","scroll_close":false,"scroll_close_reload":false,"accept_close_reload":false,"reject_close_reload":false,"showagain_tab":false,"showagain_background":"#fff","showagain_border":"#000","showagain_div_id":"#cookie-law-info-again","showagain_x_position":"100px","text":"#000","show_once_yn":false,"show_once":"10000","logging_on":false,"as_popup":false,"popup_overlay":false,"bar_heading_text":"","cookie_bar_as":"banner","popup_showagain_position":"bottom-right","widget_position":"left"}';
/* ]]> */
</script>
