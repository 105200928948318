import {DOCUMENT, isPlatformBrowser} from '@angular/common';
import {Component, Inject, Input, OnInit, PLATFORM_ID} from '@angular/core';
import {HeaderTopComponent} from '../header-top/header-top.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input() HeaderTopComponent?: HeaderTopComponent;

  constructor(@Inject(DOCUMENT) private document: Document, @Inject(PLATFORM_ID) private platformId: any) {
  }

  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) window.onscroll = function () {
      myFunction()
    };
    const header = this.document.getElementById("myHeader");
    const sticky = header.offsetTop;

    function myFunction() {
      if (window.scrollY > sticky) {
        document.getElementById('navbar_top').classList.add('sticky');
        document.body.style.paddingTop = sticky + 'px';
      } else {
        document.getElementById('navbar_top').classList.remove('sticky');
        document.body.style.paddingTop = '0';
      }
    }
  }

  ngOnInit(): void {
  }

}
